import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { OrganizationType } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";

type Props = ReturnType<typeof mapStateToProps> & {
  projectIds: number[];
  setProjectsIds: (...args: any[]) => void;
};

const SelectProject = (props: Props) => {
  const [post, setPost]: $TSFixMe = useState([]);

  const stateChange = (checked: boolean, post1: OrganizationType) => {
    let idx = props.projectIds.indexOf(post1.id);
    let temp = [...props.projectIds];
    idx > -1 ? temp.splice(idx, 1) : temp.push(post1.id);
    props.setProjectsIds(temp);
  };

  useEffect(() => {
    setPost(props.projectList?.result ?? []);
  }, []);

  return (
    <>
      <Label className="modlabel modinpulabel modinpulabel">
        Select Project <span className="required"> *</span>
      </Label>
      <div className="select-project">
        <div className="adminTable proTab d-flex flex-wrap">
          {post && post.length > 0 && post.map((p: $TSFixMe, index: number) => {
            return (
              <FormGroup check className="w-50" key={index} >
                <Label className="customcamera" check>
                  <Input
                    type="checkbox"
                    checked={props.projectIds.includes(p.id)}
                    onChange={(e) => stateChange(e.target.checked, p)}
                  />
                  {p.name}
                </Label>
              </FormGroup>
            );
          })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  projectList: state.dropdownList.dropdowns.projectListDD,
});

export default connect(mapStateToProps, {})(SelectProject);
