import React, { useContext } from "react";
import { convertKilometerToMile } from "../../../../../../../services/common.functions.services";
import { measurement } from "../../../../../../../constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

function Journey(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const idling = (props?.journey?.idealtime * 100) / (props?.journey.drivingtime + props?.journey?.idealtime);
  return (
    <div className={`${selectedTheme} d-flex flex-wrap justify-content-center`}>
      <div className="mini-data-card journey-card">
        <div className='inner-card'>
          <p className='data-text mb-1'>
            {props?.journey.drivingtime && props?.journey?.idealtime ? ((props?.journey?.idealtime * 100) / (props?.journey.drivingtime + props?.journey?.idealtime)).toFixed(2) : 0}%</p>
          <p className='heading mb-0'>
            Idling
          </p>
        </div>
      </div>
      <div className="mini-data-card journey-card">
        <div className='inner-card'>
          <p className='data-text mb-1'>
            {props?.journey?.tripcount ?? 0}
          </p>
          <p className='heading mb-0'>Journey</p>
        </div>
      </div>
      <div className="mini-data-card journey-card">
        <div className='inner-card'>
          <p className='data-text mb-1'>{props?.journey.totaldistance ? props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(props?.journey.totaldistance) : props?.journey.totaldistance.toFixed(2) : 0}</p>
          <p className='heading mb-0'>Distance({`${props.user.measurmentUnit === measurement[1].value ? "miles" : "km"}`})</p>
        </div>
      </div>
      <div className="mini-data-card journey-card">
        <div className='inner-card'>
          <p className='data-text mb-1'>68/88</p>
          <p className='heading mb-0'>Active Total</p>
        </div>
      </div>
    </div>
  );
}

export default Journey;
