import { Label, Form } from "reactstrap";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import { colourStyles, Options } from "../../../constants";

const PageSize = ({ pageSize, handlePageSize }: $TSFixMe) => (
  <Form inline>
    <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0px' }}>
      <Label className="outtab">
        Display&nbsp;
        <Select
          className="commonTab"
          defaultValue={Options.find((option) => option.value === pageSize)}
          options={Options}
          styles={colourStyles}
          classNamePrefix={"filter-select-style"}
          isSearchable={false}
          onChange={(selectedOption: any) =>
            handlePageSize(selectedOption.value)
          }
          formatOptionLabel={(option: any) => option.label} // Display the label instead of the value
        />
        &nbsp;Records Per Page
      </Label>
    </FormControl>
  </Form>
);

export default PageSize;
