import React, { useContext, useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Row, Col, Spinner } from "reactstrap";
import { NavDropdown } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ASSETS from "../../../assets";
import { LayoutContext } from "../../../context/LayoutContext";
import {
  getNotifications,
  notificationRead,
  notificationReadAll,
  removeAllNotifications,
  removeNotifications,
} from "../../../api/axios.resources";
import { convertTimestampToDate } from "../../../services/common.functions.services";
import { FaCheck } from "react-icons/fa";

export default function Notifications(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications]: $TSFixMe = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [notificationCount, setNotificationCount]: $TSFixMe = useState(0); // Add isLoading state
  const userDropdownRef: $TSFixMe = useRef(null);
  const notificationRefs: $TSFixMe = useRef({});

  const removeNotificationWithAnimation = async (id: $TSFixMe) => {
    return new Promise<void>((resolve) => {
      // Add the slide-right class to trigger the removal animation
      const notificationElement = notificationRefs.current[id];
      if (notificationElement) {
        notificationElement.classList.add("slide-right");
        notificationElement.classList.add("fade-out");
        // Wait for the animation to complete before resolving the Promise
        notificationElement.addEventListener("transitionend", () => {
          // Check if the element is still in the DOM and is a child of the expected container
          const parentContainer: $TSFixMe = document.getElementById(
            `notification-${id}`
          ); // Replace with the actual container ID
          if (parentContainer) {
            if (notificationElement.parentNode === parentContainer) {
              parentContainer.removeChild(notificationElement);
            }
          }
          // Resolve the Promise after the removal animation is complete
          resolve();
        });
      } else {
        // Resolve immediately if the element is not found
        resolve();
      }
    }).then(() => {
      // After the removal animation is complete, trigger slide-up animations for remaining notifications
      const notificationIds = Object.keys(notificationRefs.current);
      notificationIds.forEach((notificationId) => {
        const notificationElement = notificationRefs.current[notificationId];
        if (notificationElement && notificationId !== id) {
          // Add slide-up class to remaining notifications
          notificationElement.classList.add("slide-up");
        } else if (notificationElement) {
          // Remove slide-up class from the removed notification
          notificationElement.classList.remove("slide-up");
        }
      });
      // Call fetchNotifications to refresh the list
      // fetchNotifications();
    });
  };

  const removeNotification = async (id: $TSFixMe) => {
    try {
      const res: $TSFixMe = await removeNotifications(id);
      if (res.status === 200) {
        const newNotifications = notifications.filter(
          (notification: $TSFixMe) => notification.id !== id
        );
        await removeNotificationWithAnimation(id); // Wait for the animation to complete
        const notificationIds = Object.keys(notificationRefs.current);

        notificationIds.forEach((notificationId) => {
          const notificationElement = notificationRefs.current[notificationId];
          if (notificationElement) {
            notificationElement.classList.remove("slide-up");
          }
        });
        if (newNotifications.length < 5) {
          if (hasMore) {
            fetchNotifications(1);
          }
        }
        const temp2 = notifications.filter(
          (n: $TSFixMe) => n.id === id && n.read === false
        );
        if (temp2.length > 0) {
          setNotificationCount(notificationCount - 1);
        }
        setNotifications(newNotifications);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handelClearAllNotifications = async () => {
    try {
      const res: $TSFixMe = await removeAllNotifications();
      if (res.status === 200) {
        setNotifications([]);
        setNotificationCount(0);
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const res = await notificationReadAll();
      if (res.status === 200) {
        const temp = notifications.map((n: $TSFixMe) => ({ ...n, read: true }));
        setNotifications([...temp]);
        setNotificationCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleReadNotification: $TSFixMe = async (
    id: number,
    read: boolean
  ) => {
    try {
      if (read) {
        return;
      }
      const res = await notificationRead(id);
      if (res.status === 200) {
        const temp = notifications.map((n: $TSFixMe) =>
          n.id === id ? { ...n, read: true } : n
        );
        setNotificationCount(notificationCount - 1);
        setNotifications([...temp]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to render each notification item
  const renderNotification = (notification: $TSFixMe, index: $TSFixMe) => (
    <div
      className={`tabLink notification-item`}
      key={`notification-${notification?.id}+${index}`}
      id={`notification-${notification?.id}`}
      ref={(element) => {
        notificationRefs.current[notification?.id] = element;
      }}
    >
      <Row className="border-bottom-1 m-0">
        <div
          className={`${selectedTheme} notificationCol ${notification.read ? "notification-read" : "cursor-pointer"
            } col d-flex cursor-default`}
          onClick={() =>
            handleReadNotification(notification.id, notification.read)
          }
        >
          <img alt="user" src={ASSETS.ABBOTT} className="notificationImage" />
          <span className="activestatus activestatusorange"></span>
          <Col className="leftdiv pr-0">
            <div className="notificationRow1">{notification?.title}</div>
            <div className="notificationRow2">{notification?.message}</div>
            <div className="notificationRow3">
              {notification?.createdOn &&
                convertTimestampToDate(
                  props.user?.timeZone?.zoneId,
                  notification?.createdOn,
                  "MMM DD, hh:mm a"
                )}
            </div>
          </Col>
          <div className={`${selectedTheme} closeNotif`}>
            <CancelIcon
              onClick={() => removeNotification(notification.id)}
              className={`closeNotifico cursor-pointer`}
            />
          </div>
        </div>
      </Row>
      <div className="fading-line"></div>
    </div>
  );

  const fetchNotifications = async (pageNo?: $TSFixMe) => {
    try {
      const data = {
        no: pageNo ? pageNo : page, // Adjust how you get the page number here
        size: 10,
      };

      // Replace this with your actual API call using getNotification function
      const res = await getNotifications(data);

      const newNotifications = res?.result?.notifications; // Replace with your API data structure
      setNotificationCount(res.result.unreadCount);
      if (newNotifications.length !== 0) {
        setNotifications((prev: $TSFixMe) => {
          // Create a set of existing notification IDs for quick lookup
          const existingNotificationIds = new Set(
            prev.map((notification: $TSFixMe) => notification.id)
          );

          // Filter out new notifications with existing IDs
          const uniqueNewNotifications = newNotifications.filter(
            (notification: $TSFixMe) =>
              !existingNotificationIds.has(notification.id)
          );

          // Concatenate the unique new notifications with the existing notifications
          const mergedNotifications = [...prev, ...uniqueNewNotifications];
          return mergedNotifications;
        });
      }

      // Check if there are more pages to load
      if (newNotifications.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading && props.user.userId !== 0) {
      setIsLoading(true);
      fetchNotifications();
    }
  }, [page, props.user.userId]);

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleOutsideClick = (e: $TSFixMe) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div ref={userDropdownRef}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        ref={userDropdownRef}
        onClick={() => setOpen(!open)}
        className="header-icons-link z-9999"
      >
        <NotificationsNoneOutlinedIcon className="header-icons" />
        <span className="count-label">{notificationCount}</span>
      </Typography>
      {/* @ts-expect-error */}
      <NavDropdown className="notifMod" id="mouse-over-popover" show={open}>
        <button className="notificationButton">
          <ArrowDropUpIcon className="arrowIcon" fontSize="large" />
        </button>
        <div className="notificationCard">
          <Row className={`notificationRow ${selectedTheme}`}>
            <Col className="notification-text m-0">
              Notifications{` (${notificationCount})`}
              {notifications.length > 1 &&
                <button
                  onClick={() => handleMarkAllAsRead()}
                  className={`mark-as-read p-2`}
                >
                  <span className="pr-1">
                    <FaCheck />
                  </span>
                  Mark all as read
                </button>
              }
            </Col>
          </Row>
          <div className="nti-dropdown scr" id="scrollableDiv">
            <InfiniteScroll
              dataLength={notifications.length}
              next={() => setPage(page + 1)} // Load more data when scrolling
              hasMore={hasMore && !isLoading} // Prevent loading when an API call is in progress
              loader={
                // Loading indicator
                isLoading && (
                  <Spinner
                    color="primary"
                    size="sm"
                    className="mx-auto d-block"
                  />
                )
              } // Loading indicator
              scrollableTarget="scrollableDiv"
              className="overflow-hidden"
            >
              {notifications.length ? (
                notifications.map((notification: $TSFixMe, index: $TSFixMe) =>
                  renderNotification(notification, index)
                )
              ) : (
                <div className="notificationRow2 text-center p-2">
                  No Notifications left
                </div>
              )}
            </InfiniteScroll>
          </div>
          {notifications.length !== 0 && (
            <Row
              className={`justify-content-center notificationRow ${selectedTheme} clearNotif`}
              onClick={() => handelClearAllNotifications()}
            >
              Clear All Notifications
            </Row>
          )}
        </div>
      </NavDropdown>
    </div>
  );
}
