import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { VideoRequestReport } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";

interface VideoRequestReportTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & VideoRequestReportTableProps;

const VideoRequestReportTab = (props: Props) => {
  const [videoRequestReportsList, setVideoRequestReportsList] = useState<
    VideoRequestReport[]
  >([]);

  const videoRequestReportColumns = [
    { title: " Organization", data: "orgnizationname" },
    { title: "Project", data: "panicevent" },
    { title: "Vehicle Reg#", data: "vehicleregistrationno" },
    { title: "Video Requests", data: "customrequesttype" },
    { title: "High Events", data: "highevent" },
    { title: "Low Events", data: "lowevent" },
    {
      title: "Latest High Events", data: "latestHighEvent",
      format: (data: $TSFixMe) => data?.latestHighEvent ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.latestHighEvent,
          null,
          props.user.timeFormat
        ) : "N/A",
    },
  ];

  useEffect(() => {
    setVideoRequestReportsList(props.videoRequestReports.result ? props.videoRequestReports.result.data : []);
    return () => { };
  }, [props.videoRequestReports]);

  return (
    <>
      <DataTable
        data={videoRequestReportsList}
        columns={videoRequestReportColumns}
        pageData={props.videoRequestReports?.result?.page}
        totalRecrods={props.videoRequestReports?.result?.totalRecord}
        bordered
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

//export default VideoRequestReportTab;
const mapStateToProps = (state: RootState) => ({
  videoRequestReports: state.dataStore.videoRequestReports,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoRequestReportTab);
