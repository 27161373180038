import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  getDeviceListDDVideoRequest,
  getProjectListDDVideoRequest,
} from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { toast } from "react-toastify";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import usePermission from "../../../../../../../constants/usePermission";
import { convertDateToTimestamp } from "../../../../../../../services/common.functions.services";
import { requested_video } from "../../../../../../../api/axios.resources";
import ASSETS from "../../../../../../../assets";
import { eventsVideosValidationSchema } from "../../../../../../../constants";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { endLoading, isRequestVideo, startLoading } from "../../../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";

const { RangePicker } = DatePicker;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const initialState = {
  projectId: null,
  vehicleRegNo: null,
  selectedDate: null,
  c1: true,
  c2: false,
  c3: false,
  c4: false,
  c5: false,
};

const RequestVideo = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [selectedFilter, setSelectedFilter] = useState({
    projectId: null,
    vehicleRegNo: null,
    selectedDate: null,
    c1: true,
    c2: false,
    c3: false,
    c4: false,
    c5: false,
  });
  const requestVideoWrite = usePermission("REQUEST_VIDEO_WRITE");

  const handleOnSubmit = async (values: $TSFixMe) => {
    try {
      let req = {
        c1: selectedFilter.c1,
        c2: selectedFilter.c2,
        c3: selectedFilter.c3,
        c4: selectedFilter.c4,
        c5: selectedFilter.c5,
        deviceId: selectedFilter.vehicleRegNo,
        startTimestamp:
          selectedFilter.selectedDate && selectedFilter.selectedDate[0]
            ? convertDateToTimestamp(
              selectedFilter?.selectedDate[0],
              props?.user?.timeZone?.zoneId
            )
            : null,
        endTimestamp:
          selectedFilter.selectedDate && selectedFilter.selectedDate[1]
            ? convertDateToTimestamp(
              selectedFilter?.selectedDate[1],
              props?.user?.timeZone?.zoneId
            )
            : null,
      };
      props.startLoading();
      const res = await requested_video(req);
      if (res.status === 202) {
        toast.success(res.message);
        setModal(false);
        setSelectedFilter(initialState);
        props.isRequestVideo(!props.isRequestedVideo);
        toggle();
      } else {
        toast.error(res.message);
      }
      props.endLoading();
      toggle();
    } catch (error) {
      console.log("error in requestVideo api", error);
    } finally {
      props.endLoading();
    }
  };

  const apiCall = async () => {
    props.startLoading();
    if (!props.dropdowns.projectListDDVideoRequest?.result && modal) {
      await props.getProjectList();
    }
    props.endLoading();
  };

  useEffect(() => {
    if (selectedFilter.projectId && modal) {
      props.getDeviceListDD(selectedFilter.projectId);
    }
    return () => { };
  }, [selectedFilter.projectId, modal]);

  useEffect(() => {
    apiCall();
  }, [modal]);

  return (
    <div className="modalheader">
      {requestVideoWrite && (
        <span onClick={toggle}>
          <FileDownloadOutlinedIcon className="nondropico cursor-pointer" />
        </span>
      )}
      <Modal
        isOpen={modal}
        toggle={() => {
          toggle();
          setSelectedFilter(initialState);
        }}
        className="videoModal"
      >
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Video Request</h5>
          <button
            onClick={() => {
              toggle();
              setSelectedFilter(initialState);
            }}
            className={`mult ${selectedTheme}-bg`}
          >
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={eventsVideosValidationSchema}
          enableReinitialize={true}
          initialValues={{
            projectId: "",
            vehicleReg: "",
            timeStamp: "",
            c1: true,
            c2: false,
            c3: false,
            c4: false,
            c5: false,
          }}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
        >
          {({ handleBlur, errors, touched, handleSubmit, setFieldValue }) => {
            return (
              <>
                <ModalBody>
                  <Row className="align-items-baseline">
                    <Col md={6}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">Project
                          <span className="required"> *</span>
                        </div>
                        <Select
                          className={`eventsFont mt-2 ${errors.projectId && touched.projectId
                            ? "is-invalid"
                            : ""
                            }`}
                          options={props.dropdowns.projectListDDVideoRequest?.result ?? []}
                          getOptionLabel={(option: $TSFixMe) => option.name}
                          getOptionValue={(option: $TSFixMe) =>
                            option.id.toString()
                          }
                          classNamePrefix={"custom-select-style"}
                          onChange={(option: $TSFixMe) => {
                            setFieldValue("projectId", option.id);
                            setSelectedFilter((prev) => {
                              return {
                                ...prev,
                                projectId: option.id,
                                vehicleRegNo: null,
                              };
                            });
                          }}
                          value={
                            selectedFilter.projectId
                              ? props.dropdowns.projectListDDVideoRequest?.result?.find(
                                (option: any) =>
                                  option.id === selectedFilter.projectId
                              )
                              : null
                          }
                          placeholder={"Select Project"}
                          onBlur={handleBlur("projectId")}
                        />
                        <ErrorMessage
                          name="projectId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">Vehicle Reg#
                          <span className="required"> *</span>
                        </div>
                        <Select
                          className={`eventsFont mt-2 ${errors.vehicleReg && touched.vehicleReg
                            ? "is-invalid"
                            : ""
                            }`}
                          placeholder="Vehicle Reg#"
                          options={props?.dropdowns?.deviceListDDVideoRequest.result || []}
                          getOptionLabel={(option: $TSFixMe) =>
                            option.name ?? option.otherValue1
                          }
                          classNamePrefix={"custom-select-style"}
                          getOptionValue={(option: $TSFixMe) =>
                            option.id.toString()
                          }
                          onChange={(option: $TSFixMe) => {
                            setFieldValue("vehicleReg", option.otherValue1);
                            setSelectedFilter((prev) => {
                              return {
                                ...prev,
                                vehicleRegNo: option.otherValue1,
                              };
                            });
                          }}
                          value={
                            selectedFilter.vehicleRegNo
                              ? props?.dropdowns?.deviceListDDVideoRequest?.result?.find(
                                (option: any) =>
                                  option.id === selectedFilter.vehicleRegNo
                              )
                              : null
                          }
                          onBlur={handleBlur("vehicleReg")}
                        />
                        <ErrorMessage
                          name="vehicleReg"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="align-items-baseline">
                    <FormGroup>
                      <div className="modlabel modinpulabel">
                        Request Date <span className="required"> *</span>
                      </div>
                      <Field
                        component={RangePicker}
                        name="selectedDate"
                        className={`rangeHei ${errors.timeStamp && touched.timeStamp
                          ? "is-invalid "
                          : ""
                          }`}
                        onChange={(date: $TSFixMe, dateString: $TSFixMe) => {
                          if (dateString[0] !== "") {
                            setSelectedFilter((prev: $TSFixMe) => {
                              return { ...prev, selectedDate: dateString };
                            });
                            setFieldValue("timeStamp", dateString);
                          } else {
                            setSelectedFilter((prev: $TSFixMe) => {
                              return { ...prev, selectedDate: null };
                            });
                            setFieldValue("timeStamp", "");
                          }
                        }}
                        onBlur={handleBlur("timeStamp")}
                        format="MM-DD-YYYY HH:mm:ss"
                        showTime
                        inputProps={{
                          onBlur: handleBlur("timeStamp"),
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                      <ErrorMessage
                        name="timeStamp"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Row>
                  <div className="modalheader">Channel</div>
                  <FormGroup row className="mt-2">
                    <Col md={4}>
                      <FormGroup check>
                        <Label className="customcamera" check>
                          <Input
                            className="input-center"
                            autocomplete="disable"
                            type="checkbox"
                            id="customcamera"
                            disabled
                            checked={selectedFilter.c1}
                          />
                          Main camera
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup check>
                        <Label className="customcamera" check>
                          <Input
                            onClick={() => {
                              setSelectedFilter((prev) => {
                                return { ...prev, c2: !prev.c2 };
                              });
                            }}
                            className="input-center"
                            autocomplete="disable"
                            type="checkbox"
                            id="checkbox2"
                            checked={selectedFilter.c2}
                          />
                          Second camera
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup check>
                        <Label className="customcamera" check>
                          <Input
                            onClick={() => {
                              setSelectedFilter((prev) => {
                                return { ...prev, c3: !prev.c3 };
                              });
                            }}
                            className="input-center"
                            autocomplete="disable"
                            type="checkbox"
                            id="checkbox2"
                            checked={selectedFilter.c3}
                          />
                          Third camera
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup check>
                        <Label className="customcamera" check>
                          <Input
                            onClick={() => {
                              setSelectedFilter((prev) => {
                                return { ...prev, c4: !prev.c4 };
                              });
                            }}
                            className="input-center"
                            autocomplete="disable"
                            type="checkbox"
                            id="checkbox2"
                            checked={selectedFilter.c4}
                          />
                          Fourth camera
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup check>
                        <Label className="customcamera" check>
                          <Input
                            onClick={() => {
                              setSelectedFilter((prev) => {
                                return { ...prev, c5: !prev.c5 };
                              });
                            }}
                            className="input-center"
                            autocomplete="disable"
                            type="checkbox"
                            id="checkbox2"
                            checked={selectedFilter.c5}
                          />
                          Fifth camera
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </ModalBody>
                <ModalFooter className="footer-width">
                  <div className="footwidth">
                    <Button
                      className="popbtn datewidth btnBox"
                      onClick={() => {
                        toggle();
                        setSelectedFilter(initialState);
                      }}
                    >
                      <>CLOSE</>
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="footwidth">
                    <Button
                      className="popbtn datewidth btnBox"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {props.isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                      )}
                      REQUEST VIDEO
                    </Button>
                  </div>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  user: state.userSlice.user,
  isLoading: state.generalSlice.isLoading,
  isRequestedVideo: state.generalSlice.isRequestedVideo,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceListDD: (id: number) => dispatch(getDeviceListDDVideoRequest(id)),
  getProjectList: () => dispatch(getProjectListDDVideoRequest()),
  isRequestVideo: (data: $TSFixMe) => dispatch(isRequestVideo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestVideo);
