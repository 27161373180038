import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import DataTable from "../../../Common/DataTable/index";
import { DriverStatistics } from "../../../../../global.types";
import { ROUTES } from "../../../../routes/routes";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { convertTimestampToDate, encrypt } from "../../../../services/common.functions.services";

interface DriverStatisticsTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
  recordsPerPage: number;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & DriverStatisticsTableProps;

const DriverStatisticsTab = (props: Props) => {
  const [driverStatisticsList, setDriverStatisticsList] = useState<
    DriverStatistics[]
  >([]);

  const DriverStatisticsActionBtns = (data: $TSFixMe) => {
    const encryptedParams: $TSFixMe = encrypt({
      driverId: data?.data?.id,
      orgName: data?.data?.organizationname,
      projectName: data?.data?.projectname,
      name: data?.data?.name,
      score: data?.data?.score,
    });
    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.DRIVER_SCORE_CARD}/${encryptedParams}`} className="tabLink">
          <VisibilityOutlinedIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const driverStatisticsColumns = [
    { title: "Driver Name", data: "name" },
    { title: "Organization", data: "organizationname" },
    { title: "Project", data: "projectname" },
    {
      title: "Date",
      format: (data: $TSFixMe) => data.scoretime ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.scoretime,
          null,
          props.user.timeFormat
        ) : "",
    },
    { title: "Score", data: "score" },
    {
      title: "Action",
      format: (data: $TSFixMe) => <DriverStatisticsActionBtns data={data} />,
    },
  ];

  useEffect(() => {
    setDriverStatisticsList(props.driverStatistics.result ? props.driverStatistics.result.data : []);
    return () => { };
  }, [props.driverStatistics]);

  return (
    <>
      <DataTable
        data={driverStatisticsList}
        columns={driverStatisticsColumns}
        bordered
        hover={true}
        responsive={true}
        pageData={props.driverStatistics?.result?.page}
        totalRecrods={props.driverStatistics?.result?.totalRecord}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
      />
    </>
  );
};

//export default DriverStatisticsTab;

const mapStateToProps = (state: RootState) => ({
  driverStatistics: state.dataStore.driverStatistics,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverStatisticsTab);
