import { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomInput from "../../../../../../../../../Common/CustomInput/index";
import {
  commonSchema,
  CommonConfigurationAddValidationType,
} from "../../../../../../../../../../constants";
import ASSETS from "../../../../../../../../../../assets";
import { connect } from "react-redux";
import { Formik, useFormikContext } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { AddCommonConfigurationCode } from "../../../../../../../../../../redux/reducers/Management&Settings/commonConfiguration/commonConfiguration.actions";
import {
  getDeviceListDD,
  getDeviceListForProj,
} from "../../../../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../../../../../../../redux/reducers/general/general.actions";
import { TOAST_MSG } from "../../../../../../../../../../constants/toast.constants";
import { RootState } from "../../../../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../../../../redux/store/store";
import { AxiosError } from "axios";
import {
  AddCommonConfiguration, verifyDeviceType
} from "../../../../../../../../../../../global.types";
import moment from "moment";
import {
  assignConfiguration, verifyDevice,
} from "../../../../../../../../../../api/axios.resources";
import { LayoutContext } from "../../../../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type optionType = {
  label: string;
  value: number;
};

const AssignDevices = (props: Props) => {
  const [imeiOptions, setImeiOptions] = useState<optionType[]>([]);
  const [commonConfigurationList, setCommonConfigurationList] = useState<any>(
    []
  );
  const [imeis, setImeis] = useState<any>([]);
  const [projValue, setProjValue] = useState<any>(null);
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const [verifiedDevices, setVerifiedDevices] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggle = () => setModal(!modal);

  const apiCall = async (id: number) => {
    props.startLoading();
    if (id) {
      await props.getDeviceListForProj(id);
    }
    props.endLoading();
  };
  const handleSelectedFile = async (file: File | null) => {
    if (!file) {
      // Handle the case where no file is selected
      return;
    }
    setImeis([]);
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const result = event.target.result;
        if (typeof result === "string") {
          const csvData = result;
          const rows = csvData.trim().split("\n");
          const headers = rows[0].trim().split(","); // Trim the header

          // Find the index of "deviceId" in the headers
          const deviceIdIndex = headers.indexOf("deviceId");
          if (deviceIdIndex === -1) {
            toast.error("deviceId column not found in headers.");
            return;
          }
          const imeiArray = [];
          for (let i = 1; i < rows.length; i++) {
            const values = rows[i].split(",");
            if (values.length !== headers.length) {
              console.error(
                `Row ${i + 1} has a different number of values than headers.`
              );
              continue;
            }

            const imei = values[deviceIdIndex]?.trim(); // Safely access and trim the value
            if (imei) {
              imeiArray.push(imei); // Don't convert to Number here
            } else {
              console.error(`IMEI value not found in row ${i + 1}`);
            }
          }
          setImeis(imeiArray);
        }
      }
    };

    reader.onerror = (event) => {
      console.error("File reading error:", event);
    };

    reader.readAsText(file);
  };

  const fileUploadHandler = async (setFieldValue: any) => {
    setIsLoading(true);
    if (imeis) {
      let req: verifyDeviceType = {
        imeis: imeis,
        projectId: projValue,
      };
      try {
        let res: $TSFixMe = await verifyDevice(req).then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            toast.success(res.message);
            // Successfully verified devices, update the state with verified devices
            const verifiedImeis = res?.result;
            setVerifiedDevices(verifiedImeis);
            // You use verifiedImeis to automatically select verified devices in UI
            // Update your form values accordingly
            const selectedDevices = imeiOptions.filter((imei) =>
              verifiedImeis.includes(imei.value)
            );
            if (selectedDevices) {
              setFieldValue("deviceList", selectedDevices);
            }
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setVerifiedDevices([]);
    setImeis([]);
  }, [modal]);

  const filterConfiguration = async () => {
    if (props.commonConfiguration) {
      const filteredConfig: optionType[] = await props.commonConfiguration
        .filter((config: $TSFixMe) => config.status === true)
        .map((config: $TSFixMe) => ({
          label: config.name,
          value: config.id,
        }));

      setCommonConfigurationList(filteredConfig);
    }
  };

  const handleAddConfiguration = async (
    values: CommonConfigurationAddValidationType
  ) => {
    try {
      props.startLoading();
      await toast.promise(
        assignConfiguration({
          commonConfigId: values.configuration.value,
          imeis: values?.deviceList?.map((item) => item.value),
          projectId: values.project.value,
          status: values.status,
        }) as any,
        {
          pending: TOAST_MSG.LOADING,
          success: TOAST_MSG.COMMON_CONFIGURATION_SUCCESS,
        }
      );
      toggle();
    } catch (error) {
      console.log("error in configuration Add api", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    apiCall(projValue);
  }, [projValue]);

  useEffect(() => {
    if (props.dropdowns.device_proj && props.dropdowns.device_proj.result) {
      let newImeiOptions: optionType[] = props.dropdowns.device_proj.result.map(
        (device: $TSFixMe) => ({
          label: device.otherValue1,
          value: device.otherValue1,
        })
      );
      setImeiOptions(newImeiOptions);
    }
  }, [props.dropdowns.device_proj]);

  useEffect(() => {
    filterConfiguration();
  }, [props.commonConfiguration]);

  return (
    <>
      <Button onClick={toggle} className={`${selectedTheme}`}>
        Assign Devices
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <Formik
          validationSchema={commonSchema}
          enableReinitialize={true}
          initialValues={{
            deviceList: [],
            configuration: {
              label: "",
              value: 0,
            },
            project: {
              label: "",
              value: 0,
            },
            status: true,
          }}
          onSubmit={handleAddConfiguration}
        >
          {({
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            touched,
            errors,
          }) => {
            const fileUploadHandlerLogic = async () => {
              // Pass setFieldValue as an argument
              fileUploadHandler(setFieldValue);
            };

            return (
              <>
                <ModalHeader className={`mod ${selectedTheme}-bg`}>
                  <h5 className="modlabel modheadcont">Assign Devices</h5>
                  <button
                    className={`mult ${selectedTheme}-bg`}
                    onClick={toggle}
                  >
                    <img src={ASSETS.MULTIPLY} alt="multiply" />
                  </button>
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <div className="modlabel modinpulabel">
                          Select Project
                          <span className="required"> *</span>
                        </div>
                        <Select
                          placeholder="Select Project"
                          className={`
                          ${touched?.project && errors?.project
                              ? "requireSelect "
                              : ""
                            }
                        `}
                          options={
                            props.dropdowns?.projectListDD?.dropDown ?? []
                          }
                          getOptionLabel={(option) => option?.label}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          getOptionValue={(option) => option?.value.toString()}
                          value={values.project.value ? values.project : null}
                          onChange={(option) => {
                            setFieldValue("project", option);
                            setFieldValue("deviceList", []);
                            setProjValue(option?.value);
                          }}
                          maxMenuHeight={180}
                          onBlur={handleBlur("project")}
                        />
                        {touched?.project && errors?.project ? (
                          <div className="validate">
                            {errors?.project ? "required" : ""}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="modlabel modinpulabel">
                    File Upload
                  </div>
                  <Row className="align-items-start">
                    <Col xs={12} md={8}>
                      <CustomInput
                        id="fileInput1"
                        setcheckBox={(val: any) => {
                        }}
                        onFileSelect={handleSelectedFile}
                      />
                    </Col>
                    <Col md={4}>
                      <Button
                        disabled={imeis.length === 0}
                        className={`${selectedTheme} modalbtn h-35-px`}
                        onClick={fileUploadHandlerLogic}
                      >
                        {isLoading ? (
                          <Spinner color="light" size="sm" className="mr-2" />
                        ) : (
                          ""
                        )}
                        Upload
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mt-2">
                      <FormGroup>
                        <Label className="modlabel modinpulabel">
                          Select Devices
                          <span className="required"> *</span>
                        </Label>
                        <Select
                          className={
                            errors?.deviceList && touched?.deviceList
                              ? "requireSelect"
                              : ""
                          }
                          isMulti={true}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          placeholder="Device List"
                          closeMenuOnSelect={false}
                          options={imeiOptions || []}
                          maxMenuHeight={180}
                          getOptionLabel={(option: $TSFixMe) => option.label}
                          getOptionValue={(option: $TSFixMe) => option.value}
                          value={values.deviceList ? values.deviceList : null}
                          onChange={(option: $TSFixMe) => {
                            setFieldValue("deviceList", option);
                          }}
                          onBlur={handleBlur("deviceList")}
                        />
                        {touched?.deviceList && errors?.deviceList ? (
                          <div className="validate">
                            {errors?.deviceList ? "required" : ""}
                          </div>
                        ) : null}
                      </FormGroup>
                      {/* <IMEINumber imeiOptions={imeiOptions} setIMEI={setIMEI} /> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="modlabel modinpulabel">
                          Select Configuration
                          <span className="required"> *</span>
                        </div>
                        <Select
                          placeholder="Configuration"
                          className={` ${touched.configuration && errors.configuration
                            ? "requireSelect"
                            : ""
                            }`}
                          options={commonConfigurationList ?? []}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          getOptionLabel={(option: $TSFixMe) => option.label}
                          getOptionValue={(option: $TSFixMe) => option.value}
                          value={
                            values.configuration.value
                              ? values.configuration
                              : null
                          }
                          onChange={(option: $TSFixMe) => {
                            setFieldValue("configuration", option);
                          }}
                          maxMenuHeight={180}
                          onBlur={handleBlur("configuration")}
                        />
                        {touched.configuration && errors.configuration ? (
                          <div className="validate">
                            {errors.configuration ? "required" : ""}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <div className="modlabel modinpulabel">Status</div>
                    </Col>
                    <Col md={6}>
                      <RadioGroup
                        onChange={(e) =>
                          setFieldValue(
                            "status",
                            e.target.value === "true" ? true : false
                          )
                        }
                        className="radioStatus"
                        onBlur={handleBlur("status")}
                        aria-label="status"
                        defaultValue={true}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          checked={values.status}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value={false}
                          checked={!values.status}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="footer-width">
                  <div className="footwidth">
                    <Button
                      // disabled={!isValid}
                      type="submit"
                      // @ts-expect-error
                      onClick={handleSubmit}
                      className="popbtn datewidth btnBox"
                    >
                      {props.isLoading && (
                        <Spinner color="dark" size="sm" className="mr-2" />
                      )}
                      <>SAVE</>
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="footwidth">
                    <Button
                      onClick={toggle}
                      className="popbtn datewidth btnBox"
                    >
                      <>CANCEL</>
                    </Button>
                  </div>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  dropdowns: state.dropdownList.dropdowns,
  commonConfiguration:
    state.commonConfigurationsData.commonConfigurations.result,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceListForProj: (id: number) => dispatch(getDeviceListForProj(id)),
  getDeviceListDD: () => dispatch(getDeviceListDD()),
  AddCommonConfigurationCode: (data: AddCommonConfiguration) =>
    dispatch(AddCommonConfigurationCode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignDevices);
