export const GET_MANAGE_ROLES = "GET_MANAGE_ROLES";
export const GET_MANAGE_ROLES_SUCCESS = "GET_MANAGE_ROLES_SUCCESS";
export const GET_MANAGE_ROLES_ERROR = "GET_MANAGE_ROLES_ERROR";

export const ADD_MANAGE_ROLES = "ADD_MANAGE_ROLES";
export const ADD_MANAGE_ROLES_SUCCESS = "ADD_MANAGE_ROLES_SUCCESS";
export const ADD_MANAGE_ROLES_ERROR = "ADD_MANAGE_ROLES_ERROR";

export const EDIT_MANAGE_ROLES = "EDIT_MANAGE_ROLES";
export const EDIT_MANAGE_ROLES_SUCCESS = "EDIT_MANAGE_ROLES_SUCCESS";
export const EDIT_MANAGE_ROLES_ERROR = "EDIT_MANAGE_ROLES_ERROR";

export const DELETE_MANAGE_ROLES = "DELETE_MANAGE_ROLES";
export const DELETE_MANAGE_ROLES_SUCCESS = "DELETE_MANAGE_ROLES_SUCCESS";
export const DELETE_MANAGE_ROLES_ERROR = "DELETE_MANAGE_ROLES_ERROR";

export const GET_RESOURCE_ROLE = "GET_RESOURCE_ROLE";
export const GET_RESOURCE_ROLE_SUCCESS = "GET_RESOURCE_ROLE_SUCCESS";
export const GET_RESOURCE_ROLE_ERROR = "GET_RESOURCE_ROLE_ERROR";
