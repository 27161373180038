import { call, takeLatest } from "redux-saga/effects";
import { API } from "../../../api/api.constants";
import { post } from "../../../services/axios.services";
import { onError, onSuccess, safe } from "../../store/saga/sagaHelper";
import { GET_LIVE_TRACKING, GET_MAP_MONITORING, GET_VEHICLE_JOURNEY } from "./map.types";

function* getMapMonitoring({ data, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(post, API.GPS_MONITORING, data);
  return response;
}

function* getLiveTracking({ data, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(post, API.LIVE_TRACKING, data);
  return response;
}

function* getVehicleJourney({ data, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(post, API.VEHICLE_JOURNEY, data);
  return response;
}

function* mapSaga() {
  yield takeLatest(GET_MAP_MONITORING, safe(onError, getMapMonitoring, onSuccess));
  yield takeLatest(GET_LIVE_TRACKING, safe(onError, getLiveTracking, onSuccess));
  yield takeLatest(GET_VEHICLE_JOURNEY, safe(onError, getVehicleJourney, onSuccess));
}

export default mapSaga;
