import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { dataVehicle } from "../../../../../../constants";
import { LayoutContext } from "../../../../../../context/LayoutContext";

function VehicleEvent(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const { options, series } = dataVehicle(selectedTheme, props.vehicleEvent);
  return (
    <div id="chart" className={selectedTheme}>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
}

export default VehicleEvent;
