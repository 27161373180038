import { AppActions } from "../../store/globalstore";

import { GET_LIVE_TRACKING_SUCCESS, GET_MAP_MONITORING_SUCCESS, GET_VEHICLE_JOURNEY_SUCCESS, RESET_MAP_DATA } from "./map.types";

interface InitialState {
  monitoring: any,
  liveTracking: any,
  vehicleJourney: any
}

// initial State
const initialState: InitialState = {
  monitoring: [],
  liveTracking: {},
  vehicleJourney: [],
};

export const mapReducer = (
  state: InitialState = initialState,
  action: AppActions
) => {
  switch (action.type) {
    case GET_MAP_MONITORING_SUCCESS:
      return {
        ...state,
        monitoring: action.payload.result,
      };
    case GET_LIVE_TRACKING_SUCCESS:
      return {
        ...state,
        liveTracking: action.payload.result,
      };
    case GET_VEHICLE_JOURNEY_SUCCESS:
      return {
        ...state,
        vehicleJourney: action.payload.result,
      };
    case RESET_MAP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
