import { Map, TileLayer, Marker, Popup, LayersControl } from "react-leaflet";
import { useEffect, useState } from "react";
import { calculateMapBounds, convertKilometerToMile, convertTimestampToDate, getCustomIcon } from "../../../../../../../services/common.functions.services";
import { MAP_DEFAULT_LAT_LONG, MAP_SCROLLWHEELZOOM, MAP_ZOOM, measurement } from "../../../../../../../constants";
import "leaflet/dist/leaflet.css"; // Import Leaflet's CSS
import "react-leaflet-markercluster/dist/styles.min.css"; // Import react-leaflet-markercluster's CSS
import MarkerClusterGroup from "react-leaflet-markercluster"; // Correctly import MarkerClusterGroup
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { getLocation, getMonitorMarkerData } from "../../../../../../../api/axios.resources";
import { NetworkSignal } from "../../../../../../Common/Map/NetworkSignal";

const { BaseLayer } = LayersControl;

function MonitoringLoc(props: $TSFixMe) {
  const [markersData, setMarkersData]: $TSFixMe = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [markerDataLoading, setMarkerDataLoading] = useState(false);
  const [markerDetails, setMarkerDetails]: $TSFixMe = useState({});
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGetMapData = async () => {
    if (props.markerData && props.markerData.length > 0) {
      const marker: $TSFixMe = props.markerData.map((m: $TSFixMe) => {
        return {
          position: [m.latitude, m.longitude],
          ...m
        }
      });
      setMarkersData(marker);
    } else {
      setMarkersData([]);
    }
  }

  const handleGetMarkerData = async (markerData: any): Promise<void> => {
    try {
      setLocation("")
      setMarkerDataLoading(true);
      const res: $TSFixMe = await getMonitorMarkerData(markerData?.deviceId);
      if (res.status === 200) {
        const marker: $TSFixMe = Object.keys(res.result).length > 0 && res.result
        getAddressFromLatLng(markerData?.latitude, markerData?.longitude)
        setMarkerDetails(marker);
      }
    } catch (error) {
      toast.error(`${(error as $TSFixMe).message}`);
    } finally {
      setMarkerDataLoading(false);
    }
  }

  const getAddressFromLatLng = async (latitude: number, longitude: number) => {
    try {
      setLoading(true);
      if (!latitude && !longitude) {
        setLocation("No address found");
        return;
      }
      const response = await getLocation(latitude, longitude);
      if (response) {
        setLocation(response.data.display_name);
      }
      if (response.data.error && !response.data.display_name) {
        setLocation(response.data.error);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setLocation("Error fetching address");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (markersData) {
      const bounds: $TSFixMe = calculateMapBounds(markersData);
      setMapBounds(bounds);
    }
  }, [markersData]);

  useEffect(() => {
    if (props.markerData) {
      handleGetMapData();
    }
    return () => { }
  }, [props.markerData])

  const MarkerPopUp = ({ marker }: $TSFixMe) => {
    return (
      <>
        {!markerDataLoading ?
          <div>
            <div>
              <b>Vehicle Reg#</b>: {markerDetails?.vehicleRegNo}
            </div>

            <div>
              <b>Last Seen</b> :  {marker?.timestamp
                ? convertTimestampToDate(
                  props.user.timeZone.zoneId,
                  marker?.timestamp,
                  null,
                  props.user.timeFormat
                )
                : "-"}
            </div>
            <div>
              <b>DeviceId</b> : {markerDetails?.deviceId}
            </div>
            <div>
              <b>Event Count</b> : {`high- ${markerDetails?.highEvent ? markerDetails?.highEvent : "0"}, low- ${markerDetails?.lowEvent ? markerDetails?.lowEvent : "0"}`}
            </div>
            <div>
              <b>Altitude</b>: {marker?.altitude}
            </div>
            <div>
              <b>Heading:</b> {marker?.heading ?? ""}
            </div>
            <div>
              <b>Speed:</b> {`${marker.speed ? props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(marker?.speed) : markersData?.speed : "0"}  ${props.user.measurmentUnit === measurement[1].value ? 'mph' : 'km/h'}`}
            </div>
            <div>
              <b className="mr-1">Network:</b> <NetworkSignal width={14} height={14} signalValue={markerDetails?.signalQuality ?? 0} />
            </div>
            <div>
              <b>Address</b> :  {location ? <span>{location}</span> :
                loading ?
                  <span className="ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 50 200 100" height="15px" width="35px">
                      <circle fill="#000" stroke="#000" stroke-width="2" r="15" cx="40" cy="100">
                        <animate attributeName="opacity" calcMode="spline" dur="1.5" values="1;0;1;"
                          keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4" />
                      </circle>
                      <circle fill="#000" stroke="#000" stroke-width="2" r="15" cx="100" cy="100">
                        <animate attributeName="opacity" calcMode="spline" dur="1.5" values="1;0;1;"
                          keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2" />
                      </circle>
                      <circle fill="#000" stroke="#000" stroke-width="2" r="15" cx="160" cy="100">
                        <animate attributeName="opacity" calcMode="spline" dur="1.5" values="1;0;1;"
                          keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0" />
                      </circle>
                    </svg>
                  </span> :
                  <span>N/A</span>}
            </div>
          </div>
          :
          <div className='text-center'>
            <Spinner color="dark" size="sm" />
          </div>
        }
      </>
    )
  }

  return (
    <Map
      className="mapLoc"
      center={markersData[0]?.position || MAP_DEFAULT_LAT_LONG}
      zoom={MAP_ZOOM}
      scrollWheelZoom={MAP_SCROLLWHEELZOOM}
      bounds={mapBounds || undefined}
    >
      <LayersControl position="topright">
        <BaseLayer checked name="Base Map">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </BaseLayer>
        <BaseLayer name="Humanitarian">
          <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
        </BaseLayer>
        <BaseLayer name="Satellite">
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        </BaseLayer>
        <MarkerClusterGroup>
          {markersData && markersData.length > 0 && markersData.map((marker: $TSFixMe, index: number) => {
            const carAngle = marker.heading || 0;
            const customIcon = getCustomIcon(carAngle);
            return (
              <Marker key={index} position={marker.position ?? []} icon={customIcon} onClick={() => handleGetMarkerData(marker)}>
                <Popup>
                  <MarkerPopUp marker={marker} />
                </Popup>
              </Marker>
            )
          })}
        </MarkerClusterGroup>
      </LayersControl>
    </Map>
  );
}

export default MonitoringLoc;
