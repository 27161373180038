import { useContext, useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Monitoring from "./Components/monitoring";
import LiveTracking from "./Components/liveTracking";
import VehicleJourney from "./Components/vehicleJourney";
import { LayoutContext } from "../../../../context/LayoutContext";
import { THEME_TYPE } from "../../../../constants";
import { connect } from "react-redux";
import { RootState } from "../../../../redux/reducers/rootReducer";
import MonitoringLoc from "./Components/monitoring/Loc";
import LiveTrackingLoc from "./Components/liveTracking/Loc";
import VehicleJourneyMap from "../../../Common/Map/VehicleJourneyMap";
import usePermission from "../../../../constants/usePermission";

type Props = ReturnType<typeof mapStateToProps>;

function MapCard(props: Props) {
  const [Selected, setSelected] = useState("Monitoring");
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = (selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) && 'cardcolor-light';
  const monitoringReadWrite = usePermission(["MONITORING_READ", "MONITORING_WRITE"]);
  const trackingReadWrite = usePermission(["LIVE_TRACKING_READ", "LIVE_TRACKING_WRITE"]);
  const vehicleReadWrite = usePermission(["VEHICLE_JOURNEY_READ", "VEHICLE_JOURNEY_WRITE"]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    if (monitoringReadWrite) {
      setSelected("Monitoring");
    } else if (trackingReadWrite) {
      setSelected("LiveTracking");
    } else if (vehicleReadWrite) {
      setSelected("VehicleJourney");
    }
    return () => { }
  }, [monitoringReadWrite, trackingReadWrite, vehicleReadWrite]);

  return (
    <>
      <Row className="mt-0">
        <Col>
          <Card className={`card-stats md-4 mb-xl-0 devi cardcolor ${cardColorType}`}>
            <CardBody>
              <Row className="mb-2">
                <Col>
                  <div className="HeaderLeft mapcardbtn">
                    {monitoringReadWrite &&
                      <button
                        className={`${selectedTheme} ${Selected === "Monitoring"
                          ? "maptoggle active"
                          : "maptoggle"
                          }`}
                        onClick={() => setSelected("Monitoring")}
                      >
                        Monitoring
                      </button>
                    }
                    {trackingReadWrite &&
                      <button
                        className={`${selectedTheme} ${Selected === "LiveTracking"
                          ? "maptoggle active"
                          : "maptoggle"
                          }`}
                        onClick={() => setSelected("LiveTracking")}
                      >
                        Live Tracking
                      </button>
                    }
                    {vehicleReadWrite &&
                      <button
                        className={`${selectedTheme} ${Selected === "VehicleJourney"
                          ? "maptoggle active"
                          : "maptoggle"
                          }`}
                        onClick={() => setSelected("VehicleJourney")}
                      >
                        Vehicle Journey
                      </button>
                    }
                  </div>
                </Col>
              </Row>
              {Selected === "Monitoring" && <Monitoring />}
              {Selected === "LiveTracking" && <LiveTracking />}
              {Selected === "VehicleJourney" && <VehicleJourney setSelectedProjectId={setSelectedProjectId} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card
            className={`card-stats md-4 mb-xl-0 cardcolor ${cardColorType}`}
          >
            <CardBody className="p-2 mapLoc-map-page">
              {Selected === "Monitoring" && <MonitoringLoc markerData={props?.map?.monitoring} user={props.user} />}
              {Selected === "LiveTracking" && <LiveTrackingLoc markerData={props?.map?.liveTracking} user={props.user} />}
              {Selected === "VehicleJourney" && <VehicleJourneyMap projectId={selectedProjectId} markerData={props?.map?.vehicleJourney} user={props.user} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.userSlice.user,
  map: state.map,
});

export default connect(mapStateToProps)(MapCard);
