import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useContext, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ASSETS from "../../../../../../../assets";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { Device } from "../../../../../../../../global.types";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: Device;
  };

const View = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <VisibilityOutlinedIcon onClick={toggle} className="dropico" />
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">View Device</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto'
        }} className="scr">
          <div className="rit mb-2">
            <div className="deviceclrhead ml-2">Project Detail</div>
          </div>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Select Project</div>
                <Select
                  isDisabled
                  value={{
                    label: props?.data?.project?.name,
                    value: props?.data?.project?.name,
                  }}
                  classNamePrefix={"vd-select-dropdown"}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Select Driver</div>
                <Select
                  isDisabled
                  value={{
                    label: props?.data?.driverManagement?.name,
                    value: props?.data?.driverManagement?.name,
                  }}
                  classNamePrefix={"vd-select-dropdown"}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="rit mb-2">
            <div className="deviceclrhead ml-2">Device Detail</div>
          </div>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Vehicle Reg.#</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="select"
                  value={props?.data?.vehicleRegestrationNo}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Device ID.</div>
                <Input
                  className="disableField"
                  disabled
                  pattern="\d"
                  maxLength={15}
                  name="select"
                  value={props?.data?.deviceId}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Insurer Name</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="select"
                  value={props?.data?.insurerName}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Policy Number</div>
                <Input
                  className="disableField"
                  disabled
                  pattern="\d"
                  maxLength={15}
                  name="select"
                  value={props?.data?.policyNumber}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Home Depot</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="select"
                  value={props?.data?.homeDepot}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Fleet Number</div>
                <Input
                  className="disableField"
                  disabled
                  pattern="\d"
                  maxLength={15}
                  name="select"
                  value={props?.data?.fleetNumber}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Description1</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="select"
                  value={props?.data?.description1}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Description2</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="select"
                  value={props?.data?.description2}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <div className="modalheader modinpulabel">Status</div>
            </Col>
            <Col md={6}>
              <RadioGroup
                className="radioStatus"
                aria-label="status"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  checked={props?.data?.status}
                  control={<Radio />}
                  label="Active"
                  disabled
                />
                <FormControlLabel
                  value={false}
                  checked={!props?.data?.status}
                  control={<Radio />}
                  label="Deactive"
                  disabled
                />
              </RadioGroup>
            </Col>
          </Row>
          <div className="rit mb-2 mt-1">
            <div className="deviceclrhead ml-2">Camera Name</div>
          </div>
          <Row className="formRow">
            <Col md={6} className="dropinput">
              <FormGroup>
                <Label className="modalheader modinpulabel">
                  First Camera Channel
                </Label>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="camera1Name"
                  value={props?.data?.camera1Name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className="dropinput">
              <FormGroup>
                <Label className="modalheader modinpulabel">
                  Second Camera Channel
                </Label>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="camera2Name"
                  value={props?.data?.camera2Name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="formRow">
            <Col md={6} className="dropinput">
              <FormGroup>
                <Label className="modalheader modinpulabel">
                  Third Camera Channel
                </Label>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="camera3Name"
                  value={props?.data?.camera3Name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className="dropinput">
              <FormGroup>
                <Label className="modalheader modinpulabel">
                  Fourth Camera Channel
                </Label>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="camera4Name"
                  value={props?.data?.camera4Name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="formRow">
            <Col md={6} className="dropinput">
              <FormGroup>
                <Label className="modalheader modinpulabel">
                  Fifth Camera Channel
                </Label>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  name="camera5Name"
                  value={props?.data?.camera5Name}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="assi footer-width py-3">
          <Button className="popbtn vie datewidth btnBox" onClick={toggle}>
            <>CLOSE</>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  drivers: state.driverData.drivers,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
