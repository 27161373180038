export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const SET_USER = "SET_USER";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const SET_TOKEN = "SET_TOKEN";

export const UPDATE_USER_PROFILE_IMG = "UPDATE_USER_PROFILE_IMG";

// export interface User {
//   email: string;
//   password: string;
// }

// export interface LoginSuccessAction {
//   type: typeof LOGIN_SUCCESS;
//   payload: User;
// }

// export interface LogoutAction {
//   type: typeof LOGOUT;
// }

// export type UserActionTypes = LoginSuccessAction | LogoutAction;
