import { ADD_GEO_FENCING, DELETE_GEO_FENCING, DELETE_GEO_FENCING_SUCCESS, GET_GEO_FENCING, STATUS_GEO_FENCING } from "./geoFencing.types";

export const getGeoFencing = (id: number) => {
  return {
    type: GET_GEO_FENCING as typeof GET_GEO_FENCING,
    id,
    meta: { thunk: true },
  };
};

export const addGeoFencing = (data: $TSFixMe) => ({
  type: ADD_GEO_FENCING as typeof ADD_GEO_FENCING,
  data,
  meta: { thunk: true },
});

export const DeleteGeoFencing = (id: number) => {
  return {
    type: DELETE_GEO_FENCING as typeof DELETE_GEO_FENCING,
    id,
    meta: {
      thunk: true,
    },
  };
};

export const DeleteGeoFencingSuccess = ({
  id,
  meta,
}: {
  id: number;
  meta: any;
}) => {
  return {
    type: DELETE_GEO_FENCING_SUCCESS as typeof DELETE_GEO_FENCING_SUCCESS,
    id,
    meta,
  };
};

export const ChangeGeoFencingStatus = (id: number, data: $TSFixMe) => {
  return {
    type: STATUS_GEO_FENCING as typeof STATUS_GEO_FENCING,
    id,
    data,
    meta: {
      thunk: true,
    },
  };
};