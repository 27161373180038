import { API } from "../../../api/api.constants";
import { get, put as update, post } from "../../../services/axios.services";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET_USER, LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "./user.types";
import { onError, onSuccess, safe } from "../../store/saga/sagaHelper";

//login generator function
function* loginUser({ data, meta }: $TSFixMe) {

    // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(post, API.LOGIN_API, data);
    return response;
}

function* getUser({ meta }: $TSFixMe) {
    // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(get, API.USER_CONFIG,);
    return response;
}

// logout generator function
function* updateUser({ data, meta }: $TSFixMe) {
    // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(
        update,
        `${API.USER_CONFIG}`,
        data
    );
    return response;
}

function* logoutUser({ data, meta }: $TSFixMe) {
    // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(post, API.LOGOUT_API, data);
    return response;
}


function* loginSaga() {
    yield takeLatest(LOGIN_USER, safe(onError, loginUser, onSuccess));
    yield takeLatest(GET_USER, safe(onError, getUser, onSuccess));
    yield takeLatest(UPDATE_USER, safe(onError, updateUser, onSuccess));
    yield takeLatest(LOGOUT_USER, safe(onError, logoutUser, onSuccess));
}

export default loginSaga;
