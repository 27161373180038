import {
  IDeviceListDD,
  IDialCodeList,
  IDriverListDD,
  IOrgList,
  IProjectList,
  IProjectListDD,
  IResourceRolesList,
  ITimeZoneList,
  IUserRoleList,
} from "../../../../global.types";
import { AppActions } from "../../store/globalstore";
import {
  GET_DEVICE_LIST_DD_SUCCESS,
  GET_DEVICE_LIST_DD_VIDEO_REQUEST_SUCCESS,
  GET_DEVICE_LIST_FROM_PROJ_SUCCESS,
  GET_DIAL_CODE_SUCCESS,
  GET_DRIVER_LIST_DD_SUCCESS,
  GET_ORG_LIST_SUCCESS,
  GET_PROJECT_LIST_DD_SUCCESS,
  GET_PROJECT_LIST_DD_VIDEO_REQUEST_SUCCESS,
  GET_PROJECT_LIST_FROM_ORG_SUCCESS,
  GET_RESOURCE_ROLE_LIST_SUCCESS,
  GET_TIME_ZONE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_SUCCESS,
} from "./dropdown.types";

interface InitialState {
  dropdowns: {
    orgList: Partial<IOrgList>;
    projects_org: Partial<IProjectList>;
    device_proj: Partial<IDeviceListDD>;
    dialCode: Partial<IDialCodeList> & {
      dropDown?: { label: string; value: string }[];
    };
    resourceRolesList: Partial<IResourceRolesList> & {
      dropDown?: { permissionId: string; permission: string }[];
    };
    timeZoneList: Partial<ITimeZoneList> & {
      dropDown?: { label: string; value: string }[];
    };
    projectListDD: Partial<IProjectListDD> & {
      dropDown?: { label: string; value: string }[];
    };
    deviceListDD: Partial<IDeviceListDD> & {
      dropDown?: { label: string; value: string }[];
    };
    deviceListDDVideoRequest: Partial<IDeviceListDD> & {
      dropDown?: { label: string; value: string }[];
    };
    projectListDDVideoRequest: Partial<IProjectListDD> & {
      dropDown?: { label: string; value: string }[];
    };
    userRolesListDD: Partial<IUserRoleList> & {
      dropDown?: { label: string; value: number }[];
    };
    driverListDD: Partial<IDriverListDD> & {
      dropDown?: { label: string; value: string }[];
    };
  };
}

// initial State
const initialState: InitialState = {
  dropdowns: {
    projects_org: {},
    device_proj: {},
    orgList: {},
    dialCode: {},
    deviceListDDVideoRequest: {},
    projectListDDVideoRequest: {},
    resourceRolesList: {},
    timeZoneList: {},
    projectListDD: {},
    deviceListDD: {},
    userRolesListDD: {},
    driverListDD: {},
  },
};

export const dropdownReducer = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_ORG_LIST_SUCCESS:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          orgList: action.payload,
        },
      };

    case GET_DIAL_CODE_SUCCESS:
      let tmpArray = action.payload.result.map((eachItem: any) => ({
        label: eachItem,
        value: eachItem,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          dialCode: { ...action.payload, dropDown: tmpArray },
        },
      };
    case GET_PROJECT_LIST_FROM_ORG_SUCCESS:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          projects_org: action.payload,
        },
      };
    case GET_DEVICE_LIST_FROM_PROJ_SUCCESS:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          device_proj: action.payload,
        },
      };

    case GET_RESOURCE_ROLE_LIST_SUCCESS:
      let tmpArray6 = action.payload.result.map((eachItem: any) => ({
        permissionId: eachItem.id,
        permission: eachItem.name,
      }));

      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          resourceRolesList: { ...action.payload, dropDown: tmpArray6 },
        },
      };

    case GET_TIME_ZONE_LIST_SUCCESS:
      let tmpArray2 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.timezone,
        value: eachItem.zoneId,
      }));

      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          timeZoneList: { ...action.payload, dropDown: tmpArray2 },
        },
      };

    case GET_PROJECT_LIST_DD_SUCCESS:
      let tmpArray4 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name,
        value: eachItem.id,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          projectListDD: { ...action.payload, dropDown: tmpArray4 },
        },
      };
    case GET_PROJECT_LIST_DD_VIDEO_REQUEST_SUCCESS:
      let tmpArray9 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name,
        value: eachItem.id,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          projectListDDVideoRequest: { ...action.payload, dropDown: tmpArray9 },
        },
      };
    case GET_DEVICE_LIST_DD_SUCCESS:
      let tmpArray7 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name,
        value: eachItem.id,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          deviceListDD: { ...action.payload, dropDown: tmpArray7 },
        },
      };
    case GET_DEVICE_LIST_DD_VIDEO_REQUEST_SUCCESS:
      let tmpArray8 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name ?? eachItem.otherValue1,
        value: eachItem.id,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          deviceListDDVideoRequest: { ...action.payload, dropDown: tmpArray8 },
        },
      };
    case GET_USER_ROLE_LIST_SUCCESS:
      let tmpArray1 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name,
        value: eachItem.id,
      }));
      return ({
        ...state,
        dropdowns: {
          ...state.dropdowns,
          userRolesListDD: { ...action.payload, dropDown: tmpArray1 },
        },
      })

    case GET_DRIVER_LIST_DD_SUCCESS:
      let tmpArray5 = action.payload.result.map((eachItem: any) => ({
        label: eachItem.name,
        value: eachItem.id,
      }));
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          driverListDD: { ...action.payload, dropDown: tmpArray5 },
        },
      };

    default:
      return state;
  }
};
