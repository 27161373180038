import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import { getHighEvents } from "../../../../redux/reducers/DataReducer/data.actions";
import {
  endLoading,
  startLoading,
} from "../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import { GetHighEvent } from "../../../../../global.types";
import { LayoutContext } from "../../../../context/LayoutContext";
import DataTable from "../../../Common/DataTable/dataTable";
import { ROUTES } from "../../../../routes/routes";
import { Link } from "react-router-dom";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import { Popover, Tooltip } from "antd";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getLocation } from "../../../../api/axios.resources";
import { Spinner } from "reactstrap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { convertDateToTimestamp, convertTimestampToDate, encrypt } from "../../../../services/common.functions.services";
import { DEVICE_VIDEO_STATUS } from "../../../../constants";
import NotInterestedIcon from '@mui/icons-material/NotInterested';

interface HighEventsProps {
  endDate: $TSFixMe;
  startDate: $TSFixMe;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & HighEventsProps;

const HighEvents = (props: Props) => {
  const { selectedProject, selectedTheme }: $TSFixMe = useContext(LayoutContext);
  const [highEventsList, setHighEventsList] = useState<GetHighEvent[]>([]);
  const [recordsPerPage, setRecordsPerPage]: $TSFixMe = useState(10);
  const [selectedPage, setSelectedPage]: $TSFixMe = useState(1);
  const [isAsc, setIsAsc]: $TSFixMe = useState(false);
  const [sortColumn, setSortColumn]: $TSFixMe = useState("");
  const [location, setLocation] = useState('');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const HighEventsActionBtns4 = (data: $TSFixMe) => {
    const gyroCommand = data?.data?.command_info?.find((command: $TSFixMe) => command.commandType === "ACC");

    // Extract start and end timestamps if GYRO command is found
    const startTimestamp = gyroCommand?.startTimestamp;
    const endTimestamp = gyroCommand?.endTimestamp;

    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data.data.deviceId,
      id: data.data._id,
      projectId: data.data.projectId,
      type: 'HIGH_EVENT_VIDEO_INFO',
      endTimestamp: endTimestamp,
      startTimestamp: startTimestamp,
      timestamp: data.data.timestamp,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink" target="_blank">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const getAddressFromLatLng = async (latitude: number, longitude: number) => {
    try {
      if (!latitude && !longitude) {
        setLocation("No address found");
        return;
      }
      const response = await getLocation(latitude, longitude);
      if (response) {
        setLocation(response.data.display_name);
      }
      if (response.data.error && !response.data.display_name) {
        setLocation(response.data.error);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setLocation('Error fetching address');
    }
  };

  const dashHighEventsColumns = [
    {
      title: "Action",
      format: (data: $TSFixMe) => {
        const channel1Command = data?.command_info?.filter((command: $TSFixMe) => command?.channel === 1 && command?.commandType === "VIDEO")[0];
        return (
          <>
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode === 0) && (
              <HighEventsActionBtns4 data={data} />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode !== 0) && (
              <NotInterestedIcon className="nondropico" />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.pending) && (
              <Tooltip title={"Already, requested for video."} placement={"top"}>
                <TimelapseSharpIcon className="nondropico" />
              </Tooltip>
            )}
            {!channel1Command && <NotInterestedIcon className="nondropico" />}
          </>
        )
      }
    },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data.driverName },
    { title: " Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    {
      title: "Event",
      data: "eventName",
    },
    {
      title: "Location",
      format: (data: $TSFixMe) => (
        <Popover
          content={
            location ? (
              <>
                {location}
                {data?.latitude && data?.longitude &&
                  <div>
                    <a onClick={() => setSelectedEventId(null)} href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`} rel="noreferrer" target="_blank">
                      View Location <OpenInNewIcon color="primary" className="wh-12-px" sx={{ fontSize: 12 }} /></a>
                  </div>
                }
              </>
            ) :
              <Spinner color="primary" size="sm" className="mx-auto d-block" />
          }
          trigger="click"
          placement="left"
          overlayClassName={`location-popover ${selectedTheme}`}
          visible={selectedEventId === data._id}
          onVisibleChange={(visible) => {
            if (visible) {
              getAddressFromLatLng(data.latitude, data.longitude);
            }
            setSelectedEventId(visible ? data._id : null);
          }}
        >
          <LocationOnOutlinedIcon className="dropico" />
        </Popover>
      ),
    },
    { title: "DateTime", data: "timestamp", format: (data: $TSFixMe) => data.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat) : "N/A", },
  ];

  useEffect(() => {
    if (props.highEvents?.result) {
      const updatedHighEventsList = (props.highEvents?.result?.data || []).map((item, index) => ({
        ...item,
        no: index + 1,
      }));
      setHighEventsList(updatedHighEventsList);
    }
    return () => { };
  }, [props.highEvents]);

  const apiCalls = async () => {
    try {
      props.startLoading();
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: selectedProject?.id,
        page: {
          no: selectedPage,
          size: recordsPerPage
        },
        endTimestamp: props.endDate ? convertDateToTimestamp(props.endDate, props?.user?.timeZone?.zoneId) : null,
        startTimestamp: props.startDate ? convertDateToTimestamp(props.startDate, props?.user?.timeZone?.zoneId) : null
      };
      await props.getHighEvents(data);
    } catch (error) {
      console.log("error in HighEventsTab api", error);
    } finally {
      await props.endLoading();
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if ((selectedProject && Object.keys(selectedProject).length > 0) && props.user.userId !== 0) {
      apiCalls();
    }
    return () => { };
  }, [selectedProject, props.user.userId, recordsPerPage, selectedPage, isAsc, sortColumn, props.startDate]);

  return (
    <div className="mt-3">
      <DataTable
        data={highEventsList}
        columns={dashHighEventsColumns}
        bordered
        pageData={props.highEvents?.result?.page}
        totalRecrods={props.highEvents?.result?.totalRecrods}
        setPageSize={setRecordsPerPage}
        pageSize={recordsPerPage}
        setCurrentPage={setSelectedPage}
        currentPage={selectedPage}
        setSortColumn={setSortColumn}
        isPageLoading={isPageLoading}
        setIsPageLoading={setIsPageLoading}
        setIsAsc={setIsAsc}
        hover={true}
        responsive={true}
      />
    </div>
  );
};

// export default HighEvents;
const mapStateToProps = (state: RootState) => ({
  highEvents: state.dataStore.highEvents,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getHighEvents: (data: $TSFixMe) => dispatch(getHighEvents(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HighEvents);
