import { Map, TileLayer, Marker, Popup, LayersControl } from "react-leaflet";
import { useEffect, useState } from "react";
import { calculateMapBounds, convertKilometerToMile, convertTimestampToDate, getCustomIcon } from "../../../../../../../services/common.functions.services";
import { MAP_DEFAULT_LAT_LONG, MAP_SCROLLWHEELZOOM, MAP_ZOOM, measurement } from "../../../../../../../constants";

const { BaseLayer } = LayersControl;

function LiveTrackingLoc(props: $TSFixMe) {
  const [markersData, setMarkersData]: $TSFixMe = useState({});
  const [mapZoom, setMapZoom]: $TSFixMe = useState(MAP_ZOOM);
  const [mapBounds, setMapBounds] = useState(null);
  const [mapCenter, setMapCenter]: $TSFixMe = useState(MAP_DEFAULT_LAT_LONG);
  const carAngle = markersData.heading || 0;
  const customIcon = getCustomIcon(carAngle);

  const handleGetMapData = async () => {
    if (!props.markerData?.latitude) {
      setMarkersData({});
      return;
    }
    if (Object.keys(props.markerData).length > 0) {
      const marker: $TSFixMe = {
        position: [props.markerData.latitude, props.markerData.longitude],
        ...props.markerData
      };
      setMarkersData(marker);
    } else {
      setMarkersData({});
    }
  }

  useEffect(() => {
    if (props.markerData) {
      handleGetMapData();
    }
    return () => { }
  }, [props.markerData])

  //useEffect for setup selected trip line bounds and path coordinates.
  useEffect(() => {
    if (Object.keys(markersData).length > 0) {
      const bounds: $TSFixMe = calculateMapBounds([markersData]);
      const newCenter = markersData.position;
      setMapBounds(bounds);
      setMapCenter(newCenter);
    }
  }, [markersData]);

  return (
    <Map
      className="mapLoc"
      center={mapCenter || MAP_DEFAULT_LAT_LONG}
      zoom={mapZoom}
      bounds={mapBounds || undefined}
      onzoom={(e: $TSFixMe) => setMapZoom(e.target._zoom)}
      scrollWheelZoom={MAP_SCROLLWHEELZOOM}
    >
      <LayersControl position="topright">
        <BaseLayer checked name="Base Map">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </BaseLayer>
        <BaseLayer name="Humanitarian">
          <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
        </BaseLayer>
        <BaseLayer name="Satellite">
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        </BaseLayer>
        {Object.keys(markersData).length > 0 &&
          <Marker position={markersData.position[0] ? markersData.position : []} icon={customIcon}>
            <Popup>
              <div><b>Device Id:</b> {markersData?.deviceId ?? ""}</div>
              <div>
                <b>Position:</b> {markersData?.longitude && markersData?.latitude ? `${markersData?.longitude}, ${markersData?.latitude}` : ""}
              </div>
              <div>
                <b>Altitude:</b> {markersData?.altitude ?? ""}
              </div>
              <div>
                <b>Heading:</b> {markersData?.heading ?? ""}
              </div>
              <div>
              <b>Speed:</b> {`${ markersData.speed ? props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(markersData?.speed) : markersData?.speed : "0"}  ${props.user.measurmentUnit === measurement[1].value ? 'mph' : 'km/h'}`}
              </div>
              <div><b>Last Seen:</b> {markersData?.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, markersData?.timestamp, null, props.user.timeFormat) : '-'}</div>
            </Popup>
          </Marker>
        }
      </LayersControl>
    </Map>
  );
}

export default LiveTrackingLoc;
