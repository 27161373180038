export const GET_GEO_FENCING = "GET_GEO_FENCING";
export const GET_GEO_FENCING_SUCCESS = "GET_GEO_FENCING_SUCCESS";
export const GET_GEO_FENCING_ERROR = "GET_GEO_FENCING_ERROR";

export const ADD_GEO_FENCING = "ADD_GEO_FENCING";
export const ADD_GEO_FENCING_SUCCESS = "ADD_GEO_FENCING_SUCCESS";
export const ADD_GEO_FENCING_ERROR = "ADD_GEO_FENCING_ERROR";

export const DELETE_GEO_FENCING = "DELETE_GEO_FENCING";
export const DELETE_GEO_FENCING_SUCCESS = "DELETE_GEO_FENCING_SUCCESS";
export const DELETE_GEO_FENCING_ERROR = "DELETE_GEO_FENCING_ERROR";

export const STATUS_GEO_FENCING = "STATUS_GEO_FENCING";
export const STATUS_GEO_FENCING_SUCCESS = "STATUS_GEO_FENCING_SUCCESS";
export const STATUS_GEO_FENCING_ERROR = "STATUS_GEO_FENCING_ERROR";