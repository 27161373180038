import React, { useState, useRef, useEffect, useContext } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import CustomInput from "../CustomInput";
import { Button } from "reactstrap";
import { LayoutContext } from "../../../context/LayoutContext";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const CustomCropper = ({
  onComplete,
  aspect = 16 / 9,
  id,
  isLoading,
  edit = false,
  logo,
  editImageURL,
  ...props
}: $TSFixMe) => {
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const { selectedTheme } = useContext(LayoutContext);
  const [isCropped, setIsCropped] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  function onSelectFile(file: File | null) {
    if (file) {
      setIsCropped(false);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(file);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function handleCrop() {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
      return;
    }

    const croppedCanvas = document.createElement("canvas");
    canvasPreview(imgRef.current, croppedCanvas, completedCrop, scale, rotate);

    croppedCanvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error("Failed to create blob");
          return;
        }

        const file = new File([blob], "croppedImage.jpg", {
          type: "image/jpeg",
        });

        // Clear the states after getting the cropped image
        resetStates();

        // Pass the File object to the onComplete callback
        onComplete(file);

        // Set isCropped to true after cropping
        setIsCropped(true);
      },
      "image/jpeg",
      0.8
    );
  }

  function resetStates() {
    setImgSrc("");
    setCrop(undefined);
    setCompletedCrop(undefined);
    setScale(1);
    setRotate(0);
  }

  // Load the image into the crop area when the image source changes
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // Update the preview canvas when crop, scale, or rotate changes
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    if (editImageURL) {
      setImgSrc(editImageURL);
      setIsCropped(true);
    }
  }, [editImageURL]);

  return (
    <div className="custom-cropper col-md-8">
      <div className="cropper-controls ">
        <CustomInput
          id={id}
          isLoading={isLoading}
          accept={"image/*"}
          onFileSelect={onSelectFile}
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        {!isCropped ? (
          <>
            {!!imgSrc && (
              <ReactCrop
                className="height-object-fit mr-2"
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            <div>
              {!!completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: props.previewImage && props?.previewImage?.width ? props?.previewImage?.width : "186px",
                        height: props.previewImage && props?.previewImage?.height ? props?.previewImage?.height : "68px",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    {/* Show the crop button only if isCropped is false */}
                    {!isCropped && (
                      <Button
                        className={`pagebtn btn btn-secondary ${selectedTheme}-bg border-r-3`}
                        onClick={handleCrop}
                        type="button"
                        disabled={!completedCrop || !imgSrc}
                      >
                        Crop Image
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {!isLoading &&
              <img
                alt="uploaded-img"
                src={imgSrc}
                style={{
                  objectFit: "contain",
                  width: "auto",
                  height: props.previewImage && props.previewImage.height ? props?.previewImage?.height : "68px",
                }}
              />
            }
          </>
        )}
      </div>
    </div>
  );
};

export default CustomCropper;
