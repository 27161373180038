export const GET_DEVICE = "GET_DEVICE";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_ERROR = "GET_DEVICE_ERROR";

export const GET_DEVICE_FOR_PROJECT = "GET_DEVICE_FOR_PROJECT";
export const GET_DEVICE_FOR_PROJECT_SUCCESS = "GET_DEVICE_FOR_PROJECT_SUCCESS";
export const GET_DEVICE_FOR_PROJECT_ERROR = "GET_DEVICE_FOR_PROJECT_ERROR";

export const ADD_DEVICE = "ADD_DEVICE";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_ERROR = "ADD_DEVICE_ERROR";

export const EDIT_DEVICE = "EDIT_DEVICE";
export const EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_ERROR = "EDIT_DEVICE_ERROR";

export const DELETE_DEVICE = "DELETE_DEVICE";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_ERROR = "DELETE_DEVICE_ERROR";

export const GET_RENAME_CHANNEL = "GET_RENAME_CHANNEL";
export const GET_RENAME_CHANNEL_SUCCESS = "GET_RENAME_CHANNEL_SUCCESS";
export const GET_RENAME_CHANNEL_ERROR = "GET_RENAME_CHANNEL_ERROR";

export const EDIT_RENAME_CHANNEL = "EDIT_RENAME_CHANNEL";
export const EDIT_RENAME_CHANNEL_SUCCESS = "EDIT_RENAME_CHANNEL_SUCCESS";
export const EDIT_RENAME_CHANNEL_ERROR = "EDIT_RENAME_CHANNEL_ERROR";

export const GET_PART_INFO = "GET_PART_INFO";
export const GET_PART_INFO_SUCCESS = "GET_PART_INFO_SUCCESS";
export const GET_PART_INFO_ERROR = "GET_PART_INFO_ERROR";

export const EDIT_PART_INFO = "EDIT_PART_INFO";
export const EDIT_PART_INFO_SUCCESS = "EDIT_PART_INFO_SUCCESS";
export const EDIT_PART_INFO_ERROR = "EDIT_RENAME_CHANNEL_ERROR";

export const ADD_PART_INFO = "ADD_PART_INFO";
export const ADD_PART_INFO_SUCCESS = "ADD_PART_INFO_SUCCESS";
export const ADD_PART_INFO_ERROR = "ADD_PART_INFO_ERROR";
