import React, { useContext } from "react";
import ASSETS from "../../../assets";
import { THEME_TYPE } from "../../../constants";
import { LayoutContext } from "../../../context/LayoutContext";
import { connect } from "react-redux";
import { RootState } from "../../../redux/reducers/rootReducer";

const PageNotFound = (props: $TSFixMe) => {
  const { selectedTheme } = useContext(LayoutContext);
  return (
    <>
      {props.user.userId && (
        <img
          src={
            selectedTheme === THEME_TYPE.GREY_SCALE ||
            selectedTheme === THEME_TYPE.BLUE_WHITE_GREY
              ? ASSETS.ERROR_404_Light
              : ASSETS.ERROR_404_Dark
          }
          alt="error-403"
          className="w-50 h-100 m-auto"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(PageNotFound);
