import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Container,
  FormGroup,
  Form,
} from "reactstrap";
import AddAccordion from "./accordion";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutContext } from "../../../../../../../../context/LayoutContext";
import { THEME_TYPE } from "../../../../../../../../constants";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { RootState } from "../../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../../redux/store/store";
import {
  endLoading,
  startLoading,
} from "../../../../../../../../redux/reducers/general/general.actions";
import {
  getEditCommonConfiguration,
  getEditCommonConfigurationImei,
} from "../../../../../../../../redux/reducers/Management&Settings/commonConfiguration/commonConfiguration.actions";
import { connect } from "react-redux";
import FullPageLoaderModal from "../../../../../../../Common/FullPageLoader/FullPageLoaderModal";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function AddConfiguration(props: Props) {
  const [nameError, setNameError] = useState<string | null>(null);
  const [configurationData, setConfigurationData] = useState({
    name: "",
    description: "",
    status: true, // Setting default value to "Active"
  });
  const history = useHistory();
  const location = useLocation();
  const queryParams: $TSFixMe = new URLSearchParams(location.search);
  const mode: $TSFixMe = queryParams.get("mode") ?? "add";
  const id = queryParams.get("id");
  const imei: boolean = queryParams.get("imei");
  const { name, description } = configurationData;
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType =
    selectedTheme === THEME_TYPE.GREY_SCALE ||
      selectedTheme === THEME_TYPE.BLUE_WHITE_GREY
      ? "cardcolor-light"
      : "cardcolor";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setConfigurationData({
      ...configurationData,
      [name]: value,
    });
    if (name === "name") {
      if (value.trim() === "") {
        setNameError("Name should not be empty");
      } else {
        setNameError(null);
      }
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationData({
      ...configurationData,
      status: e?.target?.value === "true" ? true : false,
    });
  };

  const getCommonConfiguration = async (id: number) => {
    try {
      props.startLoading();
      let res: $TSFixMe;
      if (!imei) {
        res = await props.getEditCommonConfiguration(id);
        if (res.status === 200) {
          setConfigurationData({
            name: res?.result?.name,
            description: res?.result?.description,
            status: res?.result?.status,
          });
        }
      } else {
        setConfigurationData({
          name: props.commonConfiguration.name,
          description: props.commonConfiguration.description,
          status: props.commonConfiguration.status,
        });
      }
    } catch (error) {
      history.goBack();
      console.error("error in CommonTemplateTab", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    if (props.userConfig) {
      if (id && mode) {
        if (mode === "edit" || mode === "view") {
          getCommonConfiguration(Number(id));
        }
      }
    }
  }, [id, mode]); // Add data and mode to the dependency array

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--5" fluid>
        <Row className="mt-0">
          <Col>
            <Card
              className={`card-stats  md-4 mb-xl-0 ${cardColorType} allPage`}
            >
              <div className="mb-4">
                <Row className="mb-4">
                  <Col sm={12} md={6} className="tripsname">
                    <div className="tabpage">
                      <>Common Configuration</>
                    </div>
                  </Col>
                </Row>
                <Form className={`${selectedTheme} accordform`}>
                  <Row className="align-items-baseline">
                    <Col md={6}>
                      <FormGroup className="">
                        <Input
                          className={
                            "form-control dark-theme-form-control" + (nameError ? " is-invalid " : "")
                          }
                          autoComplete="disable"
                          type="text"
                          name="name"
                          id="Name"
                          disabled={mode === "view"}
                          placeholder="Name"
                          value={name}
                          onChange={handleInputChange}
                        />
                        <div className="dangerText mt-1">{nameError}</div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="">
                        <Input
                          className="dark-theme-form-control"
                          type="text"
                          name="description"
                          id="Description"
                          disabled={mode === "view"}
                          placeholder="Description"
                          value={description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <RadioGroup
                        onChange={handleStatusChange}
                        style={{ display: "flex", flexDirection: "row" }}
                        className="radioStatus"
                        defaultValue={"Active"}
                        aria-label="status"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          className="radioStatusConfig"
                          checked={configurationData?.status}
                          control={<Radio />}
                          disabled={mode === "view"}
                          label="Active"
                        />
                        <FormControlLabel
                          value={false}
                          disabled={mode === "view"}
                          className="radioStatusConfig"
                          checked={!configurationData?.status}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <CardBody className="tabAll mb-4 mt-6">
                <AddAccordion
                  configurationData={configurationData}
                  mode={mode ?? mode}
                  editViewData={props?.commonConfiguration}
                  theme={selectedTheme}
                  imei={imei}
                  id={Number(id)}
                  setNameError={setNameError}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.generalSlice.isLoading,
    commonConfiguration:
      state.commonConfigurationsData.commonConfigurationTemplate,
    userConfig: state.userSlice.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getEditCommonConfiguration: (id: number) =>
    dispatch(getEditCommonConfiguration(id)),
  getEditCommonConfigurationImei: (id: number) =>
    dispatch(getEditCommonConfigurationImei(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConfiguration);
