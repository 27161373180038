import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AddUser as AddUserType } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  AddUserValidationType,
  UsersManagementAddValidationSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import { getDialCode, getUserRoleListDD } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { AddUsersCode } from "../../../../../../../redux/reducers/Management&Settings/usersManagement/usersManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import SelectProject from "../../../SelectProject/SelectProject";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AddUser = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

  const apiCall = async () => {
    props.startLoading();
    if (!props.dropdowns.dialCode?.dropDown && modal) {
      await props.getDialCodeList();
    }
    if (!props.dropdowns.userRolesListDD?.result && modal) {
      await props.getUserRoleListDD();
    }
    props.endLoading();
  };

  const [projectIds, setProjectIds] = useState([]);

  const handleAddUsersManagement = async (values: AddUserValidationType) => {
    try {
      props.startLoading();
      await toast.promise(
        props.AddUsersCode({
          allowEmailAlert: values.allowAlertEmail,
          allowMaintenanceEmail: values.allowMaintenanceEmail,
          email: values.email,
          mobileNumber: values.mobileNumber,
          mobilecode: values.mobilecode,
          name: values?.name,
          password: values.password,
          projects: projectIds,
          roleId: values?.role?.id,
          status: values.status,
          sendWelcomeEmail: values.sendWelcomeEmail,
        }) as any,
        {
          success: TOAST_MSG.USERS_SUCCESS,
        }
      );
      toggle();
      setProjectIds([]);
    } catch (error) {
      console.log("error in dashboard api", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    apiCall();
    return () => { };
  }, []);


  return (
    <>
      <Button onClick={() => {
        toggle();
        setProjectIds([]);
      }} className={`${selectedTheme} ml-2`}>
        <Label className="tableModalBtnHeight range">Add User</Label>
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Add User</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={UsersManagementAddValidationSchema}
          enableReinitialize={true}
          initialValues={{
            allowAlertEmail: true,
            allowMaintenanceEmail: true,
            sendWelcomeEmail: true,
            email: "",
            mobileNumber: "",
            mobilecode: "",
            name: "",
            projectIds: [],
            password: "",
            status: true,
            role: { name: "", id: 0 },
          }}
          onSubmit={handleAddUsersManagement}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            touched,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <Row>
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Username<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (touched.name && errors.name
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        placeholder="UserName"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Email Id<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (touched.email && errors.email ? " is-invalid" : "")
                        }
                        autocomplete="disable"
                        autoFill="false"
                        placeholder="Email Id"
                        type="userEmail"
                        name="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Password<span className="required"> *</span>
                      </div>
                      <div className="position-relative">
                        <Field
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid "
                              : "") +
                            " passwordFont "
                          }
                          autocomplete="disable"
                          type={isNewPasswordVisible ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange("password")}
                          onBlur={handleBlur("password")}
                        />
                        <span className={(errors.password && touched.password
                          ? " is-invalid "
                          : "") + "eye-icon-for-password"} onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}>
                          {isNewPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="modalheader modinpulabel">
                    Mobile Number<span className="required"> *</span>
                  </div>
                </Row>
                <Row className="formRow">
                  <Col md={3}>
                    <FormGroup>
                      <Select
                        placeholder="Code"
                        className={`${touched.mobilecode && errors.mobilecode ? "requireSelect" : ""}`}
                        options={props.dropdowns.dialCode?.dropDown}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        name="mobilecode"
                        onChange={(option) =>
                          setFieldValue("mobilecode", option?.value)
                        }
                        onBlur={handleBlur("mobilecode")}
                      />
                      {touched.mobilecode && errors.mobilecode ? (
                        <div className="validate">Required</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup className="dropinput">
                      <Field
                        className={
                          "form-control" +
                          (errors.mobileNumber && touched.mobileNumber
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        name="mobileNumber"
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        placeholder="Digits Only"
                        value={values.mobileNumber}
                        onChange={handleChange("mobileNumber")}
                        onBlur={handleBlur("mobileNumber")}
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="modalheader modinpulabel">
                    Select User role<span className="required"> *</span>
                  </div>{" "}
                </Row>
                <Row>
                  <Col md={12}>
                    <Select
                      placeholder="Select User Role"
                      className={`${touched.role && errors.role ? "requireSelect" : ""
                        }`}
                      options={props.dropdowns.userRolesListDD?.result}
                      maxMenuHeight={180}
                      classNamePrefix={`filter-select-style_${selectedTheme}`}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base: $TSFixMe) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id?.toString()}
                      name="role"
                      onChange={(option) => setFieldValue("role", option)}
                      onBlur={handleBlur("role")}
                    />
                    {touched.role && errors.role ? (
                      <div className="validate">Required</div>
                    ) : null}
                  </Col>
                </Row>
                <br />
                <div className={(touched.projectIds && errors.projectIds ? " is-invalid " : "")}>
                  <SelectProject
                    projectIds={projectIds}
                    setProjectsIds={(val) => {
                      setProjectIds(val);
                      setFieldValue("projectIds", val, true);
                    }}
                  />
                </div>
                {touched.projectIds && errors.projectIds && (
                  <div className="validate">
                    {errors.projectIds
                      ? "Required"
                      : errors.projectIds}
                  </div>
                )}
                <br />
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.allowAlertEmail}
                          onChange={(e) =>
                            setFieldValue("allowAlertEmail", e.target.checked)
                          }
                        />
                        Do you Allow to Alert Email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.allowMaintenanceEmail}
                          onChange={(e) =>
                            setFieldValue(
                              "allowMaintenanceEmail",
                              e.target.checked
                            )
                          }
                        />
                        Do you Allow to Maintenance Email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.sendWelcomeEmail}
                          onChange={(value) =>
                            setFieldValue(
                              "sendWelcomeEmail",
                              value.target.checked
                            )
                          }
                        />
                        Do you want to send welcome email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <div className="modalheader">Status</div>
                  </Col>
                  <Col md={6}>
                    <RadioGroup
                      onChange={(e) =>
                        setFieldValue(
                          "status",
                          e.target.value === "true"
                        )
                      }
                      className="radioStatus"
                      onBlur={handleBlur("status")}
                      aria-label="status"
                      defaultValue={values.status}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        checked={values.status}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={false}
                        checked={!values.status}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    // @ts-expect-error
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading &&
                      <Spinner color="dark"
                        size="sm" className="mr-2" />
                    }
                    <>ADD</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDialCodeList: () => dispatch(getDialCode()),
  getUserRoleListDD: () => dispatch(getUserRoleListDD()),
  AddUsersCode: (data: AddUserType) => dispatch(AddUsersCode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
