import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  AddManageRolesCodeData,
  ResourceRole,
} from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  manageRolesAddValidationSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { AddManageRolesCode } from "../../../../../../../redux/reducers/Management&Settings/manageRoles/manageRoles.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import ToggleButton from "../../../../../../Common/ToggleButton";
import PermissionsTable from "../../../PermissionsTable";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AddRole = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleAddManageRoles = async (
    values: $TSFixMe
  ) => {
    try {
      const isPermission = values.permissions.some((obj: $TSFixMe) => obj.read || obj.write);
      if (isPermission) {
        props.startLoading();
        await toast.promise(
          props.AddManageRolesCode({
            permissions: Object.values(values.permissions),
            description: values.description ?? "",
            liveStreaming: values.liveStreaming,
            name: values.name,
            status: values.status,
          }) as any,
          {
            success: TOAST_MSG.ADD_ROLE_SUCCESS,
          }
        );

        toggle();
      } else {
        toast.error("At least one permission is required.");
      }
    } catch (error) {
      console.log("error in ManageRole Add api", error);
    } finally {
      await props.endLoading();
    }
  };

  return (
    <>
      <Button onClick={toggle} className={`${selectedTheme}`}>
        Add Role
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modalTop">
        <ModalHeader className={`mod  ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Add Role</h5>
          <button className={`mult  ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={manageRolesAddValidationSchema}
          enableReinitialize={true}
          initialValues={{
            status: true,
            liveStreaming: true,
            name: "",
            permissions: [],
            description: "",
          }}
          onSubmit={handleAddManageRoles}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <Row>
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Name<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "range " +
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid " : "")
                        }
                        autoComplete="disable"
                        type="text"
                        name="name"
                        placeholder="Role Name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Description</div>
                      <Input
                        autoComplete="disable"
                        className="range"
                        placeholder="Description"
                        type="textarea"
                        value={values.description}
                        onChange={handleChange("description")}
                        onBlur={handleBlur("description")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="d-flex align-items-center">
                    <div className="modalheader mr-3 mt-1">Live Streaming</div>
                    <ToggleButton
                      checked={values.liveStreaming}
                      onClick={(value: boolean) =>
                        setFieldValue("liveStreaming", value)
                      }
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12} className="mt-2">
                    <PermissionsTable
                      disabled={false}
                      className={errors.permissions}
                      permissions={values.permissions}
                      setPermissions={(val: { [key: number]: ResourceRole }) =>
                        setFieldValue("permissions", val)
                      }
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <div className="modalheader mb-1">Status</div>
                  </Col>
                  <Col md={6}>
                    <RadioGroup
                      onChange={(e) =>
                        setFieldValue("status", e.target.value == "true")
                      }
                      className="radioStatus"
                      onBlur={handleBlur("status")}
                      aria-label="status"
                      defaultValue={values.status}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        checked={values.status}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={false}
                        checked={!values.status}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    disabled={isSubmitting}
                    // @ts-expect-error TS(2769): No overload matches this call.
                    onClick={handleSubmit}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading && (
                      <Spinner color="dark" size="sm" className="mr-2" />
                    )}
                    <>SUBMIT</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  AddManageRolesCode: (data: AddManageRolesCodeData) =>
    dispatch(AddManageRolesCode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
