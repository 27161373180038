import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { GeoFenceEvent } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable/dataTable";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";
import { getGeoFenceEvents, getGpsInfo } from "../../../../../api/axios.resources";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import GeoFenceEventModal from "./GeoFenceEventModal";
import { LayoutContext } from "../../../../../context/LayoutContext";

interface GeoFenceEventTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & GeoFenceEventTableProps;

const ALERTEventsTab = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [geoFenceEvents, setGeoFenceEvents] = useState<GeoFenceEvent[]>([]);
  const [isModal, setIsModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [eventsInfo, setEventsInfo] = useState([]);
  const [geoFence, setGeoFence] = useState({});
  const toggle = () => setIsModal(!isModal);

  const handleGetGeoFenceData = async (data: $TSFixMe) => {
    setIsLoading(true);
    try {
      const [gpsRes, eventsRes] = await Promise.all([
        getGpsInfo({
          deviceId: data.data.deviceId,
          projectId: data.data.projectId,
          timestamp: data.data.timestamp
        }),
        getGeoFenceEvents({
          deviceId: data.data.deviceId,
          index: data.data.indexNo
        })
      ]);

      if (eventsRes.status === 200 && gpsRes.status === 200) {
        setGeoFence(eventsRes.result);
        setEventsInfo(gpsRes.result);
        if (gpsRes.result.length > 0 && Object.keys(eventsRes.result).length > 0) {
          toggle();
        } else {
          toast.error("No data found");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const GeoFenceActionBtns = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        {isLoading && selectedId === data.data._id ? (
          <Spinner color="primary" size="sm" />
        ) : (
          <DirectionsCarIcon className="dropico" onClick={() => {
            setSelectedId(data.data._id);
            handleGetGeoFenceData(data);
          }} />
        )}
        {isModal &&
          <GeoFenceEventModal geoFence={geoFence} user={props.user} toggle={toggle} modal={isModal} theme={selectedTheme} markerData={eventsInfo} />
        }
      </div>
    );
  };

  const geoFenceColumns = [
    { title: "Fence No.", data: "indexNo" },
    {
      title: "Action",
      format: (data: $TSFixMe) => <GeoFenceActionBtns data={data} />,
    },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: " Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    {
      title: "Event",
      data: "event",
      format: (data: $TSFixMe) => data.eventName,
    },
    { title: "DateTime", data: "timestamp", format: (data: $TSFixMe) => data.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat) : "N/A", },
  ];

  useEffect(() => {
    setGeoFenceEvents([...(props.geoFenceEvents?.result?.data ?? [])]);
    return () => { };
  }, [props.geoFenceEvents]);

  return (
    <>
      <DataTable
        data={geoFenceEvents}
        columns={geoFenceColumns}
        bordered
        pageData={props.geoFenceEvents?.result?.page}
        totalRecrods={props.geoFenceEvents?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

// export default ALERTEventsTab;
const mapStateToProps = (state: RootState) => ({
  geoFenceEvents: state.dataStore.geoFenceEvents,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ALERTEventsTab);
