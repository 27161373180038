import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import { AppDispatch } from "../../../../../../redux/store/store";
import {
  endLoading,
  startLoading,
} from "../../../../../../redux/reducers/general/general.actions";
import ASSETS from "../../../../../../assets";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import { toast } from "react-toastify";
import { getLowEventVideoRequest } from "../../../../../../api/axios.resources";

interface ConfirmModelProps {
  data: $TSFixMe;
  selectedFilter: $TSFixMe;
  handleFilter: (d: $TSFixMe, type?: string) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & ConfirmModelProps;

const ConfirmModel = (props: Props) => {
  const [modal, setModal] = useState(false);
  const { selectedTheme } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => setModal(!modal);

  const handleRequestVideo = async () => {
    setIsLoading(true);
    try {
      const res: $TSFixMe = await getLowEventVideoRequest({ deviceId: props.data.data.deviceId, id: props.data.data._id, organizationId: props.data.data.organizationId, projectId: props.data.data.projectId });
      if (res.status === 200) {
        toast.success(res.message);
        props.handleFilter(props.selectedFilter, "noFormSubmit");
      }
      toggle();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <>
        <div className="modLab">
          <FileDownloadOutlinedIcon className="dropico" onClick={toggle} />
        </div>
      </>
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Request Device</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
          }}
          className="scr"
        >
          <Row>
            <div className="my-3 text-center">
              <b>Are you sure you want to request a video</b>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter className="footer-width">
          <div className="footwidth">
            <Button
              onClick={handleRequestVideo}
              disabled={isLoading}
              className="popbtn datewidth btnBox"
            >
              {isLoading && (
                <Spinner color="dark" size="sm" className="mr-2" />
              )}
              <>YES</>
            </Button>
          </div>
          <div className="divider" />
          <div
            className={"footwidth"}
          >
            <Button
              onClick={toggle}
              className="popbtn datewidth btnBox"
            >
              <>NO</>
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: $TSFixMe) => ({
  dropdowns: state.dropdownList.dropdowns,
  manageRoles: state.manageRolesData.manageRoles,
  token: state.userSlice.token,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModel);
