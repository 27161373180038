import { useEffect, useState } from "react";
import { Collapse, Row, Input, FormGroup, Form, Col, Label } from "reactstrap";
import CustomCropper from "../../../../../../../Common/CustomCropper";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { uploadPicture } from "../../../../../../../../api/axios.resources";

const SetLogo = (props: $TSFixMe) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState({
    headerLargeLogo: false,
    headerSmallLogo: false,
    footerLargeLogo: false,
    footerSmallLogo: false,
  });
  const [logo, setLogo] = useState({
    headerLargeLogo: "",
    headerSmallLogo: "",
    footerLargeLogo: "",
    footerSmallLogo: "",
  });

  const formik: any = useFormikContext();

  const toggle = () => setIsOpen(!isOpen);

  const logoUploadHandler = async (file: File | null, id: string) => {
    setIsLoading((prevIsLoading) => ({
      ...prevIsLoading,
      [id]: true, // or false depending on the scenario
    }));
    try {
      let formData = new FormData();
      await formData.append("picture", file as Blob);

      if (file) {
        // API Call for uploading file
        let res = await uploadPicture(formData);
        if (res.status === 200) {
          setLogo({ ...logo, [id]: res.result });
          toast.success("Logo uploaded successfully");
        } else if (res.status === 406) {
          toast.error(" sasfasfasdfasdfasdfasdf", res.message);
        }
      }
    } catch (error) {
      toast.error("error in uploading logo");
    } finally {
      setIsLoading((prevIsLoading) => ({
        ...prevIsLoading,
        [id]: false, // or false depending on the scenario
      }));
    }
  };

  useEffect(() => {
    let logoObj = {
      logo: isOpen,
      headerLargeLogo: logo?.headerLargeLogo,
      headerSmallLogo: logo?.headerSmallLogo,
      footerLargeLogo: logo?.footerLargeLogo,
      footerSmallLogo: logo?.footerSmallLogo,
    };

    if (logo) {
      props.setLogo(logoObj);
      formik.setValues({
        ...formik.values,
        logo: isOpen,
        headerLargeLogo: logo?.headerLargeLogo,
        headerSmallLogo: logo?.headerSmallLogo,
        footerLargeLogo: logo?.footerLargeLogo,
        footerSmallLogo: logo?.footerSmallLogo,
      });
    }
  }, [
    isOpen,
    logo.footerLargeLogo,
    logo.footerSmallLogo,
    logo.headerLargeLogo,
    logo.headerSmallLogo,
  ]);

  return (
    <Form>
      <FormGroup check>
        <Row className="mb-2">
          <Col>
            <Label className="customcamera" check>
              <Input
                autoComplete="disable"
                type="checkbox"
                color="primary"
                checked={isOpen}
                onChange={toggle}
              />
              Set Logo
            </Label>
          </Col>
        </Row>
        <Row>
          {/* <Col> */}
          <Collapse isOpen={isOpen}>
            <Row>
              <Label className="modlabel modinpulabel col-md-4">
                Header Large Logo
              </Label>
              <CustomCropper
                isLoading={isLoading?.headerLargeLogo}
                id="header-large-logo"
                editImageURL={logo.headerLargeLogo}
                aspect={93 / 34}
                onComplete={(file: $TSFixMe) => {
                  logoUploadHandler(file, "headerLargeLogo");
                }}
              />
            </Row>
            <br></br>
            <Row>
              <Label className="modlabel modinpulabel  col-md-4">
                Header Small Logo
              </Label>
              <CustomCropper
                isLoading={isLoading?.headerSmallLogo}
                id="header-small-logo"
                editImageURL={logo.headerSmallLogo}
                aspect={4 / 3}
                previewImage={
                  {
                    width: "72px",
                    height: "54px"
                  }
                }
                onComplete={(file: $TSFixMe) => {
                  logoUploadHandler(file, "headerSmallLogo");
                }}
              />
            </Row>
            <br></br>
            <Row>
              <Label className="modlabel modinpulabel  col-md-4">
                Footer Large Logo
              </Label>
              <CustomCropper
                isLoading={isLoading?.footerLargeLogo}
                id="footer-large-logo"
                editImageURL={logo.footerLargeLogo}
                aspect={93 / 34}
                onComplete={(file: $TSFixMe) => {
                  logoUploadHandler(file, "footerLargeLogo");
                }}
              />
            </Row>
            <br></br>
            <Row>
              <Label className="modlabel modinpulabel  col-md-4">
                Footer Small Logo
              </Label>
              <CustomCropper
                isLoading={isLoading?.footerSmallLogo}
                id="footer-small-logo"
                aspect={4 / 3}
                previewImage={
                  {
                    width: "72px",
                    height: "54px"
                  }
                }
                editImageURL={logo.footerSmallLogo}
                onComplete={(file: $TSFixMe) => {
                  logoUploadHandler(file, "footerSmallLogo");
                }}
              />
            </Row>
            <br></br>
          </Collapse>
          {/* </Col> */}
        </Row>
      </FormGroup>
    </Form>
  );
};

export default SetLogo;
