import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { driverDetails } from "../../../../../../../constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

function Headcards(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const [driverInfoCard, setDriverInfoCard] = useState(driverDetails);

  const handleGetCardData = () => {
    const updatedData = [...driverInfoCard];
    if (props.driverName) {
      updatedData[0].contentParent = props.driverName
    }
    if (props.orgName) {
      updatedData[1].contentParent = props.orgName
    }
    if (props.projectName) {
      updatedData[2].contentParent = props.projectName
    }
    setDriverInfoCard(updatedData);
  }

  useEffect(() => {
    handleGetCardData();
    return () => { }
  }, [props.orgName, props.projectName, props.driverName])

  return (
    <div className="row mx-0 mb-2">
      {driverInfoCard.map((item: $TSFixMe, index: number) => (
        <div key={index} className={`col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 ${item.classHead}`}>
          <div
            className={`dashbtn btn btn-outline-success btn-lg btn-block ${selectedTheme}`}
          >
            <div
              className={`dashboardIconWrapper icon-${index}`}
            >
              {item.icon}
            </div>
            <div className="dashBtnCont">
              <h3 className="num">{item.contentParent}</h3>
              <p className="tex">{item.contentChild}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Headcards;
