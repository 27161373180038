export const GET_SUPPORT_TICKET = "GET_SUPPORT_TICKET";
export const GET_SUPPORT_TICKET_SUCCESS = "GET_SUPPORT_TICKET_SUCCESS";
export const GET_SUPPORT_TICKET_ERROR = "GET_SUPPORT_TICKET_ERROR";

export const ADD_SUPPORT_TICKET = "ADD_SUPPORT_TICKET";
export const ADD_SUPPORT_TICKET_SUCCESS = "ADD_SUPPORT_TICKET_SUCCESS";
export const ADD_SUPPORT_TICKET_ERROR = "ADD_SUPPORT_TICKET_ERROR";

export const EDIT_SUPPORT_TICKET = "EDIT_SUPPORT_TICKET";
export const EDIT_SUPPORT_TICKET_SUCCESS = "EDIT_SUPPORT_TICKET_SUCCESS";
export const EDIT_SUPPORT_TICKET_ERROR = "EDIT_SUPPORT_TICKET_ERROR";

export const DELETE_SUPPORT_TICKET = "DELETE_SUPPORT_TICKET";
export const DELETE_SUPPORT_TICKET_SUCCESS = "DELETE_SUPPORT_TICKET_SUCCESS";
export const DELETE_SUPPORT_TICKET_ERROR = "DELETE_SUPPORT_TICKET_ERROR";
