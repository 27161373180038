import { useEffect, useState, Fragment, useContext } from "react";
import { Row, Col, Table } from "reactstrap";
import Columns from "./Columns";
import Rows from "./Rows";
import Pagination from "./Pagination";
import Filter from "./Filter";
import PageSize from "./PageSize";
import Counting from "./Counting";
import { like, resolveObjProp } from "./utils";
import { THEME_TYPE } from "../../../constants";
import { LayoutContext } from "../../../context/LayoutContext";
//@ts-expect-error
import MyComponent from "react-fullpage-custom-loader";

const defaultProps = {
  sentences: [],
  loaderType: "ball-clip-rotate",
  // color: "#007ae1",
  fadeIn: true,
  startFadeOut: false,
};

const DataTable = (props: $TSFixMe) => {
  const [dataFiltered, setDataFiltered] = useState(props.data ?? []);
  const [maxPage, setMaxPage] = useState(0);
  const [searchDataSize, setSearchDataSize]: $TSFixMe = useState(null);
  const [searchPage, setSearchPage]: $TSFixMe = useState(null);
  const { selectedTheme } = useContext(LayoutContext);
  const [filter, setFilter] = useState("");
  const [currentSortedCol, setCurrentSortedCol] = useState({
    idx: 0,
    sortedBy: 1,
  });
  let data1 = props.data.sort(function (a: $TSFixMe, b: $TSFixMe) {
    return a.id - b.id;
  });
  const data = data1 || [];
  const columns = props.columns;
  const onClickRow = props.onClickRow || null;

  const handlePageSize = (val: $TSFixMe) => {
    if (props.pageSize !== val) {
      props.setIsPageLoading(true);
    }
    props.setPageSize(val);
    setMaxPage(Math.ceil(props.totalRecrods / val));
    props.setCurrentPage(1);
  };

  const handleFilter = (val: $TSFixMe) => {
    setFilter(val);

    const query =
      "%" + val.toLowerCase().replace(new RegExp(" ", "g"), "%") + "%";

    let results = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      if (val === "") {
        results.push(row);
        continue;
      }

      const searchRow = columns.map((col: $TSFixMe) => {
        if (col.data) {
          return resolveObjProp(col.data, row);
        } else {
          return "";
        }
      });
      const cols = Object.values(searchRow).join(" ");
      if (like(cols, query)) {
        results.push(row);
      }
    }

    if (val === "") {
      setMaxPage(Math.ceil(props?.totalRecrods / props.pageSize));
      setSearchDataSize(null);
      setSearchPage(null);
    } else {
      setMaxPage(Math.ceil(results.length / props.pageSize));
      setSearchDataSize(results.length);
      setSearchPage(1);
    }
    setDataFiltered(results);
  };

  const handleCurrentPage = (page: $TSFixMe) => {
    if (props.currentPage !== page) {
      props.setIsPageLoading(true);
    }
    props.setCurrentPage(page);
  };

  //TODO: As of now comment this code bcz sorting handle from API.
  // const sortData = (i: number, nextSort: number, passedData: [] = []) => {
  //   const sortData = passedData.length !== 0
  //     && [
  //       ...passedData.sort((a: $TSFixMe, b: $TSFixMe) => {
  //         const col = columns[i];
  //         let keyA = "",
  //           keyB = "";

  //         if (col.data) {
  //           keyA = resolveObjProp(col.data, a);
  //           keyB = resolveObjProp(col.data, b);
  //         } else {
  //           keyA = col.format(a);
  //           keyB = col.format(b);
  //         }

  //         if (keyA < keyB) return -nextSort;
  //         if (keyA > keyB) return nextSort;
  //         return 0;
  //       }),
  //     ];
  //   return sortData;
  // };

  const handleSortColumn = (i: number, nextSort: number) => {
    // let data = sortData(i, nextSort, props.data);
    // setDataFiltered(data);
    // props.setCurrentPage(1);
    props.setIsPageLoading(true);
    props.setSortColumn(columns[i].data);
    props.setIsAsc(nextSort === -1 ? false : true);
    setCurrentSortedCol({ idx: i, sortedBy: nextSort });
  };

  useEffect(() => {
    // let data = sortData(
    //   currentSortedCol.idx,
    //   currentSortedCol.sortedBy,
    //   props.data
    // );
    setMaxPage(Math.ceil(props?.totalRecrods / (props.pageSize ?? props.pageSize ?? 10)));
    setDataFiltered(props.data);
    return () => { };
  }, [props.data]);

  return (
    <Fragment>
      <Row className="mb-2">
        <Col sm={12} md={6}>
          <PageSize pageSize={props.pageSize} handlePageSize={handlePageSize} />
        </Col>
        <Col sm={12} md={6} className="pageEnd">
          <Filter filter={filter} handleFilter={handleFilter} cardColorType={selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor'} />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table
            className="imptab imptab2 position-relative"
            bordered={props.bordered}
            hover={props.hover}
            borderless={props.borderless}
            responsive={props.responsive}
          >
            <Columns
              columns={columns}
              selectedTheme={selectedTheme}
              currentSortedCol={currentSortedCol}
              handleSortColumn={handleSortColumn}
            />
            {dataFiltered.length ? (
              <>
                <Rows
                  columns={columns}
                  currentRows={dataFiltered}
                  selectedTheme={selectedTheme}
                  onClickRow={onClickRow}
                />
              </>
            ) : (
              <tbody>
                <tr className={`${selectedTheme} hoverStyle`}>
                  <td colSpan={columns.length}>
                    <span className={(selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE || selectedTheme === THEME_TYPE.CLARET_BLUE) ? "outtab" : ""}>No Data Available</span>
                  </td>
                </tr>
              </tbody>
            )}
            {props?.pageData && dataFiltered.length !== 0 && props.isPageLoading &&
              <div className={`${selectedTheme} table-loader`}>
                <MyComponent {...defaultProps} />
              </div>
            }
          </Table>
        </Col>
      </Row>
      {props?.totalRecrods && props?.pageData && dataFiltered.length !== 0 ? (
        <Row>
          <Col xs="12" md="3" className="tableCol">
            <Counting
              currentPage={searchPage ?? props?.pageData?.no}
              pageSize={searchDataSize ?? props.pageSize}
              currentMax={searchDataSize ?? props?.totalRecrods}
            />
          </Col>
          <Col xs="12" md="9" className="text-right">
            <Pagination
              currentPage={searchPage ?? props.currentPage}
              selectedTheme={selectedTheme}
              handleCurrentPage={handleCurrentPage}
              maxPage={maxPage}
            />
          </Col>
        </Row>
      ) : ""}
    </Fragment>
  );
};

export default DataTable;
