import React from "react";
import { Container } from "reactstrap";
import MapCard from "./mapCard";
import FullPageLoaderModal from "../../Common/FullPageLoader/FullPageLoaderModal";
import { connect } from "react-redux";
import { RootState } from "../../../redux/reducers/rootReducer";

type Props = ReturnType<typeof mapStateToProps>;

function Map(props: Props) {
  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <MapCard />
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
});

export default connect(mapStateToProps)(Map);
