import { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import Slider from "@material-ui/core/Slider";
import Select, { MultiValue, SingleValue } from "react-select";
import { Col, Row, FormGroup, Button, Form, Container, CardBody, Input, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "@material-ui/core";
import COLORS from "../../../../../../colors";
import { getEditCommonConfigurationImei } from "../../../../../../redux/reducers/Management&Settings/commonConfiguration/commonConfiguration.actions";
import { endLoading, startLoading } from "../../../../../../redux/reducers/general/general.actions";
import { AppDispatch } from "../../../../../../redux/store/store";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { AddCommonConfigurationTemplateType } from "../../../../../../../global.types";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import { THEME_TYPE } from "../../../../../../constants";
import { addCommonConfigurationSection, getRFIdDriverList, getWakeUpCondition, getWakeUpConditionTime, restoreFactorySettings, updateCommonConfiguration, updateCommonConfigurationName } from "../../../../../../api/axios.resources";
import FullPageLoaderModal from "../../../../../Common/FullPageLoader/FullPageLoaderModal";
import ToggleButton from "../../../../../Common/ToggleButton";

interface Options1 {
  value: string;
  label: string;
}

// @ts-expect-error TS(2345): Argument of type '(theme: Theme) => { root: { widt... Remove this comment to see the full error message
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "20%",
    fontWeight: "600",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const PrettoSlider = withStyles(() => ({
  root: {
    color: COLORS.pureMostlyPureBlue,
    height: 8,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: COLORS.blue,
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-100% + 1px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);


type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function EditDeviceConfig(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const [online, setonline] = useState(false);
  const [online1, setonline1] = useState(false);
  const [options, setOptions] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [options1, setOptions1] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [options2, setOptions2] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [driversList, setDriversList] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [isLoading, setIsLoading]: $TSFixMe = useState<boolean>(false);
  const [isNameLoading, setIsNameLoading] = useState<boolean>(false);
  const [errors, setErrors]: $TSFixMe = useState({}); // State to hold validation errors
  const [expanded, setExpanded] = useState("");
  const [nameError, setNameError] = useState<string | null>(null);
  const history = useHistory();
  const classes = useStyles();
  const [formData, setFormData] = useState<AddCommonConfigurationTemplateType>({
    aggregatePackageSetting: {
      aggregatePackage: false,
    },
    apnSetting: {
      apn: "",
      mcc: "",
      mnc: "",
      name: "",
      password: "",
      userName: "",
    },
    audioConfiguration: {
      recordAudio: false,
    },
    axisDetailsConfiguration: {
      detailedVehicleAxisData: false,
    },
    axisValue: {
      highAxisValue: {
        acceleration: 0,
        braking: 0,
        bump: 0,
        fall: 0,
        leftCornering: 0,
        rightCornering: 0,
      },
      lowAxisValue: {
        acceleration: 0,
        braking: 0,
        bump: 0,
        fall: 0,
        leftCornering: 0,
        rightCornering: 0,
      },
    },
    cameraConfiguration: {
      fifthCameraChannel: false,
      // firstCameraChannel: false,
      fourthCameraChannel: false,
      secondCameraChannel: false,
      thirdCameraChannel: false,
    },
    driverRFIdConfiguration: {
      enable: false,
      csEnable: false,
      driverIds: [],
    },
    dataPackage: {
      dataPackageId: 0,
    },
    description: "",
    name: "",
    parkModeSetting: {
      wakeUpConditionId: 1,
      wakeUpMode: false,
    },
    partsDetail: {
      cabling: "",
      driverPassengerCameras: "",
      hdd: "",
      hub: "",
      monitor: "",
      reversingCamera: "",
      sideCamera: "",
      sideSensorKit: "",
      streetangelct: "",
      suziKit: "",
    },
    restoreFactorySetting: {
      level: 1,
    },
    roamingSetting: {
      roaming: false,
    },
    sleepDelay: {
      allowUseOfVehicleBetteryAfterIgnitionOff: false,
      wakeUpConditionTimeId: 1,
    },
    speedConfiguration: {
      high: online,
      highSpeedGreterThan: 0,
      highSpeedLessThan: 0,
      low: online1,
      lowSpeedGreterThan: 0,
      lowSpeedLessThan: 0,
    },
    status: false,
    videoConfiguration: {
      videoPerOffset: 0,
      videoPostOffset: 0,
    },
    warrantyOption: {
      warrantyOptionId: 1,
    },
  });
  const location = useLocation();
  const queryParams: $TSFixMe = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const imei: boolean = queryParams.get("imei");
  const cardColorType =
    selectedTheme === THEME_TYPE.GREY_SCALE ||
      selectedTheme === THEME_TYPE.BLUE_WHITE_GREY
      ? "cardcolor-light"
      : "cardcolor";

  const getPanelKey = (panel: string): string => {
    switch (panel) {
      case "panel1":
        return "aggregatePackageSetting";
      case "panel2":
        return "apnSetting";
      case "panel3":
        return "audioConfiguration";
      case "panel4":
        return "axisDetailsConfiguration";
      case "panel5":
        return "axisValue";
      case "panel6":
        return "cameraConfiguration";
      case "panel7":
        return "dataPackage";
      case "panel8":
        return "partsDetail";
      case "panel9":
        return "parkModeSetting";
      case "panel10":
        return "roamingSetting";
      case "panel11":
        return "restoreFactorySetting";
      case "panel12":
        return "sleepDelay";
      case "panel13":
        return "speedConfiguration";
      case "panel14":
        return "videoConfiguration";
      case "panel15":
        return "warrantyOption";
      default:
        return ""; // Handle unknown panels appropriately
    }
  };

  const apiCall = async () => {
    try {
      props.startLoading();
      const res1 = await restoreFactorySettings();
      let val1: Options1[] = res1.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(val1);
      const res2 = await getWakeUpCondition();
      let val2: Options1[] = res2.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions1(val2);
      const res3 = await getWakeUpConditionTime();
      let val3: Options1[] = res3.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions2(val3);
      const res4 = await getRFIdDriverList(id);
      let val4: Options1[] = res4.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setDriversList(val4);
    } catch (error) {
      console.log("error", error);
    } finally {
      props.endLoading();
    }
  };

  const handleChange =
    (panel: $TSFixMe) => (event: $TSFixMe, isExpanded: $TSFixMe) => {
      setExpanded(isExpanded ? panel : "");
    };

  const validatePartsDetail = (partsDetail: $TSFixMe) => {
    const errors: $TSFixMe = {};

    if (!partsDetail.cabling) {
      errors.partsDetailCabling = "Cabling is required";
    }
    if (!partsDetail.driverPassengerCameras) {
      errors.partsDetailDriverPassengerCameras =
        "Driver Passenger Cameras is required";
    }
    if (!partsDetail.hdd) {
      errors.partsDetailHdd = "HDD is required";
    }
    if (!partsDetail.hub) {
      errors.partsDetailHub = "Hub is required";
    }
    if (!partsDetail.monitor) {
      errors.partsDetailMonitor = "Monitor is required";
    }
    if (!partsDetail.reversingCamera) {
      errors.partsDetailReversingCamera = "Reversing Camera is required";
    }
    if (!partsDetail.sideCamera) {
      errors.partsDetailSideCamera = "Side Camera is required";
    }
    if (!partsDetail.sideSensorKit) {
      errors.partsDetailSideSensorKit = "Side Sensor Kit is required";
    }
    if (!partsDetail.streetangelct) {
      errors.partsDetailStreetangelct = "Road devil is required";
    }
    if (!partsDetail.suziKit) {
      errors.partsDetailSuziKit = "Suzi Kit is required";
    }

    return errors;
  };

  const validateApnSetting = (apnSetting: $TSFixMe) => {
    const errors: $TSFixMe = {};

    if (!apnSetting.name) {
      errors.apnSettingName = "Name is required";
    }
    if (!apnSetting.mcc) {
      errors.apnSettingMCC = "MCC is required";
    }
    if (!apnSetting.mnc) {
      errors.apnSettingMNC = "MNC is required";
    }
    if (!apnSetting.apn) {
      errors.apnSettingAPN = "APN is required";
    }
    if (!apnSetting.userName) {
      errors.apnSettingUserName = "User Name is required";
    }

    return errors;
  };

  const updateFormData = (key: string, newData: $TSFixMe) => {
    setFormData((prevFormData: $TSFixMe) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        ...newData,
      },
    }));
  };

  const handleAccordionSubmit = async (panel: string, data?: $TSFixMe, moduleURL?: $TSFixMe) => {
    try {
      setIsLoading(true);
      if (panel === "panel8") {
        const errors = validatePartsDetail(data);
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        }
      } else if (panel === "panel2") {
        const errors = validateApnSetting(data);
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        }
      }

      const panelKey = getPanelKey(panel); // Replace this with the actual logic to get the key based on the panel
      let req = {
        id: formData?.id,
        moduleURL,
        data,
      }
      let res: $TSFixMe;
      const hasId = checkForObjectId(data);
      if (hasId) {
        res = await updateCommonConfiguration(req)
      } else {
        res = await addCommonConfigurationSection(req)
      }
      if (res.status >= 200 && res.status < 300) {
        updateFormData(panelKey, res.result);
        toast.success(res.message)
        setExpanded("");
      } else {
        toast.error(res.message)
      }
    } catch (e) {
      toast.error((e as $TSFixMe).error)
    } finally {
      setIsLoading(false);
    }
  };

  // Callback function to recursively check if an object or its nested properties have an 'id'
  const checkForObjectId = (object: $TSFixMe): boolean => {
    if (object === null || typeof object !== 'object') {
      return false;
    }

    if (object.id !== undefined) {
      return true;
    }

    for (const key in object) {
      if (checkForObjectId(object[key])) {
        return true;
      }
    }

    return false;
  };

  const handleAccordionCancel = () => {
    setExpanded("");
  };

  const handleInputChange =
    (panel: string) => (name: string, value: string | boolean | number | string[]) => {
      setFormData((prevFormData: $TSFixMe) => {
        // Split the name string to access nested properties
        const nameParts = name.split(".");
        // Traverse the nested properties one by one
        let updatedFormData = { ...prevFormData };
        let target = updatedFormData[panel];
        for (let i = 0; i < nameParts.length - 1; i++) {
          target = target?.[nameParts[i]];
          if (!target) {
            // Handle the case where an intermediate property is undefined
            // You can initialize it if required or return early
            return updatedFormData;
          }
        }

        // Update the final property with the provided value
        target[nameParts[nameParts.length - 1]] = value;

        return updatedFormData;
      });
    };
    const handleDriverChange = (selectedOptions: MultiValue<Options1>) => {
      if (selectedOptions.length === 0) {
        toast.error('At least one driver must be selected.');
      } else {
        handleInputChange('driverRFIdConfiguration')('driverIds', selectedOptions.map(option => option.value));
      }
    };

  const handleNameSubmit = async () => {
    try {
      setIsNameLoading(true);
      if (!formData?.name) {
        setNameError("Name is required");
        return;
      } else {
        let req = {
          id: formData?.id,
          data: {
            name: formData.name,
            description: formData.description,
          }
        }
        const res: $TSFixMe = await updateCommonConfigurationName(req)
        if (res.status === 200) {
          toast.success(res.message)
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsNameLoading(false);

    }
  }

  useEffect(() => {
    if (!props?.commonConfiguration?.id) {
      props.getEditCommonConfigurationImei(id);
    }
    // Filter out properties with null values
    const filteredData = Object.fromEntries(
      Object?.entries(props.commonConfiguration).filter(([key, value]) => value !== null)
    );

    // Spread formData and then spread filteredData
    setFormData({ ...formData, ...filteredData });

    // Update submittedAccordions based on keys in filteredData
    // setSubmittedAccordions((prevState: $TSFixMe) => {
    //   const updatedAccordions: $TSFixMe = { ...prevState };

    //   // Iterate over keys of submittedAccordions
    //   Object.keys(updatedAccordions).forEach((key) => {
    //     // Check if the key is present in filteredData
    //     if (filteredData.hasOwnProperty(key)) {
    //       updatedAccordions[key] = true;
    //     } else {
    //       updatedAccordions[key] = false;
    //     }
    //   });

    //   return updatedAccordions;
    // });
  }, [props.commonConfiguration]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />

      <Container className="mt--5" fluid>
        <Row className="mt-0">
          <Col>
            <Card
              className={`card-stats  md-4 mb-xl-0 ${cardColorType} allPage`}
            >
              <div className="mb-4">
                <Row className="mb-4">
                  <Col sm={12} md={6} className="tripsname">
                    <div className="tabpage">
                      <>Device Configuration</>
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="pageEnd tabSet">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      className={`${selectedTheme}`}
                      disabled={props.isLoading}
                    >
                      Exit
                    </Button>
                  </Col>
                </Row>
                <Form className={`${selectedTheme} accordform`}>
                  <Row className="align-items-baseline">
                    <Col md={5}>
                      <FormGroup>
                        <Input
                          className={
                            "form-control dark-theme-form-control" + (nameError ? " is-invalid " : "")
                          }
                          autoComplete="disable"
                          type="text"
                          name="name"
                          id="Name"
                          value={formData.name}
                          placeholder="Name"
                          onChange={(e: $TSFixMe) => setFormData({ ...formData, name: e.target.value })}

                        />
                        <div className="dangerText mt-1">{nameError}</div>
                      </FormGroup>
                    </Col>
                    <Col md={5} >
                      <FormGroup>
                        <Input
                          className="dark-theme-form-control"
                          type="text"
                          name="description"
                          id="Description"
                          placeholder="Description"
                          value={formData.description}
                          onChange={(e: $TSFixMe) => setFormData({ ...formData, description: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2} className="headtagend text-end">
                      <Button className={`${selectedTheme} h-35-px`} onClick={handleNameSubmit}>
                        {isNameLoading ? <Spinner size="sm" className="mr-2" /> : null}
                        Save
                      </Button>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={3}>
                      <RadioGroup
                        onChange={handleStatusChange}
                        style={{ display: "flex", flexDirection: "row" }}
                        className="radioStatus"
                        value={formData.status}
                        aria-label="status"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          className="radioStatusConfig"
                          checked={configurationData?.status}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value={false}
                          className="radioStatusConfig"
                          checked={!configurationData?.status}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    </Col>
                  </Row> */}
                </Form>
              </div>
              <CardBody className="tabAll mb-4 mt-6">
                <div className={((classes as $TSFixMe).root, "commonConfigurationAccord")}>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className={`mt-md-0 mt-5 ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className={`accordionSummary accordianTop mt-md-0 mt-5`}
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Aggregate Package Setting
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails pb-4">
                      <>
                        <Row className="accordionData">
                          <Col md={3}>
                            <div className="modinpulabel modlabel">Aggregate Package</div>
                          </Col>
                          <Col md={0} className="accordianAggregate mt-md-0 mt-1">
                            <ToggleButton
                              checked={formData?.aggregatePackageSetting?.aggregatePackage}
                              onClick={(value: boolean) =>
                                handleInputChange("aggregatePackageSetting")(
                                  "aggregatePackage",
                                  value
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel1", formData?.aggregatePackageSetting, "aggregate-package-setting");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className={`accordionSummary ${selectedTheme}`}
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Apn Setting
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row className="accordianApn align-items-baseline">
                          <Col md={6} className="">
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="name">
                                Name
                              </div>
                              <Input
                                autoComplete="disable"
                                className={`datewidth dark-theme-form-control ${(errors?.apnSettingName ? " is-invalid " : "")}`}
                                type="text" // Assuming 'type' should be 'text' for name input
                                placeholder="Name"
                                value={formData?.apnSetting?.name}
                                onChange={(event) =>
                                  handleInputChange("apnSetting")(
                                    "name",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.apnSettingName && (
                                <div className="validate">
                                  {errors?.apnSettingName}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="mcc">
                                MCC
                              </div>

                              <Input
                                autoComplete="disable"
                                className={`datewidth dark-theme-form-control ${(errors?.apnSettingMCC ? " is-invalid " : "")}`}
                                type="text" // Assuming 'type' should be 'text' for mcc input
                                placeholder="MCC"
                                value={formData?.apnSetting?.mcc}
                                onChange={(event) =>
                                  handleInputChange("apnSetting")("mcc", event.target.value)
                                }
                              />
                              {errors?.apnSettingMCC && (
                                <div className="validate">
                                  {errors?.apnSettingMCC}
                                </div>
                              )}
                            </FormGroup>

                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="mnc">
                                MNC
                              </div>

                              <Input
                                autoComplete="disable"
                                className={`datewidth dark-theme-form-control ${(errors?.apnSettingMNC ? " is-invalid " : "")}`}
                                type="text" // Assuming 'type' should be 'text' for mnc input
                                placeholder="MNC"
                                value={formData?.apnSetting?.mnc}
                                onChange={(event) =>
                                  handleInputChange("apnSetting")("mnc", event.target.value)
                                }
                              />
                              {errors?.apnSettingMNC && (
                                <div className="validate">
                                  {errors?.apnSettingMNC}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="apn">
                                APN
                              </div>

                              <Input
                                autoComplete="disable"
                                className={`datewidth dark-theme-form-control ${(errors?.apnSettingAPN ? " is-invalid " : "")}`}
                                type="text" // Assuming 'type' should be 'text' for apn input
                                placeholder="APN"
                                value={formData?.apnSetting?.apn}
                                onChange={(event) =>
                                  handleInputChange("apnSetting")("apn", event.target.value)
                                }
                              />
                              {errors?.apnSettingAPN && (
                                <div className="validate">
                                  {errors?.apnSettingAPN}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="user">
                                User
                              </div>
                              <Input
                                className={`datewidth dark-theme-form-control ${(errors?.apnSettingUserName ? " is-invalid " : "")}`}
                                autoComplete="disable"
                                type="text" // Assuming 'type' should be 'text' for user input
                                placeholder="User"
                                value={formData?.apnSetting?.userName}
                                onChange={(event) =>
                                  handleInputChange("apnSetting")(
                                    "userName",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.apnSettingUserName && (
                                <div className="validate">
                                  {errors?.apnSettingUserName}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modinpulabel modlabel" htmlFor="password">
                                Password
                              </div>
                              <Input
                                type="text"
                                className="dark-theme-form-control"
                                placeholder="Password"
                                value={formData?.apnSetting?.password}
                                autoComplete="disable"
                                onChange={(event) =>
                                  handleInputChange("apnSetting")(
                                    "password",
                                    event.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onSubmit={(values) => {
                          }}
                          onClick={() => {
                            handleAccordionSubmit("panel2", formData.apnSetting, "apn-setting");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Audio Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row>
                          <Col xs={4} className="modinpulabel modlabel">
                            <>Record Audio</>
                          </Col>
                          <Col xs={1} className="accordianRoaming mt-md-0 mt-1">
                            <ToggleButton
                              checked={formData?.audioConfiguration?.recordAudio}
                              onClick={(value: boolean) => {
                                handleInputChange("audioConfiguration")(
                                  "recordAudio",
                                  value
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel3", formData.audioConfiguration, "audio-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Axis Details Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row className="accordionData">
                          <Col md={3}>
                            <div className="modinpulabel modlabel">
                              Detailed Vehicle AxisData
                            </div>
                          </Col>
                          <Col md={0} className="accordianRoaming mt-md-0 mt-1">
                            <ToggleButton
                              checked={
                                formData?.axisDetailsConfiguration?.detailedVehicleAxisData
                              }
                              onClick={(value: boolean) => {
                                handleInputChange("axisDetailsConfiguration")(
                                  "detailedVehicleAxisData",
                                  value
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel4", formData.axisDetailsConfiguration, "axis-details-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Axis Value
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <>
                          <Row>
                            <Col>
                              <div className="label-light">
                                <span className="accordionBold">LOW AXIS VALUE(G)*</span>{" "}
                                Ideal Value For This Field Is 0.4
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.lowAxisValue?.braking ?? 0}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.braking",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Braking
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                                value={formData?.axisValue?.lowAxisValue?.acceleration}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.acceleration",
                                    value as number
                                  );
                                }}
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Acceleration
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                                value={formData?.axisValue?.lowAxisValue?.leftCornering}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.leftCornering",
                                    value as number
                                  );
                                }}
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Left Cornering
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                                value={formData?.axisValue?.lowAxisValue?.rightCornering}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.rightCornering",
                                    value as number
                                  );
                                }}
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Right Cornering
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                                value={formData?.axisValue?.lowAxisValue?.fall}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.fall",
                                    value as number
                                  );
                                }}
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Fall
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                                value={formData?.axisValue?.lowAxisValue?.bump}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "lowAxisValue.bump",
                                    value as number
                                  );
                                }}
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Bump
                              </div>
                            </Col>
                          </Row>
                        </>

                        <br />
                        <>
                          <Row>
                            <Col>
                              <div className="label-light mb-2">
                                <span className="accordionBold mt-md-0 mt-2">
                                  HIGH AXIS VALUE(G)*
                                </span>{" "}
                                Ideal Value For This Field Is 0.9
                              </div>
                            </Col>
                          </Row>
                        </>
                        <br />
                        <>
                          <Row className="mt-3">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.braking}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.braking",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Braking
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.acceleration}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.acceleration",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Acceleration
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.leftCornering}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.leftCornering",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Left Cornering
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.rightCornering}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.rightCornering",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Right Cornering
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.fall}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.fall",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Fall
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                value={formData?.axisValue?.highAxisValue?.bump}
                                onChange={(event: any, value: number | number[]) => {
                                  handleInputChange("axisValue")(
                                    "highAxisValue.bump",
                                    value as number
                                  );
                                }}
                                step={0.1}
                                min={0}
                                max={2}
                                valueLabelDisplay="on"
                              />
                              <div className="accordionDescrip accordianSliderDesc label-light">
                                Bump
                              </div>
                            </Col>
                          </Row>
                        </>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel5", formData?.axisValue, "axis-value");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel6bh-content"
                      id="panel6bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Camera Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <div className="datewidth">
                        <Row className="accordionData">
                          {/* <Col xs={12} md={6}>
                  <Editable
                  tag="p"
                  type="text1 label-light"
                  maxLength="20"
                  // onChange={onChange1}
                    value={text1}
                  /> */}
                          {/* <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig modinpulabel modlabel">
                              First Camera Channel (Front)
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.cameraConfiguration?.firstCameraChannel}
                                onClick={(value: boolean) =>
                                  handleInputChange("cameraConfiguration")(
                                    "firstCameraChannel",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col> */}
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig  modinpulabel modlabel">
                              Second Camera Channel
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.cameraConfiguration?.secondCameraChannel}
                                onClick={(value: boolean) =>
                                  handleInputChange("cameraConfiguration")(
                                    "secondCameraChannel",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig modinpulabel modlabel">
                              Third Camera Channel
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.cameraConfiguration?.thirdCameraChannel}
                                onClick={(value: boolean) =>
                                  handleInputChange("cameraConfiguration")(
                                    "thirdCameraChannel",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig modinpulabel modlabel">
                              Fourth Camera Channel
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.cameraConfiguration?.fourthCameraChannel}
                                onClick={(value: boolean) =>
                                  handleInputChange("cameraConfiguration")(
                                    "fourthCameraChannel",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig modinpulabel modlabel">
                              Fifth Camera Channel
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.cameraConfiguration?.fifthCameraChannel}
                                onClick={(value: boolean) =>
                                  handleInputChange("cameraConfiguration")(
                                    "fifthCameraChannel",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel6", formData?.cameraConfiguration, "camera-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel7bh-content"
                      id="panel7bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Data Package
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <div className="d-flex align-items-center w-75">
                          <div className="mx-auto">
                            <Input
                              type="radio"
                              name="customRadio0"
                              className="warranty-radio-btn"
                              id="monthly"
                              checked={formData?.dataPackage?.dataPackageId === 0}
                              onChange={() =>
                                handleInputChange("dataPackage")("dataPackageId", 0)
                              }
                            />
                            <label className="customcamera  label-light cursor-pointer" htmlFor="monthly">Monthly DD</label>
                          </div>
                          <div className="mx-auto">
                            <Input
                              type="radio"
                              name="customRadio1"
                              className="warranty-radio-btn"
                              id="one-year"
                              checked={formData?.dataPackage?.dataPackageId === 1}
                              onChange={() =>
                                handleInputChange("dataPackage")("dataPackageId", 1)
                              }
                            />
                            <label className="customcamera  label-light cursor-pointer" htmlFor="one-year">
                              1 Year Data Package
                            </label>
                          </div>
                          <div className="mx-auto">
                            <Input
                              type="radio"
                              id="two-year"
                              name="customRadio2"
                              className="warranty-radio-btn"
                              checked={formData?.dataPackage?.dataPackageId === 2}
                              onChange={() =>
                                handleInputChange("dataPackage")("dataPackageId", 2)
                              }
                            />
                            <label className="customcamera  label-light cursor-pointer" htmlFor="two-year">
                              2 Year Data Package
                            </label>
                          </div>
                          <div className="mx-auto">
                            <Input
                              type="radio"
                              name="customRadio3"
                              id="three-year"
                              className="warranty-radio-btn"
                              checked={formData?.dataPackage?.dataPackageId === 3}
                              onChange={() =>
                                handleInputChange("dataPackage")("dataPackageId", 3)
                              }
                            />
                            <label className="customcamera  label-light cursor-pointer" htmlFor="three-year">
                              3 Year Data Package
                            </label>
                          </div>
                        </div>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel7", formData?.dataPackage, "data-package");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel8"}
                    onChange={handleChange("panel8")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel8bh-content"
                      id="panel8bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Parts Details
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="address">
                                Road Devil
                              </div>

                              <Input
                                autoComplete="disable"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailStreetangelct ? " is-invalid " : "")}`}
                                type="text"
                                name="address"
                                id="address"
                                placeholder=""
                                value={formData?.partsDetail?.streetangelct}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "streetangelct",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailStreetangelct && (
                                <div className="validate">
                                  {errors?.partsDetailStreetangelct}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="sideCameras">
                                Side Cameras
                              </div>

                              <Input
                                type="text"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailSideCamera ? " is-invalid " : "")}`}
                                placeholder=""
                                name="sideCameras"
                                id="sideCameras"
                                value={formData?.partsDetail?.sideCamera}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "sideCamera",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailSideCamera && (
                                <div className="validate">
                                  {errors?.partsDetailSideCamera}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              <div className="modlabel modinpulabel">Reversing Camera</div>
                              <Input
                                // @ts-expect-error
                                type="reversingCamera"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailReversingCamera ? " is-invalid " : "")}`}
                                placeholder=""
                                name="reversingCameras"
                                id="reversingCameras"
                                value={formData?.partsDetail?.reversingCamera}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "reversingCamera",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailReversingCamera && (
                                <div className="validate">
                                  {errors?.partsDetailReversingCamera}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="hub">
                                Hub
                              </div>

                              <Input
                                autoComplete="disable"
                                // @ts-expect-error
                                type="hub"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailHub ? " is-invalid " : "")}`}
                                placeholder=""
                                name="hub"
                                id="hub"
                                value={formData?.partsDetail?.hub}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "hub",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailHub && (
                                <div className="validate">
                                  {errors?.partsDetailHub}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="hdd">
                                HDD
                              </div>

                              <Input
                                autoComplete="disable"
                                // @ts-expect-error
                                type="hdd"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailHdd ? " is-invalid " : "")}`}
                                name="hdd"
                                id="hdd"
                                placeholder=""
                                value={formData?.partsDetail?.hdd}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "hdd",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailHdd && (
                                <div className="validate">
                                  {errors?.partsDetailHdd}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="cabling">
                                Cabling
                              </div>

                              <Input
                                // @ts-expect-error
                                type="cabling"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailCabling ? " is-invalid " : "")}`}
                                name="cabling"
                                id="cabling"
                                placeholder=""
                                value={formData?.partsDetail?.cabling}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "cabling",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailCabling && (
                                <div className="validate">
                                  {errors?.partsDetailCabling}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="monitor">
                                Monitor
                              </div>

                              <Input
                                // @ts-expect-error
                                type="monitor"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailMonitor ? " is-invalid " : "")}`}
                                name="monitor"
                                id="monitor"
                                placeholder=""
                                value={formData?.partsDetail?.monitor}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "monitor",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailMonitor && (
                                <div className="validate">
                                  {errors?.partsDetailMonitor}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="modlabel modinpulabel">Side Sensor Kit</div>

                              <Input
                                // @ts-expect-error
                                type="sideSensorKit"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailSideSensorKit ? " is-invalid " : "")}`}
                                name="sideSensorKit"
                                id="sideSensorKit"
                                placeholder=""
                                value={formData?.partsDetail?.sideSensorKit}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "sideSensorKit",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailSideSensorKit && (
                                <div className="validate">
                                  {errors?.partsDetailSideSensorKit}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-baseline">
                          <Col md={6}>
                            <FormGroup>
                              {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                              <div className="modlabel modinpulabel" htmlFor="suziKit">
                                Suzi Kit
                              </div>

                              <Input
                                // @ts-expect-error
                                type="suziKit"
                                name="suziKit"
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailSuziKit ? " is-invalid " : "")}`}
                                id="suziKit"
                                placeholder=""
                                value={formData?.partsDetail?.suziKit}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "suziKit",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailSuziKit && (
                                <div className="validate">
                                  {errors?.partsDetailSuziKit}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div
                                className="modlabel modinpulabel"
                                // @ts-expect-error
                                htmlFor="driver/PassengerCameras"
                              >
                                Driver/Passenger Cameras
                              </div>

                              <Input
                                // @ts-expect-error
                                type="driver/PassengerCameras"
                                name="driver/PassengerCameras"
                                id="driver/PassengerCameras"
                                placeholder=""
                                className={`datewidth dark-theme-form-control ${(errors?.partsDetailDriverPassengerCameras ? " is-invalid " : "")}`}
                                value={formData?.partsDetail?.driverPassengerCameras}
                                onChange={(event) =>
                                  handleInputChange("partsDetail")(
                                    "driverPassengerCameras",
                                    event.target.value
                                  )
                                }
                              />
                              {errors?.partsDetailDriverPassengerCameras && (
                                <div className="validate">
                                  {errors?.partsDetailDriverPassengerCameras}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel8", formData?.partsDetail, "parts-detail");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel9"}
                    onChange={handleChange("panel9")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel9bh-content"
                      id="panel9bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Park Mode Setting
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row>
                          <Col md={5}>
                            <Row>
                              <Col sm={6}>
                                <p className="mt-md-3 label-light">WakeUp Mode</p>
                              </Col>
                              <Col sm={6}>
                                <ToggleButton
                                  checked={formData?.parkModeSetting?.wakeUpMode}
                                  onClick={(value: boolean) =>
                                    handleInputChange("parkModeSetting")(
                                      "wakeUpMode",
                                      value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={5}>
                            <Row>
                              <Col sm={12} className="mt-md-0 mt-5 ml-md-5">
                                <div className="label-light mb-1">Wake up Condition</div>
                                <Select
                                  getOptionLabel={(option: Options1) => option.label}
                                  getOptionValue={(option: Options1) => option.value}
                                  options={options1}
                                  value={options1.find(
                                    (option: Options1) =>
                                      option.value ==
                                      formData?.parkModeSetting?.wakeUpConditionId.toString()
                                  )}
                                  classNamePrefix={"filter-select-style"}
                                  onChange={(selectedOption: SingleValue<Options1>) =>
                                    handleInputChange("parkModeSetting")(
                                      "wakeUpConditionId",
                                      selectedOption ? Number(selectedOption.value) : 1
                                    )
                                  }
                                  placeholder="Wakeup on external power"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel9", formData?.parkModeSetting, "park-mode-setting");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel10"}
                    onChange={handleChange("panel10")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel10bh-content"
                      id="panel10bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Roaming Setting
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row>
                          <Col xs={4} className="modinpulabel modlabel">
                            <>Roaming</>
                          </Col>
                          <Col xs={1} className="accordianRoaming mt-md-0 mt-1">
                            <ToggleButton
                              checked={formData?.roamingSetting?.roaming}
                              onClick={(value: boolean) =>
                                handleInputChange("roamingSetting")("roaming", value)
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel10", formData?.roamingSetting, "roaming-setting");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme}  ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel11"}
                    onChange={handleChange("panel11")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel11bh-content"
                      id="panel11bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Restore Factory Settings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <div className="accordion11">
                        <Row>
                          <Col md={6}>
                            <div className="modinpulabel modlabel mb-2">Select Level</div>
                            <Select
                              getOptionLabel={(option: Options1) => option.label}
                              getOptionValue={(option: Options1) => option.value}
                              options={options}
                              classNamePrefix={"filter-select-style"}
                              value={options.find(
                                (option) =>
                                  option.value ==
                                  formData?.restoreFactorySetting?.level.toString()
                              )}
                              onChange={(selectedOption: SingleValue<Options1>) =>
                                handleInputChange("restoreFactorySetting")(
                                  "level",
                                  selectedOption ? selectedOption.value : 1
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel11", formData?.restoreFactorySetting, "restore-factory-setting");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel12"}
                    onChange={handleChange("panel12")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel12bh-content"
                      id="panel12bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Sleep Delay
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <div className="modinpulabel accordionleft">
                          * This Setting is Supported Only In road devil Firmware's Device.
                        </div>
                        <br />
                        <Row className="accordionSlider">
                          <Col md={6} className="accordionleft">
                            <div className="modlabel modinpulabel mb-1">
                              Wake up Condition
                            </div>
                            <Select
                              getOptionLabel={(option: Options1) => option.label}
                              getOptionValue={(option: Options1) => option.value}
                              options={options2}
                              classNamePrefix={"filter-select-style"}
                              value={options2.find(
                                (option) =>
                                  option.value ==
                                  formData?.sleepDelay?.wakeUpConditionTimeId.toString()
                              )}
                              onChange={(selectedOption: SingleValue<Options1>) =>
                                handleInputChange("sleepDelay")(
                                  "wakeUpConditionTimeId",
                                  selectedOption ? Number(selectedOption.value) : 1
                                )
                              }
                            />
                          </Col>
                          <Col
                            className="modlabel modinpulabel accordionleft mt-md-0 mt-2 ml-md-1"
                            md={4}
                          >
                            <Row>
                              <Col md={10}>
                                <>Allow Use Of Vehicle's Battery After Ignition Off:</>
                              </Col>
                              <Col md={2}>
                                <ToggleButton
                                  checked={
                                    formData?.sleepDelay
                                      ?.allowUseOfVehicleBetteryAfterIgnitionOff
                                  }
                                  onClick={(value: boolean) =>
                                    handleInputChange("sleepDelay")(
                                      "allowUseOfVehicleBetteryAfterIgnitionOff",
                                      value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel12", formData?.sleepDelay, "sleep-delay");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel13"}
                    onChange={handleChange("panel13")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel13bh-content"
                      id="panel13bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Speed Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <>
                        <>
                          <Row>
                            <Col md={6}>
                              <Row className="d-flex  align-items-center">
                                <Col xs={5} className="mt-2 label-light">
                                  Low
                                </Col>
                                <Col xs={7}>
                                  <ToggleButton
                                    checked={formData?.speedConfiguration?.low}
                                    // onClick={(value: $TSFixMe) => setonline(!online)}
                                    onClick={(value: boolean) =>
                                      handleInputChange("speedConfiguration")("low", value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-3">
                              <Row className="d-flex  align-items-center">
                                <Col xs={5} className="mt-2 label-light">
                                  High
                                </Col>
                                <Col xs={7}>
                                  <ToggleButton
                                    checked={formData?.speedConfiguration?.high}
                                    onClick={(value: boolean) =>
                                      handleInputChange("speedConfiguration")("high", value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>

                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={1}
                                min={0}
                                max={10}
                                disabled={!formData?.speedConfiguration?.low}
                                valueLabelDisplay="on"
                                value={formData?.speedConfiguration?.lowSpeedLessThan}
                                onChange={(event, value) =>
                                  handleInputChange("speedConfiguration")(
                                    "lowSpeedLessThan",
                                    value as number
                                  )
                                }
                              />
                              <div className="dataModal accordianSliderDesc label-light">
                                Low Speed % (for less then 80KPH/50MPH)
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={1}
                                min={0}
                                disabled={!formData?.speedConfiguration?.high}

                                max={10}
                                valueLabelDisplay="on"
                                value={formData?.speedConfiguration?.highSpeedLessThan}
                                onChange={(event, value) =>
                                  handleInputChange("speedConfiguration")(
                                    "highSpeedLessThan",
                                    value as number
                                  )
                                }
                              />
                              <div className="dataModal accordianSliderDesc label-light">
                                High Speed % (for less then 80KPH/50MPH)
                              </div>
                            </Col>
                          </Row>
                        </>
                        <>
                          <Row className="mt-5">
                            <Col md={6}>
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={1}
                                min={0}
                                max={10}
                                disabled={!formData?.speedConfiguration?.low}
                                valueLabelDisplay="on"
                                value={formData?.speedConfiguration?.lowSpeedGreterThan}
                                onChange={(event, value) =>
                                  handleInputChange("speedConfiguration")(
                                    "lowSpeedGreterThan",
                                    value as number
                                  )
                                }
                              />
                              <div className="dataModal accordianSliderDesc label-light">
                                Low Speed % (for greater then 80KPH/50MPH)
                              </div>
                            </Col>
                            <Col md={6} className="mt-md-0 mt-5">
                              <PrettoSlider
                                aria-label="pretto slider"
                                step={1}
                                disabled={!formData?.speedConfiguration?.high}
                                min={0}
                                max={10}
                                valueLabelDisplay="on"
                                value={formData?.speedConfiguration?.highSpeedGreterThan}
                                onChange={(event, value) =>
                                  handleInputChange("speedConfiguration")(
                                    "highSpeedGreterThan",
                                    value as number
                                  )
                                }
                              />
                              <div className="dataModal accordianSliderDesc label-light">
                                High Speed % (for greater then 80KPH/50MPH)
                              </div>
                            </Col>
                          </Row>
                        </>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel13", formData?.speedConfiguration, "speed-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel14"}
                    onChange={handleChange("panel14")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel14bh-content"
                      id="panel14bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Video Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <div className="datewidth">
                        <Row className="mt-5">
                          <Col md={6}>
                            <PrettoSlider
                              aria-label="pretto slider"
                              step={1}
                              min={0}
                              max={10}
                              valueLabelDisplay="on"
                              value={formData?.videoConfiguration?.videoPerOffset}
                              onChange={(event, value) =>
                                handleInputChange("videoConfiguration")(
                                  "videoPerOffset",
                                  value as number
                                )
                              }
                            />
                            <div className="dataModal accordianSliderDesc label-light">
                              VideoPreOffset(Seconds)
                            </div>
                          </Col>
                          <Col md={6} className="mt-md-0 mt-5">
                            <PrettoSlider
                              aria-label="pretto slider"
                              step={1}
                              min={0}
                              max={20}
                              valueLabelDisplay="on"
                              value={formData?.videoConfiguration?.videoPostOffset}
                              onChange={(event, value) =>
                                handleInputChange("videoConfiguration")(
                                  "videoPostOffset",
                                  value as number
                                )
                              }
                            />
                            <div className="dataModal accordianSliderDesc label-light">
                              VideoPostOffset(Seconds)
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel14", formData?.videoConfiguration, "video-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel15"}
                    onChange={handleChange("panel15")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel15bh-content"
                      id="panel15bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Warranty Option
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="accordianDetails">
                      <>
                        <Row className="accordianWarranty">
                          <Col md={1}>
                            <Input
                              type="radio"
                              id="0-year"
                              name="customRadio"
                              className="warranty-radio-btn"
                              checked={formData?.warrantyOption?.warrantyOptionId === 1}
                              onChange={() =>
                                handleInputChange("warrantyOption")("warrantyOptionId", 1)
                              }
                            />
                            <label className="customcamera label-light cursor-pointer" htmlFor="0-year">0 Year</label>
                          </Col>
                          <Col md={1}>
                            <Input
                              type="radio"
                              id="1-year"
                              name="customRadio"
                              className="warranty-radio-btn"
                              checked={formData?.warrantyOption?.warrantyOptionId === 2}
                              onChange={() =>
                                handleInputChange("warrantyOption")("warrantyOptionId", 2)
                              }
                            />
                            <label className="customcamera label-light cursor-pointer" htmlFor="1-year">1 Year</label>
                          </Col>
                          <Col md={1}>
                            <Input
                              type="radio"
                              id="2-year"
                              name="customRadio"
                              className="warranty-radio-btn"
                              checked={formData?.warrantyOption?.warrantyOptionId === 3}
                              onChange={() =>
                                handleInputChange("warrantyOption")("warrantyOptionId", 3)
                              }
                            />
                            <label className="customcamera label-light cursor-pointer" htmlFor="2-year">2 Year</label>
                          </Col>
                          <Col md={1}>
                            <Input
                              type="radio"
                              name="customRadio"
                              className="warranty-radio-btn"
                              id="3-year"
                              checked={formData?.warrantyOption?.warrantyOptionId === 4}
                              onChange={() =>
                                handleInputChange("warrantyOption")("warrantyOptionId", 4)
                              }
                            />
                            <label className="customcamera label-light cursor-pointer" htmlFor="3-year">3 Year</label>
                          </Col>
                        </Row>
                      </>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel15", formData?.warrantyOption, "warranty-option");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel16"}
                    onChange={handleChange("panel16")}
                    className={`accordionTop ${selectedTheme}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
                      aria-controls="panel6bh-content"
                      id="panel16bh-header"
                      className="accordionSummary"
                    >
                      <Typography className={(classes as $TSFixMe).heading}>
                        Driver RFID Configuration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails">
                      <div className="datewidth">
                        <Row className="accordionData">
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig  modinpulabel modlabel">
                              Enable
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.driverRFIdConfiguration?.enable ?? false}
                                onClick={(value: boolean) =>
                                  handleInputChange("driverRFIdConfiguration")(
                                    "enable",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                            <div className="toggleConfig modinpulabel modlabel">
                              CsEnable
                            </div>
                            <div className="accordianSlider">
                              <ToggleButton
                                checked={formData?.driverRFIdConfiguration?.csEnable ?? false}
                                onClick={(value: boolean) =>
                                  handleInputChange("driverRFIdConfiguration")(
                                    "csEnable",
                                    value
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="accordianSlider ml-2">
                          <Col md={6} className="accordionleft">
                            <div className="modlabel modinpulabel mb-1">
                              Select Drivers
                            </div>
                            <Select
                              getOptionLabel={(option: Options1) => option.label}
                              getOptionValue={(option: Options1) => option.value}
                              options={driversList}
                              isMulti={true}
                              classNamePrefix={"filter-select-style"}
                              value={driversList.filter((option: Options1) => 
                                // @ts-ignore
                                formData?.driverRFIdConfiguration?.driverIds?.includes(option.value)
                              )}
                              onChange={handleDriverChange}

                            />
                          </Col>
                        </Row>
                      </div>
                    </AccordionDetails>
                    <Divider className="accordionDivider" />
                    <AccordionActions>
                      <div className="divAlign2 d-flex align-items-center">
                        <Button
                          onClick={() => {
                            handleAccordionSubmit("panel16", formData.driverRFIdConfiguration, "driver-rfid-configuration");
                          }}
                          className={`${selectedTheme}`}
                        >
                          {
                            isLoading ? (
                              <Spinner
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null
                          }
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            handleAccordionCancel();
                          }}
                          className={`${selectedTheme} ml-3`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </AccordionActions>
                  </Accordion>

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  commonConfiguration:
    state.commonConfigurationsData.commonConfigurationTemplate,
  userConfig: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getEditCommonConfigurationImei: (id: number) =>
    dispatch(getEditCommonConfigurationImei(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDeviceConfig);
