const COLORS: any = {
  veryDarkShadeBlue: "#0c1425",
  veryDarkMostlyBlackblue: "#121929",
  veryLightShadeCyanBlue: "#bcd0f7",
  darkShadeBlue: "#1a243a",
  blueEbonyClay: "#272e48",
  white: "#fff",
  veryDarkGrayishBlue: "#525a5e",
  black: "#000",
  pink: "#ed1f68",
  lightShadeGrey: "#d2d2d2",
  veryLightShadeGray: "#efefef",
  lightGrayishBlue: "#bfc7dc",
  brightPink: "#e83e8c",
  vivideRed: "#f60303",
  blueRibbon: "#0055ee",
  sushiBlue: "#8bc34a",
  mischka: "ced1e0",
  pureMostlyPureBlue: "#007bff",
  blue: "#0000FF",
  malibu: "#80bdff",
  ghost: "#ced4da",
  denim: "#177ddc",
  dodgerBlue: "#1890ff",
  greyCool: "#212529",
  cyanBlue: "#007ae1",
  mainlyBlue: "#9fa8b9",
  charade: "#2e323c",
  mediumLightShadeRed: "#ff3e3e",
  mediumDarkShadeBlue: "#333c5b",
  rhino: "#333366",
  lightShade: "rgb(239,239,239)",
  lightBlue: "#f6f7f8",
  lightBlue_200: "#cfd1d8",
  windyBlue: "#b1b9c4",
  darkMidnightBlue: "#003366",
  blueCoolColor: "#768287",
  veryDarkDesaturatedBlue: "#1a233a",
  pureBlue: "#007ae1",
  lightRed: "#ff3e3e",
  lightGreen: "#00bb42",
  newCar: "#264ac9",
  black_pink_purple: "#264ac9",
  black_pink_purple_gradient: "#ed1f68",
  blue_white_gray: "#0168b5",
  blue_white_gray_gradient: "#0168b5",
  gray_scale: "#768287",
  gray_scale_gradient: "#768287",
  claret_blue: "#0168b5",
  claret_blue_gradient: "#7f1734",
};

export default COLORS;
