import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, Col, Container, Label, Row, Spinner } from "reactstrap";
import {
  THEME_TYPE,
  commonEventValidationSchema,
  driverStatisticsDownloadColumns,
} from "../../../constants";
import { getDriverListDD, getProjectListDD } from "../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../redux/reducers/general/general.actions";
import { RootState } from "../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../redux/store/store";
import FullPageLoaderModal from "../../Common/FullPageLoader/FullPageLoaderModal";
import DriverStatisticsTab from "./tab";
import { LayoutContext } from "../../../context/LayoutContext";
import { getDriverStatistics } from "../../../redux/reducers/DataReducer/data.actions";
import { convertTimestampToDate } from "../../../services/common.functions.services";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Tooltip } from "antd";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function DriverStatistics(props: Props) {
  const { selectedTheme, selectedProject } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage]: $TSFixMe = useState(10);
  const [selectedPage, setSelectedPage]: $TSFixMe = useState(1);
  const [isAsc, setIsAsc]: $TSFixMe = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [sortColumn, setSortColumn]: $TSFixMe = useState("");
  const [selectedFilter, setSelectedFilter]: $TSFixMe = useState({
    project: null,
    driver: null,
  });

  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const formikRef: $TSFixMe = useRef();

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      scoretime: convertTimestampToDate(props.user.timeZone.zoneId, data.scoretime, null, props.user.timeFormat)
    }));
  };

  const handleFilter = async (values: $TSFixMe, type?: $TSFixMe) => {
    if (type !== "noFormSubmit") {
      setIsLoading(true);
      setSelectedPage(1);
    }
    try {
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: values?.project?.id,
        driverId: values?.driver?.id,
        page: {
          no: type === "noFormSubmit" ? selectedPage : 1,
          size: recordsPerPage
        },
      };
      await props.getDriverStatistics(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  };

  const apiCall = async () => {
    props.startLoading();
    if (props.dropdowns.projectListDD && props.dropdowns.projectListDD?.result?.length === 0) {
      props.getProjectListDD();
    }
    props.endLoading();
  };

  useEffect(() => {
    if (selectedFilter.project) {
      props.getDriverListDD(selectedFilter.project.id);
    }
    return () => { }
  }, [selectedFilter.project]);

  useEffect(() => {
    if (selectedFilter.project && !isLoading) {
      handleFilter(selectedFilter, "noFormSubmit");
    }
    return () => { }
  }, [recordsPerPage, selectedPage, isAsc, sortColumn]);

  useEffect(() => {
    if (selectedFilter.project === null) {
      // props.resetDeviceReports();
    }
    return () => { }
  }, [selectedFilter]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCall();
    }
  }, [props.user.userId]);

  useEffect(() => {
    if (selectedProject && Object.keys(selectedProject).length > 0) {
      setSelectedPage(1);
      setSelectedFilter((prev: $TSFixMe) => {
        return { ...prev, project: selectedProject, driver: null }
      });
      formikRef.current.setFieldValue("driver", {});
      let tempData = selectedFilter;
      tempData['project'] = selectedProject
      tempData['driver'] = null
      handleFilter(tempData, "noFormSubmit");
    }
    return () => { }
  }, [selectedProject]);

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    Driver Statistics
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd">
                  {props.driverStatistics &&
                    props.driverStatistics.result &&
                    props.driverStatistics?.result?.data.length ? (
                    <CSVLink
                      filename={"driver-statistics-file.csv"}
                      data={preprocessCsvData(props?.driverStatistics?.result?.data)}
                      headers={driverStatisticsDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                </Col>
              </Row>
              <Formik
                validationSchema={commonEventValidationSchema}
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={{
                  driver: {},
                  project: selectedProject ?? {},
                }}
                onSubmit={handleFilter}
              >
                {({ handleBlur, setFieldValue, errors, touched, setTouched, handleSubmit, isSubmitting }) => (
                  <>
                    <div className={`${selectedTheme} mb-4`}>
                      <div className="align-items-baseline d-flex flex-wrap">
                        <div className="w-45 pr-2">
                          <Label
                            className="modlabel modinpulabel mb-1 f-13"
                          >
                            Project
                          </Label>
                          <Select
                            className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                            placeholder="Project"
                            classNamePrefix={"filter-select-style"}
                            options={props.dropdowns.projectListDD.result ?? []}
                            value={
                              selectedFilter.project
                                ? props.dropdowns.projectListDD?.result?.find(
                                  (option: any) => option.id === selectedFilter.project.id
                                )
                                : null
                            }
                            getOptionLabel={(option: $TSFixMe) => option.name}
                            getOptionValue={(option: $TSFixMe) => option.id.toString()}
                            onChange={(option: $TSFixMe) => {
                              setTouched({ ...touched, project: false });
                              setSelectedFilter((prev: $TSFixMe) => {
                                return { ...prev, project: option, driver: null }
                              });
                              setFieldValue("project", option);
                              setFieldValue("driver", {});
                            }}
                            maxMenuHeight={180}
                            onBlur={handleBlur("project")}
                          />
                          {touched.project && errors.project && (
                            <div className="validate">
                              {errors.project ? "Required" : errors.project}
                            </div>
                          )}
                        </div>
                        <div className="w-45 px-2">
                          <Label
                            className="modlabel modinpulabel mb-1 f-13"
                          >
                            Driver
                          </Label>
                          <Select
                            className={`${touched.driver && errors.driver ? "requireSelect" : ""}`}
                            placeholder="Select Driver"
                            classNamePrefix={"filter-select-style"}
                            options={props.dropdowns.driverListDD?.result ?? []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id.toString()}
                            value={
                              selectedFilter.driver
                                ? props?.dropdowns?.driverListDD?.result?.find(
                                  (option: any) => option.id === selectedFilter.driver
                                )
                                : null
                            }
                            onChange={(option: $TSFixMe) => {
                              setSelectedFilter((prev: $TSFixMe) => {
                                return { ...prev, driver: option, }
                              });
                              setFieldValue("driver", option)
                            }}
                            maxMenuHeight={180}
                            onBlur={handleBlur("driver")}
                          />
                          <div className="validate">&nbsp;</div>
                        </div>
                        <div className="w-10 pl-2 mt-auto">
                          <div onClick={() => handleSubmit()} className="header-icons-btn filter-btn ml-auto">
                            <div className="filter-icons">
                              {isLoading ? <Spinner color="light"
                                size="sm" /> : <><FilterAltIcon /> Filter </>}
                            </div>
                          </div>
                          <div className="validate">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
              <DriverStatisticsTab
                setRecordsPerPage={setRecordsPerPage}
                recordsPerPage={recordsPerPage}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                setSortColumn={setSortColumn}
                setIsAsc={setIsAsc}
                isPageLoading={isPageLoading}
                setIsPageLoading={setIsPageLoading}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  driverStatistics: state.dataStore.driverStatistics,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getProjectListDD: () => dispatch(getProjectListDD()),
  getDriverListDD: (id: number) => dispatch(getDriverListDD(id)),
  getDriverStatistics: (data: $TSFixMe) => dispatch(getDriverStatistics(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverStatistics);
