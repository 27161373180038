import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import CachedIcon from "@material-ui/icons/Cached";
import { calculateTotalDrivenTime, convertKilometerToMile, convertTimestampToDate, encrypt, secondToTimeFormat } from '../../../../../services/common.functions.services';
import { measurement } from "../../../../../constants";
import DataTable from "../../../../Common/DataTable/dataTable";
import usePermission from "../../../../../constants/usePermission";
import { Link } from "react-router-dom";

interface VehicalTripTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  handleTripRecalculate: (d: $TSFixMe) => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  projectId: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & VehicalTripTableProps;

const VehicleTripTab = (props: Props) => {
  const [vehicleTripsList, setVehicleTripsList]: $TSFixMe = useState([]);
  const isVehicleTripWrite = usePermission("VEHICLE_TRIP_WRITE");

  const VehicleTripActionBtns = (data: $TSFixMe) => {
    const encryptedParams: $TSFixMe = encrypt({
      proj: props?.projectId,
      id: data?.data?._id,
      hEvent: data?.data?.highEventCount,
      lEvent: data?.data?.lowEventCount,
      end: data?.data?.endTime,
      start: data?.data?.startTime,
      vehicleRegNo: data?.data?.deviceId,
    });
    return (
      <Link to={`/trip-details/${encryptedParams}`} className="tabLink" target="_blank">
        <DirectionsCarIcon className="dropico" />
      </Link>
    );
  };

  const VehicleTripActionBtns2 = (data: $TSFixMe) => {
    return (
      <DirectionsCarIcon className="tripActionBtns2 dropico" />
    );
  };

  const vehicleTripColumns = [
    { title: "Vehicle Reg#", data: "vehicleRegNo" },
    { title: "Device Id", data: "deviceId" },
    {
      title: "View Trip",
      format: (data: $TSFixMe) => data && data.status ? <VehicleTripActionBtns data={data} /> : <VehicleTripActionBtns2 />,
    },

    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data?.driverName },
    {
      title: "Start Time",
      format: (data: $TSFixMe) => data.startTime ? convertTimestampToDate(props.user.timeZone.zoneId, data.startTime, null, props.user.timeFormat) : "N/A",
    },
    {
      title: "End Time",
      format: (data: $TSFixMe) => data.endTime ? convertTimestampToDate(props.user.timeZone.zoneId, data.endTime, null, props.user.timeFormat) : "N/A",
    },
    {
      title: `Distance(${props.user.measurmentUnit === measurement[1].value ? "ml" : "km"}.)`,
      format: (data: $TSFixMe) => data?.totalDistance ? props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(data.totalDistance) : data?.totalDistance?.toFixed(2) : "N/A",
    },
    { title: "Driven Time(s)", format: (data: $TSFixMe) => data.startTime && data.endTime ? calculateTotalDrivenTime(data.startTime, data.endTime) : "" },
    {
      title: "Idle Time",
      format: (data: $TSFixMe) => data?.idealTime ? secondToTimeFormat(data?.idealTime) : 0,
    },
    { title: "High Events", data: "highEventCount" },
    { title: "Low Events", data: "lowEventCount" },
  ];

  if (isVehicleTripWrite) {
    vehicleTripColumns.splice(2, 0, {
      title: "Action",
      format: (data: $TSFixMe) => data.status && data?.totalDistance && data?.startTime && data?.endTime ? "N/A" : <CachedIcon className="dropico" onClick={() => props.handleTripRecalculate(data._id)} />,
    },);
  }

  useEffect(() => {
    setVehicleTripsList([...(props.vehicleTrips?.result?.data ?? [])]);
    return () => { };
  }, [props.vehicleTrips]);

  return (
    <DataTable
      data={vehicleTripsList}
      columns={vehicleTripColumns}
      bordered
      pageData={props.vehicleTrips?.result?.page}
      totalRecrods={props.vehicleTrips?.result?.totalRecrods}
      setPageSize={props.setRecordsPerPage}
      pageSize={props.recordsPerPage}
      setCurrentPage={props.setSelectedPage}
      setSortColumn={props.setSortColumn}
      setIsAsc={props.setIsAsc}
      currentPage={props.selectedPage}
      isPageLoading={props.isPageLoading}
      setIsPageLoading={props.setIsPageLoading}
      hover={true}
      responsive={true}
    />
  );
};

//export default VehicleTripTab;
const mapStateToProps = (state: RootState) => ({
  vehicleTrips: state.dataStore.vehicleTripData,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleTripTab);
