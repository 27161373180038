import React, { useState, useEffect, useCallback } from "react";
import { Circle, LayersControl, Map, Marker, Polygon, Polyline, Popup, TileLayer } from "react-leaflet";
import { Slider, Tooltip } from "antd";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { SliderMarks } from "antd/lib/slider";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { MAP_DEFAULT_LAT_LONG, MAP_SPEED_TYPE, MAP_ZOOM, measurement } from "../../../../../constants";
import { calculateMapBounds, convertKilometerToMile, convertTimestampToDate, getCustomIcon } from "../../../../../services/common.functions.services";
import ASSETS from "../../../../../assets";
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import SkipNext from '@mui/icons-material/SkipNext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const { BaseLayer } = LayersControl;

function GeoFenceEventModal(props: $TSFixMe) {
    const [markersData, setMarkersData]: $TSFixMe = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [mapCenter, setMapCenter]: $TSFixMe = useState(MAP_DEFAULT_LAT_LONG);
    const [mapBounds, setMapBounds] = useState(null);
    const [animationSpeed, setAnimationSpeed] = useState(MAP_SPEED_TYPE["1x"]);
    const [mapZoom, setMapZoom] = useState(MAP_ZOOM);
    const [mapLayer, setMapLayer] = useState("Base Map");
    const polygonCoordinates = props?.geoFence && props?.geoFence?.coordinates.length > 0 && props?.geoFence?.coordinates.map((c: $TSFixMe) => [c.latitude, c.longitude]);
    let animationInterval: NodeJS.Timeout | null = null;

    const marks: SliderMarks = {
        0: '0.5x',
        25: '1x',
        50: '2x',
        75: '3x',
        100: '4x',
    };

    const speedToZoomMapping: $TSFixMe = {
        '0.5x': 18,
        '1x': 18,
        '2x': 18,
        '3x': 17,
        '4x': 16,
    };

    const tipFormatter = (value: $TSFixMe) => {
        return `Speed: ${marks[value]}`;
    };

    // Memoize handleSliderChange callback
    const handleSliderChange = useCallback((value: number) => {
        const speed: $TSFixMe = marks[value];
        setAnimationSpeed(MAP_SPEED_TYPE[speed]);
        setMapZoom(speedToZoomMapping[speed]);
    }, [setAnimationSpeed, setMapZoom, marks]);

    const handleGetMapData = () => {
        if (props.markerData && props.markerData.length > 0) {
            const startMarker = {
                position: [props.markerData[0].latitude, props.markerData[0].longitude],
                color: "green",
                ...props.markerData[0],
            };

            const endMarker = {
                position: [props.markerData[props.markerData.length - 1].latitude, props.markerData[props.markerData.length - 1].longitude],
                color: "red",
                ...props.markerData[props.markerData.length - 1],
            };

            const marker = props.markerData.map((m: $TSFixMe) => {
                return {
                    position: [m.latitude, m.longitude],
                    ...m,
                };
            });

            setMarkersData([startMarker, ...marker, endMarker]);
        } else {
            setMarkersData([]);
        }
    };

    const handlePlayButtonClick = useCallback(() => {
        if (isPlaying) {
            return;
        }
        setIsPlaying(true);
        // setMapZoom(AFTER_PLAY_MAP_ZOOM_LEVEL);
        setMapLayer("Satellite");
        setCurrentIndex(prevIndex => (prevIndex < markersData.length - 1) ? prevIndex : 0);
    }, [isPlaying, setIsPlaying, setMapZoom, setMapLayer, markersData]);


    const handleStopButtonClick = useCallback(() => {
        setIsPlaying(false);
        if (animationInterval) {
            clearInterval(animationInterval);
        }
    }, [setIsPlaying, animationInterval]);

    const handleMapZoomChange = useCallback((event: $TSFixMe) => {
        const newZoomLevel = event.target.getZoom();
        setMapZoom(newZoomLevel);
    }, [setMapZoom]);

    useEffect(() => {
        if (isPlaying && currentIndex < markersData.length - 1) {
            animationInterval = setInterval(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, animationSpeed);
        } else if (currentIndex === markersData.length - 1) {
            setIsPlaying(false);
        }

        return () => {
            if (animationInterval) {
                clearInterval(animationInterval);
                animationInterval = null;
            }
        };
    }, [isPlaying, markersData, currentIndex, animationSpeed]);

    useEffect(() => {
        if (markersData.length > 0) {
            const pathCoordinates = markersData.map((marker: $TSFixMe) => marker.position);
            const bounds: $TSFixMe = calculateMapBounds(markersData);
            const newCenter = pathCoordinates[currentIndex];
            setMapBounds(bounds);
            setMapCenter(newCenter);
        }
    }, [markersData, currentIndex]);

    useEffect(() => {
        if (props.markerData) {
            handleGetMapData();
        }
        return () => { };
    }, [props.markerData]);

    return (
        <Modal isOpen={props.modal}
            toggle={() => props.toggle()}
            className="geoFence-event-modal">
            <ModalHeader className={`mod ${props.theme}-bg`}>
                <div className="modlabel modheadcont mb-0">Geo Fence Event</div>
                <button className={`mult ${props.theme}-bg`} onClick={() => props.toggle()}>
                    <img src={ASSETS.MULTIPLY} alt="multiply" />
                </button>
            </ModalHeader>
            <ModalBody>
                <div className={`position-relative ${props.theme}`}>
                    <Map
                        className="mapLoc"
                        center={mapCenter || MAP_DEFAULT_LAT_LONG}
                        zoom={mapZoom}
                        scrollWheelZoom={true}
                        bounds={mapBounds || undefined}
                        onZoom={handleMapZoomChange}
                        preferCanvas={true}
                    >
                        <LayersControl position="topright">

                            <BaseLayer checked={mapLayer === "Base Map"} name="Base Map" onClick={() => setMapLayer("Base Map")}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            </BaseLayer>
                            <BaseLayer checked={mapLayer === "Humanitarian"} name="Humanitarian" onClick={() => setMapLayer("Humanitarian")}>
                                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
                            </BaseLayer>
                            <BaseLayer checked={mapLayer === "Satellite"} name="Satellite" onClick={() => setMapLayer("Satellite")}>
                                <TileLayer
                                    url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                />
                            </BaseLayer>

                            {markersData && markersData.length > 0 && (
                                <>
                                    <Polyline positions={markersData.map((marker: $TSFixMe) => marker.position)} color="red" weight={5} />
                                    <ReactLeafletDriftMarker duration={animationSpeed} position={markersData[currentIndex].position} icon={getCustomIcon(markersData[currentIndex]?.heading)}>
                                        <Popup>
                                            <div>
                                                <b>Device Id:</b> {markersData[currentIndex]?.deviceId}
                                            </div>
                                            <div>
                                                <b>Date & Time:</b>{" "}
                                                {markersData[currentIndex]?.timestamp
                                                    ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[currentIndex]?.timestamp, null, props.user.timeFormat)
                                                    : "-"}
                                            </div>
                                        </Popup>
                                    </ReactLeafletDriftMarker>
                                </>
                            )}


                            {/* Show start and end markers with car icons */}
                            {markersData && markersData.length > 0 && (
                                <>
                                    <Marker position={markersData[0].position ?? []} icon={getCustomIcon(markersData[0]?.heading, "green", "map")}>
                                        <Popup>
                                            <div>
                                                <b>Device Id:</b> {markersData[0]?.deviceId}
                                            </div>
                                            <div>
                                                <b>Date & Time:</b>{" "}
                                                {markersData[0]?.timestamp
                                                    ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[0]?.timestamp, null, props.user.timeFormat)
                                                    : "-"}
                                            </div>
                                        </Popup>
                                    </Marker>

                                    <Marker position={markersData[markersData.length - 1].position} icon={getCustomIcon(markersData[markersData.length - 1]?.heading, "red", "map")}>
                                        <Popup>
                                            <div>
                                                <b>Device Id:</b> {markersData[markersData.length - 1]?.deviceId}
                                            </div>
                                            <div>
                                                <b>Date & Time:</b>{" "}
                                                {markersData[markersData.length - 1]?.timestamp
                                                    ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[markersData.length - 1]?.timestamp, null, props.user.timeFormat)
                                                    : "-"}
                                            </div>
                                        </Popup>
                                    </Marker>
                                </>
                            )}
                            {props?.geoFence?.latitude && props?.geoFence?.longitude && props?.geoFence?.radius &&
                                <Circle center={[props?.geoFence?.latitude, props?.geoFence?.longitude]} radius={props?.geoFence?.radius} />
                            }
                            {polygonCoordinates.length > 0 &&
                                <Polygon positions={polygonCoordinates} pathOptions={{ fillColor: 'blue' }} />
                            }
                        </LayersControl>
                    </Map>
                    {markersData && markersData.length > 0 &&
                        <div className="map-play-box map-play-custom-width">
                            <div className="vehicle-info">
                                <p>Device Id: {markersData[currentIndex]?.deviceId}</p>
                                <p>Speed: {`${props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(markersData[currentIndex]?.speed) : markersData[currentIndex]?.speed}  ${props.user.measurmentUnit === measurement[1].value ? 'mph' : 'km/h'}`}</p>
                                <p>Latitude: {markersData[currentIndex]?.latitude}</p>
                                <p>Longitude: {markersData[currentIndex]?.longitude}</p>
                                <p>Date & Time: {markersData[currentIndex]?.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[currentIndex]?.timestamp, null, props.user.timeFormat) : "-"}</p>
                            </div>
                            <div className="mt-1">
                                <div className="d-flex align-items-center justify-content-around">
                                    <Tooltip placement="top" title={"Previous"}>
                                        <button className="play-btn"
                                            onClick={() => setCurrentIndex((prevIndex) => {
                                                const temp = prevIndex - 5;
                                                return temp >= 0 ? temp : 0;
                                            })}>
                                            <SkipPrevious />
                                        </button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={!isPlaying ? "Play" : "Pause"}>
                                        <button className="play-btn" onClick={() => { !isPlaying ? handlePlayButtonClick() : handleStopButtonClick() }}>{!isPlaying ? <PlayCircleIcon /> : <PauseCircleIcon />}</button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={"Next"}>
                                        <button className="play-btn"
                                            onClick={() => setCurrentIndex((prevIndex) => {
                                                const temp = prevIndex + 5;
                                                return temp < markersData.length - 1 ? temp : markersData.length - 1;
                                            })}>
                                            <SkipNext />
                                        </button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={"Restart"}>
                                        <button className="play-btn"
                                            onClick={() => setCurrentIndex(0)}>
                                            <RestartAltIcon />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="w-100 mt-1">
                                    <span className="text-white f-10">Speed</span>
                                    <Slider
                                        marks={marks}
                                        step={null}
                                        tipFormatter={tipFormatter}
                                        defaultValue={75}
                                        onChange={handleSliderChange}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ModalBody>
        </Modal>
    );
}

export default GeoFenceEventModal;
