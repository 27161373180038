import React, { useContext, useEffect, useState } from "react";
//@ts-expect-error
import Fade from "react-reveal/Fade";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ReferBatteryVoltasGraph from "./referBatteryVoltage";
import TripsDetailButton from "./tripsDetailButton";
import { connect } from "react-redux";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { measurement, THEME_TYPE } from "../../../../../constants";
import VehicleJourneyMap from "../../../../Common/Map/VehicleJourneyMap";
import { AppDispatch } from "../../../../../redux/store/store";
import {
  getVehicleTripsDetails,
  getVehicleTripsGps,
} from "../../../../../redux/reducers/DataReducer/data.actions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getHighEventVehicleTrip, getLowEventVehicleTrip } from "../../../../../api/axios.resources";
import { convertKilometerToMile, decrypt } from "../../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function TripsDetail(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const [vehicleTripGpsChart, setVehicleTripGpsChart] = useState([]);
  const [vehicleTripLowEvent, setVehicleTripLowEvent] = useState([]);
  const [vehicleTripHighEvent, setVehicleTripHighEvent] = useState([]);
  const { encryptedParams }: $TSFixMe = useParams();
  const decryptData = decrypt(encryptedParams);

  const cardColorType =
    selectedTheme === THEME_TYPE.GREY_SCALE ||
      selectedTheme === THEME_TYPE.BLUE_WHITE_GREY
      ? "cardcolor-light"
      : "cardcolor";

  const handleGetTripDetails = async (id: string) => {
    try {
      let tripData: $TSFixMe = {
        projectId: decryptData.proj,
        deviceId: decryptData.vehicleRegNo,
      };

      let data: $TSFixMe = {
        projectId: decryptData.proj,
        deviceId: decryptData.vehicleRegNo,
        // page: {
        //   no: selectedPage,
        //   size: recordsPerPage
        // },
        startTimestamp: decryptData.start,
        endTimestamp: decryptData.end,
      };
      if (id && data.projectId) {
        Promise.all([
          props.getVehicleTripsDetails(tripData, id),
          props.getVehicleTripsGps(data),
        ]);
        if (Number(decryptData.lEvent) > 0) {
          const resLowEvent = await getLowEventVehicleTrip(data);
          if (resLowEvent && resLowEvent.status === 200) {
            const tempData = resLowEvent.result.map((r: $TSFixMe) => {
              return { ...r, popupType: "LOW_EVENTS_BY_ID" }
            });
            setVehicleTripLowEvent(tempData);
          }
        }
        if (Number(decryptData.hEvent) > 0) {
          const resHighEvent = await getHighEventVehicleTrip(data);
          if (resHighEvent && resHighEvent.status === 200) {
            const tempData = resHighEvent.result.map((r: $TSFixMe) => {
              return { ...r, popupType: "HIGH_EVENTS_BY_ID" }
            });
            setVehicleTripHighEvent(tempData);
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`${(error as $TSFixMe).data.message}`);
    }
  };

  useEffect(() => {
    if (decryptData.id && decryptData.proj) {
      handleGetTripDetails(decryptData.id);
    }
  }, [decryptData.id, decryptData.proj]);

  useEffect(() => {
    if (props?.vehicleTripGps?.result) {
      const chartTemp: $TSFixMe = props?.vehicleTripGps?.result;
      const chartData = chartTemp.map((r: $TSFixMe) => {
        const speed = props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(r.speed) : r.speed;
        return [r.timestamp, speed];
      });
      setVehicleTripGpsChart(chartData);
    }

    return () => { };
  }, [props?.vehicleTripGps?.result]);

  return (
    <div className="dashboardMinDiv bg-gradient-info pb-1 pt-0 pt-md-8">
      <Container className="mt--7" fluid>
        <TripsDetailButton
          selectedTheme={selectedTheme}
          tripData={props?.vehicleTripDetails?.result}
          user={props.user}
        />
        <div className={`card-stats md-4 mb-xl-0 pb-1`}>
          <Row className="mt-2">
            <Col className="col-12">
              <Card
                className={`card-stats shadowCard ${cardColorType}`}
              >
                <CardBody className="tabAll">
                  <div className="driverScoreCardBody">
                    <VehicleJourneyMap
                      markerData={props?.vehicleTripGps?.result}
                      user={props?.user}
                      projectId={decryptData.proj}
                      highEventTrip={vehicleTripHighEvent}
                      lowEventTrip={vehicleTripLowEvent}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-12">
              <Fade right duration={3000}>
                <Card
                  className={`card-stats shadowCard ${cardColorType} allPage`}
                >
                  <CardBody className="tabAll">
                    <CardTitle className="tabpage">
                      Refer Battery Voltas Graph
                    </CardTitle>
                    <div className="driverScoreCardBody">
                      <ReferBatteryVoltasGraph
                        user={props?.user}
                        selectedTheme={selectedTheme}
                        vehicleTripGpsChart={vehicleTripGpsChart}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  vehicleTripDetails: state.dataStore.vehicleTripDetails,
  vehicleTripGps: state.dataStore.vehicleTripGps,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getVehicleTripsDetails: (data: $TSFixMe, id: string) =>
    dispatch(getVehicleTripsDetails(data, id)),
  getVehicleTripsGps: (data: $TSFixMe) => dispatch(getVehicleTripsGps(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripsDetail);
