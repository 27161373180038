import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { createRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormGroup,
    Row,
    Spinner,
} from "reactstrap";
import { ResetPasswordValidationSchema, TEST_SITE_KEY } from "../../../constants";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { loginUser } from "../../../redux/reducers/user/user.actions";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../../api/axios.resources";
import ReCAPTCHA from "react-google-recaptcha";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ASSETS from "../../../assets";

type Props = ReturnType<typeof mapDispatchToProps>;

const ResetPassword = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const history = useHistory();
    const historyParams = useHistory();
    let param = historyParams.location.search.split("=")[1]
    let reCaptchaRef: any = createRef();

    const formSubmitHandler = async (values: $TSFixMe) => {
        let data = {
            param,
            password: values.confirmPassword
        }
        setIsLoading(true);
        try {
            const res: $TSFixMe = await resetPassword(data)
            if (res.status === 200) {
                toast.success(res.data.message)
                setIsLoading(false);
                history.push("/")
            } else {
                toast.error("Password not reset")
            }
        } catch (e) {
            toast.error((e as $TSFixMe).error.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Container fluid style={{ padding: "0px", margin: "0px", width: "100%" }}>
                <Formik
                    validationSchema={ResetPasswordValidationSchema}
                    enableReinitialize={true}
                    initialValues={{
                        newPassword: "",
                        confirmPassword: "",
                        recaptcha: "",
                    }}
                    onSubmit={(values) => formSubmitHandler(values)}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setSubmitting,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Row className="mt-0">
                                <Col>
                                    <div className="auth-screen">
                                        <Row
                                            className="d-flex justify-content-center align-items-center flex-column w-100 m-auto"
                                        >
                                            <Col
                                                md={4}
                                                sm={6}
                                                style={{
                                                    border: "1px solid rgb(255 255 255 / 60%)",
                                                    padding: "20px 40px",
                                                    boxShadow: "inset rgb(255 255 255 / 70%) 0px -1px 12px 3px",
                                                    borderRadius: "10px",
                                                    backgroundColor: "rgb(255 255 255 / 75%)",
                                                }}
                                            >
                                                <div className="loginLogo m-auto">
                                                    <img src={ASSETS.STREET_ANGEL} alt="company logo" style={{ width: "300px" }} />
                                                </div>
                                                <h3
                                                    className="text-center"
                                                    style={{ color: "#333", fontSize: "18px", marginBottom: "30px" }}
                                                >
                                                    Reset Password
                                                </h3>
                                                <FormGroup className="dropinput">
                                                    <div
                                                        className="modalheader"
                                                        // @ts-expect-error
                                                        htmlFor="newPassword"
                                                    >
                                                        New Password
                                                        <span className="required"> *</span>
                                                    </div>
                                                    <div className="position-relative">
                                                        <Field
                                                            className={
                                                                "form-control mt-1" +
                                                                (errors.newPassword && touched.newPassword
                                                                    ? " is-invalid "
                                                                    : "") +
                                                                " passwordFont "
                                                            }
                                                            autocomplete="disable"
                                                            type={isNewPasswordVisible ? "text" : "password"}
                                                            name="newPassword"
                                                            id="newPassword"
                                                            placeholder="Enter your new password"
                                                            value={values.newPassword}
                                                            onChange={handleChange("newPassword")}
                                                            onBlur={handleBlur("newPassword")}
                                                        />
                                                        <span className={(errors.newPassword && touched.newPassword
                                                            ? " is-invalid "
                                                            : "") + "eye-icon-for-password"} onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}>
                                                            {isNewPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </span>
                                                        <ErrorMessage
                                                            name="newPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="dropinput mt-4">
                                                    <div
                                                        className="modalheader"
                                                        // @ts-expect-error
                                                        htmlFor="confirmPassword"
                                                    >
                                                        Confirm Password
                                                        <span className="required"> *</span>
                                                    </div>
                                                    <div className="position-relative">
                                                        <Field
                                                            className={
                                                                "form-control mt-1" +
                                                                (errors.confirmPassword && touched.confirmPassword
                                                                    ? " is-invalid "
                                                                    : "") +
                                                                " passwordFont "
                                                            }
                                                            autocomplete="disable"
                                                            type={isConfirmPasswordVisible ? "text" : "password"}
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            placeholder="Confirm your new password"
                                                            value={values.confirmPassword}
                                                            onChange={handleChange("confirmPassword")}
                                                            onBlur={handleBlur("confirmPassword")}
                                                        />
                                                        <span className={(errors.confirmPassword && touched.confirmPassword
                                                            ? " is-invalid "
                                                            : "") + "eye-icon-for-password"} onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}>
                                                            {isConfirmPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </span>
                                                        <ErrorMessage
                                                            name="confirmPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <div className="text-center mt-4">
                                                    <ReCAPTCHA
                                                        className="re-captcha"
                                                        ref={reCaptchaRef}
                                                        onChange={(value) => {
                                                            setFieldValue("recaptcha", value);
                                                            setSubmitting(false);
                                                        }}
                                                        sitekey={TEST_SITE_KEY}
                                                    />
                                                    {errors.recaptcha && touched.recaptcha && (
                                                        <p className="recaptch-error">
                                                            {errors.recaptcha}
                                                        </p>
                                                    )}
                                                    <Button
                                                        className="my-3 w-40 h-35-px py-2 d-flex align-items-center justify-content-center mx-auto"
                                                        // @ts-expect-error
                                                        onClick={handleSubmit}
                                                        type="submit"
                                                    >
                                                        {isLoading ? (
                                                            <Spinner
                                                                color="light"
                                                                size="sm"
                                                            />
                                                        ) : "Save Password"}

                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        loginUser: (values: { email: string; password: string }) => dispatch(loginUser(values)),
    };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
