import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { EventReport } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";

interface EventReportTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & EventReportTableProps;

const EventReportTab = (props: Props) => {
  const [eventReportsList, setEventReportsList] = useState<EventReport[]>([]);

  const eventReportColumns = [
    { title: "Vehicle Reg#", data: "vehicleregistrationno" },
    { title: "Device ID", data: "deviceid" },
    { title: "Organization", data: "organizationname" },
    { title: "Total Events", data: "totalevent" },
    { title: "High Events", data: "highevent" },
    { title: "Low Events", data: "lowevent" },
    {
      title: "Latest High Events", data: "latestHighEvent",
      format: (data: $TSFixMe) => data?.latestHighEvent ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.latestHighEvent,
          null,
          props.user.timeFormat
        ) : "N/A",
    },
  ];

  useEffect(() => {
    setEventReportsList(props.eventReports.result ? props.eventReports.result.data : []);
    return () => { };
  }, [props.eventReports]);

  return (
    <DataTable
      data={eventReportsList}
      columns={eventReportColumns}
      bordered
      pageData={props.eventReports?.result?.page}
      totalRecrods={props.eventReports?.result?.totalRecord}
      setPageSize={props.setRecordsPerPage}
      pageSize={props.recordsPerPage}
      setCurrentPage={props.setSelectedPage}
      currentPage={props.selectedPage}
      setSortColumn={props.setSortColumn}
      setIsAsc={props.setIsAsc}
      isPageLoading={props.isPageLoading}
      setIsPageLoading={props.setIsPageLoading}
      hover={true}
      responsive={true}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  eventReports: state.dataStore.eventReports,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EventReportTab);
