import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { driverScoreCardData } from "../../../../../../../constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

function Score(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const { options, series } = driverScoreCardData(selectedTheme, props.score);

  return (
    <div id="chart">
      <ReactApexChart
        // @ts-expect-error
        options={options}
        series={series}
        type="radialBar"
        height={300}
      />
    </div>
  );
}

export default Score;
