import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { dataHarsh } from "../../../../../../../constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

function HarshEvents(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const { options, series } = dataHarsh(props.harshEvent);
  return (
    <>
      {props.harshEvent?.acceleration && props.harshEvent?.braking && props.harshEvent?.cornering ? (
        <ReactApexChart
          // @ts-expect-error
          options={options}
          series={series}
          type="donut"
          height={300.467}
        />
      ) : (
        <div className="label-light text-center" style={{ height: "280px" }}>No Data Found..</div>
      )}
      <div className={`${selectedTheme} d-flex align-items-center justify-content-center`}>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>{props?.harshEvent?.acceleration ?? 0}</p>
            <p className='heading mb-0'>
              Acceleration Events
            </p>
          </div>
        </div>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>{props?.harshEvent?.braking ?? 0}</p>
            <p className='heading mb-0'>Braking Events</p>
          </div>
        </div>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>{props?.harshEvent?.cornering ?? 0}</p>
            <p className='heading mb-0'>Cornering</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HarshEvents;
