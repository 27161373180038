import { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col, Card, CardBody, Container, Spinner } from "reactstrap";
import {
  measurement,
  time,
  BpCheckedIcon,
  BpIcon,
  THEME_TYPE,
} from "../../../../constants";
import "react-dropdown/style.css";
import Select from "react-select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ToggleButton from "../../../Common/ToggleButton";
import CustomInput from "../../../Common/CustomInput/index";
import { LayoutContext, LayoutContextModel } from "../../../../context/LayoutContext";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { endLoading, startLoading } from "../../../../redux/reducers/general/general.actions";
import { getUser, updateUser, updateUserProfileImg } from "../../../../redux/reducers/user/user.actions";
import { AppDispatch } from "../../../../redux/store/store";
import { connect } from "react-redux";
import { Options, UserConfigData } from "../../../../../global.types";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadProfilePicture } from "../../../../api/axios.resources";
import { getTimeZoneList } from "../../../../redux/reducers/dropdowns/dropdown.actions";
import { Tooltip } from "antd";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;


export type UserConfig = Omit<UserConfigData, "id" | "measurmentUnitName" | "userName">

type UserConfigurationType = {
  measurmentUnit: $TSFixMe;
  profilePicture: string;
  receiveAlertEmail: boolean;
  receiveMaintenanceEmail: boolean;
  theme: string;
  timeFormat: $TSFixMe;
  userId: number;
  userTimeZone: Options;
}

const initialState: UserConfigurationType = {
  measurmentUnit: null,
  profilePicture: "",
  receiveAlertEmail: true,
  receiveMaintenanceEmail: true,
  theme: "",
  timeFormat: null,
  userId: 0,
  userTimeZone: {
    value: "",
    label: "",
  },
};

function UserConfiguration(props: Props) {
  const [userConfig, setUserConfig] = useState(initialState);
  const [profilePicture, setProfilePicture]: $TSFixMe = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [selected1, setSelected1] = useState(true);
  const [selected2, setSelected2] = useState(true);
  const history = useHistory();
  const { setSelectedTheme, selectedTheme }: LayoutContextModel = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';

  const apiCall = async () => {
    props.startLoading();
    // Check if the timeZoneList is not available
    if (!props.dropdowns.timeZoneList?.result) {
      // Retrieve the timeZoneList
      props.getTimeZoneList();
    }
    // Check if the user is not available
    if (!props.user) {
      // Retrieve the user
      props.getUser();
    }
    props.endLoading();
  };

  const profileUpdateHandler = async () => {
    try {
      setIsConfigLoading(true);

      // Update user configuration and wait for the state update to complete.
      await setUserConfig((prev: UserConfigurationType) => ({
        ...prev,
        receiveAlertEmail: selected1,
        receiveMaintenanceEmail: selected2,
        userId: props?.user?.userId,
      }));

      let userConfigReq = {
        ...userConfig,
        measurmentUnit: userConfig.measurmentUnit ? userConfig.measurmentUnit.value : "",
        timeFormat: userConfig.timeFormat ? userConfig.timeFormat.value : "",
        userTimeZone: userConfig.userTimeZone.value !== "" ? userConfig.userTimeZone.value : "",
        receiveAlertEmail: selected1,
        receiveMaintenanceEmail: selected2,
        userId: props?.user?.userId,
        profilePicture: userConfig.profilePicture,
        theme: selectedTheme,
      };
      // Use the updated userConfig directly in the API call.
      let res: any = await props.updateUser(userConfigReq);

      if (res.status === 200) {
        toast.success("Profile updated successfully");
        await localStorage.setItem("user-config-vendor", JSON.stringify(res?.result));
        setSelectedTheme(res?.result.theme);
      } else {
        toast.error("Error while performing action. Please try again later.");
      }

      setIsConfigLoading(false);
      history.goBack();
    } catch (error) {
      setIsConfigLoading(false);
      console.log("error in updateUser", error);
    }
  };

  const FileHandler = (file: File | null) => {
    setProfilePicture(file);
  };

  const FileUploadHandler = async () => {
    let formData = new FormData();
    formData.append("picture", profilePicture);
    let req = {
      param: { id: props?.user?.userId },
      formData: formData,
    };
    try {
      if (profilePicture) {
        setIsLoading(true);
        // API Call for uploading file
        const res = await uploadProfilePicture(req);
        if (res.status === 200) {
          props.updateUserProfileImg(res.result);
          toast.success("Profile picture updated successfully");
          setUserConfig((prev: $TSFixMe) => {
            return {
              ...prev,
              profilePicture: res.result,
            };
          });
        } else {
          toast.error("Profile picture not updated");
        }
      }
    } catch (error) {
      console.log("error in updateUser", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (props.user) {
      setUserConfig((prev: $TSFixMe) => ({
        ...prev,
        measurmentUnit: measurement.filter(
          (item: $TSFixMe) => item.value === props.user.measurmentUnit
        )[0],
        userTimeZone: { label: props.user?.timeZone?.timezone, value: props.user?.timeZone?.zoneId },
        timeFormat: time.filter(
          (item: $TSFixMe) => item.value === props.user.timeFormat
        )[0],
        theme: selectedTheme,
        profilePicture: props.user.profilePicture,
      }));
      setSelected1(props.user.receiveAlertEmail);
      setSelected2(props.user.receiveMaintenanceEmail);
    }
  }, [props.user]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="header headerDiv bg-gradient-info pb-1 pt-0 pt-md-8">
      <Container className="mt--7" fluid>
        <Row className="mt-0">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <div className="mb-4">
                <div className="tabpage mb-4">User Configuration</div>
                <Row>
                  <Col xl="12">
                    <Form>
                      <div className="d-flex align-items-start">
                        <div className="w-28 px-2">
                          <div className="modlabel modinpulabel mb-1">
                            Select Time Zone
                          </div>
                          <Select
                            className="z-9999"
                            classNamePrefix={"filter-select-style"}
                            options={
                              props.dropdowns?.timeZoneList?.dropDown
                            }
                            onChange={(values) => {
                              setUserConfig((prev: $TSFixMe) => {
                                return {
                                  ...prev,
                                  userTimeZone: values,
                                };
                              });
                            }}
                            value={userConfig?.userTimeZone?.value !== "" ? userConfig?.userTimeZone : null}
                            maxMenuHeight={180}
                          />
                        </div>
                        <div className="w-16 px-2">
                          <div className="modlabel modinpulabel mb-1">
                            Select Measurement
                          </div>
                          <Select
                            classNamePrefix={"filter-select-style"}
                            options={measurement}
                            onChange={(values) => {
                              setUserConfig((prev: $TSFixMe) => {
                                return {
                                  ...prev,
                                  measurmentUnit: values,
                                };
                              });
                            }}
                            value={userConfig.measurmentUnit}
                          />
                        </div>
                        <div className="w-16 px-2">
                          <div className="modlabel modinpulabel mb-1">
                            Select Time Format
                          </div>
                          <Select
                            classNamePrefix={"filter-select-style"}
                            options={time}
                            onChange={(values) => {
                              setUserConfig((prev: $TSFixMe) => {
                                return {
                                  ...prev,
                                  timeFormat: values,
                                };
                              });
                            }}
                            value={userConfig.timeFormat}
                          />
                        </div>
                        <div className="w-36 px-2">
                          <div className="modlabel modinpulabel mb-1">
                            Profile Picture
                          </div>
                          <CustomInput
                            className="datewidth"
                            accept="image/png, image/gif, image/jpeg"
                            id="fileInput2"
                            onFileSelect={FileHandler}
                          />
                        </div>
                        <div className="pl-2 pt-4 mt-auto mb-1 px-2">
                          <div onClick={FileUploadHandler} className="header-icons-btn ml-auto">
                            <Tooltip title={"Upload"}
                              align={{
                                points: ['bc', 'tc'], // Align tooltip top-center to button bottom-center
                                offset: [0, 5], // Adjust the offset (x, y)
                                targetOffset: [0, 0], // Adjust the target offset
                              }}
                              overlayInnerStyle={{ fontSize: '12px', padding: '3px 8px', minHeight: '22px' }}>

                              {isLoading ? <div className="header-icons d-flex align-items-center justify-content-center"><Spinner color="light"
                                size="sm" /></div> : <CloudUploadIcon className="header-icons" />}
                            </Tooltip>
                          </div>
                          <div className="validate">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="w-40 px-2">
                          <div className="mb-4">
                            <div className="modlabel modinpulabel">
                              Receive Alert Email
                            </div>
                            <ToggleButton
                              checked={selected1}
                              onClick={() => {
                                setSelected1(!selected1);
                              }}
                            />
                          </div>
                          <div className="mb-4">
                            <div className="modlabel modinpulabel">
                              Receieve Maintenance Email
                            </div>
                            <ToggleButton
                              checked={selected2}
                              onClick={() => {
                                setSelected2(!selected2);
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-60 px-2">
                          <div className="modlabel modinpulabel">Select Theme</div>
                          <RadioGroup>
                            <div className="d-flex flex-wrap w-100">
                              <div className="w-50 pr-2">
                                <FormControlLabel
                                  className="rad label-light"
                                  value="gray_scale"
                                  control={
                                    <Radio
                                      sx={{
                                        "&:hover": {
                                          bgcolor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                      // @ts-expect-error TS(2322): Type '"white"' is not assignable to type '"default... Remove this comment to see the full error message
                                      color="white"
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                      checked={selectedTheme === THEME_TYPE.GREY_SCALE}
                                      onClick={(e: any) => setSelectedTheme(e.target.value)}
                                    />
                                  }
                                  label="Grey Scale"
                                />
                                <span className="foo grey" />
                              </div>
                              <div className="w-50 pl-2">
                                <FormControlLabel
                                  className="rad label-light"
                                  value="black_pink_purple"
                                  control={
                                    <Radio
                                      sx={{
                                        "&:hover": {
                                          bgcolor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                      // @ts-expect-error TS(2322): Type '"white"' is not assignable to type '"default... Remove this comment to see the full error message
                                      color="white"
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                      checked={selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE}
                                      onClick={(e: any) => setSelectedTheme(e.target.value)}
                                    />
                                  }
                                  label="Black-Pink-Purple"
                                />
                                <span className="foo pink" />
                              </div>
                              <div className="w-50 pr-2">
                                <FormControlLabel
                                  className="rad label-light"
                                  value="blue_white_gray"
                                  control={
                                    <Radio
                                      sx={{
                                        "&:hover": {
                                          bgcolor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                      // @ts-expect-error TS(2322): Type '"white"' is not assignable to type '"default... Remove this comment to see the full error message
                                      color="white"
                                      checked={selectedTheme === THEME_TYPE.BLUE_WHITE_GREY}
                                      checkedIcon={<BpCheckedIcon />}
                                      icon={<BpIcon />}
                                      onClick={(e: any) => setSelectedTheme(e.target.value)}
                                    />
                                  }
                                  label="Blue-White-Grey"
                                />
                                <span className="foo blue" />
                              </div>
                              <div className="w-50 pl-2">
                                <FormControlLabel
                                  className="rad label-light"
                                  value="claret_blue"
                                  control={
                                    <Radio
                                      sx={{
                                        "&:hover": {
                                          bgcolor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                      // @ts-expect-error TS(2322): Type '"white"' is not assignable to type '"default... Remove this comment to see the full error message
                                      color="white"
                                      checkedIcon={<BpCheckedIcon />}
                                      checked={selectedTheme === THEME_TYPE.CLARET_BLUE}
                                      onClick={(e: any) => setSelectedTheme(e.target.value)}
                                      icon={<BpIcon />}
                                    />
                                  }
                                  label=" Claret-Blue"
                                />
                                <span className="foo claret" />
                              </div>
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                      <Row className="px-2 mb-2">
                        <Col md={2}>
                          <Button block className={`${selectedTheme} d-flex align-items-center justify-content-center h-35-px`} onClick={profileUpdateHandler}>
                            {isConfigLoading ? (
                              <Spinner
                                color="light"
                                size="sm"
                              />
                            ) : "Update"}
                          </Button>
                        </Col>
                        <Col md={2}>
                          <Button block className={`${selectedTheme} h-35-px`}
                            onClick={() => {
                              setSelectedTheme(props.user.theme);
                              props.getUser();
                              history.goBack();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    dropdowns: state.dropdownList.dropdowns,
    user: state.userSlice.user,
    isSubmitLoading: state.generalSlice.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    endLoading: () => dispatch(endLoading()),
    getTimeZoneList: () => dispatch(getTimeZoneList()),
    getUser: () => dispatch(getUser()),
    updateUser: (data: $TSFixMe) => dispatch(updateUser(data)),
    updateUserProfileImg: (data: $TSFixMe) => dispatch(updateUserProfileImg(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserConfiguration);