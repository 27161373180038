import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { THEME_TYPE, manageRoleDownloadColumns } from "../../../../constants";
import { RootState } from "../../../../redux/reducers/rootReducer";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import ManageRolesTab from "./tab";
import AddRole from "./tab/Components/addRole";
import { useContext } from "react";
import { LayoutContext } from "../../../../context/LayoutContext";
import usePermission from "../../../../constants/usePermission";
import { convertTimestampToDate } from "../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps>;

function ManageRoles(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const isRolesWrite = usePermission("ROLES_MANAGEMENT_WRITE");

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      createdOn: convertTimestampToDate(props.user.timeZone.zoneId, data.createdOn, null, props.user.timeFormat)
    }));
  };

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />

      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <> Role Management</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.manageRoles &&
                    props.manageRoles.result &&
                    props.manageRoles.result.length ? (
                    <CSVLink
                      filename={"manage-roles-file.csv"}
                      data={preprocessCsvData(props?.manageRoles?.result)}
                      headers={manageRoleDownloadColumns}
                    >
                      <Button className={`${selectedTheme} mr-2`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {/* <Import /> */}
                  {isRolesWrite && <AddRole />}
                </Col>
              </Row>
              <CardBody className="tabAll">
                <ManageRolesTab isRolesWrite={isRolesWrite} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  manageRoles: state.manageRolesData.manageRoles,
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(ManageRoles);
