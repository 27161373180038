import React, { useContext, useState } from "react";
import { Card, CardBody, Row, Col, Container, Button } from "reactstrap";
import DriverManagementTab from "./tab";
import AddDriver from "./tab/Components/driverAdd/index";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import { connect } from "react-redux";
import { THEME_TYPE, driverManagmentDownloadColumns } from "../../../../constants";
import { CSVLink } from "react-csv";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../../context/LayoutContext";
import usePermission from "../../../../constants/usePermission";
import { useLocation } from "react-router-dom";
type Props = ReturnType<typeof mapStateToProps>;

function DriverManagement(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const isDriveWrite = usePermission("DRIVER_MANAGEMENT_WRITE");

  const location = useLocation();
  const queryParams: $TSFixMe = new URLSearchParams(location.search);
  const [driverId, setDriverId]: $TSFixMe = useState(queryParams.get("id"));

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`"card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Driver Management</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.drivers &&
                    props.drivers.result &&
                    props.drivers.result.length ? (
                    <CSVLink
                      filename={"driver-management-file.csv"}
                      data={props.drivers.result}
                      headers={driverManagmentDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {isDriveWrite &&
                    <AddDriver />
                  }
                </Col>
              </Row>
              <CardBody className="tabAll">
                <DriverManagementTab isWritable={isDriveWrite} driverId={driverId} setDriverId={setDriverId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  drivers: state.driverData.drivers,
});

export default connect(mapStateToProps)(DriverManagement);
