import { UserConfig, loginRequest } from "../../../../global.types";
import { GET_USER, LOGIN_USER, LOGOUT_USER, SET_TOKEN, SET_USER, UPDATE_USER, UPDATE_USER_PROFILE_IMG } from "./user.types";

export const loginUser = (data: loginRequest) => {
  return {
    type: LOGIN_USER as typeof LOGIN_USER,
    data,
    meta: {
      thunk: true,
    },
  };
};

export const getUser = () => {
  return {
    type: GET_USER as typeof GET_USER,
    meta: {
      thunk: true,
    },
  };
};

// export const setUser = (data: UserConfigData) => {
//   return {
//     type: SET_USER as typeof SET_USER,
//     data,
//   };
// };
export const logoutUser = (data: $TSFixMe) => {
  return {
    type: LOGOUT_USER as typeof LOGOUT_USER,
    data,
    meta: {
      thunk: true,
    },
  };
};

export const updateUser = (data: UserConfig) => {
  return {
    type: UPDATE_USER as typeof UPDATE_USER,
    data,
    meta: {
      thunk: true,
    },
  };
};

export const updateUserProfileImg = (data: $TSFixMe) => {
  return {
    type: UPDATE_USER_PROFILE_IMG as typeof UPDATE_USER_PROFILE_IMG,
    data,
  };
};
// export const setToken = (data: string) => {
//   return {
//     type: SET_TOKEN as typeof SET_TOKEN,
//     data,
//   };
// };
