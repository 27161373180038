import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import ASSETS from "../../../assets";
import { endLoading, startLoading } from "../../../redux/reducers/general/general.actions";
import { AppDispatch } from "../../../redux/store/store";
import { toast } from "react-toastify";
import { getTripVideoRequest } from "../../../api/axios.resources";
import { LayoutContext } from "../../../context/LayoutContext";

interface ConfirmModelProps {
  endPointData: $TSFixMe;
  startPointData: $TSFixMe;
  isVideoRequest: boolean;
  projectId: $TSFixMe;
  setIsVideoRequest: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & ConfirmModelProps;

const VideoRequest = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestVideo = async () => {
    setIsLoading(true);
    try {
      const res: $TSFixMe = await getTripVideoRequest({
        deviceId: props.startPointData.deviceId,
        endTimestamp: props.endPointData.timestamp,
        startTimestamp: props.startPointData.timestamp,
        projectId: props.projectId
      });
      if (res.status === 200) {
        toast.success(res.message);
      }
      props.setIsVideoRequest(false);
    } catch (e) {
      console.log(e);
    } finally {
      props.setIsVideoRequest(false);
    }
  };

  return (
    <>
      <Modal isOpen={props.isVideoRequest}
        toggle={() => props.setIsVideoRequest(!props.isVideoRequest)} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Request video</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={() => props.setIsVideoRequest(!props.isVideoRequest)}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
          }}
          className="scr"
        >
          <Row>
            <div className="my-3 text-center">
              <b>Are you sure you want to request a video</b>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter className="footer-width">
          <div className="footwidth">
            <Button
              onClick={handleRequestVideo}
              disabled={isLoading}
              className="popbtn datewidth btnBox"
            >
              {isLoading && (
                <Spinner color="dark" size="sm" className="mr-2" />
              )}
              <>YES</>
            </Button>
          </div>
          <div className="divider" />
          <div
            className={"footwidth"}
          >
            <Button
              onClick={() => props.setIsVideoRequest(false)}
              className="popbtn datewidth btnBox"
            >
              <>NO</>
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: $TSFixMe) => ({
  token: state.userSlice.token,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoRequest);
