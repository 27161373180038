import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActiveSafetyEvent } from "../../../../../../global.types";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable/dataTable";
import { Link } from "react-router-dom";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import { convertTimestampToDate, encrypt } from "../../../../../services/common.functions.services";
import { DEVICE_VIDEO_STATUS } from "../../../../../constants";
import { Tooltip } from "antd";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ROUTES } from "../../../../../routes/routes";

interface activeSafetyEventsTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & activeSafetyEventsTableProps;

const ActiveSafetyEventsTab = (props: Props) => {
  const [activeSafetyEvents, setActiveSafetyEvents] = useState<ActiveSafetyEvent[]>([]);

  const ActiveSafetyActionBtns1 = (data: $TSFixMe) => {
    const gyroCommand = data?.data?.command_info?.find((command: $TSFixMe) => command.commandType === "ACC");

    // Extract start and end timestamps if GYRO command is found
    const startTimestamp = gyroCommand?.startTimestamp;
    const endTimestamp = gyroCommand?.endTimestamp;

    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data.data.deviceId,
      id: data.data._id,
      projectId: data.data.projectId,
      type: 'ACTIVE_SAFETY_EVENTS_INFO',
      endTimestamp: endTimestamp,
      startTimestamp: startTimestamp,
      timestamp: data.data.timestamp,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink" target="_blank">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const activeSafetyColumns = [
    {
      title: "Action",
      format: (data: $TSFixMe) => {
        const channel1Command = data?.command_info?.filter((command: $TSFixMe) => command?.channel === 1 && command?.commandType === "VIDEO")[0];
        return (
          <>
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode === 0) && (
              <ActiveSafetyActionBtns1 data={data} />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode !== 0) && (
              <NotInterestedIcon className="nondropico" />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.pending) && (
              <Tooltip title={"Already, requested for video."} placement={"top"}>
                <TimelapseSharpIcon className="nondropico" />
              </Tooltip>
            )}
            {!channel1Command && <NotInterestedIcon className="nondropico" />}
          </>
        )
      },
    },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data.driverName },
    { title: "Project", data: "projectName", sorting: false, format: (data: $TSFixMe) => data.projectName },
    { title: "Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    { title: "DateTime", data: "timestamp", format: (data: $TSFixMe) => data.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat) : "N/A", },
  ];

  useEffect(() => {
    const updatedHighEventsList = (props.activeSafetyEvents?.result?.data || []).map((item, index) => ({
      ...item,
      no: index + 1,
    }));
    setActiveSafetyEvents(updatedHighEventsList);
    return () => { };
  }, [props.activeSafetyEvents]);

  return (
    <>
      <DataTable
        data={activeSafetyEvents}
        columns={activeSafetyColumns}
        bordered
        pageData={props.activeSafetyEvents?.result?.page}
        totalRecrods={props.activeSafetyEvents?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        currentPage={props.selectedPage}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeSafetyEvents: state.dataStore.activeSafetyEvents,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSafetyEventsTab);
