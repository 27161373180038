import React, { useContext, useEffect, useState } from "react";
import { Container, CardBody, Col, Row, Card, CardTitle } from "reactstrap";
import Score from "./Components/Score";
import FullPageLoaderModal from "../../../../../Common/FullPageLoader/FullPageLoaderModal";
import { connect } from "react-redux";
//@ts-expect-error
import Fade from "react-reveal/Fade";
import HarshEvents from "./Components/HarshEvents";
import HoursPerDay from "./Components/HoursPerDay";
import Journey from "./Components/Journey";
import Headcards from "./Components/HeadCards";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import { useParams } from "react-router-dom";
import { getDriverStatisticsJourney, getHarshEvents, getHoursPerDay } from "../../../../../../api/axios.resources";
import { DAY_ORDER } from "../../../../../../constants";
import { decrypt } from "../../../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps>;

function DriverScoreCard(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const { encryptedParams }: $TSFixMe = useParams();
  const decryptData = decrypt(encryptedParams);

  const [harshEvent, setHarshEvent] = useState({});
  const [hourPerDay, setHourPerDay] = useState([]);
  const [journey, setJourney] = useState({});

  const handleGetDriverScore = async () => {
    try {
      const [harshEventRes, hourPerDayRes, journeyRes] = await Promise.all([
        getHarshEvents(decryptData.driverId),
        getHoursPerDay(decryptData.driverId),
        getDriverStatisticsJourney(decryptData.driverId),
      ]);
      if (harshEventRes.status === 200) {
        setHarshEvent(harshEventRes.result);
      }
      if (hourPerDayRes.status === 200) {
        const temp = hourPerDayRes.result.sort((a: $TSFixMe, b: $TSFixMe) => {
          const trimmedA = a.day_name.trim();
          const trimmedB = b.day_name.trim();
          return DAY_ORDER.indexOf(trimmedA) - DAY_ORDER.indexOf(trimmedB);
        });
        setHourPerDay(temp);
      }
      if (journeyRes.status === 200) {
        setJourney(journeyRes.result);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (Number(decryptData.driverId)) {
      handleGetDriverScore();
    }
    return () => { }
  }, [decryptData.driverId])

  return (
    <div className="dashboardMinDiv bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Fade right duration={2000}>
          <Headcards orgName={decryptData.orgName} projectName={decryptData.projectName} driverName={decryptData.name} />
        </Fade>

        <div className="card-stats md-4 mb-xl-0 pb-1">
          <Row className="align-items-baseline">
            <Col className="col-lg-6 col-12 col-xl-6 col-md-12 col-sm-12 tabAll scoreLeft">
              <Card className={`driverScoreCards ${selectedTheme}`}>
                <CardBody className="cardbody">
                  <CardTitle className="cardheader">Harsh Events</CardTitle>
                  <div className="mt-1">
                    <HarshEvents harshEvent={harshEvent} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6 col-12 col-xl-6 col-md-12 col-sm-12 tabAll scoreRight">
              <Card className={`driverScoreCards  ${selectedTheme}`}>
                <CardBody className="cardbody">
                  <CardTitle className="cardheader">Hours Per Day</CardTitle>
                  <div className="mt-1">
                    <HoursPerDay hourPerDay={hourPerDay} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-2 align-items-baseline">
            <Col className="col-lg-6 col-12 col-xl-6 col-md-12 col-sm-12 tabAll scoreLeft">
              <Card className={`driverScoreCards ${selectedTheme} pb-3`}>
                <CardBody className="cardbody">
                  <CardTitle className="cardheader mb-4">Score</CardTitle>
                  <div className="mb-1">
                    <Score score={decryptData.score} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6 col-12 col-xl-6 col-md-12 col-sm-12 tabAll scoreRight">
              <Card className={`driverScoreCards ${selectedTheme}`}>
                <CardBody className="cardbody">
                  <CardTitle className="cardheader">Journey</CardTitle>
                  <div className="mt-3">
                    <Journey journey={journey} user={props.user} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(DriverScoreCard);
