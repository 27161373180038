import moment from "moment-timezone";
import L from 'leaflet';
import { TIME_FORMAT_FOR_12_HOUR, TIME_FORMAT_FOR_24_HOUR, carIcon, mapIcon, time } from "../constants";
import CryptoJS from 'crypto-js';

export const filterArrayOfObj = (
  arr: Array<any>,
  key: string,
  value: string,
  sort: { order: "asc" | "desc"; key: string | null } = {
    order: "asc",
    key: null,
  }
): Array<any> => {
  const result =
    arr && arr.length
      ? arr.filter((obj) => {
        return obj[key] == value;
      })
      : [];
  if (sort.key && result.length > 0) {
    return sortByKey(result, sort.key, sort.order);
  }
  return result;
};

export function sortByKey(
  array: Array<any>,
  key: string,
  order: "asc" | "desc"
) {
  return order === "desc"
    ? array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    })
    : array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function convertTimestampToDate(timeZoneName: string = "Asia/Kolkata", timestamp: any, format?: any, timeFormat?: number) {
  if (isNaN(timestamp)) {
    // Return "N/A" for invalid timestamp
    return "N/A";
  }
  const timeFormatWithDate = timeFormat && timeFormat === time[1].value ? TIME_FORMAT_FOR_12_HOUR : timeFormat && timeFormat === time[0].value ? TIME_FORMAT_FOR_24_HOUR : null;
  // Convert Unix timestamp to a moment object
  const momentUtc = moment.unix(Number(timestamp));
  // Convert to the desired timezone (Etc/GMT+8)
  const selectedTimezone = momentUtc.tz(timeZoneName ?? "Asia/Kolkata");
  // Format the moment object to a readable string
  const formattedDate = selectedTimezone.format(timeFormatWithDate && format ? `${format} ${timeFormatWithDate}` : format ?? `MM-DD-YYYY ${timeFormatWithDate}`);
  // Format the date using Moment.js with the custom timezone offset
  return formattedDate;
}

export function convertDateToTimestamp(date: string, timezone: any = "Asia/Kolkata") {
  // List of possible date formats to attempt for parsing (with and without time part)
  const dateFormats = [
    'MM-DD-YYYY HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss',
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'Do MMMM, YYYY'
    // Add more date formats as needed
  ];

  const momentDate = moment.tz(date, dateFormats, timezone ?? "Asia/Kolkata");
  return momentDate.isValid() ? momentDate.unix() : null;;
}

export function convertMileToKilometer(value: number) {
  const kmValue = value * 1.609344;
  return kmValue.toFixed(2);
}

export function convertKilometerToMile(value: number) {
  const kmValue = value * 0.621371;
  return Number(kmValue.toFixed(2));
}

export const getCustomIcon = (angle: number, color?: string, iconType?: string) => {
  const carColor = color || 'red';
  const marker = iconType === "map" ? mapIcon(carColor) : carIcon(carColor);
  return L.divIcon({
    className: `custom-map-icon ${iconType === "map" ? "map-marker" : "car-marker"}`,
    html: `<div style="${iconType !== "map" && `transform: rotate(${angle}deg); display: flex; justify-content: center;`}">
    ${marker}</div>`,
    iconSize: iconType !== "map" ? [24, 28] : [26, 26],
  });
};

export const calculateMapBounds = (markersData: $TSFixMe) => {
  if (!markersData || markersData.length === 0) return null;

  let minLat = markersData[0].position[0];
  let maxLat = markersData[0].position[0];
  let minLng = markersData[0].position[1];
  let maxLng = markersData[0].position[1];

  markersData.forEach((marker: $TSFixMe) => {
    const lat = marker.position[0];
    const lng = marker.position[1];
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  return [
    [minLat, minLng],
    [maxLat, maxLng],
  ];
};

export function calculateTotalDrivenTime(startEpoch: number, endEpoch: number): string {
  // Calculate the difference in milliseconds
  const timeDifference: number = endEpoch - startEpoch;
  // Convert milliseconds to hours, minutes, and seconds
  const hours: number = Math.floor(timeDifference / (100 * 6 * 6));
  const minutes: number = Math.floor((timeDifference % (100 * 6 * 6)) / (100 * 6));
  const seconds: number = Math.floor((timeDifference % (100 * 6)) / 100);

  // Return the total driven time in the format HH:MM:SS
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const getEventIcon = (angle: number, url: string) => {
  return L.divIcon({
    className: `custom-map-icon`,
    html: `
    <div style="transform: rotate(${angle}deg);">
    <img src="${url}" />
    </div>`,
    iconSize: [32, 30],
  });
};

const secretKey = 'HvLYGqWjKFboXmiU';
const IV = 'zDfRpKuBhJmTgVeS';

// Encrypt function
export const encrypt = (data: $TSFixMe) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey),
    {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: CryptoJS.enc.Utf8.parse(IV)
    }).toString();
};

// Decrypt function
export const decrypt = (ciphertext: $TSFixMe) => {
  // const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(IV)
      }
    );
    const decryptedText = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedText;
  } catch (error) {
    console.error(error);
    throw new Error('Decryption failed');
  }
};

export const secondToTimeFormat = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const calculatePolygonArea = (coords: $TSFixMe) => {
  const coordinates = coords.map(({ longitude, latitude }: $TSFixMe) => [parseFloat(latitude), parseFloat(longitude)]);
  const R = 6371000; // Earth's radius in meters
  let totalArea = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    const p1 = coordinates[i];
    const p2 = coordinates[i + 1];

    const lat1 = p1[1] * Math.PI / 180;
    const lat2 = p2[1] * Math.PI / 180;
    const deltaLat = (p2[1] - p1[1]) * Math.PI / 180;
    const deltaLng = (p2[0] - p1[0]) * Math.PI / 180;

    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    totalArea += R * c;
  }

  return Math.abs(totalArea);
}