import React from 'react'
import { convertTimestampToDate, encrypt } from '../../../services/common.functions.services'
import { Spinner } from 'reactstrap';
import { DEVICE_VIDEO_STATUS } from '../../../constants';
import { ROUTES } from '../../../routes/routes';
import { Link } from 'react-router-dom';
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getLowEventVideoRequest } from '../../../api/axios.resources';
import { toast } from 'react-toastify';

export const TripEventPopup = (props: $TSFixMe) => {
    const gyroCommand = props.tripEventInfo?.command_info?.find((command: $TSFixMe) => command.commandType === "ACC");

    // Extract start and end timestamps if GYRO command is found
    const startTimestamp = gyroCommand?.startTimestamp;
    const endTimestamp = gyroCommand?.endTimestamp;

    const handleRequestVideo = async (data: $TSFixMe) => {
        // setIsLoading(true);
        try {
            const res: $TSFixMe = await getLowEventVideoRequest({ deviceId: data.deviceId, id: data._id, organizationId: data.organizationId, projectId: data.projectId });
            if (res.status === 200) {
                toast.success(res.message);
            }
        } catch (e) {
            console.log(e);
        } finally {
            // setIsLoading(false);
        }
    };

    const encryptedParamsHighEvent: $TSFixMe = encrypt({
        deviceId: props.tripEventInfo?.deviceId,
        id: props.tripEventInfo?._id,
        projectId: props.tripEventInfo?.projectId,
        type: 'HIGH_EVENT_VIDEO_INFO',
        endTimestamp: endTimestamp,
        startTimestamp: startTimestamp,
        timestamp: props.tripEventInfo?.timestamp,
    });

    const encryptedParamsLowEvent: $TSFixMe = encrypt({
        deviceId: props.tripEventInfo?.deviceId,
        id: props.tripEventInfo?._id,
        projectId: props.tripEventInfo?.projectId,
        type: 'LOW_EVENT_VIDEO_INFO',
        endTimestamp: endTimestamp,
        startTimestamp: startTimestamp,
        timestamp: props.tripEventInfo?.timestamp,
    });

    return (
        <>
            <div className="text-heading pe-3">Trip Event Information</div>
            {props.isTripEventLoading &&
                <div className='text-center'>
                    <Spinner color="dark" size="sm" />
                </div>
            }
            {!props.isTripEventLoading && props.tripEventInfo && Object.keys(props.tripEventInfo).length === 0 &&
                <div className="text-center">
                    No Data Found...
                </div>
            }
            {!props.isTripEventLoading && props.tripEventInfo && Object.keys(props.tripEventInfo).length > 0 &&
                <>
                    <div>
                        <b>Vehicle Reg#:</b> {props.tripEventInfo?.vehicleRegNo}
                    </div>
                    <div>
                        <b>Device Id:</b> {props.tripEventInfo?.deviceId}
                    </div>
                    <div>
                        <b>Project Name:</b> {props.tripEventInfo?.projectName}
                    </div>
                    <div>
                        <b>Organization Name:</b> {props.tripEventInfo?.organizationName}
                    </div>
                    <div>
                        <b>Driver Name:</b> {props.tripEventInfo?.driverName}
                    </div>
                    <div>
                        <b>Event Name:</b> {props.tripEventInfo?.eventName}
                    </div>
                    <div>
                        <b>Location:</b> {props.tripEventInfo?.latitude && props.tripEventInfo?.longitude && props.location}
                    </div>
                    <div>
                        <b>Date & Time:</b>{" "}
                        {props.tripEventInfo?.timestamp
                            ? convertTimestampToDate(props.user.timeZone.zoneId, props.tripEventInfo?.timestamp, null, props.user.timeFormat)
                            : "-"}
                    </div>
                    <div className="d-flex align-items-center">
                        <b>Action:</b>{" "}
                        {props.actionType === "HIGH_EVENTS_BY_ID" &&
                            <>
                                {props.tripEventInfo?.command_info.length > 0 && props.tripEventInfo?.command_info.some((command: $TSFixMe) => command.status === DEVICE_VIDEO_STATUS.completed) && (
                                    <div className="imptabbody">
                                        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParamsHighEvent}`} className="tabLink" target="_blank">
                                            <PlayCircleOutlineSharpIcon className="dropico icon-green" />
                                        </Link>
                                    </div>
                                )}
                                {props.tripEventInfo?.command_info.length > 0 && props.tripEventInfo?.command_info.every((command: $TSFixMe) => command.status === DEVICE_VIDEO_STATUS.pending) &&
                                    <div>
                                        <TimelapseSharpIcon className="nondropico icon-blue" /> Already, requested for video.
                                    </div>
                                }
                                {(props.tripEventInfo?.command_info.length === 0 || props.tripEventInfo?.command_info === null) && <NotInterestedIcon className="nondropico icon-red" />}
                            </>
                        }
                        {props.actionType === "LOW_EVENTS_BY_ID" &&
                            <>
                                {props.tripEventInfo?.command_info.length > 0 && props.tripEventInfo?.command_info.some((command: $TSFixMe) => command.status === DEVICE_VIDEO_STATUS.completed) && (
                                    <div className="imptabbody">
                                        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParamsLowEvent}`} className="tabLink" target="_blank">
                                            <PlayCircleOutlineSharpIcon className="dropico icon-green" />
                                        </Link>
                                    </div>
                                )}
                                {props.tripEventInfo?.command_info.length > 0 && props.tripEventInfo?.command_info.every((command: $TSFixMe) => command.status === DEVICE_VIDEO_STATUS.pending) &&
                                    <div>
                                        <TimelapseSharpIcon className="nondropico icon-blue" /> Already, requested for video.
                                    </div>
                                }
                                {(props.tripEventInfo?.command_info.length === 0 || props.tripEventInfo?.command_info === null) && <FileDownloadOutlinedIcon className="dropico icon-blue" onClick={() => handleRequestVideo(props.tripEventInfo)} />}
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}
