import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { GetLowEvent } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../routes/routes";
import { getLocation } from "../../../../../api/axios.resources";
import { Popover, Tooltip } from "antd";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { convertTimestampToDate, encrypt } from "../../../../../services/common.functions.services";
import { Spinner } from "reactstrap";
import ConfirmModel from "./ConfirmModel";
import { DEVICE_VIDEO_STATUS } from "../../../../../constants";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";

interface lowEventTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  handleFilter: (d: $TSFixMe, type?: string) => void;
  selectedFilter: $TSFixMe;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  lowEventTableProps;

const LowEventsTab = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [lowEventsList, setLowEventsList] = useState<GetLowEvent[]>([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [location, setLocation] = useState("");

  const LowEventsActionBtns1 = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        <ConfirmModel data={data} handleFilter={props.handleFilter} selectedFilter={props.selectedFilter} />
      </div>
    );
  };

  const getAddressFromLatLng = async (latitude: number, longitude: number) => {
    try {
      if (!latitude && !longitude) {
        setLocation("No address found");
        return;
      }
      const response = await getLocation(latitude, longitude);
      if (response) {
        setLocation(response.data.display_name);
      }
      if (response.data.error && !response.data.display_name) {
        setLocation(response.data.error);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setLocation("Error fetching address");
    }
  };

  const LowEventsActionBtns4 = (data: $TSFixMe) => {
    const gyroCommand = data?.data?.command_info?.find((command: $TSFixMe) => command.commandType === "ACC");

    // Extract start and end timestamps if GYRO command is found
    const startTimestamp = gyroCommand?.startTimestamp;
    const endTimestamp = gyroCommand?.endTimestamp;

    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data.data.deviceId,
      id: data.data._id,
      projectId: data.data.projectId,
      type: 'LOW_EVENT_VIDEO_INFO',
      endTimestamp: endTimestamp,
      startTimestamp: startTimestamp,
      timestamp: data.data.timestamp,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink" target="_blank">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const lowEventsColumns = [
    {
      title: "Action",
      format: (data: $TSFixMe) => {
        const channel1Command = data?.command_info?.filter((command: $TSFixMe) => command?.channel === 1 && command?.commandType === "VIDEO")[0];
        return (
          <>
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode === 0) && (
              <LowEventsActionBtns4 data={data} />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode !== 0) && (
              <NotInterestedIcon className="nondropico" />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.pending) && (
              <Tooltip title={"Already, requested for video."} placement={"top"}>
                <TimelapseSharpIcon className="nondropico" />
              </Tooltip>
            )}
            {!channel1Command && <LowEventsActionBtns1 data={data} />}
          </>
        )
      },
    },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data.driverName },
    { title: " Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    {
      title: "Event",
      data: "eventName",
    },
    {
      title: "Location",
      format: (data: $TSFixMe) => (
        <Popover
          content={
            location ? (
              <>
                {location}
                {data?.latitude && data?.longitude &&
                  <div>
                    <a
                      onClick={() => setSelectedEventId(null)}
                      href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      View Location{" "}
                      <OpenInNewIcon color="primary" sx={{ fontSize: 12 }} className="wh-12-px" />
                    </a>
                  </div>
                }
              </>
            ) : (
              <Spinner color="primary" size="sm" className="mx-auto d-block" />
            )
          }
          trigger="click"
          placement="left"
          overlayClassName={`location-popover ${selectedTheme}`}
          visible={selectedEventId === data._id}
          onVisibleChange={(visible) => {
            if (visible) {
              getAddressFromLatLng(data.latitude, data.longitude);
            }
            setSelectedEventId(visible ? data._id : null);
          }}
        >
          <LocationOnOutlinedIcon className="dropico" />
        </Popover>
      ),
    },
    {
      title: "DateTime",
      data: "timestamp",
      format: (data: $TSFixMe) => data.timestamp ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.timestamp,
          null,
          props.user.timeFormat
        ) : "",
    },
  ];

  useEffect(() => {
    const updatedLowEventsList = (props.lowEvents?.result?.data || []).map(
      (item, index) => ({
        ...item,
        no: index + 1,
      })
    );
    setLowEventsList(updatedLowEventsList);
    return () => { };
  }, [props.lowEvents]);

  return (
    <>
      <DataTable
        data={lowEventsList}
        columns={lowEventsColumns}
        bordered
        pageData={props.lowEvents?.result?.page}
        totalRecrods={props.lowEvents?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  lowEvents: state.dataStore.lowEvents,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LowEventsTab);
