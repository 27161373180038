export enum TOAST_MSG {
  LOADING = "Working...",
  DELETED = "Record has been deleted",
  ADD_ROLE_SUCCESS = "Role Added Successfully!",
  EDIT_MANAGE_ROLES_SUCCESS = "Role Updated Successfully!",
  ROLE_ERROR = "Error in Role!",
  COMMON_CONFIGURATION_SUCCESS = "Configuration Added Successfully!",
  EDIT_COMMON_CONFIGURATION_SUCCESS = "Configuration Updated Successfully!",
  DELETE_COMMON_CONFIGURATION_SUCCESS = "Configuration Deleted Successfully",
  COMMON_CONFIGURATION_ERROR = "Error in Configuration!",
  DEVICE_SUCCESS = "Device Added Successfully!",
  VERIFY_DEVICE_SUCCESS = "Device verified Successfully!",
  EDIT_DEVICE_SUCCESS = "Device Updated Successfully!",
  DEVICE_ERROR = "Error in Device!",
  DRIVER_SUCCESS = "Driver Added Successfully!",
  EDIT_DRIVER_SUCCESS = "Driver Updated Successfully!",
  DRIVER_ERROR = "Error in Driver!",
  PROJECT_SUCCESS = "Project Added Successfully!",
  EDIT_PROJECT_SUCCESS = "Project Updated Successfully!",
  PROJECT_ERROR = "Error in Project!",
  SUPPORT_TICKET_SUCCESS = "Ticket Added Successfully!",
  EDIT_SUPPORT_TICKET_SUCCESS = "Ticket Updated Successfully!",
  SUPPORT_TICKET_ERROR = "Error in Ticket!",
  USERS_SUCCESS = "Users Added Successfully!",
  EDIT_USERS_SUCCESS = "Users Updated Successfully!",
  USERS_ERROR = "Error in Users!",
  GEO_FENCING_SUCCESS = "Geo Fencing Added Successfully!",
  GEO_FENCING_ERROR = "Error in Geo Fencing!!",
}

export enum TOAST_MSG_GEO_FENCING_DELETE {
  DELETE_GEO_FENCING_SUCCESS = "Geo Fencing Deleted Successfully!",
  DELETE_GEO_FENCING_ERROR = "",
  LOADING = "Working...",
}

export enum TOAST_MSG_GEO_FENCING_STATUS_CHANGE {
  STATUS_CHANGE_GEO_FENCING_SUCCESS = "Geo Fencing updated Successfully!",
  STATUS_CHANGE_GEO_FENCING_ERROR = "",
  LOADING = "Working...",
}