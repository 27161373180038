const ASSETS = {
  //png
  ABBOTT: require("./png/Abbott.png"),
  DOWN_ARROW_TABLE: require("./png/down-arrow-table.png"),
  EDIT: require("./png/edit.png"),
  EDIT_HOVER: require("./png/editHover.png"),
  INPUT_DROPDOWN: require("./png/inputdropdown.png"),
  SIGN: require("./png/sign.png"),
  SIGN_HOVER: require("./png/signHover.png"),
  STREET: require("./png/RD_LogoFav.png"),
  STREET_ANGEL: require("./png/RD_Logo.png"),
  WHITE_STREET_ANGEL: require("./png/RD_Logo.png"),
  USER2: require("./png/user2.png"),
  ICON_SELECT: require("./png/icon-select.png"),
  ICON_SELECT_WHITE: require("./png/icon-select-white.png"),
  ICON_BREAK: require("./png/break.png"),
  ICON_ACCELERATION: require("./png/acceleration.png"),
  ICON_CORNORING: require("./png/cornoring.png"),
  ICON_FALL: require("./png/fall.png"),
  ICON_BUMP: require("./png/bump.png"),
  //svg
  DOUBLE_ARROW: require("./svg/double-arrow.svg").default,
  ERROR_404_Dark: require("./svg/404-Error-dark.svg").default,
  ERROR_404_Light: require("./svg/404-Error-light.svg").default,
  DOUBLE_ARROW_TABLE: require("./svg/down-arrow-table.svg").default,
  DOWN_ARROW: require("./svg/down-arrow.svg").default,
  EXIT: require("./svg/exit.svg").default,
  ICON_NEW_3: require("./svg/icon-new-3.svg").default,
  ICON_NEW_4: require("./svg/icon-new-4.svg").default,
  ICON_NEW_5: require("./svg/icon-new-5.svg").default,
  LINEW: require("./svg/lines.svg").default,
  MENU_ARROW: require("./svg/menu-arrow.svg").default,
  MULTIPLY: require("./svg/multiply.svg").default,
  PERSON: require("./svg/person.svg").default,
  SEARCH: require("./svg/search.svg").default,
  SEARCH_ICON: require("./svg/search-icon.svg").default,
  SETTINGS: require("./svg/settings.svg").default,
  TRASH: require("./svg/Trash.svg").default,
  UP_ARROW: require("./svg/up-arrow.svg").default,
  MILES: require("./svg/miles.svg").default,
  ONLINE: require("./svg/online.svg").default,
  TOTAL: require("./svg/total.svg").default,
};

export default ASSETS;
