import { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { connect } from "react-redux";
import Select from "react-select";
import ASSETS from "../../../../../../../assets";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { CTAdminUser } from "../../../../../../../../global.types";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { data: CTAdminUser };

const View = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <VisibilityOutlinedIcon onClick={toggle} className="dropico" />
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">View User</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto'
        }} className="scr">
          <Row>
            <Col md={12}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Username</div>
                <Input
                  className="disableField"
                  disabled
                  type="text"
                  value={props.data.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Email Id</div>
                <Input
                  className="disableField"
                  disabled
                  type="email"
                  value={props.data.email}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Mobile Number</div>
                <Select
                  placeholder={"Country Code"}
                  isDisabled
                  classNamePrefix={"vd-select-dropdown"}
                  value={{
                    label: props.data.mobilecode,
                    value: props.data.mobilecode,
                  }}
                  options={props.dropdowns.dialCode?.dropDown}
                />
              </FormGroup>
            </Col>
            <Col md={9}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  <br />
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Digits Only"
                  value={props.data.mobileNumber}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div className="modalheader modinpulabel">Select User Role</div>
            <Col md={12}>
              <FormGroup className="dropinput">
                <div className="modalheader"></div>
                <Select
                  isDisabled
                  value={props.data.role}
                  classNamePrefix={"vd-select-dropdown"}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id.toString()}
                  options={props.dropdowns.userRolesListDD.result}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="modlabel modinpulabel modinpulabel">
                Select Project
              </Label>
              <div className="select-project mb-3">
                <div className="adminTable proTab d-flex flex-wrap">
                  {props.dropdowns.projectListDD.result && props.dropdowns.projectListDD.result.length > 0 &&
                    props.dropdowns.projectListDD.result.map((o: $TSFixMe, index: $TSFixMe) => {
                      return (
                        <FormGroup check className="w-50" key={index} >
                          <Label className="customcamera" check>
                            <Input
                              type="checkbox"
                              disabled
                              checked={props.data.projectNames ? props.data.projectNames.split(',').includes(o.name) : false}
                            />
                            {o.name}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label check className="inpucheck">
                  <Input
                    disabled
                    type="checkbox"
                    checked={props.data.allowEmailAlert}
                  />
                  Do you Allow to Alert Email
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label check className="inpucheck">
                  <Input
                    disabled
                    type="checkbox"
                    checked={props.data.allowMaintenanceEmail}
                  />
                  Do you Allow to Maintenance Email
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label check className="inpucheck">
                  <Input
                    disabled
                    type="checkbox"
                    checked={props.data.sendWelcomeEmail}
                  />
                  Do you want to send welcome email
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={2}>
              <Label className="modlabel modinpulabel">Status</Label>
            </Col>
            <Col md={6}>
              <RadioGroup className="radioStatus">
                <FormControlLabel
                  value={true}
                  checked={props.data.status}
                  control={<Radio />}
                  label="Active"
                  disabled
                />
                <FormControlLabel
                  value={true}
                  checked={!props.data.status}
                  control={<Radio />}
                  label="Deactive"
                  disabled
                />
              </RadioGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="assi footer-width py-3">
          <Button className="popbtn vie datewidth btnBox" onClick={toggle}>
            <>CLOSE</>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(View);
