import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../../../api/api.constants";
import { get } from "../../../services/axios.services";
import { onError, onSuccess, safe } from "../../store/saga/sagaHelper";
import {
  GET_DEVICE_LIST_DD,
  GET_DEVICE_LIST_DD_VIDEO_REQUEST,
  GET_DEVICE_LIST_FROM_PROJ,
  GET_DIAL_CODE,
  GET_DRIVER_LIST_DD,
  GET_ORG_LIST,
  GET_PROJECT_LIST_DD,
  GET_PROJECT_LIST_DD_VIDEO_REQUEST,
  GET_PROJECT_LIST_FROM_ORG,
  GET_RESOURCE_ROLE_LIST,
  GET_TIME_ZONE_LIST,
  GET_USER_ROLE_LIST,
} from "./dropdown.types";

function* getDialCodeList({ meta }: $TSFixMe) {
  //@ts-expect-error
  const response = yield call(get, API.DIALCODE);
  return response;
}

function* getProjectsUnderOrgList({ id, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.PROJECT_UNDER_ORGANIZATION + "/" + id);
  return response;
}
function* getDevicesUnderProjList({ id, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.DEVICE_LIST_DD + "/" + id);
  return response;
}
function* getResourceRolesList({ meta }: $TSFixMe) {
  //@ts-expect-error
  const response = yield call(get, API.RESOURCE_ROLES);
  return response;
}

function* getTimeZoneList({ meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.TIME_ZONE_LIST);
  return response;
}

function* getProjectListDD({ meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.PROJECT_LIST_DD);
  return response;
}

function* getDeviceListDD({ meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.DEVICE_LIST_DD);
  return response;
}

function* getUserRoleListDD({ meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.USER_ROLE_LIST_DD);
  return response;
}

function* getDriverListDD({ id, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(get, API.DRIVER_LIST_DD + "/" + id);
  return response;
}

function* dropDownSaga() {
  yield takeLatest(GET_DIAL_CODE, safe(onError, getDialCodeList, onSuccess));
  yield takeLatest(
    GET_PROJECT_LIST_FROM_ORG,
    safe(onError, getProjectsUnderOrgList, onSuccess)
  );
  yield takeLatest(
    GET_DEVICE_LIST_FROM_PROJ,
    safe(onError, getDevicesUnderProjList, onSuccess)
  );
  yield takeLatest(
    GET_RESOURCE_ROLE_LIST,
    safe(onError, getResourceRolesList, onSuccess)
  );
  yield takeLatest(GET_TIME_ZONE_LIST, safe(onError, getTimeZoneList, onSuccess));
  yield takeLatest(GET_PROJECT_LIST_DD, safe(onError, getProjectListDD, onSuccess));
  yield takeLatest(GET_PROJECT_LIST_DD_VIDEO_REQUEST, safe(onError, getProjectListDD, onSuccess));
  yield takeLatest(GET_DEVICE_LIST_DD, safe(onError, getDeviceListDD, onSuccess));
  yield takeLatest(GET_DEVICE_LIST_DD_VIDEO_REQUEST, safe(onError, getDevicesUnderProjList, onSuccess));
  // yield takeLatest(GET_ORG_LIST, safe(onError, getOrganizationList, onSuccess));
  yield takeLatest(GET_DRIVER_LIST_DD, safe(onError, getDriverListDD, onSuccess));
  yield takeLatest(GET_USER_ROLE_LIST, safe(onError, getUserRoleListDD, onSuccess));
}

export default dropDownSaga;
