export const GET_DRIVER = "GET_DRIVER";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";

export const GET_DRIVER_FOR_PROJECT = "GET_DRIVER_FOR_PROJECT";
export const GET_DRIVER_FOR_PROJECT_SUCCESS = "GET_DRIVER_FOR_PROJECT_SUCCESS";
export const GET_DRIVER_FOR_PROJECT_ERROR = "GET_DRIVER_FOR_PROJECT_ERROR";

export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_ERROR = "ADD_DRIVER_ERROR";

export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_ERROR = "EDIT_DRIVER_ERROR";

export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_ERROR = "DELETE_DRIVER_ERROR";
