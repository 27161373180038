import { useContext, useEffect, useState } from "react";
import DataTable from "../../../../Common/DataTable";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { connect } from "react-redux";
import {
  getDriver,
  getDriverForProj,
} from "../../../../../redux/reducers/Management&Settings/driverManagement/driverManagement.actions";
import { Driver } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import usePermission from "../../../../../constants/usePermission";
import DriverView from "./Components/driverView";
import DriverEdit from "./Components/driverEdit";
import DriverDelete from "./Components/driverDelete";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { useHistory } from "react-router-dom";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isWritable: boolean;
    driverId: string;
    setDriverId: React.Dispatch<React.SetStateAction<any>>;
  };

const DriverManagementTab = (props: Props) => {
  const [driversList, setDriversList] = useState<Driver[]>([]);
  const { selectedProject }: $TSFixMe = useContext(LayoutContext);

  const history = useHistory();

  const { isWritable, driverId, setDriverId } = props;

  const DriverActionBtns = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        <DriverView data={data.data} />
        {isWritable && <DriverEdit data={data.data} />}
        {isWritable && <DriverDelete data={data.data} />}
      </div>
    );
  };

  const driverColumns = [
    { title: "Project", data: "projectName" },
    { title: "Name", data: "name" },
    { title: "Country", data: "countryName" },
    // { title: "Country", format: (data: Driver) => <Country data={data} /> },
    { title: "Mobile", data: "mobileNo" },
    { title: "Emergency Contact", data: "emergencyContact" },
    { title: "Email", data: "email" },
    { title: "Status", format: (data: Driver) => <>{data.status + ""}</> },
    {
      title: "Action",
      format: (data: Driver) => <DriverActionBtns data={data} />,
    },
  ];

  const apiCalls = async () => {
    if (selectedProject.id) {
      try {
        // await props.getDriver();
        if (driverId) {
          await props.getDriver({ driverId });
        } else {
          await props.getDriverForProject(selectedProject.id);
        }
      } catch (error) {
        console.log("error in DriverManagementTab api", error);
      } finally {
        await props.endLoading();
      }
    }
  };

  useEffect(() => {
    if (props.drivers?.result) {
      setDriversList([...props.drivers.result]);
    }
    return () => { };
  }, [props.drivers]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      setDriverId(null);
      apiCalls();
    }
    return () => { };
  }, [selectedProject]);

  useEffect(() => {
    if (!props.driverId) {
      history.push("/driver-management");
    }
    return () => { };
  }, [props.driverId]);

  return (
    <>
      <>
        <DataTable
          data={driversList}
          columns={driverColumns}
          bordered
          hover={true}
          responsive={true}
        />
      </>
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  drivers: state.driverData.drivers,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDriver: (params: $TSFixMe) => dispatch(getDriver(params)),
  getDriverForProject: (id: $TSFixMe) => dispatch(getDriverForProj(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverManagementTab);
