import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { useEffect, useRef } from "react";
import { MAP_DEFAULT_LAT_LONG } from "../../../../../constants";
import { calculatePolygonArea } from "../../../../../services/common.functions.services";

function Loc(props: $TSFixMe) {
  const mapRef = useRef(null);
  const isMapInitialized = useRef(false);
  const isCircleDrawnRef = useRef(false);

  useEffect(() => {
    if (!isMapInitialized.current && mapRef.current) {
      const map = L.map(mapRef.current).setView(props.geoFence && props.geoFence?.latitude ? [props?.geoFence?.latitude,
      props?.geoFence?.longitude] : props.geoFence.coordinates && props.geoFence.coordinates.length > 0 ? [props?.geoFence?.coordinates[0]?.latitude,
      props?.geoFence?.coordinates[0]?.longitude] : MAP_DEFAULT_LAT_LONG, 13);
      isMapInitialized.current = true;

      const baseLayers = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
      const humanitarian = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png');
      const satellite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}');

      const layers = {
        "Base Map": baseLayers,
        "Humanitarian": humanitarian,
        "Satellite": satellite,
      };

      L.control.layers(layers).addTo(map);

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          remove: true, // Enable remove functionality for drawn circles
        },
        draw: {
          polyline: false,
          circle: {
            shapeOptions: {
              color: "#007bff",
            },
          },
          rectangle: false,
          polygon: {
            shapeOptions: {
              color: "#007bff",
            },
            allowIntersection: false, // Disable polygon intersection
            drawError: {
              color: "#e74c3c", // Set color for draw error (e.g., intersection)
              timeout: 1000, // Set how long the draw error message is shown (milliseconds)
            },
          },
          marker: false,
          circlemarker: false,
        },
      });

      if (props.mode === "view") {
        map.removeControl(drawControl);
      } else {
        map.addControl(drawControl);
      }

      const onDrawCreated = (event: any) => {
        const { layerType, layer } = event;

        if (layerType === "circle" && !isCircleDrawnRef.current) {
          // If a circle is drawn and it's the first one, add it to the map and feature group
          map.addLayer(layer);
          drawnItems.addLayer(layer);

          const latLng = layer.getLatLng(); // Get the center of the circle
          const radius = layer.getRadius(); // Get the radius of the circle

          props.setGeoFence((prev: any) => {
            return {
              ...prev,
              latitude: latLng.lat,
              longitude: latLng.lng,
              radius: radius,
              coordinates: null,
              geofenceType: 1,
            };
          });

          isCircleDrawnRef.current = true; // Mark that a circle has been drawn
        }

        //TODO Implement polygon in map
        if (layerType === "polygon" && !isCircleDrawnRef.current) {

          const latLngs = layer.getLatLngs()[0]; // Assuming a single polygon ring
          let selectedPoints = [];

          // Select only the first three points
          if (latLngs.length >= 4) {
            selectedPoints = latLngs.slice(0, 4);
          } else {
            selectedPoints = latLngs;
          }

          // Add the first point to the end to close the polygon
          selectedPoints.push(selectedPoints[0]);

          // Create a new polygon with the selected points
          const newPolygon = L.polygon(selectedPoints, {
            color: "#007bff",
          });

          // Replace the existing layer with the new polygon
          map.removeLayer(layer);
          map.addLayer(newPolygon);
          drawnItems.addLayer(newPolygon);

          // Convert the coordinates to a format suitable for saving or processing
          const polygonCoords = selectedPoints.map((latLng: $TSFixMe) => [latLng.lng.toFixed(6), latLng.lat.toFixed(6)]);

          // Assuming coordinatesArray is the array of coordinates you have
          const coordinates = polygonCoords.map(([longitude, latitude]: $TSFixMe) => ({
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          }));
          props.setGeoFence((prev: any) => {
            return {
              ...prev,
              latitude: null,
              longitude: null,
              radius: calculatePolygonArea(coordinates).toFixed(0),
              coordinates: coordinates,
              geofenceType: 2,
            };
          });
          isCircleDrawnRef.current = true; // Mark that a circle has been drawn
        }
      };

      const onDrawDeleted = () => {
        // Reset the flag when the circle is deleted
        isCircleDrawnRef.current = false;
        props.setGeoFence((prev: any) => {
          return {
            ...prev,
            latitude: null,
            longitude: null,
            radius: null,
            coordinates: null,
            geofenceType: null,
          };
        });
      };

      map.on(L.Draw.Event.CREATED, onDrawCreated);
      map.on(L.Draw.Event.DELETED, onDrawDeleted);
      // Check if initial circle properties are available in props
      if (
        props.mode === "view" && props?.geoFence && props.geoFence.geofenceType === 1 &&
        !isCircleDrawnRef.current &&
        !isNaN(props.geoFence.latitude) &&
        !isNaN(props.geoFence.longitude) &&
        !isNaN(props.geoFence.radius)
      ) {
        // If initial circle properties are available, draw the default circle using these values
        const defaultCircle = L.circle(
          [props.geoFence.latitude, props.geoFence.longitude],
          props.geoFence.radius,
          {
            color: "#007bff",
          }
        );
        map.addLayer(defaultCircle);
        drawnItems.addLayer(defaultCircle);

        // Set the circle drawn flag to true
        isCircleDrawnRef.current = true;
      }

      // Check if initial circle properties are available in props
      if (
        props.mode === "view" && props?.geoFence && props.geoFence.geofenceType === 2 &&
        !isCircleDrawnRef.current &&
        props.geoFence.latitude === null &&
        props.geoFence.longitude === null &&
        props?.geoFence.coordinates && props?.geoFence.coordinates.length > 0
      ) {
        // If initial polygon properties are available, draw the default polygon using these values
        const coordinates = props?.geoFence.coordinates.map(({ longitude, latitude }: $TSFixMe) => [parseFloat(latitude), parseFloat(longitude)]);

        const defaultPolygon = L.polygon(coordinates, {
          color: "#007bff",
        });
        const bounds = L.latLngBounds(coordinates);
        map.fitBounds(bounds);
        map.addLayer(defaultPolygon);
        drawnItems.addLayer(defaultPolygon);

        // Set the circle drawn flag to true
        isCircleDrawnRef.current = true;
      }
    }
  }, [props]);

  return (
    <div ref={mapRef} style={{ height: "500px" }}></div>
  );
}

export default Loc;
