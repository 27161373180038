import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const Filter = ({
  filter,
  handleFilter,
  cardColorType
}: $TSFixMe) => (
  <div className="filterForm">
    <FormGroup>
      <Label className="outtab">
        Search
        <Input
          type="text"
          value={filter}
          className={`mt-1 ${cardColorType}`}
          onChange={(e) => handleFilter(e.target.value || "")}
        />
      </Label>
    </FormGroup>
  </div>
);

export default Filter;
