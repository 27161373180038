import { Card, Col, Row } from "reactstrap";
//@ts-expect-error
import Fade from "react-reveal/Fade";
import { useContext } from "react";
import { LayoutContext } from "../../../../context/LayoutContext";

function DashboardButton(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  return (
    <>
      <Fade right duration={1500}>
        <div className="row mx-0">
          {props.deviceInfoCard.map((item: $TSFixMe, index: number) => (
            <div key={index} className={`col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 px-0 py-2 ${item.itemClass}`}>
              <div className={`dashbtn btn btn-outline-success btn-lg btn-block ${selectedTheme}`}>
                <div className={`dashboardIconWrapper icon-${index}`}>
                  {item.iconAssets}
                </div>
                <div className="dashBtnCont">
                  <h3 className="num">{typeof item.infoNum === 'number' && Number.isInteger(item.infoNum) ? item.infoNum : typeof item.infoNum === 'number' ? item.infoNum.toFixed(2) : "N/A"}</h3>
                  <p className="tex">{item.infoName}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fade>
    </>
  );
}

export default DashboardButton;
