import React, { useContext, useState } from "react";
import { Card, CardBody, Row, Col, Container, Button } from "reactstrap";
import DeviceManagementTab from "./tab";
import { connect } from "react-redux";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import AddRole from "./tab/Components/deviceAddRole";
import { THEME_TYPE, deviceManagmentDownloadColumns } from "../../../../constants";
import { CSVLink } from "react-csv";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../../context/LayoutContext";
import usePermission from "../../../../constants/usePermission";
import { useLocation } from "react-router-dom";
import { convertTimestampToDate } from "../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps>;

function DeviceManagement(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const isDeviceWrite = usePermission("DEVICE_MANAGEMENT_WRITE");
  const location = useLocation();
  const queryParams: $TSFixMe = new URLSearchParams(location.search);
  const [deviceId, setDeviceId]: $TSFixMe = useState(queryParams.get("id"));

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      createdOn: convertTimestampToDate(props.user.timeZone.zoneId, data.createdOn, null, props.user.timeFormat)
    }));
  };

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Device Management</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.devices &&
                    props.devices.result &&
                    props.devices.result.length ? (
                    <CSVLink
                      filename={"device-management-file.csv"}
                      data={preprocessCsvData(props?.devices?.result)}
                      headers={deviceManagmentDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {/* {isDeviceWrite && <AddRole />} */}

                </Col>
              </Row>
              <CardBody className="tabAll overflow-table">
                <DeviceManagementTab isDeviceWrite={isDeviceWrite} deviceId={deviceId} setDeviceId={setDeviceId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

//export default DeviceManagement;
const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  devices: state.devicesData.devices,
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(DeviceManagement);
