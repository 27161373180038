import React, { useState, useEffect } from "react";
import { Map, Marker, Polyline, Popup, TileLayer, LayersControl } from "react-leaflet";
import { Slider, Tooltip } from "antd";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { SliderMarks } from "antd/lib/slider";
import { calculateMapBounds, convertKilometerToMile, convertTimestampToDate, getCustomIcon, getEventIcon } from "../../../services/common.functions.services";
import { AFTER_PLAY_MAP_ZOOM_LEVEL, HIGH_EVENTS_ICONS, LOW_EVENTS_ICONS, MAP_DEFAULT_LAT_LONG, MAP_SCROLLWHEELZOOM, MAP_SPEED_TYPE, MAP_ZOOM, measurement } from "../../../constants";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { getLocation, getTripEventInfo } from "../../../api/axios.resources";
import { TripEventPopup } from "./TripEventPopup";
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SkipNext from '@mui/icons-material/SkipNext';
import VideoCallIcon from "@material-ui/icons/VideoCall";
import Edit from '@mui/icons-material/EditRoad';
import Close from '@mui/icons-material/Close';
import L from 'leaflet';
import VideoRequest from "./videoRequest";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { RootState } from "../../../redux/reducers/rootReducer";

const { BaseLayer } = LayersControl;

function VehicleJourneyMap(props: $TSFixMe) {
  const [markersData, setMarkersData]: $TSFixMe = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mapCenter, setMapCenter]: $TSFixMe = useState(MAP_DEFAULT_LAT_LONG);
  const [mapBounds, setMapBounds] = useState(null);
  const [animationSpeed, setAnimationSpeed] = useState(MAP_SPEED_TYPE["3x"]);
  const [mapZoom, setMapZoom] = useState(MAP_ZOOM);
  const [tripEventInfo, setTripEventInfo]: $TSFixMe = useState({});
  const [isTripEventLoading, setIsTripEventLoading]: $TSFixMe = useState(false);
  const [location, setLocation] = useState("");
  const [mapLayer, setMapLayer] = useState("Base Map");
  const [isMapEdit, setIsMapEdit] = useState(false);
  const [startLatLng, setStartLatLng] = useState(null);
  const [endLatLng, setEndLatLng] = useState(null);
  const [isVideoRequest, setIsVideoRequest] = useState(false);
  const [startPointData, setStartPointData] = useState({});
  const [endPointData, setEndPointData] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  let animationInterval: NodeJS.Timeout | null = null;


  const marks: SliderMarks = {
    0: '0.5x',
    25: '1x',
    50: '2x',
    75: '3x',
    100: '4x',
  };

  const speedToZoomMapping: $TSFixMe = {
    '0.5x': 18,
    '1x': 18,
    '2x': 18,
    '3x': 17,
    '4x': 16,
  };

  const tipFormatter = (value: $TSFixMe) => {
    return `Speed: ${marks[value]}`;
  };

  const handleSliderChange = (value: number) => {
    const speed: $TSFixMe = marks[value];
    setAnimationSpeed(MAP_SPEED_TYPE[speed]);
    setMapZoom(speedToZoomMapping[speed]);
  };

  const handleGetMapData = () => {
    if (props.markerData && props.markerData.length > 0) {
      const startMarker = {
        position: [props.markerData[0].latitude, props.markerData[0].longitude],
        color: "green",
        ...props.markerData[0],
      };

      const endMarker = {
        position: [props.markerData[props.markerData.length - 1].latitude, props.markerData[props.markerData.length - 1].longitude],
        color: "red",
        ...props.markerData[props.markerData.length - 1],
      };

      const marker = props.markerData.map((m: $TSFixMe) => {
        return {
          position: [m.latitude, m.longitude],
          ...m,
        };
      });

      setMarkersData([startMarker, ...marker, endMarker]);
    } else {
      setMarkersData([]);
    }
  };

  //Handle Play Trip
  const handlePlayButtonClick = () => {
    if (isPlaying) {
      return;
    }
    setIsPlaying(true);
    setMapZoom(AFTER_PLAY_MAP_ZOOM_LEVEL);
    setMapLayer("Satellite");
    if (currentIndex < markersData.length - 1) {
      setCurrentIndex(currentIndex);
    } else {
      setCurrentIndex(0);
    }
  };

  //Handle Stop Trip
  const handleStopButtonClick = () => {
    setIsPlaying(false);
    if (animationInterval) {
      clearInterval(animationInterval);
    }
  };

  const handleMapZoomChange = (event: $TSFixMe) => {
    const newZoomLevel = event.target.getZoom(); // Get the new zoom level
    setMapZoom(newZoomLevel); // Update the mapZoom state
  };

  //Handle Get Trip Details
  const handleTripEvent = async (type: string, info: $TSFixMe) => {
    setIsTripEventLoading(true);
    try {
      const data = {
        deviceId: info?.deviceId,
        id: info._id,
        projectId: props.projectId
      }
      const [res, response] = await Promise.all([getTripEventInfo(data, type), getLocation(info.latitude, info.longitude)]);
      if (res.status === 200) {
        setTripEventInfo(res.result);
        setLocation(response.data.display_name);
        setIsTripEventLoading(false);
      }
    } catch (error) {
      setIsTripEventLoading(false);
      console.log(error);
    }
  }

  //Handle Edit Trip PolyLine in map.
  const handlePolylineClick = (e: $TSFixMe) => {
    if (isMapEdit && !isPlaying) {
      const latlng = e.latlng;

      let closestMarker: $TSFixMe = null;
      let closestDistance = Number.MAX_VALUE;

      markersData.forEach((marker: $TSFixMe) => {
        const distance = latlng.distanceTo(marker.position);
        if (distance < closestDistance) {
          closestMarker = marker;
          closestDistance = distance;
        }
      });

      if (closestMarker) {
        if (!startLatLng) {
          setStartLatLng(closestMarker.position);
          setStartPointData(closestMarker);
        } else if (!endLatLng) {
          setEndLatLng(closestMarker.position);
          setEndPointData(closestMarker);
        } else {
          setStartPointData(closestMarker);
          setStartLatLng(closestMarker.position);
          setEndLatLng(null);
          setEndPointData({});
        }
      }
    }
  };

  //Handle Draw Trip PolyLine in map.
  const getActualPolyline = () => {
    return (
      <Polyline
        positions={markersData.map((marker: $TSFixMe) => marker.position)}
        color={isMapEdit ? "#007ae1" : "#ff3e3e"}
        weight={4}
        onclick={handlePolylineClick}
      />
    );
  };

  //Handle draw selected trip polyLine in map.
  const getSelectedPolyline = () => {
    if (startLatLng && endLatLng && isMapEdit) {
      const startLat = startLatLng[0];
      const startLng = startLatLng[1];
      const endLat = endLatLng[0];
      const endLng = endLatLng[1];

      // Determine the start and end points along the polyline
      const startIndex = markersData.findIndex((marker: $TSFixMe) => marker.latitude === startLat && marker.longitude === startLng);
      const endIndex = markersData.findIndex((marker: $TSFixMe) => marker.latitude === endLat && marker.longitude === endLng);

      if (startIndex === -1 || endIndex === -1) {
        console.error('Start or end point not found in markersData');
        return null;
      }
      // Ensure startIndex < endIndex
      const [smallerIndex, largerIndex] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
      const data = markersData.slice(smallerIndex, largerIndex + 1);
      setSelectedData(data);
    }
  };

  //useEffect for Play animation trip.
  useEffect(() => {
    if (isPlaying && currentIndex < markersData.length - 1) {
      animationInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, animationSpeed);
    } else if (currentIndex === markersData.length - 1) {
      setIsPlaying(false);
    }

    return () => {
      if (animationInterval) {
        clearInterval(animationInterval);
        animationInterval = null;
      }
    };
  }, [isPlaying, markersData, currentIndex, animationSpeed]);

  //useEffect for setup selected trip line bounds and path coordinates.
  useEffect(() => {
    if (markersData.length > 0) {
      const pathCoordinates = markersData.map((marker: $TSFixMe) => marker.position);
      const bounds: $TSFixMe = calculateMapBounds(markersData);
      const newCenter = pathCoordinates[currentIndex];
      setMapCenter(newCenter);
      if (selectedData.length > 0) {
        // Use a zoom level that keeps the entire selectedData visible
        const selectedBounds: $TSFixMe = calculateMapBounds(selectedData);
        setMapBounds(selectedBounds);
      } else {
        // Use the zoom level for after the trip is played
        setMapBounds(bounds);
      }
    }
  }, [markersData, currentIndex, selectedData]);

  useEffect(() => {
    if (props.markerData) {
      handleGetMapData();
    }
    return () => { };
  }, [props.markerData]);

  //useEffect for get selected polyline.
  useEffect(() => {
    getSelectedPolyline();
    if (!isMapEdit) {
      setStartLatLng(null);
      setEndLatLng(null);
      setIsVideoRequest(false);
      setStartPointData({});
      setEndPointData({});
      setSelectedData([]);
    }
  }, [startLatLng, endLatLng, isMapEdit]);

  return (
    <div className="position-relative">
      {markersData && markersData.length > 0 && (
        <div className="video-request-tool">
          <Tooltip overlayClassName="custom-font-tooltip" placement="right" title={isMapEdit ? "Close" : "Edit Trip for Video Request"} >
            <Button className="play-btn" disabled={isPlaying}
              onClick={() => {
                setMapZoom(AFTER_PLAY_MAP_ZOOM_LEVEL);
                setCurrentIndex(currentIndex < markersData.length - 1 ? Number(currentIndex) !== 0 ? currentIndex + 1 : 2 : currentIndex);
                setIsMapEdit(!isMapEdit);
              }}>
              {isMapEdit ? <Close fontSize="small" /> : <Edit fontSize="small" />}
            </Button>
          </Tooltip>
          {isMapEdit && startLatLng && endLatLng &&
            <Tooltip overlayClassName="custom-font-tooltip" placement="right" title={"Video Request"} >
              <Button disabled={isPlaying} className="play-btn mt-2"
                onClick={() => setIsVideoRequest(true)}>
                <VideoCallIcon fontSize="small" />
              </Button>
            </Tooltip>
          }
          {isVideoRequest &&
            <VideoRequest projectId={props.projectId} endPointData={endPointData} startPointData={startPointData} isVideoRequest={isVideoRequest} setIsVideoRequest={setIsVideoRequest} />
          }
        </div>
      )}
      <Map
        className="mapLoc"
        center={mapCenter || MAP_DEFAULT_LAT_LONG}
        zoom={mapZoom}
        scrollWheelZoom={true}
        bounds={mapBounds || undefined}
        onZoom={handleMapZoomChange}
        preferCanvas={true}
      >
        <LayersControl position="topright">


          <BaseLayer checked={mapLayer === "Base Map"} name="Base Map" onClick={() => setMapLayer("Base Map")}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer checked={mapLayer === "Humanitarian"} name="Humanitarian" onClick={() => setMapLayer("Humanitarian")}>
            <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer checked={mapLayer === "Satellite"} name="Satellite" onClick={() => setMapLayer("Satellite")}>
            <TileLayer
              url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            />
          </BaseLayer>
          {markersData && markersData.length > 0 && (
            <>
              {getActualPolyline()}
              {selectedData.length > 0 && endLatLng && (
                <Polyline positions={selectedData.map((marker: $TSFixMe) => marker.position)} color="#00bb42" weight={4} />
              )}
              {/* 
              //TODO: As of now hide dots marker
              {isMapEdit && markersData.map((marker: $TSFixMe, index: $TSFixMe) => (
                <Marker key={index} position={marker.position} icon={L.divIcon({
                  className: 'selected-marker-edit',
                  iconSize: [6, 6], // [width, height]
                  iconAnchor: [3, 6], // [halfWidth, height]
                  popupAnchor: [0, -6] // [offsetX, offsetY]
                })}
                  onClick={(e: $TSFixMe) => handlePolylineClick(e)}
                />
              ))} 
              */}
              {startLatLng && isMapEdit && (
                <Marker position={startLatLng} icon={
                  L.divIcon({
                    className: 'selected-marker-start',
                  })}
                />
              )}
              {endLatLng && isMapEdit && (
                <Marker position={endLatLng} icon={L.divIcon({
                  className: 'selected-marker-end',
                })} />
              )}
              <ReactLeafletDriftMarker duration={animationSpeed} position={markersData[currentIndex].position} icon={getCustomIcon(markersData[currentIndex]?.heading)}>
                {/* <Popup>
                  <div>
                    <b>Device Id:</b> {markersData[currentIndex]?.deviceId}
                  </div>
                  <div>
                    <b>Date & Time:</b>{" "}
                    {markersData[currentIndex]?.timestamp
                      ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[currentIndex]?.timestamp, null, props.user.timeFormat)
                      : "-"}
                  </div>
                </Popup> */}
              </ReactLeafletDriftMarker>
            </>
          )}
          {/* Show markers with Event Trip */}
          {markersData && markersData.length > 0 && props.highEventTrip && props.highEventTrip.length > 0 && props.highEventTrip.map((marker: $TSFixMe, index: number) => (
            <>
              {marker.latitude && marker.longitude && (
                <Marker
                  key={index}
                  position={[marker.latitude, marker.longitude] ?? []}
                  icon={getEventIcon(marker.heading, HIGH_EVENTS_ICONS[`${marker.alertCode}`])}
                  onclick={() => handleTripEvent(marker.popupType, marker)}
                >
                  <Popup className="trip-event-popup">
                    <TripEventPopup tripEventInfo={tripEventInfo} actionType={marker.popupType} location={location} user={props.user} isTripEventLoading={isTripEventLoading} />
                  </Popup>
                </Marker>
              )}
            </>
          ))}
          {markersData && markersData.length > 0 && props.lowEventTrip && props.lowEventTrip.length > 0 && props.lowEventTrip.map((marker: $TSFixMe, index: number) => (
            <>
              {
                marker.latitude && marker.longitude && (
                  <Marker
                    key={index}
                    position={[marker.latitude, marker.longitude] ?? []}
                    icon={getEventIcon(marker.heading, LOW_EVENTS_ICONS[`${marker.alertCode}`])}
                    onclick={() => handleTripEvent(marker.popupType, marker)}
                  >
                    <Popup className="trip-event-popup">
                      <TripEventPopup tripEventInfo={tripEventInfo} actionType={marker.popupType} location={location} user={props.user} isTripEventLoading={isTripEventLoading} />
                    </Popup>
                  </Marker>
                )}
            </>
          ))}
          {/* End... */}

          {/* Show start and end markers with car icons */}
          {markersData && markersData.length > 0 && (
            <>
              <Marker position={markersData[0].position ?? []} icon={getCustomIcon(markersData[0]?.heading, "green", "map")}>
                {/* <Popup>
                  <div>
                    <b>Device Id:</b> {markersData[0]?.deviceId}
                  </div>
                  <div>
                    <b>Date & Time:</b>{" "}
                    {markersData[0]?.timestamp
                      ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[0]?.timestamp, null, props.user.timeFormat)
                      : "-"}
                  </div>
                </Popup> */}
              </Marker>

              <Marker position={markersData[markersData.length - 1].position} icon={getCustomIcon(markersData[markersData.length - 1]?.heading, "red", "map")}>
                {/* <Popup>
                  <div>
                    <b>Device Id:</b> {markersData[markersData.length - 1]?.deviceId}
                  </div>
                  <div>
                    <b>Date & Time:</b>{" "}
                    {markersData[markersData.length - 1]?.timestamp
                      ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[markersData.length - 1]?.timestamp, null, props.user.timeFormat)
                      : "-"}
                  </div>
                </Popup> */}
              </Marker>
            </>
          )}
        </LayersControl>
      </Map>
      {markersData && markersData.length > 0 &&
        <div className="map-play-box">
          <div className="vehicle-info">
            <p>Device Id: {markersData[currentIndex]?.deviceId}</p>
            <p>Speed: {`${props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(markersData[currentIndex]?.speed) : markersData[currentIndex]?.speed}  ${props.user.measurmentUnit === measurement[1].value ? 'mph' : 'km/h'}`}</p>
            <p>Latitude: {markersData[currentIndex]?.latitude}</p>
            <p>Longitude: {markersData[currentIndex]?.longitude}</p>
            <p>Date & Time: {markersData[currentIndex]?.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, markersData[currentIndex]?.timestamp, null, props.user.timeFormat) : "-"}</p>
          </div>
          <div className="mt-1">
            <div className="d-flex align-items-center justify-content-around">
              <Tooltip placement="top" title={"Previous"}>
                <button className="play-btn"
                  onClick={() => setCurrentIndex((prevIndex) => {
                    const temp = prevIndex - 5;
                    return temp >= 0 ? temp : 0;
                  })}>
                  <SkipPrevious />
                </button>
              </Tooltip>
              <Tooltip placement="top" title={!isPlaying ? "Play" : "Stop"}>
                <button className="play-btn" onClick={() => { !isPlaying ? handlePlayButtonClick() : handleStopButtonClick() }}>{!isPlaying ? <PlayCircleIcon /> : <StopCircleIcon />}</button>
              </Tooltip>
              <Tooltip placement="top" title={"Next"}>
                <button className="play-btn"
                  onClick={() => setCurrentIndex((prevIndex) => {
                    const temp = prevIndex + 5;
                    return temp < markersData.length - 1 ? temp : markersData.length - 1;
                  })}>
                  <SkipNext />
                </button>
              </Tooltip>
              <Tooltip placement="top" title={"Restart"}>
                <button className="play-btn"
                  onClick={() => setCurrentIndex(0)}>
                  <RestartAltIcon />
                </button>
              </Tooltip>
            </div>
            <div className="w-100 mt-1">
              <span className="text-white f-10">Speed</span>
              <Slider
                marks={marks}
                step={null}
                tipFormatter={tipFormatter}
                defaultValue={75}
                onChange={handleSliderChange}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(VehicleJourneyMap);