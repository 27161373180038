import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./app/redux/store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import LayoutContextProvider from "./app/context/LayoutContext";

ReactDOM.render(
    <Provider store={store}>
      <LayoutContextProvider>
        <App />
      </LayoutContextProvider>
    </Provider>,
  document.getElementById("root")
);
