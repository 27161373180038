import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { Device } from "../../../../../../../../global.types";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { getEditCommonConfigurationImei } from "../../../../../../../redux/reducers/Management&Settings/commonConfiguration/commonConfiguration.actions";
import { connect } from "react-redux";
import { ROUTES } from "../../../../../../../routes/routes";
import { toast } from "react-toastify";
import { getDeviceLog, healthCheck } from "../../../../../../../api/axios.resources";
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: Device;
  };
function Settings(props: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getCommonConfiguration = async (id: number) => {
    try {
      props.startLoading();
      let res: $TSFixMe = await props.getEditCommonConfigurationImei(id);
      if (res) {
        history.push(
          ROUTES.EDIT_DEVICE_CONFIG + "?mode=edit&id=" + id + "&imei=" + true
        );
      }
    } catch (error) {
      console.log("error in CommonTemplateTab", error);
    } finally {
      await props.endLoading();
    }
  };

  const handleConfigurationClick = () => {
    toggle();
    getCommonConfiguration(props?.data?.deviceId);
  };

  const handleHealthCheck = async () => {
    try {
      const res = await healthCheck(props?.data?.deviceId, {});
      if (res.status === 200) {
        toast.success(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetLog = async () => {
    try {
      const res = await getDeviceLog(props?.data?.deviceId, {});
      if (res.status === 200) {
        toast.success(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        className="dropdown-toggle-settings"
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <SettingsOutlinedIcon className="dropico" />
        <ExpandMoreOutlinedIcon className="dropico" />
      </DropdownToggle>
      <DropdownMenu className="settingdrop">
        <div className="settingdropmenu" onClick={() => {
          toggle();
          handleHealthCheck();
        }}>
          Health-Check
        </div>
        {props.userConfig.vendorAdmin &&
          <div onClick={handleConfigurationClick} className="settingdropmenu">
            Configuration
          </div>
        }
      </DropdownMenu>
    </Dropdown>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.generalSlice.isLoading,
    commonConfiguration:
      state.commonConfigurationsData.commonConfigurationTemplate,
    userConfig: state.userSlice.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getEditCommonConfigurationImei: (id: number) =>
    dispatch(getEditCommonConfigurationImei(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
