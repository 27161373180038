import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { VehicleTrip } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { convertKilometerToMile, secondToTimeFormat } from "../../../../../services/common.functions.services";
import { measurement } from "../../../../../constants";

interface VehicleReportTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & VehicleReportTableProps;

const VehicleReportTab = (props: Props) => {
  const [vehicleTrips, setVehicleTrips] = useState<VehicleTrip[]>([]);

  const vehicleTripReportColumns = [
    { title: "Vehicle Reg#", data: "vehicleregistrationno" },
    { title: " Organization", data: "organizationname" },
    { title: "Project", data: "projectname" },
    { title: "Total Trip Count", data: "totaltrip" },
    {
      title: `Total Distance Covered(${props.user.measurmentUnit === measurement[1].value ? "ml" : "km"}.)`,
      format: (data: $TSFixMe) => props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(data.totaldistance) : data.totaldistance.toFixed(2),
    },
    { title: "High Event Count", data: "totalhighevent" },
    { title: "Low Event Count", data: "totallowevent" },
    {
      title: "Total Idle Time",
      format: (data: $TSFixMe) => data?.totalidealtime ? secondToTimeFormat(data?.totalidealtime) : 0,
    },
  ];

  useEffect(() => {
    setVehicleTrips(props.vehicleTrips.result ? props.vehicleTrips.result.data : []);
    return () => { };
  }, [props.vehicleTrips]);

  return (
    <>
      <DataTable
        data={vehicleTrips}
        columns={vehicleTripReportColumns}
        bordered
        pageData={props.vehicleTrips?.result?.page}
        totalRecrods={props.vehicleTrips?.result?.totalRecord}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

//export default VehicleReportTab;
const mapStateToProps = (state: RootState) => ({
  vehicleTrips: state.dataStore.vehicleTrips,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleReportTab);
