import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { dataTop10 } from "../../../../constants";
import { LayoutContext } from "../../../../context/LayoutContext";

function Top10Drivers(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const { options, series }: any = dataTop10(selectedTheme, props.topDriver);
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={350}
    />
  );
}

export default Top10Drivers;
