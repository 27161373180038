import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Device } from "../../../../../../global.types";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import {
  getDevices,
  getDevicesForProject,
} from "../../../../../redux/reducers/Management&Settings/deviceManagement/deviceManagement.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable";
import DeviceDelete from "./Components/deviceDelete";
import DeviceEdit from "./Components/deviceEdit";
import DeviceView from "./Components/deviceView";
import DeviceSettings from "./Components/settings";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";
import usePermission from "../../../../../constants/usePermission";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { useHistory } from "react-router-dom";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isDeviceWrite?: boolean;
    deviceId?: number;
    setDeviceId: React.Dispatch<React.SetStateAction<any>>;
  };

const DeviceManagementTab = (props: Props) => {
  const [devicesList, setDevicesList] = useState<Device[]>([]);
  const { selectedProject }: $TSFixMe = useContext(LayoutContext);
  const history = useHistory();
  const { isDeviceWrite, deviceId, setDeviceId } = props;
  const DeviceActionBtns = ({ data }: { data: Device }) => {
    return (
      <div className="imptabbody">
        {data ? (
          <>
            {isDeviceWrite && <DeviceSettings data={data} />}
            <DeviceView data={data} />
            {isDeviceWrite && <DeviceEdit data={data} />}
            {isDeviceWrite && <DeviceDelete data={data} />}
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  };

  const columns = [
    { title: "No:", data: "id" ?? [] },
    {
      title: "Action",
      format: (data: Device) =>
        data ? (
          <>
            {" "}
            <DeviceActionBtns data={data} />
          </>
        ) : null,
    },
    { title: "Project", data: "project.name" ?? "N/A" },
    {
      title: "Vehicle Reg#",
      data: "vehicleRegestrationNo", sorting: false,
      format: (data: Device) => (
        <span>{data?.vehicleRegestrationNo ?? "N/A"}</span>
      ),
    },
    {
      title: "Device ID",
      data: "deviceId", sorting: false,
      format: (data: Device) => <span>{data?.deviceId ?? "N/A"}</span>,
    },
    {
      title: "Date Time",
      format: (data: Device) => (
        <span>
          {data?.createdOn
            ? convertTimestampToDate(
              props.user?.timeZone?.zoneId,
              data?.createdOn,
              null,
              props.user.timeFormat
            )
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Driver Name",
      data: "driverManagement.name" ?? "N/A",
    },
    {
      title: "Status",
      format: (data: Device) => <>{data.status + ""}</>,
    },
  ];

  const apiCalls = async () => {
    if (selectedProject.id && props.user.userId !== 0) {
      try {
        props.startLoading();
        await props.getDevicesForProject(selectedProject.id, { deviceId });
      } catch (error) {
        console.log("error in DeviceManagementTab", error);
      } finally {
        await props.endLoading();
      }
    }
  };

  useEffect(() => {
    setDevicesList([...(props.devices.result ?? [])]);
    return () => { };
  }, [props.devices]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCalls();
      setDeviceId(null);
    }
    return () => { };
  }, [props.user.userId, selectedProject.id]);

  useEffect(() => {
    if (!deviceId) {
      history.push("/device-management");
    }
    return () => { }
  }, [deviceId]);

  return (
    <>
      <>
        <DataTable
          data={devicesList}
          columns={columns}
          bordered
          hover={true}
          responsive={true}
        />
      </>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  devices: state.devicesData.devices,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDevices: () => dispatch(getDevices()),
  getDevicesForProject: (id: number, params: $TSFixMe) =>
    dispatch(getDevicesForProject(id, params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceManagementTab);
