import { useContext, useEffect, useRef, useState } from "react";
import user2 from "../../../assets/png/user2.png";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { ROUTES } from "../../../routes/routes";
import { LayoutContext } from "../../../context/LayoutContext";
import Cookies from "js-cookie";
import { logoutUser } from "../../../redux/reducers/user/user.actions";
import { AppDispatch } from "../../../redux/store/store";
import { connect } from "react-redux";
import { RootState } from "../../../redux/reducers/rootReducer";
import ChangePassword from "../../Sidebar&Navbar/ChangePassword";
import LockSharp from '@material-ui/icons/LockSharp';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const User = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const userDropdownRef: $TSFixMe = useRef(null);

  const handleLogout = async () => {
    setOpen(false);
    try {
      const token = Cookies.get("road-devil-vendor-token");
      const res: $TSFixMe = await props.logout({ jwttoken: token });
      if (res.status === 200) {
        Cookies.remove("road-devil-vendor-token");
        Cookies.remove("road-devil-vendor-refreshtoken");
        localStorage.removeItem("user-config-vendor");
        localStorage.removeItem("selectedProjectData");
        window.location.href = "/"; // Redirect to login page
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleOutsideClick = (e: $TSFixMe) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="usermainDiv"
      // onMouseEnter={() => setOpen(true)}
      // onMouseLeave={() => setOpen(false)}
      ref={userDropdownRef}
    >
      <img className="user1" src={props.user.profilePicture ?? user2} alt="user2" onClick={() => setOpen(!open)} />
      {/* @ts-expect-error TS(2741): Property 'title' is missing in type '{ children: E... Remove this comment to see the full error message */}
      <NavDropdown
        id="collasible-nav-dropdown"
        show={open}
        className="userIcon"
      >
        <button className="userButton">
          <ArrowDropUpIcon className="arrowIcon" fontSize="large" />
        </button>
        <div className="userCard">
          <div className="userprofilediv">
            <img src={props.user.profilePicture} alt="user2" className="userprofileimage" />
            <div className="d-grid ml-2">
              <span className="userprofilename text-capitalize">{props.user.userName}</span>
              <span className="usertypeclass">Super User</span>
            </div>
          </div>
          <>
            <Link to={ROUTES.USER_CONFIGURATION} className="tabLink">
              <button onClick={() => setOpen(false)} className={`${selectedTheme} profileOptionBtn userProfile`}>
                <PersonOutlineOutlinedIcon className="ico sig" />
                <span className="user-text">Edit Profile</span>
              </button>
            </Link>
            <p className="tabLink mb-0">
              <button className={`${selectedTheme} profileOptionBtn userProfile`} onClick={() => {
                toggle();
                setOpen(false);
              }}>
                <LockSharp className="ico sig" />
                <span className="user-text">Change Password</span>
              </button>
              {modal &&
                <ChangePassword toggle={toggle} modal={modal} />
              }
            </p>
            <Link to="#" className="tabLink">
              <button className={`${selectedTheme} profileOptionBtn userProfile`} onClick={handleLogout}>
                <ExitToAppOutlinedIcon className="ico sig" />
                <span className="user-text">Sign Out</span>
              </button>
            </Link>
          </>
        </div>
      </NavDropdown>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.userSlice.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: (data: $TSFixMe) => dispatch(logoutUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);