export const GET_MAP_MONITORING = "GET_MAP_MONITORING";
export const GET_MAP_MONITORING_SUCCESS = "GET_MAP_MONITORING_SUCCESS";
export const GET_MAP_MONITORING_ERROR = "GET_MAP_MONITORING_ERROR";

export const GET_LIVE_TRACKING = "GET_LIVE_TRACKING";
export const GET_LIVE_TRACKING_SUCCESS = "GET_LIVE_TRACKING_SUCCESS";
export const GET_LIVE_TRACKING_ERROR = "GET_LIVE_TRACKING_ERROR";

export const GET_VEHICLE_JOURNEY = "GET_VEHICLE_JOURNEY";
export const GET_VEHICLE_JOURNEY_SUCCESS = "GET_VEHICLE_JOURNEY_SUCCESS";
export const GET_VEHICLE_JOURNEY_ERROR = "GET_VEHICLE_JOURNEY_ERROR";

export const RESET_MAP_DATA = "RESET_MAP_DATA";
