import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AddDeviceType, Device } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  DeviceEditSchema,
  deviceValidationEditSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { EditDeviceCode } from "../../../../../../../redux/reducers/Management&Settings/deviceManagement/deviceManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { getDriverListDD } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: Device;
  };

const Edit = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleUpdateDevice = async (values: DeviceEditSchema) => {
    try {
      props.startLoading();
      await toast.promise(
        props.EditDeviceCode({
          id: props?.data?.id,
          camera1Name: values.camera1Name !== "" ? values.camera1Name : "camera1",
          camera2Name: values.camera2Name !== "" ? values.camera2Name : "camera2",
          camera3Name: values.camera3Name !== "" ? values.camera3Name : "camera3",
          camera4Name: values.camera4Name !== "" ? values.camera4Name : "camera4",
          camera5Name: values.camera5Name !== "" ? values.camera5Name : "camera5",
          description1: values.description1 ?? "",
          description2: values.description2 ?? "",
          deviceId: values.deviceId,
          driverManagementId: values.driverManagementId.id,
          fleetNumber: values.fleetNumber ?? "",
          homeDepot: values.homeDepot ?? "",
          insurerName: values.insurerName ?? "",
          policyNumber: values.policyNumber ?? "",
          projectId: values.projectId.id,
          status: values.status,
          vehicleRegestrationNo: values.vehicleRegestrationNo,
        }) as any,
        {
          success: TOAST_MSG.EDIT_DEVICE_SUCCESS,
        }
      );
      toggle();
    } catch (error) {
      console.log("error in DeviceManagement Edit api", error);
    } finally {
      await props.endLoading();
    }
  };

  const handleDriverListForProject = async (projectId: number) => {
    try {
      props.startLoading();
      await props.getDriverListDD(projectId);
    } catch (error) {
      console.log("handleDriverListForProject error ", error);
    } finally {
      props.endLoading();
    }
  };

  useEffect(() => {
    if (modal && props?.data?.project?.id) {
      handleDriverListForProject(props?.data?.project?.id);
    }
  }, [modal, props?.data?.project?.id]);

  return (
    <>
      <EditOutlinedIcon onClick={toggle} className="dropico" />
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Edit Device</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={deviceValidationEditSchema}
          enableReinitialize={true}
          initialValues={{
            projectId: { id: props?.data?.project?.id, name: props?.data?.project?.name },
            driverManagementId: { id: props?.data?.driverManagement?.id, name: props?.data?.driverManagement?.name },
            vehicleRegestrationNo: props?.data?.vehicleRegestrationNo ?? "",
            deviceId: props?.data?.deviceId ?? "",
            camera1Name: props?.data?.camera1Name ?? "",
            camera2Name: props?.data?.camera2Name ?? "",
            camera3Name: props?.data?.camera3Name ?? "",
            camera4Name: props?.data?.camera4Name ?? "",
            camera5Name: props?.data?.camera5Name ?? "",
            insurerName: props?.data?.insurerName ?? "",
            policyNumber: props?.data?.policyNumber ?? "",
            homeDepot: props?.data?.homeDepot ?? "",
            fleetNumber: props?.data?.fleetNumber ?? "",
            description1: props?.data?.description1 ?? "",
            description2: props?.data?.description2 ?? "",
            status: props?.data?.status,
          }}
          onSubmit={handleUpdateDevice}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            isValid,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <div className="rit mb-2">
                  <div className="deviceclrhead ml-2">Project Detail</div>
                </div>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup>
                      <div className="modalheader modinpulabel">
                        Select Project<span className="required"> *</span>
                      </div>
                      <Select
                        className={`
                           ${touched.projectId && errors.projectId
                            ? "requireSelect"
                            : ""
                          }`}
                        placeholder={"Select Project"}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        options={props.dropdowns.projectListDD?.result ?? []}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id.toString()}
                        value={values?.projectId}
                        onChange={(option) => {
                          setFieldValue("projectId", option);
                          handleDriverListForProject(option?.id ?? 0);
                          setFieldValue("driverManagementId", {});
                        }}
                        maxMenuHeight={180}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onBlur={handleBlur("projectId")}
                      />
                      {touched.projectId && errors.projectId ? (
                        <div className="validate">Required</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <div className="modalheader modinpulabel">
                        Select Driver<span className="required"> *</span>
                      </div>
                      <Select
                        className={`${touched.driverManagementId &&
                          errors.driverManagementId
                          ? "requireSelect"
                          : ""
                          }
                        `}
                        placeholder="Select Driver"
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        options={props.dropdowns.driverListDD?.result ?? []}
                        value={
                          Object.keys(values.driverManagementId).length !== 0
                            ? props.dropdowns.driverListDD?.result?.find(
                              (option: any) => option.id === values.driverManagementId.id
                            )
                            : null
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id.toString()}
                        // @ts-expect-error
                        onChange={(option: Driver) => {
                          setFieldValue("driverManagementId", option);
                        }}
                        maxMenuHeight={180}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onBlur={handleBlur("driverManagementId")}
                      />
                      {touched.driverManagementId &&
                        errors.driverManagementId ? (
                        <div className="validate">Required</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="rit mb-2">
                  <div className="deviceclrhead ml-2">Device Detail</div>
                </div>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Vehicle Reg.#<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (errors.vehicleRegestrationNo &&
                            touched.vehicleRegestrationNo
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        type="text"
                        placeholder="Vehicle Regestration No"
                        value={values?.vehicleRegestrationNo}
                        onChange={handleChange("vehicleRegestrationNo")}
                        onBlur={handleBlur("vehicleRegestrationNo")}
                      />
                      <ErrorMessage
                        name="vehicleRegestrationNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Device ID.<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (errors.deviceId && touched.deviceId
                            ? " is-invalid "
                            : "") + (values?.deviceId && " disableField")
                        }
                        placeholder="Device Id"
                        autocomplete="disable"
                        type="text"
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        name="deviceId"
                        disabled={values?.deviceId}
                        value={values?.deviceId}
                        onChange={handleChange("deviceId")}
                        onBlur={handleBlur("deviceId")}
                      />
                      <ErrorMessage
                        name="deviceId"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Insurer Name</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        name="select"
                        value={values?.insurerName}
                        placeholder="Insurer Name"
                        onChange={handleChange("insurerName")}
                        onBlur={handleBlur("insurerName")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Policy Number</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        value={values?.policyNumber}
                        placeholder="Policy Number"
                        onChange={handleChange("policyNumber")}
                        onBlur={handleBlur("policyNumber")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Home Depot</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        value={values?.homeDepot}
                        placeholder="Home Depot"
                        onChange={handleChange("homeDepot")}
                        onBlur={handleBlur("homeDepot")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Fleet Number</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        value={values?.fleetNumber}
                        placeholder="Fleet Number"
                        onChange={handleChange("fleetNumber")}
                        onBlur={handleBlur("fleetNumber")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Description1</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        value={values?.description1}
                        placeholder="Description 1"
                        onChange={handleChange("description1")}
                        onBlur={handleBlur("description1")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">Description2</div>
                      <Input
                        autocomplete="disable"
                        type="text"
                        name="select"
                        placeholder="Description 2"
                        value={values?.description2}
                        onChange={handleChange("description2")}
                        onBlur={handleBlur("description2")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <div className="modalheader modinpulabel">Status</div>
                  </Col>
                  <Col md={6}>
                    <RadioGroup
                      onChange={(e) =>
                        setFieldValue(
                          "status",
                          e.target.value === "true"
                        )
                      }
                      className="radioStatus"
                      onBlur={handleBlur("status")}
                      aria-label="status"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        checked={values?.status}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={false}
                        checked={!values?.status}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
                <div className="rit mb-2 mt-1">
                  <div className="deviceclrhead ml-2">Camera Name</div>
                </div>
                <Row className="formRow">
                  <Col md={6} className="dropinput">
                    <FormGroup>
                      <Label className="modalheader modinpulabel">
                        First Camera Channel
                      </Label>
                      <Field
                        // className={errors.camera1Name}
                        className={
                          "form-control" +
                          (errors.camera1Name && touched.camera1Name
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        placeholder="First Camera Channel"
                        autocomplete="disable"
                        name="camera1Name"
                        value={values.camera1Name}
                        onChange={handleChange("camera1Name")}
                        onBlur={handleBlur("camera1Name")}
                      />
                      <ErrorMessage
                        name="camera1Name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} className="dropinput">
                    <FormGroup>
                      <Label className="modalheader modinpulabel">
                        Second Camera Channel
                      </Label>
                      <Field
                        // className={errors.camera2Name}
                        className={
                          "form-control" +
                          (errors.camera2Name && touched.camera2Name
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        autocomplete="disable"
                        placeholder="Second Camera Channel"
                        name="camera2Name"
                        value={values.camera2Name}
                        onChange={handleChange("camera2Name")}
                        onBlur={handleBlur("camera2Name")}
                      />
                      <ErrorMessage
                        name="camera2Name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6} className="dropinput">
                    <FormGroup>
                      <Label className="modalheader modinpulabel">
                        Third Camera Channel
                      </Label>
                      <Field
                        // className={errors.camera3Name}
                        className={
                          "form-control" +
                          (errors.camera3Name && touched.camera3Name
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        autocomplete="disable"
                        placeholder="Third Camera Channel"
                        name="camera3Name"
                        value={values.camera3Name}
                        onChange={handleChange("camera3Name")}
                        onBlur={handleBlur("camera3Name")}
                      />
                      <ErrorMessage
                        name="camera3Name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} className="dropinput">
                    <FormGroup>
                      <Label className="modalheader modinpulabel">
                        Fourth Camera Channel
                      </Label>
                      <Field
                        // className={errors.camera4Name}
                        className={
                          "form-control" +
                          (errors.camera4Name && touched.camera4Name
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        autocomplete="disable"
                        placeholder="Fourth Camera Channel"
                        name="camera4Name"
                        value={values.camera4Name}
                        onChange={handleChange("camera4Name")}
                        onBlur={handleBlur("camera4Name")}
                      />
                      <ErrorMessage
                        name="camera4Name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6} className="dropinput">
                    <FormGroup>
                      <Label className="modalheader modinpulabel">
                        Fifth Camera Channel
                      </Label>
                      <Field
                        // className={errors.camera5Name}
                        className={
                          "form-control" +
                          (errors.camera5Name && touched.camera5Name
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        type="camera5Name"
                        placeholder="Fifth Camera Channel"
                        name="camera5Name"
                        value={values.camera5Name}
                        onChange={handleChange("camera5Name")}
                        onBlur={handleBlur("camera5Name")}
                      />
                      <ErrorMessage
                        name="camera5Name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    disabled={isSubmitting}
                    // @ts-expect-error
                    onClick={handleSubmit}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading &&
                      <Spinner color="dark"
                        size="sm" className="mr-2" />
                    }
                    <>UPDATE</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  EditDeviceCode: (data: AddDeviceType) => dispatch(EditDeviceCode(data)),
  getDriverListDD: (id: number) => dispatch(getDriverListDD(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
