import React from 'react'
import ReactApexChart from "react-apexcharts";
import { measurement, THEME_TYPE } from '../../../../../../constants';
import { convertTimestampToDate } from '../../../../../../services/common.functions.services';
import COLORS from '../../../../../../colors';

const ReferBatteryVoltasGraph = ({ selectedTheme, vehicleTripGpsChart, user }: { selectedTheme: string, vehicleTripGpsChart: $TSFixMe, user: $TSFixMe }) => {
    const data = {
        series: [
            {
                name: `speed(${user.measurmentUnit == measurement[1].value ? 'mph' : 'km/h'})`,
                data: [...vehicleTripGpsChart]
            },
        ],
        options: {
            chart: {
                id: "area-datetime",
                type: "area",
                height: 350,
                foreColor:
                    (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
                        selectedTheme === THEME_TYPE.CLARET_BLUE) &&
                    COLORS.lightBlue_200,
                zoom: {
                    autoScaleYaxis: true,
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        pan: false,
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
                style: "hollow",
            },
            xaxis: {
                type: "category",
                tickAmount: 6,
                labels: {
                    formatter: function (value: $TSFixMe) {
                        if (value) {
                            const date = value && convertTimestampToDate(user?.timeZone?.zoneId, Number(value.toFixed(0)), null, user.timeFormat);
                            return date.split(/(?<=^\S+)\s/);
                        }
                    }
                }
            },
            // tooltip: {
            //     x: {
            //         show: true,
            //         format: "dd MMM yyyy",
            //         formatter: function (value: { series: $TSFixMe }) {
            //             const date = vehicleTripGpsChart.length > 0 && convertTimestampToDate(user?.timeZone?.zoneId, value);
            //             return date;
            //         },
            //     },
            // },
            colors: [COLORS[`${selectedTheme}`]],
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100],
                },
            },
        },
        selection: "one_year",
    };

    return (
        <>
            <div id="chart">
                <div id="chart-timeline">
                    {/* @ts-expect-error TS(2769): No overload matches this call. */}
                    <ReactApexChart options={data.options} series={data.series} type="area" height={350} />
                </div>
            </div>
        </>
    );
};

export default ReferBatteryVoltasGraph;
