import React from 'react'

export const NetworkSignal = ({ signalValue, width, height }: $TSFixMe) => {
    const getColor = (index: $TSFixMe) => {
        if (signalValue > 20) return 'green';
        if (signalValue >= 15 && index <= 2) return 'green';
        if (signalValue >= 10 && index <= 1) return 'green';
        if (signalValue >= 2 && index === 0) return 'green';
        return 'gray';
    };

    if (signalValue < 2 || signalValue > 30) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#8b98a6"
                    fillRule="evenodd"
                    d="M2 12.75a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75H2zm5.5-3a.75.75 0 0 0-.75.75v9c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-9a.75.75 0 0 0-.75-.75h-3zm6-3a.75.75 0 0 0-.75.75v12c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75h-3zm5.5-3a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75h-3z"
                    className="color8B98A6 svgShape"
                    clipRule="evenodd"
                />
                <path
                    fill="#ee4d4d"
                    fillRule="evenodd"
                    d="M7.53 2.47a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0Z"
                    className="colorEE4D4D svgShape"
                    clipRule="evenodd"
                />
                <path
                    fill="#ee4d4d"
                    fillRule="evenodd"
                    d="M2.47 2.47a.75.75 0 0 0 0 1.06l4 4a.75.75 0 0 0 1.06-1.06l-4-4a.75.75 0 0 0-1.06 0Z"
                    className="colorEE4D4D svgShape"
                    clipRule="evenodd"
                />
            </svg>
        );
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path d="M6 12.36a1 1 0 0 0-1-1H2.5a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1H5a1 1 0 0 0 1-1v-6.5zm5.5-3a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v9.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-9.5zm5.5-3a1 1 0 0 0-1-1h-2.5a1 1 0 0 0-1 1v12.5a1 1 0 0 0 1 1H16a1 1 0 0 0 1-1V6.36zm5.5-2.5a1 1 0 0 0-1-1H19a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-15z" fill={getColor(3)} />
            <path d="M6 12.36a1 1 0 0 0-1-1H2.5a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1H5a1 1 0 0 0 1-1v-6.5z" fill={getColor(0)} />
            <path d="M11.5 9.36a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v9.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-9.5z" fill={getColor(1)} />
            <path d="M17 6.36a1 1 0 0 0-1-1h-2.5a1 1 0 0 0-1 1v12.5a1 1 0 0 0 1 1H16a1 1 0 0 0 1-1V6.36z" fill={getColor(2)} />
            <path d="M22.5 3.86a1 1 0 0 0-1-1H19a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-15z" fill={getColor(3)} />
        </svg>
    );
}
