import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AddDriver, Driver } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  driverValidationEditSchema,
  DriverValidationEditType,
} from "../../../../../../../constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import {
  EditDriverCode,
  getDriver,
} from "../../../../../../../redux/reducers/Management&Settings/driverManagement/driverManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { getProjectListForOrg } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: Driver;
  };

const Edit = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleUpdateDriver = async (values: DriverValidationEditType) => {
    try {
      props.startLoading();
      await toast.promise(
        props.EditDriverCode({
          id: props.data.id,
          email: values.email,
          rfId: values?.rfId.toUpperCase(),
          emergencyContact: values.emergencyContact,
          emergencyContactCode: values?.emergencyContactCode ? values?.emergencyContactCode : "",
          mobileNo: values.mobileNo,
          mobileNoCode: values?.mobileNoCode ? values?.mobileNoCode : "",
          name: values.dName,
          countryName: values.country ?? "",
          projectId: values.project.id,
          status: values.status,
        }) as any,
        {
          success: TOAST_MSG.EDIT_DRIVER_SUCCESS,
        }
      );
      toggle();
    } catch (error) {
      console.log("error in DriverEdit api", error);
    } finally {
      await props.endLoading();
    }
  };

  return (
    <>
      <span onClick={toggle} className="tableModalBtn">
        <EditOutlinedIcon className="dropico" />
      </span>
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Edit Driver</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={driverValidationEditSchema}
          enableReinitialize={true}
          initialValues={{
            dName: props.data.name ?? "",
            mobileNo: props.data.mobileNo ?? "",
            email: props.data.email ?? "",
            rfId: props.data.rfId ?? "",
            emergencyContact: props.data.emergencyContact ?? "",
            project: props.data.project,
            status: props.data.status,
            country: props.data.countryName ?? "",
            emergencyContactCode: props?.data?.emergencyContactCode ?? "",
            mobileNoCode: props.data.mobileNoCode ?? "",
          }}
          onSubmit={handleUpdateDriver}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            touched,
            isSubmitting,
            errors,
          }) => {
            return (
              <>
                <ModalBody style={{
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'auto'
                }} className="scr">
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <div className="modalheader mb-1">
                          Select Project<span className="required"> *</span>
                        </div>
                        <Select
                          className={`${touched.project && errors.project
                            ? "requireSelect"
                            : ""
                            }`}
                          placeholder={"Select Project"}
                          options={props.dropdowns.projectListDD.result ?? []}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id.toString()}
                          value={values.project}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          maxMenuHeight={180}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base: $TSFixMe) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          onChange={(option) => setFieldValue("project", option)}
                          onBlur={handleBlur("project")}
                        />
                        {touched.project && errors.project && (
                          <div className="validate">
                            {errors.project ? "Required" : null}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="formRow">
                    <Col md={6}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">
                          Driver Name<span className="required"> *</span>
                        </div>
                        <Field
                          className={
                            "form-control mt-1" +
                            (errors.dName && touched.dName
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          placeholder="Driver Name"
                          type="text"
                          name="dName"
                          value={values.dName}
                          onChange={handleChange("dName")}
                          onBlur={handleBlur("dName")}
                        />
                        <ErrorMessage
                          name="dName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">Country</div>
                        <Input
                          className="mt-1"
                          autocomplete="disable"
                          type="text"
                          placeholder="Country"
                          value={values.country}
                          onChange={handleChange("country")}
                          onBlur={handleBlur("country")}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="modalheader mb-1">
                      Mobile Number<span className="required"> *</span>
                    </div>
                  </Row>
                  <Row className="formRow">
                    <Col md={3}>
                      <FormGroup>
                        <Select
                          className={`${touched.mobileNoCode && errors.mobileNoCode
                            ? "requireSelect"
                            : ""
                            }`}
                          placeholder={"Code"}
                          maxMenuHeight={180}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base: $TSFixMe) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={props.dropdowns.dialCode?.dropDown}
                          value={values?.mobileNoCode ? {
                            label: values?.mobileNoCode,
                            value: values?.mobileNoCode
                          } : null}
                          onChange={(option: $TSFixMe) =>
                            setFieldValue("mobileNoCode", option.value)
                          }
                          onBlur={handleBlur("mobileNoCode")}
                        />
                        {touched.mobileNoCode && errors.mobileNoCode ? (
                          <div className="validate">Required</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={9}>
                      <FormGroup className="dropinput">
                        <Field
                          className={
                            "form-control mt-1" +
                            (errors.mobileNo && touched.mobileNo
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          placeholder="Emergency Contact"
                          onInput={(e: $TSFixMe) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                          }}
                          maxLength={15}
                          name="mobileNo"
                          value={values.mobileNo}
                          onChange={handleChange("mobileNo")}
                          onBlur={handleBlur("mobileNo")}
                        />
                        <ErrorMessage
                          name="mobileNo"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="modalheader mb-1">
                      Emergency Contact 2<span className="required"> *</span>
                    </div>
                  </Row>
                  <Row className="formRow">
                    <Col md={3}>
                      <FormGroup>
                        <Select
                          className={`${touched.emergencyContactCode && errors.emergencyContactCode
                            ? "requireSelect"
                            : ""
                            }`}
                          placeholder={"Code"}
                          maxMenuHeight={180}
                          classNamePrefix={`filter-select-style_${selectedTheme}`}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base: $TSFixMe) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={props.dropdowns.dialCode?.dropDown}
                          value={values?.emergencyContactCode ? {
                            label: values?.emergencyContactCode,
                            value: values?.emergencyContactCode
                          } : null}
                          onChange={(option: $TSFixMe) =>
                            setFieldValue("emergencyContactCode", option.value)
                          }
                          onBlur={handleBlur("emergencyContactCode")}
                        />
                        {touched.emergencyContactCode &&
                          errors.emergencyContactCode ? (
                          <div className="validate">Required</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={9}>
                      <FormGroup className="dropinput">
                        <Field
                          className={
                            "form-control mt-1" +
                            (errors.emergencyContact && touched.emergencyContact
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          onInput={(e: $TSFixMe) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                          }}
                          maxLength={15}
                          placeholder="Emergency Contact"
                          name="emergencyContact"
                          value={values.emergencyContact}
                          onChange={handleChange("emergencyContact")}
                          onBlur={handleBlur("emergencyContact")}
                        />
                        <ErrorMessage
                          name="emergencyContact"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">
                          Email Id<span className="required"> *</span>
                        </div>
                        <Field
                          className={
                            "form-control mt-1" +
                            (errors.email && touched.email
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          type="userEmail"
                          name="email"
                          placeholder="Email Id"
                          value={values.email}
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="dropinput">
                        <div className="modalheader">
                          RF Id<span className="required"> *</span>
                        </div>
                        <Field
                          className={
                            "form-control mt-1" +
                            (errors.rfId && touched.rfId
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          type="text"
                          name="rfId"
                          placeholder="RF Id"
                          value={values.rfId}
                          onChange={handleChange("rfId")}
                          onBlur={handleBlur("rfId")}
                        />
                        <ErrorMessage
                          name="rfId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <div className="modalheader mb-1">Status</div>
                    </Col>
                    <Col md={8}>
                      <RadioGroup
                        onChange={(e) => {
                          setFieldValue("status", e.target.value === "true");
                        }}
                        className="radioStatus"
                        onBlur={handleBlur("status")}
                        aria-label="status"
                        defaultValue={true}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          checked={values.status}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value={false}
                          checked={!values.status}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="footer-width">
                  <div className="footwidth">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      // @ts-expect-error
                      onClick={handleSubmit}
                      className="popbtn datewidth btnBox"
                    >
                      {props.isLoading && (
                        <Spinner color="dark" size="sm" className="mr-2" />
                      )}
                      <>UPDATE</>
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="footwidth">
                    <Button onClick={toggle} className="popbtn datewidth btnBox">
                      <>CANCEL</>
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
  // projects: state.dropdownList.dropdowns.projects_org,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  EditDriverCode: (data: Omit<AddDriver, "defaultDriver"> & { id: number }) =>
    dispatch(EditDriverCode(data)),
  getDriver: () => dispatch(getDriver()),
  getProjectListForOrg: (organizationId: number) => dispatch(getProjectListForOrg(organizationId))

});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
