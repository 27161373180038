import React, { useContext, useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { THEME_TYPE, userManagmentDownloadColumns } from "../../../../constants";
import {
  getDialCode,
} from "../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import UsersManagementTab from "./tab";
import AddUser from "./tab/Components/add/index";
import { LayoutContext } from "../../../../context/LayoutContext";
import usePermission from "../../../../constants/usePermission";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function UsersManagement(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const isUserWrite = usePermission("USERS_MANAGEMENT_WRITE");

  const apiCall = async () => {
    try {
      props.startLoading();
      if (Object.keys(props.dropdowns.dialCode).length === 0 && props.dropdowns.dialCode?.result?.length === 0) {
        props.getDialCodeList();
      }
    } catch (error) {
      console.log("AddUser error", error);
    } finally {
      props.endLoading();
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Users Management </>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.users &&
                    props.users.result &&
                    props.users.result.length ? (
                    <CSVLink
                      filename={"users-management-file.csv"}
                      data={props.users.result}
                      headers={userManagmentDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {isUserWrite && <AddUser />}

                </Col>
              </Row>
              <CardBody className="tabAll">
                <UsersManagementTab />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  dropdowns: state.dropdownList.dropdowns,
  users: state.userData.users,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDialCodeList: () => dispatch(getDialCode()),
});
export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement);
