import React, { useContext } from 'react'
import Switch from "react-switch";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from '../../../../../../../redux/reducers/general/general.actions';
import { AppDispatch } from '../../../../../../../redux/store/store';
import { ChangeGeoFencingStatus, DeleteGeoFencing, getGeoFencing } from '../../../../../../../redux/reducers/Trips&GeoFence/geoFencing/geoFencing.actions';
import ASSETS from '../../../../../../../assets';
import { TOAST_MSG_GEO_FENCING_DELETE, TOAST_MSG_GEO_FENCING_STATUS_CHANGE } from '../../../../../../../constants/toast.constants';
import { LayoutContext } from '../../../../../../../context/LayoutContext';

const DeleteGeoFence = (props: $TSFixMe) => {
  const { selectedProject }: $TSFixMe = useContext(LayoutContext);
  const handleChange = async (nextChecked: $TSFixMe, id: number) => {
    if (id) {
      const data = {
        status: nextChecked
      }
      try {
        props.startLoading();
        await toast.promise(props.changeGeoFencingStatus(id, data) as any, {
          success: TOAST_MSG_GEO_FENCING_STATUS_CHANGE.STATUS_CHANGE_GEO_FENCING_SUCCESS,
        });
        await props.getGeoFencing(selectedProject.id);
      } catch (error) {
        console.log("Status Geo Fence error", error);
      } finally {
        await props.endLoading();
      }
    }
  };


  const deletePost = async () => {
    if (props?.data?.data?.id) {
      if (window.confirm("Are you sure to delete this record?")) {
        try {
          props.startLoading();
          await toast.promise(props.deleteGeoFencing(props?.data?.data?.id) as any, {
            success: TOAST_MSG_GEO_FENCING_DELETE.DELETE_GEO_FENCING_SUCCESS,
          });
        } catch (error) {
          console.log("Delete Geo Fence error", error);
        } finally {
          await props.endLoading();
        }
      }
    }
  }

  return (
    <div className="d-flex align-content-center">
      <span>
        <Switch
          onChange={(nextChecked) => handleChange(nextChecked, props?.data?.data?.id)}
          checked={props?.data?.data?.status}
          width={32}
          height={16}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#007ae1"
          className="react-switch"
        />
      </span>
      <div className="multIcon geo-fencing-actions" onClick={() => deletePost()}>
        <img src={ASSETS.MULTIPLY} className="multiplyIcon" alt="icon" />
      </div>
    </div>
  )
}


const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  deleteGeoFencing: (id: number) => dispatch(DeleteGeoFencing(id)),
  getGeoFencing: (id: number) => dispatch(getGeoFencing(id)),
  changeGeoFencingStatus: (id: number, data: $TSFixMe) => dispatch(ChangeGeoFencingStatus(id, data)),
});

export default connect(null, mapDispatchToProps)(DeleteGeoFence);