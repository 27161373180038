import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { THEME_TYPE } from "../constants";
import { connect } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { getUser } from "../redux/reducers/user/user.actions";
import { AppDispatch } from "../redux/store/store";
import Cookies from "js-cookie";
import {
    getDialCode,
    getProjectListDD,
    getTimeZoneList,
} from "../redux/reducers/dropdowns/dropdown.actions";
import {
    endLoading,
    startLoading,
} from "../redux/reducers/general/general.actions";

export interface LayoutContextModel {
    [key: string]: any;
    // doLogout: () => void;
    setSelectedTheme: (d: string) => void;
    selectedTheme: string;
    setSelectedProject: (d: $TSFixMe) => void;
    selectedProject: string;
    setSelectedSaasEventType: (d: $TSFixMe) => void;
    selectedSaasEventType: number | null;
}

const initialState: LayoutContextModel = {
    // doLogout: () => {},
    selectedTheme: "",
    setSelectedTheme: () => { },
    selectedProject: "",
    setSelectedProject: () => { },
    selectedSaasEventType: 1,
    setSelectedSaasEventType: () => { },
};

export const LayoutContext = createContext(initialState);

function LayoutContextProvider(props: $TSFixMe) {
    const token = Cookies.get("road-devil-vendor-token") || "";
    let userConfig: any = JSON.parse(
        localStorage?.getItem("user-config-vendor") || "null"
    );
    const [selectedTheme, setSelectedTheme]: any = useState(
        userConfig && userConfig.theme
            ? userConfig.theme
            : THEME_TYPE.BLACK_PINK_PURPLE
    );
    const [selectedProject, setSelectedProject]: $TSFixMe = useState(null);
    const saasEventType: $TSFixMe = localStorage.getItem("selectedSaasEventTypeForVd");
    const [selectedSaasEventType, setSelectedSaasEventType]: $TSFixMe = useState(saasEventType ?? 1);
    const projectInfo: $TSFixMe = localStorage.getItem("selectedProjectData");

    const getUserConfig = async () => {
        try {
            if (props.user.userId === 0) {
                const res: $TSFixMe = await props.getUser();
                setSelectedTheme(res.result.theme);
                localStorage.setItem("user-config-vendor", JSON.stringify(res.result));
            }
        } catch (error) {
            console.log("Error occurred while fetching user configuration:", error);
            throw error; // Rethrow the error to be caught later.
        }
    };

    const apiCalls = async () => {
        try {
            props.startLoading();
            await Promise.all([
                props.getProjectListDD(),
                props.getDialCodeList(),
                props.getTimeZoneList(),
            ]);
        } catch (error) {
            console.error(error);
            // Handle the error or re-throw it
        } finally {
            props.endLoading();
        }
    };

    useEffect(() => {
        if (token) {
            getUserConfig();
            apiCalls();
        }
        return () => { };
    }, [token]);

    useEffect(() => {
        if (userConfig && userConfig.theme) {
            setSelectedTheme(userConfig.theme);
        }
    }, [userConfig?.theme]);

    useEffect(() => {
        if (
            props.projectListDD &&
            props.projectListDD?.result &&
            props.projectListDD?.result.length > 0 &&
            projectInfo === null
        ) {
            setSelectedProject(props.projectListDD?.result[0]);
            localStorage.setItem(
                "selectedProjectData",
                JSON.stringify(props.projectListDD?.result[0])
            );
        }
        return () => { };
    }, [props.projectListDD?.result]);

    useEffect(() => {
        if (projectInfo !== null) {
            setSelectedProject(JSON.parse(projectInfo));
        }
        if (saasEventType !== null) {
            setSelectedSaasEventType(JSON.parse(saasEventType));
        }
        return () => { };
    }, []);

    return (
        <LayoutContext.Provider
            value={{
                // doLogout,
                selectedTheme,
                setSelectedTheme,
                token,
                setSelectedProject,
                selectedProject,
                getUserConfig,
                selectedSaasEventType,
                setSelectedSaasEventType,
            }}
        >
            {props.children}
        </LayoutContext.Provider>
    );
}

const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
    projectListDD: state.dropdownList.dropdowns.projectListDD,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startLoading: () => dispatch(startLoading()),
    endLoading: () => dispatch(endLoading()),
    getUser: () => dispatch(getUser()),
    getDialCodeList: () => dispatch(getDialCode()),
    getProjectListDD: () => dispatch(getProjectListDD()),
    getTimeZoneList: () => dispatch(getTimeZoneList()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutContextProvider);
