import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { dataProject } from "../../../../constants";
import { LayoutContext } from "../../../../context/LayoutContext";

function ProjectSplit(props: $TSFixMe) {
  const { selectedTheme } = useContext(LayoutContext);
  const { options, Team } = dataProject(selectedTheme, props.projectSplit);
  return (
    <div id="chart">
      <ReactApexChart
        // @ts-expect-error
        options={options}
        series={Team}
        type="pie"
      />
    </div>
  );
}

export default ProjectSplit;
