import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { createRef, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import { LoginValidationSchema, TEST_SITE_KEY } from "../../../constants";
import { connect } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { getUser, loginUser } from "../../../redux/reducers/user/user.actions";
import Cookies from "js-cookie";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  LayoutContext,
  LayoutContextModel,
} from "../../../context/LayoutContext";
import ReCAPTCHA from "react-google-recaptcha";
import FullPageLoader from "../../Common/FullPageLoader";
import { loginByToken } from "../../../api/axios.resources";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ASSETS from "../../../assets";

type Props = ReturnType<typeof mapDispatchToProps>;

const Login = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { getUserConfig }: LayoutContextModel = useContext(LayoutContext);
  let reCaptchaRef: any = createRef();
  const location: $TSFixMe = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loginToken = queryParams.get("token");
  const token = Cookies.get("road-devil-vendor-token");
  const history = useHistory();
  const handleLoginAsToken = async () => {
    try {
      let req = {
        token: loginToken,
      };
      const res: any = await loginByToken(req);
      if (res.status === 200) {
        Cookies.set("road-devil-vendor-token", res.result.jwttoken);
        Cookies.set("road-devil-vendor-refreshtoken", res.result.refreshtoken);
        getUserConfig();
        setTimeout(() => {
          if (location.state) {
            history.replace(location.state.from.pathname);
          } else {
            // If there's no previous location, redirect to a default route
            history.replace('/dashboard');
          }
        }, 1000);
      }
    } catch (Error) {
      setTimeout(() => {
        history.push("/");
      }, 1000);
    }
  };

  useEffect(() => {
    if (loginToken) {
      handleLoginAsToken();
    }
  }, [loginToken]);

  useEffect(() => {
    if (token && !loginToken) {
      getUserConfig();
      setTimeout(() => {
        if (location.state && location.state.from) {
          history.push(location.state.from.pathname);
        } else {
          // If there's no previous location, redirect to a default route
          history.push('/dashboard');
        }
      }, 1000);
    }
  }, [token]);

  return (
    <>
      {loginToken ? (
        <>
          <FullPageLoader isLoading={true} />
        </>
      ) : (
        <Container
          fluid
          style={{ padding: "0px", margin: "0px", width: "100%" }}
        >
          <Formik
            validationSchema={LoginValidationSchema}
            enableReinitialize={true}
            initialValues={{
              email: "",
              password: "",
              recaptcha: "",
            }}
            onSubmit={async (values: $TSFixMe, { setFieldError }) => {
              localStorage.removeItem("user-config-vendor");
              localStorage.removeItem("selectedProjectData");
              setIsLoading(true);
              try {
                const res: any = await props.loginUser(values);
                if (res.status === 200) {
                  Cookies.set("road-devil-vendor-token", res.result.jwttoken);
                  Cookies.set("road-devil-vendor-refreshtoken", res.result.refreshtoken);
                  getUserConfig();
                  setTimeout(() => {
                    if (location.state) {
                      history.replace(location.state.from.pathname);
                      history.go(0)
                    } else {
                      // If there's no previous location, redirect to a default route
                      history.push('/dashboard');
                    }
                  }, 1000);
                }
              } catch (error) {
                setFieldError("general", (error as $TSFixMe).data.message);
              } finally {
                setTimeout(() => {
                  setIsLoading(false);
                }, 1500);
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setSubmitting,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form>
                <Row className="mt-0">
                  <Col>
                    <div
                      className="auth-screen">
                      <Row
                        className="d-flex justify-content-center align-items-center flex-column w-100 m-auto"
                      >

                        <Col
                          md={4}
                          sm={6}
                          style={{
                            border: "1px solid rgb(255 255 255 / 60%)",
                            padding: "20px 40px",
                            boxShadow: "inset rgb(255 255 255 / 70%) 0px -1px 12px 3px",
                            borderRadius: "10px",
                            backgroundColor: "rgb(255 255 255 / 75%)",
                          }}
                        >
                          {/* <h3
                            className="text-center mb-4"
                            style={{ color: "#fff" }}
                          >
                            Login
                          </h3> */}
                          <div className="loginLogo m-auto">
                            <img src={ASSETS.STREET_ANGEL} alt="company logo" style={{ width: "300px" }} />
                          </div>
                          <FormGroup className="dropinput">
                            <div
                              className="modalheader"
                              // @ts-expect-error
                              htmlFor="email"
                            >
                              Email<span className="required"> *</span>
                            </div>
                            <Field
                              className={
                                "form-control mt-1" +
                                (errors.email && touched.email
                                  ? " is-invalid "
                                  : "")
                              }
                              autoComplete="disable"
                              type="userEmail"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup className="dropinput mt-4 mb-1">
                            <div className="modalheader">
                              Password<span className="required"> *</span>
                            </div>
                            <div className="position-relative">
                              <Field
                                className={
                                  "form-control mt-1" +
                                  (errors.password && touched.password
                                    ? " is-invalid "
                                    : "") +
                                  " passwordFont "
                                }
                                autoComplete="disable"
                                type={isPasswordVisible ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange("password")}
                                onBlur={handleBlur("password")}
                              />
                              <span className={(errors.password && touched.password
                                ? " is-invalid "
                                : "") + "eye-icon-for-password"} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </span>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </FormGroup>
                          <Row className="text-right">
                            <Link
                              className="text-decoration-none w-auto ms-auto auth-link-text"
                              to={"/forgot-password"}
                            >
                              Forgot Password?
                            </Link>
                          </Row>
                          <div className="text-center mt-4">
                            {errors.general && (
                              <p className="text-danger">{errors.general}</p>
                            )}
                            <ReCAPTCHA
                              className="re-captcha"
                              ref={reCaptchaRef}
                              onChange={(value) => {
                                setFieldValue("recaptcha", value);
                                setSubmitting(false);
                              }}
                              sitekey={TEST_SITE_KEY}
                            />
                            {errors.recaptcha && touched.recaptcha && (
                              <p className="recaptch-error">
                                {errors.recaptcha}
                              </p>
                            )}
                            <Button
                              className="my-3 w-25 py-2 h-35-px d-flex align-items-center justify-content-center mx-auto"
                              // @ts-expect-error
                              onClick={handleSubmit}
                              type="submit"
                            >
                              {isLoading ? (
                                <Spinner
                                  color="light"
                                  size="sm"
                                />
                              ) : "Login"}

                            </Button>
                            {/* <Button
                            className="pagebtn mt-2"
                            // @ts-expect-error
                            onClick={resetForm}
                          >
                            <Label className="tableModalBtnHeight range">
                              Reset
                            </Label>
                          </Button> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    loginUser: (values: { email: string; password: string }) =>
      dispatch(loginUser(values)),
    getUserConfiguration: () => dispatch(getUser()),
  };
};

export default connect(null, mapDispatchToProps)(Login);
