import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const usePermission = (permission: $TSFixMe) => {
    const user = useSelector((state: $TSFixMe) => state.userSlice.user);
    // let user: any = JSON.parse(localStorage?.getItem("user-config-vendor") || "null");
    const [isPermission, setIsPermission] = useState(false);

    const hasPermission = (permission: $TSFixMe) => {
        if (user && user?.roles?.length > 0) {
            if (permission === "ALL") {
                setIsPermission(true);
            } else if (Array.isArray(permission) && permission.length !== 0) {
                const isPermissionFind = permission.some(value => user.roles.includes(value));
                setIsPermission(isPermissionFind);
            } else {
                const isPermissionIncludes = user.roles.includes(permission);
                setIsPermission(isPermissionIncludes);
            }
        }
    };

    useEffect(() => {
        hasPermission(permission);
    }, [user, permission]);

    return isPermission;
};

export default usePermission;