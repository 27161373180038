import { GetActiveSafetyEventsResp, GetDeviceReportResp, GetDeviceStatusResp, GetDriverStatisticsResp, GetEventReportResp, GetGeoFenceEventsResp, GetHighEventsResp, GetLowEventsResp, GetPanicButtonEventsResp, GetRequestedVideoResp, GetSaaSEventResp, GetTamperEventsResp, GetVehicleTripResp, GetVideoRequestReportsResp, VehicleTripsDetailsResp, VehicleTripsListResp } from "../../../../global.types";
import {
  DELETE_IMPORT_DEVICES,
  DELETE_IMPORT_DEVICES_SUCCESS,
  GET_ACTIVE_SAFETY_EVENTS,
  GET_CHAT_DATA,
  GET_COMPLETED,
  GET_DEVICE_REPORTS,
  GET_DEVICE_STATUS,
  GET_DRIVER_STATISTICS,
  GET_ENGINEER_SERVICE_CALLS,
  GET_EVENT_REPORTS,
  GET_FIRST_CONNECTS,
  GET_GEO_FENCE_EVENTS,
  GET_HIGH_EVENTS,
  GET_IMPORT_DEVICES,
  GET_LOCATION_REPORTS,
  GET_LOW_EVENTS,
  GET_ORGANIZATION_REPORTS,
  GET_PANIC_BUTTON_EVENTS,
  GET_PENDING_DEVICES,
  GET_REQUESTED_VIDEO,
  GET_SA_AS_EVENTS,
  GET_TAMPER_EVENTS,
  GET_VEHICLE_TRIP,
  GET_VEHICLE_TRIP_DETAIL,
  GET_VEHICLE_TRIP_GPS,
  GET_VEHICLE_TRIP_LIST,
  GET_VEHICLE_TRIP_REPORTS,
  GET_VIDEO_REQUEST_REPORTS,
  RESET_DEVICE_REPORTS,
  RESET_EVENT_REPORTS,
  RESET_GEO_FENCE_EVENTS,
  RESET_HIGH_EVENTS,
  RESET_LOW_EVENTS,
  RESET_PANIC_BUTTON_EVENTS,
  RESET_REQUESTED_VIDEO,
  RESET_SA_AS_EVENTS,
  RESET_VEHICLE_TRIP,
  RESET_VEHICLE_TRIP_LIST,
  RESET_VIDEO_REQUEST_REPORTS,
} from "./data.types";

export const getDeviceStatus = (data: GetDeviceStatusResp) => {
  return {
    type: GET_DEVICE_STATUS as typeof GET_DEVICE_STATUS,
    data,
    meta: { thunk: true },
  };
};

export const addChatdata = (msg: $TSFixMe) => {
  return {
    type: GET_CHAT_DATA as typeof GET_CHAT_DATA,
    payload: msg,
  };
};

export const getLowEvents = (data: GetLowEventsResp) => {
  return {
    type: GET_LOW_EVENTS as typeof GET_LOW_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const resetLowEvents = () => {
  return {
    type: RESET_LOW_EVENTS as typeof RESET_LOW_EVENTS,
  };
};

export const getRequestedVideo = (data: GetRequestedVideoResp) => {
  return {
    type: GET_REQUESTED_VIDEO as typeof GET_REQUESTED_VIDEO,
    data,
    meta: { thunk: true },
  };
};

export const getDriverStatistics = (data: GetDriverStatisticsResp) => {
  return {
    type: GET_DRIVER_STATISTICS as typeof GET_DRIVER_STATISTICS,
    data,
    meta: {
      thunk: true,
    },
  };
};
export const getVehicleTrips = (data: GetVehicleTripResp) => {
  return {
    type: GET_VEHICLE_TRIP as typeof GET_VEHICLE_TRIP,
    data,
    meta: { thunk: true },
  };
};

export const getVideoRequestReports = (data: GetVideoRequestReportsResp) => {
  return {
    type: GET_VIDEO_REQUEST_REPORTS as typeof GET_VIDEO_REQUEST_REPORTS,
    data,
    meta: { thunk: true },
  };
};

export const resetVideoRequestReports = () => {
  return {
    type: RESET_VIDEO_REQUEST_REPORTS as typeof RESET_VIDEO_REQUEST_REPORTS,
  };
};

export const resetRequestedVideo = () => {
  return {
    type: RESET_REQUESTED_VIDEO as typeof RESET_REQUESTED_VIDEO,
  };
};

export const resetVehicleTrips = () => {
  return {
    type: RESET_VEHICLE_TRIP as typeof RESET_VEHICLE_TRIP,
  };
};

export const getVehicleTripReports = () => {
  return {
    type: GET_VEHICLE_TRIP_REPORTS as typeof GET_VEHICLE_TRIP_REPORTS,
    meta: { thunk: true },
  };
};

export const getOrganizationReports = () => {
  return {
    type: GET_ORGANIZATION_REPORTS as typeof GET_ORGANIZATION_REPORTS,
    meta: { thunk: true },
  };
};

export const getDeviceReports = (data: GetDeviceReportResp) => {
  return {
    type: GET_DEVICE_REPORTS as typeof GET_DEVICE_REPORTS,
    data,
    meta: { thunk: true },
  };
};

export const getLocationReports = (data: GetDeviceReportResp) => {
  return {
    type: GET_LOCATION_REPORTS as typeof GET_LOCATION_REPORTS,
    data,
    meta: { thunk: true },
  };
};

export const resetDeviceReports = () => {
  return {
    type: RESET_DEVICE_REPORTS as typeof RESET_DEVICE_REPORTS,
  };
};

export const getEventReports = (data: GetEventReportResp) => {
  return {
    type: GET_EVENT_REPORTS as typeof GET_EVENT_REPORTS,
    data,
    meta: { thunk: true },
  };
};

export const resetEventReports = () => {
  return {
    type: RESET_EVENT_REPORTS as typeof RESET_EVENT_REPORTS,
  };
};

export const getFirstConnects = () => {
  return {
    type: GET_FIRST_CONNECTS as typeof GET_FIRST_CONNECTS,
    meta: { thunk: true },
  };
};

export const getImportDevices = () => {
  return {
    type: GET_IMPORT_DEVICES as typeof GET_IMPORT_DEVICES,
    meta: { thunk: true },
  };
};

export const deleteImportDevicesCode = (id: number) => {
  return {
    type: DELETE_IMPORT_DEVICES as typeof DELETE_IMPORT_DEVICES,
    id,
    meta: {
      thunk: true,
    },
  };
};

export const deleteImportDevicesCodeSuccess = ({ id, meta }: $TSFixMe) => {
  return {
    type: DELETE_IMPORT_DEVICES_SUCCESS as typeof DELETE_IMPORT_DEVICES_SUCCESS,
    id,
    meta,
  };
};

export const getEngineerServiceCalls = () => {
  return {
    type: GET_ENGINEER_SERVICE_CALLS as typeof GET_ENGINEER_SERVICE_CALLS,
    meta: { thunk: true },
  };
};

export const getPendingDevices = () => {
  return {
    type: GET_PENDING_DEVICES as typeof GET_PENDING_DEVICES,
    meta: { thunk: true },
  };
};

export const getCompleted = () => {
  return {
    type: GET_COMPLETED as typeof GET_COMPLETED,
    meta: { thunk: true },
  };
};

export const getPanicButtonEvents = (data: GetPanicButtonEventsResp) => {
  return {
    type: GET_PANIC_BUTTON_EVENTS as typeof GET_PANIC_BUTTON_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const resetPanicButtonEvents = () => {
  return {
    type: RESET_PANIC_BUTTON_EVENTS as typeof RESET_PANIC_BUTTON_EVENTS,
  };
};

export const getSaAsEvents = (data: GetSaaSEventResp, params: $TSFixMe) => {
  return {
    type: GET_SA_AS_EVENTS as typeof GET_SA_AS_EVENTS,
    data,
    params,
    meta: { thunk: true },
  };
};

export const resetSaAsEvents = () => {
  return {
    type: RESET_SA_AS_EVENTS as typeof RESET_SA_AS_EVENTS,
  };
};

export const getHighEvents = (data: GetHighEventsResp) => {
  return {
    type: GET_HIGH_EVENTS as typeof GET_HIGH_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const resetHighEvents = () => {
  return {
    type: RESET_HIGH_EVENTS as typeof RESET_HIGH_EVENTS,
  };
};

export const getGeoFenceEvents = (data: GetGeoFenceEventsResp) => {
  return {
    type: GET_GEO_FENCE_EVENTS as typeof GET_GEO_FENCE_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const getActiveSafetyEvents = (data: GetActiveSafetyEventsResp) => {
  return {
    type: GET_ACTIVE_SAFETY_EVENTS as typeof GET_ACTIVE_SAFETY_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const getTamperEvents = (data: GetTamperEventsResp) => {
  return {
    type: GET_TAMPER_EVENTS as typeof GET_TAMPER_EVENTS,
    data,
    meta: { thunk: true },
  };
};

export const resetGeoFenceEvents = () => {
  return {
    type: RESET_GEO_FENCE_EVENTS as typeof RESET_GEO_FENCE_EVENTS,
  };
};

export const resetVehicleTripsList = () => {
  return {
    type: RESET_VEHICLE_TRIP_LIST as typeof RESET_VEHICLE_TRIP_LIST,
  };
};

export const getVehicleTripsList = (data: VehicleTripsListResp) => {
  return {
    type: GET_VEHICLE_TRIP_LIST as typeof GET_VEHICLE_TRIP_LIST,
    data,
    meta: { thunk: true }
  };
};

export const getVehicleTripsDetails = (data: VehicleTripsDetailsResp, id: string) => {
  return {
    type: GET_VEHICLE_TRIP_DETAIL as typeof GET_VEHICLE_TRIP_DETAIL,
    data,
    id,
    meta: { thunk: true }
  };
};

export const getVehicleTripsGps = (data: VehicleTripsDetailsResp) => {
  return {
    type: GET_VEHICLE_TRIP_GPS as typeof GET_VEHICLE_TRIP_GPS,
    data,
    meta: { thunk: true }
  };
};