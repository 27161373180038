import { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";

import Select from "react-select";
import { ErrorMessage, Field, Formik } from "formik";
import { toast } from "react-toastify";
import {
  GeoFenceAddType,
  alarm,
  geoFenceValidationSchema,
} from "../../../../../../../constants";
import ASSETS from "../../../../../../../assets";
import Loc from "../../../Loc";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import {
  getDeviceListForProj,
  getProjectListDD,
} from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { addGeoFencing } from "../../../../../../../redux/reducers/Trips&GeoFence/geoFencing/geoFencing.actions";
import { AddGeoFencingType } from "../../../../../../../../global.types";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

export interface geoFence {
  latitude: number | null;
  longitude: number | null;
  radius: number | null;
  coordinates?: $TSFixMe;
  geofenceType: number | null;
}

const initialState: geoFence = {
  latitude: null,
  longitude: null,
  radius: null,
  coordinates: null,
  geofenceType: null,
};

const GeoFenceInitialValueItem = {
  vehicleReg: {
    label: "",
    value: "",
  },
  project: {
    value: 0,
    label: "",
  },
  alarm: {
    label: "",
    value: 0,
  },
  geoFenceName: "",
};

const AddGeoFence = (props: $TSFixMe) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [geoFence, setGeoFence] = useState(initialState);
  const [projectValue, setProjectValue] = useState(null);
  const [geoFenceInitialValues, setGeoFenceInitialValues] = useState(
    GeoFenceInitialValueItem
  );
  const projectsList =
    props.dropdowns.projectListDD &&
    props.dropdowns.projectListDD.result &&
    props.dropdowns.projectListDD.result.map((pro: $TSFixMe) => ({
      label: pro.name,
      value: pro.id,
    }));
  const deviceList =
    props?.dropdowns?.device_proj &&
    props?.dropdowns?.device_proj?.result &&
    props?.dropdowns?.device_proj?.result.map((device: $TSFixMe) => ({
      label: device.name ?? device.otherValue1,
      value: device.otherValue1,
    }));

  const addGeoFenceHandler = async (values: GeoFenceAddType) => {
    try {
      props.startLoading();
      if ((geoFence.latitude && geoFence.longitude) || geoFence.coordinates) {
        await toast.promise(
          props.addGeoFencing({
            deviceId: values.vehicleReg.value,
            coordinates: geoFence.coordinates && geoFence.coordinates.slice(0, 4),
            geofenceType: geoFence.geofenceType,
            latitude: geoFence.latitude,
            longitude: geoFence.longitude,
            name: values.geoFenceName,
            projectId: values.project.value,
            radius: Number(geoFence?.radius),
            type: values.alarm.value,
          }) as any,
          {
            success: TOAST_MSG.GEO_FENCING_SUCCESS,
          }
        );
        setGeoFence(initialState);
        props.toggle();
      } else {
        toast.error("Select Geo Fence Area");
      }
    } catch (error) {
      console.log("error add geofence", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    if (props.mode === "view" && props.selectedGeoFence) {
      setGeoFence({
        latitude: props.selectedGeoFence.latitude,
        longitude: props.selectedGeoFence.longitude,
        radius: props.selectedGeoFence.radius,
        coordinates: props.selectedGeoFence.coordinates,
        geofenceType: props.selectedGeoFence.geofenceType,
      });

      setGeoFenceInitialValues({
        vehicleReg: {
          label: props.selectedGeoFence.vehicleRegestrationNo,
          value: props.selectedGeoFence.deviceId,
        },
        project: {
          value: props.selectedGeoFence.projectId,
          label: props.selectedGeoFence.projectName,
        },
        alarm: alarm.filter(
          (a: $TSFixMe) => a.value === props.selectedGeoFence.type
        ),
        geoFenceName: props.selectedGeoFence.name,
      });
    }

    return () => {
      setGeoFenceInitialValues(GeoFenceInitialValueItem);
      setGeoFence(initialState);
    };
  }, [props.mode, props.selectedGeoFence]);

  const apiCall = async () => {
    if (props.modal) {
      props.getProjectListDD();
    }
  };

  useEffect(() => {
    if (projectValue && props.modal) {
      const apiCall = async () => {
        await props.getDeviceListDD(projectValue);
      };
      apiCall();
    }
    return () => { };
  }, [projectValue, props.modal]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={() => props.toggle()}
        className="modtopGeoFence"
      >
        <ModalHeader className={`mod  ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Add GeoFence</h5>
          <button
            className={`mult  ${selectedTheme}-bg`}
            onClick={() => props.toggle()}
          >
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={geoFenceValidationSchema}
          enableReinitialize={true}
          initialValues={geoFenceInitialValues}
          onSubmit={addGeoFenceHandler}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
          }) => (
            <>
              <ModalBody>
                <Row className="align-items-start">
                  <Col md={3}>
                    <FormGroup>
                      <Label className="modlabel modinpulabel">Project
                        <span className="required"> *</span>
                      </Label>
                      <Select
                        className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                        placeholder="Project"
                        options={projectsList || []}
                        value={values.project.value ? values.project : null}
                        name="project"
                        onChange={(option: $TSFixMe) => {
                          setFieldValue("vehicleReg", {});
                          setProjectValue(option?.value);
                          setFieldValue("project", option);
                        }}
                        isDisabled={props.mode === "view"}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onBlur={handleBlur("project")}
                      />
                      {touched.project && errors.project && (
                        <div className="validate">
                          {errors.project ? "Required" : errors.project}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="modlabel modinpulabel">
                        Vehicle Reg#
                        <span className="required"> *</span>
                      </Label>
                      <Select
                        className={`${touched.vehicleReg && errors.vehicleReg ? "requireSelect" : ""}`}
                        placeholder="Vehicle Reg#"
                        name="vehicleReg"
                        options={deviceList || []}
                        value={
                          values.vehicleReg.value ? values.vehicleReg : null
                        }
                        onChange={(option: $TSFixMe) => {
                          setFieldValue("vehicleReg", option);
                        }}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        isDisabled={props.mode === "view"}
                        onBlur={handleBlur("vehicleReg")}
                      />
                      {touched.vehicleReg && errors.vehicleReg && (
                        <div className="validate">
                          {errors.vehicleReg ? "Required" : errors.vehicleReg}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="modlabel modinpulabel">
                        Alarm
                        <span className="required"> *</span>
                      </Label>
                      <Select
                        className={`${touched.alarm && errors.alarm ? "requireSelect" : ""}`}
                        name="alarm"
                        placeholder="Alarm on entry Only"
                        options={alarm || []}
                        value={values.alarm.value !== 0 ? values.alarm : null}
                        onChange={(option: $TSFixMe) =>
                          setFieldValue("alarm", option)
                        }
                        isDisabled={props.mode === "view"}
                        onBlur={handleBlur("alarm")}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                      />
                      {touched.alarm && errors.alarm && (
                        <div className="validate">
                          {errors.alarm ? "Required" : errors.alarm}
                        </div>
                      )}
                    </FormGroup>
                    <Label className="modlabel modinpulabel">
                      GeoFence Name
                      <span className="required"> *</span>
                    </Label>
                    <Field
                      autoComplete="disable"
                      className={
                        "form-control" +
                        (errors.geoFenceName && touched.geoFenceName
                          ? " is-invalid "
                          : "")
                      }
                      name="geoFenceName"
                      type="geoFenceName"
                      disabled={props.mode === "view"}
                      placeholder="Name of Geo-Fence"
                      value={values.geoFenceName}
                      onChange={handleChange("geoFenceName")}
                      onBlur={handleBlur("geoFenceName")}
                    />
                    <ErrorMessage
                      name="geoFenceName"
                      component="div"
                      className="invalid-feedback"
                    />
                    {!geoFence.coordinates && (
                      <div className="mt-4">
                        <p className="modlabel modinpulabel">
                          Latitude : {geoFence.latitude}{" "}
                        </p>
                        <p className="modlabel modinpulabel">
                          {" "}
                          Longitude : {geoFence.longitude}{" "}
                        </p>
                        <p className="modlabel modinpulabel">
                          {" "}
                          Radius : {geoFence.radius}{" "}
                        </p>
                      </div>
                    )}
                    {!geoFence.latitude && !geoFence.longitude && geoFence.coordinates && geoFence.coordinates.length > 0 && (
                      <div className="mt-4">
                        <p className="modlabel modinpulabel">Polygon area (square meters) : {geoFence.radius} </p>
                      </div>
                    )}
                  </Col>
                  <Col md={9}>
                    <Loc
                      setGeoFence={setGeoFence}
                      geoFence={geoFence}
                      mode={props.mode}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                {props.mode !== "view" && (
                  <>
                    <div className="footwidth">
                      <Button
                        disabled={isSubmitting}
                        // @ts-expect-error TS(2769): No overload matches this call.
                        onClick={handleSubmit}
                        className="popbtn datewidth btnBox"
                      >
                        {props.isLoading && (
                          <Spinner color="dark" size="sm" className="mr-2" />
                        )}
                        <>ADD</>
                      </Button>
                    </div>
                    <div className="divider" />
                  </>
                )}
                <div
                  className={`${props.mode === "view" ? "w-100 py-3" : "footwidth"
                    }`}
                >
                  <Button
                    onClick={() => props.toggle()}
                    className="popbtn datewidth btnBox"
                  >
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceListDD: (id: number) => dispatch(getDeviceListForProj(id)),
  getProjectListDD: () => dispatch(getProjectListDD()),
  addGeoFencing: (data: AddGeoFencingType) => dispatch(addGeoFencing(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGeoFence);
