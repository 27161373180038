import { useState, useContext } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import RadioGroup from "@mui/material/RadioGroup";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { connect } from "react-redux";
import ASSETS from "../../../../../../../assets";
import ToggleButton from "../../../../../../Common/ToggleButton";
import PermissionsTable from "../../../PermissionsTable";
import { Permission, Role } from "../../../../../../../../global.types";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import { toast } from "react-toastify";
import { getManageRoleById } from "../../../../../../../api/axios.resources";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: Role;
  };

const ViewRole = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const [roleData, setRoleData]: any = useState({});
  const toggle = () => setModal(!modal);

  const handleGetRoleById = async (id: number) => {
    try {
      const res = await getManageRoleById(id);
      if (res.status === 200) {
        setRoleData(res.result);
        toggle();
      } else {
        toast.error("No data found.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <span className="tableModalBtn">
        <VisibilityOutlinedIcon onClick={() => handleGetRoleById(props.data.id)} className="dropico" />
      </span>
      <Modal isOpen={modal} toggle={toggle} className="modalTop">
        <ModalHeader className={`mod  ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">View Role</h5>
          <button className={`mult  ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto'
        }} className="scr">
          <Row>
            <Col md={12}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Name</div>
                <Input
                  className="disableField"
                  autocomplete="disable"
                  disabled
                  type="text"
                  value={roleData.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Description</div>
                <Input
                  autocomplete="disable"
                  disabled
                  type="textarea"
                  name="select"
                  className="disableField range"
                  value={roleData.description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex mb-4">
            <Col xs={12} md={6} className="d-flex align-items-center ">
              <div className="modlabel modinpulabel mt-1 mr-3">Live Streaming</div>
              <ToggleButton checked={roleData.liveStreaming} />
            </Col>
          </Row>
          <PermissionsTable
            disabled={true}
            className={"mt-2"}
            permissions={roleData.rolePermission}
            setPermissions={(val: { [key: number]: Permission }) => {

            }}
          />
          <Row>
            <Col md={2}>
              <div className="modlabel modinpulabel mt-1">Status</div>
            </Col>
            <Col md={6}>
              <RadioGroup
                className="radioStatus"
                aria-label="status"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  checked={roleData.status}
                  control={<Radio />}
                  label="Active"
                  disabled
                />
                <FormControlLabel
                  value={false}
                  checked={!roleData.status}
                  control={<Radio />}
                  label="Deactive"
                  disabled
                />
              </RadioGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="footer-width py-3">
          <Button className="popbtn vie btnBox datewidth" onClick={toggle}>
            <>CLOSE</>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRole);
