import { AppActions } from "../../store/globalstore";
import { GET_USER_SUCCESS, LOGIN_USER_SUCCESS, LOGOUT_USER_SUCCESS, SET_TOKEN, UPDATE_USER_PROFILE_IMG, UPDATE_USER_SUCCESS } from "./user.types";

interface InitialState {
  user: {
    id: number,
    measurmentUnit: number,
    measurmentUnitName: string,
    profilePicture: string,
    receiveAlertEmail: boolean,
    receiveMaintenanceEmail: boolean,
    theme: string,
    timeFormat: number,
    userId: number,
    timeZone: { zoneId: string, timezone: string },
    userName: string;
    vendorAdmin?: boolean;
  },
  token: string,
}

// initial State
const initialState: InitialState = {
  user: {
    id: 0,
    measurmentUnit: 0,
    measurmentUnitName: "",
    profilePicture: "",
    receiveAlertEmail: true,
    receiveMaintenanceEmail: true,
    theme: "gray_scale",
    timeFormat: 0,
    userId: 0,
    timeZone: {
      zoneId: "",
      timezone: "",
    },
    userName: "",
  },
  token: "",
  // guestModeOn: false,
};

export const userReducer = (
  state = initialState,
  action: AppActions): InitialState => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, token: action.payload.result.jwttoken };
    case GET_USER_SUCCESS:
      return { ...state, user: action.payload.result };
    // case SET_USER:
    //   return { ...state, user: action.data };
    case UPDATE_USER_SUCCESS:
      return { ...state, user: action.payload.result };
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: initialState.user, token: "" };
    case UPDATE_USER_PROFILE_IMG:
      return {
        ...state, user: {
          ...state.user,
          profilePicture: action.data
        }
      };
    // case SET_TOKEN:
    //   return { ...state, token: action.data };
    default:
      return state;
  }
};
