import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { RequestedVideo } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { ROUTES } from "../../../../../routes/routes";
import { Link } from "react-router-dom";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import { convertTimestampToDate, encrypt } from "../../../../../services/common.functions.services";
import VideoRequest from "./Components/videoRequest";
import { DEVICE_VIDEO_STATUS, keysToExtract } from "../../../../../constants";
import { Tooltip } from "antd";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
interface RequestedVideoTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RequestedVideoTableProps;

const RequestedVideoTab = (props: Props) => {
  const [requestedVideoList, setRequestedVideoList] = useState<
    RequestedVideo[]
  >([]);

  const RequestedVideoActionBtns1 = (data: $TSFixMe) => {

    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data.data.deviceId,
      id: data.data._id,
      projectId: data.data.projectId,
      type: 'REQUESTED_VIDEO_INFO',
      endTimestamp: data.data.endTimestamp,
      startTimestamp: data.data.startTimestamp,
      timestamp: data.data.currentTimestamp,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink" target="_blank">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  function extractCObjectNames(obj: $TSFixMe) {
    const cKeys = Object.keys(obj).filter((key) => /^c\d/.test(key));
    return cKeys.join(",");
  }

  const RequestedVideoActionBtns2 = () => {
    return <VideoRequest />;
  };

  const requestedVideoColumns = [
    {
      title: "Action",
      format: (data: $TSFixMe) => {
        const channel1Command = data?.command_info?.filter((command: $TSFixMe) => command?.channel === 1 && command?.commandType === "VIDEO")[0];
        return (
          <>
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode === 0) && (
              <RequestedVideoActionBtns1 data={data} />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.completed && channel1Command.responseCode !== 0) && (
              <NotInterestedIcon className="nondropico" />
            )}
            {channel1Command && (channel1Command.status === DEVICE_VIDEO_STATUS.pending) && (
              <Tooltip title={"Already, requested for video."} placement={"top"}>
                <TimelapseSharpIcon className="nondropico" />
              </Tooltip>
            )}
            {!channel1Command && <NotInterestedIcon className="nondropico" />}
          </>
        )
      },
    },
    // {
    //   title: "Request New Time",
    //   format: () => <RequestedVideoActionBtns2 />,
    // },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    {
      title: "Start Time",
      data: "startTimestamp",
      format: (data: $TSFixMe) => data.startTimestamp ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.startTimestamp,
          null,
          props.user.timeFormat
        ) : "N/A",
    },
    {
      title: "Finish Time",
      data: "endTimestamp",
      format: (data: $TSFixMe) => data.endTimestamp ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.endTimestamp,
          null,
          props.user.timeFormat
        ) : "N/A",
    },
    { title: "Device ID", data: "deviceId" },
    {
      title: "Requested Channel",
      format: (data: $TSFixMe) => {
        const extractedKeys: $TSFixMe = {};
        keysToExtract.forEach((key: $TSFixMe) => {
          if (data.hasOwnProperty(key)) {
            extractedKeys[key] = data[key];
          }
        });
        return extractedKeys && Object.keys(extractedKeys).length > 0 &&
          Object.keys(extractedKeys).map((item) => {
            const num = item.replace(/^\D+/g, ''); // Extract numeric part of the string
            return parseInt(num);
          }).join(", ")
      }
    },
    { title: "Project", data: "projectName", sorting: false, format: (data: $TSFixMe) => data.projectName },
    {
      title: "Requested By",
      data: "created_by", sorting: false,
      format: (data: $TSFixMe) => data.created_by,
    },
    // {
    //   title: "Requested Channel",
    //   format: (data: $TSFixMe) => extractCObjectNames(data),
    // },
    {
      title: "Requested Time",
      data: "currentTimestamp",
      format: (data: $TSFixMe) => data.currentTimestamp ?
        convertTimestampToDate(
          props.user.timeZone.zoneId,
          data.currentTimestamp,
          null,
          props.user.timeFormat
        ) : "N/A",
    },
  ];

  useEffect(() => {
    const updatedHighEventsList = (
      props.requestedVideos?.result?.data || []
    ).map((item, index) => ({
      ...item,
      no: index + 1,
    }));
    setRequestedVideoList(updatedHighEventsList);
    return () => { };
  }, [props.requestedVideos]);

  return (
    <>
      <DataTable
        data={requestedVideoList}
        columns={requestedVideoColumns}
        bordered
        pageData={props.requestedVideos?.result?.page}
        totalRecrods={props.requestedVideos?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  requestedVideos: state.dataStore.requestedVideos,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RequestedVideoTab);
