import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import { Col, Spinner } from "reactstrap";
import { LayoutContext } from "../../../context/LayoutContext";
import { toast } from "react-toastify";

type Props = {
  onFileSelect?: (file: File | null) => void;
  id?: string;
  className?: string;
  setcheckBox?: any;
  accept?: string;
  isLoading?: boolean;
};

const CustomInput = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(null);
    const file: any = event.target.files?.[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    if (file && file.size >= maxSize) {
      toast.error("File size should be less than 5 MB");
      return;
    }
    setSelectedFile(file);
    // if (props.id == "fileInput2") {
    //   props.setcheckBox(true);
    // } else {
    //   props.setcheckBox(false);
    // }

    if (props.onFileSelect) {
      props.onFileSelect(file);
    }
  };
  return (
    <div className="customUploadHead">
      <input
        type="file"
        accept={` ${props.accept
          ? props.accept
          : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          } `}
        className="inputFile1"
        id={props.id}
        onChange={handleFileInput}
      />
      <label htmlFor={props.id} className="inpuLabel">
        {selectedFile && <p className="uploadFileName">{selectedFile.name}</p>}
        <div className={`customUploadBtn ${selectedTheme}`}>
          <Button
            variant="contained"
            component="span"
            disabled={props.isLoading}
            className={`table-fixed customUpload ${selectedTheme}-bg`}
          >
            <i className="fa fa-upload mr-1" aria-hidden="true" />
            <div className="uploadIcn">Select File</div>
            {props.isLoading && <Spinner color="light" className="ml-2 xs-loader" />}
          </Button>
        </div>
      </label>
    </div>
  );
};

export default CustomInput;
