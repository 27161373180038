import {
  GET_DEVICE_LIST_DD,
  GET_DEVICE_LIST_DD_VIDEO_REQUEST,
  GET_DEVICE_LIST_FROM_PROJ,
  GET_DIAL_CODE,
  GET_DRIVER_LIST_DD,
  GET_ORG_LIST,
  GET_PROJECT_LIST_DD,
  GET_PROJECT_LIST_DD_VIDEO_REQUEST,
  GET_PROJECT_LIST_FROM_ORG,
  GET_RESOURCE_ROLE_LIST,
  GET_TIME_ZONE_LIST,
  GET_USER_ROLE_LIST,
} from "./dropdown.types";

export const getOrgList = () => {
  return {
    type: GET_ORG_LIST as typeof GET_ORG_LIST,
    meta: {
      thunk: true,
    },
  };
};

export const getDialCode = () => {
  return {
    type: GET_DIAL_CODE as typeof GET_DIAL_CODE,
    meta: {
      thunk: true,
    },
  };
};

export const getResourceRoleList = () => {
  return {
    type: GET_RESOURCE_ROLE_LIST as typeof GET_RESOURCE_ROLE_LIST,
    meta: {
      thunk: true,
    },
  };
};

export const getProjectListForOrg = (id: number) => {
  return {
    type: GET_PROJECT_LIST_FROM_ORG as typeof GET_PROJECT_LIST_FROM_ORG,
    id,
    meta: {
      thunk: true,
    },
  };
};
export const getDeviceListForProj = (id: number) => {
  return {
    type: GET_DEVICE_LIST_FROM_PROJ as typeof GET_DEVICE_LIST_FROM_PROJ,
    id,
    meta: {
      thunk: true,
    },
  };
};

export const getTimeZoneList = () => {
  return {
    type: GET_TIME_ZONE_LIST as typeof GET_TIME_ZONE_LIST,
    meta: {
      thunk: true,
    },
  };
};

export const getProjectListDD = () => {
  return {
    type: GET_PROJECT_LIST_DD as typeof GET_PROJECT_LIST_DD,
    meta: {
      thunk: true,
    },
  };
};

export const getDeviceListDDVideoRequest = (id: number) => {
  return {
    type: GET_DEVICE_LIST_DD_VIDEO_REQUEST as typeof GET_DEVICE_LIST_DD_VIDEO_REQUEST,
    id,
    meta: {
      thunk: true,
    },
  };
};

export const getProjectListDDVideoRequest = () => {
  return {
    type: GET_PROJECT_LIST_DD_VIDEO_REQUEST as typeof GET_PROJECT_LIST_DD_VIDEO_REQUEST,
    meta: {
      thunk: true,
    },
  };
};

export const getDeviceListDD = () => {
  return {
    type: GET_DEVICE_LIST_DD as typeof GET_DEVICE_LIST_DD,
    meta: {
      thunk: true,
    },
  };
};

export const getUserRoleListDD = () => {
  return {
    type: GET_USER_ROLE_LIST as typeof GET_USER_ROLE_LIST,
    meta: {
      thunk: true,
    },
  };
};

export const getDriverListDD = (id: number) => {
  return {
    type: GET_DRIVER_LIST_DD as typeof GET_DRIVER_LIST_DD,
    id,
    meta: {
      thunk: true,
    },
  };
};
