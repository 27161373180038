import React, { useContext } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { LayoutContext } from "../../../context/LayoutContext";
import { THEME_TYPE } from "../../../constants";
// import Paper from "@material-ui/core/Paper";

export default function CopyrightFooter() {
  const currentYear = new Date().getFullYear();
  const { selectedTheme } = useContext(LayoutContext);
  const themeColor = (selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) && 'sidebar-light';
  return (
    <>
      <Row>
        <Col xl="12" className={`main_footer_copyrightText ${themeColor}`}>
          <Card className={`${themeColor} card-stats md-3 copyrightText`}>
            {/* <Paper elevation={6}> */}
            <CardBody className={`copyrightfooter ${selectedTheme}-bg`}>
              Copyright Road Devil {currentYear}
            </CardBody>
            {/* </Paper> */}
          </Card>
        </Col>
      </Row>
    </>
  );
}
