import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deviceReportColumns } from "../../../../../constants";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { DeviceReport } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable/dataTable";

interface DeviceReportTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
  recordsPerPage: number;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & DeviceReportTableProps;

const DeviceReportTab = (props: Props) => {
  const [deviceReports, setDeviceReports] = useState<DeviceReport[]>([]);

  useEffect(() => {
    setDeviceReports(props.deviceReports.result ? props.deviceReports.result.data : []);
    return () => { };
  }, [props.deviceReports]);

  return (
    <>
      <DataTable
        data={deviceReports}
        columns={deviceReportColumns}
        bordered
        pageData={props.deviceReports?.result?.page}
        totalRecrods={props.deviceReports?.result?.totalRecord}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

//export default DeviceReportTab;
const mapStateToProps = (state: RootState) => ({
  deviceReports: state.dataStore.deviceReports,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeviceReportTab);
