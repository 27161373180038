import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AddProject as AddProjectType } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  AddProjectValidationType,
  projectValidationSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import { getDialCode, getProjectListDD } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { AddProjectMgmt } from "../../../../../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import SetLogo from "./setLogo";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AddProject = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [logo, setLogo] = useState({
    logo: false,
    headerLargeLogo: "",
    headerSmallLogo: "",
    footerLargeLogo: "",
    footerSmallLogo: "",
  });

  const handleAddOrganization = async (values: AddProjectValidationType) => {
    try {
      props.startLoading();
      const res: $TSFixMe = await toast.promise(
        props.AddProjectCode({
          address: values.address ?? "",
          altEmail: values?.altEmail,
          city: values.city ?? "",
          contactPersonName: values.contactPersonName,
          contactPersonPhoneCode: values.contactPersonPhoneCode,
          contactPersonPhoneNo: values.contactPersonPhoneNo,
          country: values.country ?? "",
          email: values.email,
          faxNo: values.faxNo,
          footerLogoLarge: logo.footerLargeLogo,
          footerLogoSmall: logo.footerSmallLogo,
          leaderLogoLarge: logo.headerLargeLogo,
          leaderLogoSmall: logo.headerSmallLogo,
          logo: logo.logo,
          name: values.name,
          officeNo: values.officeNo ?? "",
          postalcode: values.postalCode,
          services: values.service ?? "",
          state: values.state ?? "",
          website: values.website,
        }) as any,
        {
          success: TOAST_MSG.PROJECT_SUCCESS,
        }
      );
      if (res.status === 200 || res.status === 201) {
        await props.getProjectListDD();
      }
      toggle();
    } catch (error) {
      console.log("error in Organization Management Add api", error);
    } finally {
      await props.endLoading();
    }
  };

  const apiCall = async () => {
    props.startLoading();
    if (!props.dropdowns.dialCode?.dropDown && modal) {
      await props.getDialCodeList();
    }
    props.endLoading();
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <>
      <Button onClick={toggle} className={`${selectedTheme}`}>
        <Label className="tableModalBtnHeight range">Add Project</Label>
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        //
        className="videoModal"
      >
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <div className="modlabel modheadcont mb-0">Add Project</div>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={projectValidationSchema}
          enableReinitialize={true}
          initialValues={{
            name: "",
            postalCode: "",
            contactPersonName: "",
            contactPersonPhoneNo: "",
            contactPersonPhoneCode: "",
            altEmail: "",
            faxNo: "",
            website: "",
            email: "",
            logo: true,
            headerLargeLogo: "",
            headerSmallLogo: "",
            footerLargeLogo: "",
            footerSmallLogo: "",
            city: "",
            country: "",
            officeNo: "",
            address: "",
            service: "",
            state: "",
          }}
          onSubmit={handleAddOrganization}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
            touched,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label className="modlabel modinpulabel" htmlFor="projectName">
                        Project Name<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid " : "")
                        }
                        name="name"
                        id="projectName"
                        placeholder="Project Name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label className="modlabel modinpulabel" htmlFor="address">Project Address</label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Project Address"
                        value={values.address}
                        onChange={handleChange("address")}
                        onBlur={handleBlur("address")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="city"
                      >
                        City
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="city"
                        placeholder="City"
                        value={values.city}
                        onChange={handleChange("city")}
                        onBlur={handleBlur("city")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="state"
                      >
                        State
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="state"
                        placeholder="State"
                        value={values.state}
                        onChange={handleChange("state")}
                        onBlur={handleBlur("state")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="country"
                        placeholder="Country"
                        value={values.country}
                        onChange={handleChange("country")}
                        onBlur={handleBlur("country")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        Postal Code<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.postalCode && touched.postalCode
                            ? " is-invalid "
                            : "")
                        }
                        pattern="\d*"
                        maxLength="10"
                        id="postalCode"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={values.postalCode}
                        onChange={handleChange("postalCode")}
                        onBlur={handleBlur("postalCode")}
                      />
                      <ErrorMessage
                        name="postalCode"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="services"
                      >
                        Services
                      </label>
                      <Input
                        type="text"
                        id="services"
                        autoComplete="disable"
                        placeholder="Services"
                        value={values.service}
                        onChange={handleChange("service")}
                        onBlur={handleBlur("service")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="contactPersonName"
                      >
                        Contact Person Name<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.contactPersonName &&
                            touched.contactPersonName
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        name="contactPersonName"
                        id="contactPersonName"
                        placeholder="Contact Person Name"
                        value={values.contactPersonName}
                        onChange={handleChange("contactPersonName")}
                        onBlur={handleBlur("contactPersonName")}
                      />
                      <ErrorMessage
                        name="contactPersonName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <label
                    className="modlabel modinpulabel"
                    htmlFor="contactPersonPhoneNo"
                  >
                    Contact Person Mobile
                    <span className="required"> *</span>
                  </label>
                </Row>
                <Row className="align-items-baseline">
                  <Col md={3}>
                    <FormGroup>
                      <Select
                        placeholder="Code"
                        className={`
                          ${touched.contactPersonPhoneCode &&
                            errors.contactPersonPhoneCode
                            ? "requireSelect"
                            : ""
                          }
                        `}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        options={props.dropdowns.dialCode?.dropDown}
                        // value={values.contactPersonMobileCode}
                        onChange={(option) =>
                          setFieldValue(
                            "contactPersonPhoneCode",
                            option?.value
                          )
                        }
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onBlur={handleBlur("contactPersonPhoneCode")}
                      />
                      {touched.contactPersonPhoneCode &&
                        errors.contactPersonPhoneCode ? (
                        <div className="validate">Required</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup>
                      <Field
                        autoComplete="disable"
                        id="contactPersonPhoneNo"
                        className={
                          "form-control" +
                          (errors.contactPersonPhoneNo &&
                            touched.contactPersonPhoneNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="contactPersonPhoneNo"
                        placeholder="Contact Person Mobile"
                        value={values.contactPersonPhoneNo}
                        onChange={handleChange("contactPersonPhoneNo")}
                        onBlur={handleBlur("contactPersonPhoneNo")}
                      />
                      <ErrorMessage
                        name="contactPersonPhoneNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="officeNo"
                      >
                        Office No
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.officeNo && touched.officeNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="officeNo"
                        id="officeNo"
                        placeholder="Office Number"
                        value={values.officeNo}
                        onChange={handleChange("officeNo")}
                        onBlur={handleBlur("officeNo")}
                      />
                      <ErrorMessage
                        name="officeNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="faxNo"
                      >
                        Fax No<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.faxNo && touched.faxNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="faxNo"
                        id="faxNo"
                        placeholder="Fax No"
                        value={values.faxNo}
                        onChange={handleChange("faxNo")}
                        onBlur={handleBlur("faxNo")}
                      />
                      <ErrorMessage
                        name="faxNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="website"
                      >
                        Website<span className="required"> *</span>
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.website && touched.website
                            ? " is-invalid "
                            : "")
                        }
                        autoComplete="disable"
                        type="website"
                        name="website"
                        id="website"
                        placeholder="Website"
                        value={values.website}
                        onChange={handleChange("website")}
                        onBlur={handleBlur("website")}
                      />
                      <ErrorMessage
                        name="website"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="email"
                      >
                        Email<span className="required"> *</span>
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.email && touched.email
                            ? " is-invalid "
                            : "")
                        }
                        autoComplete="disable"
                        type="userEmail"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="altEmail"
                      >
                        Alternate Email Id
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.altEmail && touched.altEmail
                            ? " is-invalid "
                            : "")
                        }
                        autoComplete="disable"
                        type="userEmail"
                        name="altEmail"
                        id="altEmail"
                        placeholder="Alternate Email Id"
                        value={values.altEmail}
                        onChange={handleChange("altEmail")}
                        onBlur={handleBlur("altEmail")}
                      />
                      <ErrorMessage
                        name="altEmail"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="px-1">
                  <Col md={12}>
                    <SetLogo setLogo={setLogo} />
                    {touched.logo && errors.logo && (
                      <div className="validate mb-3">
                        {errors.logo === "require"
                          ? "Required"
                          : errors.logo}
                      </div>
                    )}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    type="submit"
                    // @ts-expect-error
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading &&
                      <Spinner color="dark"
                        size="sm" className="mr-2" />
                    }
                    <>ADD</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDialCodeList: () => dispatch(getDialCode()),
  AddProjectCode: (data: AddProjectType) => dispatch(AddProjectMgmt(data)),
  getProjectListDD: () => dispatch(getProjectListDD()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
