import React from 'react';
//@ts-expect-error
import MyComponent from "react-fullpage-custom-loader";

const defaultProps = {
    sentences: [],
    loaderType: "ball-clip-rotate",
    // color: "#007ae1",
    color: "#eff1f5",
    fadeIn: true,
    startFadeOut: false,
};

const FullLoader = () => {
    return (
        <div className='full-loader'>
            <MyComponent {...defaultProps} />
        </div>
    )
}

export default FullLoader;