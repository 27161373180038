import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { RootState } from "../../../redux/reducers/rootReducer";
import FullPageLoaderModal from "../../Common/FullPageLoader/FullPageLoaderModal";
import ToggleButton from "../../Common/ToggleButton";
import DeviceStatusTab from "./tab/tab";
import { LayoutContext } from "../../../context/LayoutContext";
import { THEME_TYPE, deviceStatusDownloadColumns } from "../../../constants";
import { endLoading, startLoading } from "../../../redux/reducers/general/general.actions";
import { AppDispatch } from "../../../redux/store/store";
import { getDeviceStatus } from "../../../redux/reducers/DataReducer/data.actions";
import { CSVLink } from "react-csv";
import { convertTimestampToDate } from "../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function DeviceStatus(props: Props) {
  const [online, setOnline] = useState(false);
  const { selectedTheme, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage]: $TSFixMe = useState(10);
  const [selectedPage, setSelectedPage]: $TSFixMe = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isAsc, setIsAsc]: $TSFixMe = useState(false);
  const [sortColumn, setSortColumn]: $TSFixMe = useState("");

  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      latestLoginDate: convertTimestampToDate(props.user.timeZone.zoneId, data?.login?.timestamp, null, props.user.timeFormat),
      latestGpsDate: convertTimestampToDate(props.user.timeZone.zoneId, data?.gps?.timestamp, null, props.user.timeFormat),
      CAMERA_1: "online",
      CAMERA_2: data?.CAMERA_2?.status ? "online" : "offline",
      CAMERA_3: data?.CAMERA_3?.status ? "online" : "offline",
      CAMERA_4: data?.CAMERA_4?.status ? "online" : "offline",
      CAMERA_5: data?.CAMERA_5?.status ? "online" : "offline",
    }));
  };

  const handleFilter = async () => {
    setIsLoading(true);
    try {
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: selectedProject.id,
        offline: online,
        page: {
          no: selectedPage,
          size: recordsPerPage
        },
      };
      await props.getDeviceStatus(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading && props.user.userId !== 0) {
      handleFilter();
    }
    return () => { }
  }, [props.user.userId, recordsPerPage, selectedPage, isAsc, sortColumn, online, selectedProject.id]);

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Device Status</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.deviceStatus &&
                    props.deviceStatus.result &&
                    props.deviceStatus.result.data.length ? (
                    <CSVLink
                      filename={"device-status-file.csv"}
                      data={preprocessCsvData(props?.deviceStatus?.result?.data)}
                      headers={deviceStatusDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Row>
                    <Col sm={12} md={1} className="toggleBtnStyl">
                      <div className="driverdetailsnum">Offline</div>
                    </Col>
                    <Col sm={12} md={1} className="tripsname pt-3">
                      <ToggleButton
                        checked={online}
                        onClick={(value: boolean) => {
                          setIsPageLoading(true);
                          setOnline(!online);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <CardBody className="tabAll">
                <DeviceStatusTab
                  setRecordsPerPage={setRecordsPerPage}
                  recordsPerPage={recordsPerPage}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  setSortColumn={setSortColumn}
                  setIsAsc={setIsAsc}
                  isPageLoading={isPageLoading}
                  handleFilter={handleFilter}
                  setIsPageLoading={setIsPageLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  deviceStatus: state.dataStore.deviceStatus,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceStatus: (data: $TSFixMe) => dispatch(getDeviceStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceStatus);