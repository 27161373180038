import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { THEME_TYPE, projectManagmentDownloadColumns } from "../../../../constants";
import { RootState } from "../../../../redux/reducers/rootReducer";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import ProjectManagementTab from "./tab";
import AddProject from "./tab/Components/add/index";
import { useContext, useState } from "react";
import { LayoutContext } from "../../../../context/LayoutContext";
import usePermission from "../../../../constants/usePermission";
import { useLocation } from "react-router-dom";

type Props = ReturnType<typeof mapStateToProps>;

function ProjectManagement(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const isProjectWrite = usePermission("PROJECT_MANAGEMENT_WRITE");
  const location = useLocation();
  const queryParams: $TSFixMe = new URLSearchParams(location.search);
  const [projectId, setProjectId]: $TSFixMe = useState(queryParams.get("id"));

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Project Management</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.projects &&
                    props.projects.result &&
                    props.projects.result.length ? (
                    <CSVLink
                      filename={"project-management-file.csv"}
                      data={props.projects.result}
                      headers={projectManagmentDownloadColumns}
                    >
                      <Button className={`${selectedTheme} mr-2`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {isProjectWrite &&
                    <AddProject />
                  }
                </Col>
              </Row>
              <CardBody className="tabAll">
                <ProjectManagementTab isProjectWrite={isProjectWrite} projectId={projectId} setProjectId={setProjectId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  projects: state.projectData.project_mgmt,
});

export default connect(mapStateToProps)(ProjectManagement);
