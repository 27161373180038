export const GET_DIAL_CODE = "GET_DIAL_CODE";
export const GET_DIAL_CODE_SUCCESS = "GET_DIAL_CODE_SUCCESS";
export const GET_DIAL_CODE_ERROR = "GET_DIAL_CODE_ERROR";

export const GET_PROJECT_LIST_FROM_ORG = "GET_PROJECT_LIST_FROM_ORG";
export const GET_PROJECT_LIST_FROM_ORG_SUCCESS =
  "GET_PROJECT_LIST_FROM_ORG_SUCCESS";
export const GET_PROJECT_LIST_FROM_ORG_ERROR = "GET_PROJECT_LIST_ERROR";

export const GET_DEVICE_LIST_FROM_PROJ = "GET_DEVICE_LIST_FROM_PROJ";
export const GET_DEVICE_LIST_FROM_PROJ_SUCCESS =
  "GET_DEVICE_LIST_FROM_PROJ_SUCCESS";
export const GET_DEVICE_LIST_FROM_PROJ_ERROR = "GET_DEVICE_LIST_ERROR";

export const GET_RESOURCE_ROLE_LIST = "GET_RESOURCE_ROLE_LIST";
export const GET_RESOURCE_ROLE_LIST_SUCCESS = "GET_RESOURCE_ROLE_LIST_SUCCESS";
export const GET_RESOURCE_ROLE_LIST_ERROR = "GET_RESOURCE_ROLE_LIST_ERROR";

export const GET_ORG_LIST = "GET_ORG_LIST";
export const GET_ORG_LIST_SUCCESS = "GET_ORG_LIST_SUCCESS";
export const GET_ORG_LIST_ERROR = "GET_ORG_LIST_ERROR";

export const GET_TIME_ZONE_LIST = "GET_TIME_ZONE_LIST";
export const GET_TIME_ZONE_LIST_SUCCESS = "GET_TIME_ZONE_LIST_SUCCESS";
export const GET_TIME_ZONE_LIST_ERROR = "GET_TIME_ZONE_LIST_ERROR";

export const GET_PROJECT_LIST_DD = "GET_PROJECT_LIST_DD";
export const GET_PROJECT_LIST_DD_SUCCESS = "GET_PROJECT_LIST_DD_SUCCESS";
export const GET_PROJECT_LIST_DD_ERROR = "GET_PROJECT_LIST_DD_ERROR";

export const GET_DEVICE_LIST_DD = "GET_DEVICE_LIST_DD";
export const GET_DEVICE_LIST_DD_SUCCESS = "GET_DEVICE_LIST_DD_SUCCESS";
export const GET_DEVICE_LIST_DD_ERROR = "GET_DEVICE_LIST_DD_ERROR";

export const GET_USER_ROLE_LIST = "GET_USER_ROLE_LIST";
export const GET_USER_ROLE_LIST_SUCCESS = "GET_USER_ROLE_LIST_SUCCESS";
export const GET_USER_ROLE_LIST_ERROR = "GET_USER_ROLE_LIST_ERROR";

export const GET_DRIVER_LIST_DD = "GET_DRIVER_LIST_DD";
export const GET_DRIVER_LIST_DD_SUCCESS = "GET_DRIVER_LIST_DD_SUCCESS";
export const GET_DRIVER_LIST_DD_ERROR = "GET_DRIVER_LIST_DD_ERROR";

export const GET_DEVICE_LIST_DD_VIDEO_REQUEST = "GET_DEVICE_LIST_DD_VIDEO_REQUEST";
export const GET_DEVICE_LIST_DD_VIDEO_REQUEST_SUCCESS = "GET_DEVICE_LIST_DD_VIDEO_REQUEST_SUCCESS";
export const GET_DEVICE_LIST_DD_VIDEO_REQUEST_ERROR = "GET_DEVICE_LIST_DD_VIDEO_REQUEST_ERROR";

export const GET_PROJECT_LIST_DD_VIDEO_REQUEST = "GET_PROJECT_LIST_DD_VIDEO_REQUEST";
export const GET_PROJECT_LIST_DD_VIDEO_REQUEST_SUCCESS = "GET_PROJECT_LIST_DD_VIDEO_REQUEST_SUCCESS";
export const GET_PROJECT_LIST_DD_VIDEO_REQUEST_ERROR = "GET_PROJECT_LIST_DD_VIDEO_REQUEST_ERROR";
