import { useEffect, useState } from "react";
import DataTable from "../../../../Common/DataTable";
import { connect } from "react-redux";
import { ProjectMgMtType } from "../../../../../../global.types";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { getProjectMgmt } from "../../../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import usePermission from "../../../../../constants/usePermission";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../routes/routes";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ProjectView from "./Components/projectView";
import ProjectEdit from "./Components/projectEdit";
import ProjectDelete from "./Components/projectDelete";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isProjectWrite: boolean;
    projectId: string;
    setProjectId: React.Dispatch<React.SetStateAction<any>>;
  };

const ProjectManagementTab = (props: Props) => {
  const [projectsList, setProjectsList] = useState<ProjectMgMtType[]>([]);
  const { isProjectWrite, projectId, setProjectId } = props;
  const history = useHistory();

  const ProjectActionBtns = (data: $TSFixMe) => {
    return (
      <div className="tabAction tabSet">
        {/* {isProjectWrite &&
          <Link to={ROUTES.COMMON_CONFIG} className="tabLink">
            <SettingsOutlinedIcon className="dropico" />
          </Link>
        } */}
        <ProjectView data={data.data} />
        {isProjectWrite && <ProjectEdit data={data.data} />}
        {isProjectWrite && <ProjectDelete data={data.data} />}
      </div>
    );
  };

  const projectColumns = [
    { title: "Project", data: "name" },
    { title: "Country", data: "country" },
    { title: "Devices", data: "totalDevice" },
    { title: "Contact Person", data: "contactPersonName" },
    { title: "Email", data: "email" },
    {
      title: "Action",
      format: (data: ProjectMgMtType) => <ProjectActionBtns data={data} />,
    },
  ];

  const apiCalls = async () => {
    try {
      props.startLoading();
      await props.getProject({ projectId });
    } catch (error) {
      console.log("error in project Tab api", error);
    } finally {
      await props.endLoading();
    }
    props.endLoading();
  };

  useEffect(() => {
    setProjectsList([...(props.projects.result ?? [])]);
    return () => { };
  }, [props.projects]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      setProjectId(null);
      apiCalls();
    }
    return () => { };
  }, [props.user.userId]);

  useEffect(() => {
    if (!projectId) {
      history.push("/project-management");
    }
    return () => { };
  }, [projectId]);

  return (
    <>
      <DataTable
        data={projectsList}
        columns={projectColumns}
        bordered
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  projects: state.projectData.project_mgmt,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getProject: (params: $TSFixMe) => dispatch(getProjectMgmt(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectManagementTab);
