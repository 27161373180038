import { useContext, useEffect, useState } from "react";
import DataTable from "../../../../Common/DataTable";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { getGeoFencing } from "../../../../../redux/reducers/Trips&GeoFence/geoFencing/geoFencing.actions";
import { AppDispatch } from "../../../../../redux/store/store";
import { GeoFencing } from "../../../../../../global.types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteGeoFence from "./Components/DeleteGeoFence";
import { GEOFENCE_TYPE, alarm } from "../../../../../constants";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { LayoutContext } from "../../../../../context/LayoutContext";

const GeoFencingTab = (props: $TSFixMe) => {
  const { selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [geoFencingList, setGeoFencingList] = useState<GeoFencing[]>([]);

  const GeoFencingActionBtns3 = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        {data.data && data.data.status ? <CheckIcon className="dropico" /> : <CloseIcon className="dropico" />}
      </div>
    );
  };

  const GeoFencingActionBtns2 = (data: $TSFixMe) => {
    return (
      <DeleteGeoFence data={data} />
    );
  };

  const geoFencingColumns = [
    { title: "Vehicle Reg#", data: "vehicleRegestrationNo" },
    // { title: "Start Time", data: "startTime" },
    // { title: "End Time", data: "endTime" },
    // { title: "From", data: "" },
    // { title: "To", data: "" },
    { title: "Device ID", data: "imei" },
    { title: "Organization", data: "organizationManagementName" },
    { title: "Project", data: "projectName" },
    { title: "Type", format: (data: $TSFixMe) => alarm[data.type - 1].label },
    { title: "Geofence Area", data: "radius" },
    { title: "Fence No", data: "index" },
    {
      title: "Status",
      format: (data: $TSFixMe) => <GeoFencingActionBtns3 data={data} />,
    },
    {
      title: "Geofence Type",
      format: (data: $TSFixMe) => GEOFENCE_TYPE[data.geofenceType],
    },
    {
      title: "Created Date",
      format: (data: $TSFixMe) => data.createdOn ? convertTimestampToDate(props.user?.timeZone?.zoneId, data.createdOn, null, props.user.timeFormat) : "N/A",
    },
    {
      title: "Location",
      format: (data: $TSFixMe) => (
        <div className="imptabbody">
          <LocationOnOutlinedIcon className="dropico" onClick={() => {
            props.setSelectedGeoFence(data);
            props.setMode("view");
            props.toggle();
          }} />
        </div>
      ),
    }
  ];

  if (props.isGeoFencingWrite) {
    geoFencingColumns.push({
      title: "Action",
      format: (data: $TSFixMe) => <GeoFencingActionBtns2 data={data} />,
    },)
  }

  const apiCalls = async () => {
    try {
      props.startLoading();
      await props.getGeoFencing(selectedProject.id);
    } catch (error) {
      console.log("error in GeoFencingTab", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    if (Object.keys(props.geoFencing).length && props.geoFencing.result) {
      setGeoFencingList(props.geoFencing.result);
    }
    return () => { };
  }, [props.geoFencing]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCalls();
    }
    return () => { };
  }, [props.user.userId, selectedProject.id]);

  return (
    <>
      <DataTable
        data={geoFencingList}
        columns={geoFencingColumns}
        bordered
        hover={true}
        responsive={true}
      />
    </>
  );
};

//export default GeoFencingTab;
const mapStateToProps = (state: RootState) => ({
  geoFencing: state.geoFencingData.geoFencing,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getGeoFencing: (id: number) => dispatch(getGeoFencing(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeoFencingTab);
