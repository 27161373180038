import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { ProjectMgMtType } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import SetLogo from "./setLogo";
import Select from "react-select";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> & {
  data: ProjectMgMtType;
};
const View = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <span className="tableModalBtn">
        <VisibilityOutlinedIcon onClick={toggle} className="dropico" />
      </span>
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <div className="modlabel modheadcont mb-0">View Project</div>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto'
        }} className="scr">
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Project Name</div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Project Name"
                  value={props.data.name}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">Project Address</div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Project Address"
                  value={props.data.address}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel" >
                  City
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="City"
                  value={props.data.city}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  State
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="State"
                  value={props.data.state}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Country
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Country"
                  value={props.data.country}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Postal Code
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Postal Code"
                  value={props.data.postalcode}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                <div className="modalheader modinpulabel" htmlFor="services">
                  Services
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Services"
                  value={props.data.services}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Contact Person Name
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Contact Person Name"
                  value={props.data.contactPersonName}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup className="dropinput">
                <div className="modlabel modinpulabel">
                  Code
                </div>
                <Select
                  placeholder="Code"
                  isDisabled
                  value={{
                    label: props.data.contactPersonPhoneCode,
                    value: props.data.contactPersonPhoneCode,
                  }}
                  classNamePrefix={"vd-select-dropdown"}
                  options={props.dropdowns.dialCode?.dropDown}
                />
              </FormGroup>
            </Col>
            <Col md={9}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  <br />
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Contact Person Mobile"
                  value={props.data.contactPersonPhoneNo}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Office No
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Office No"
                  value={props.data.officeNo}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Fax No
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Fax No"
                  value={props.data.faxNo}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Website
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Website"
                  value={props.data.website}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="dropinput">
                <div className="modalheader modinpulabel">
                  Email
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Email"
                  value={props.data.email}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="dropinput">
                {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message */}
                <div className="modalheader modinpulabel" htmlFor="Alternate Contact">
                  Alternate Email Id
                </div>
                <Input
                  className="disableField"
                  disabled
                  placeholder="Alternate Email Id"
                  value={props.data.altEmail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="px-1">
            <Col md={12}>
              <SetLogo logo={props.data.logo} />
              {props.data.logo &&
                <div>
                  <Label className="modlabel modinpulabel f-13 mb-2">
                    Header Large Logo
                  </Label>
                  <div className="logo-large">
                    <img src={props.data.leaderLogoLarge} alt="leaderLogoLarge" />
                  </div>
                  <Label className="modlabel modinpulabel f-13 mb-2 mt-3">
                    Header Small Logo
                  </Label>
                  <div className="logo-small">
                    <img src={props.data.leaderLogoSmall} alt="leaderLogoSmall" />
                  </div>
                  <Label className="modlabel modinpulabel f-13 mb-2 mt-3">
                    Footer Large Logo
                  </Label>
                  <div className="logo-large">
                    <img src={props.data.footerLogoLarge} alt="footerLogoLarge" />
                  </div>
                  <Label className="modlabel modinpulabel f-13 mb-2 mt-3">
                    Footer Small Logo
                  </Label>
                  <div className="logo-small">
                    <img src={props.data.footerLogoSmall} alt="footerLogoSmall" />
                  </div>
                </div>
              }
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="assi footer-width py-3">
          <Button className="popbtn vie datewidth btnBox" onClick={toggle}>
            <>CLOSE</>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
});

export default connect(mapStateToProps)(View);
