import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { dataHours } from "../../../../../../../constants";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

const HoursPerDay = (props: $TSFixMe) => {
  const { selectedTheme } = useContext(LayoutContext);
  const data = props?.hourPerDay ? props?.hourPerDay.map((h: $TSFixMe) => Number(h.totalevent)) : [];
  const { series, options } = dataHours(selectedTheme, data);

  return (
    <div id="chart">
      <ReactApexChart
        series={series}
        // @ts-expect-error
        options={options}
        type="bar"
        height={265}
      />
      <div className={`${selectedTheme} d-flex align-items-center justify-content-center`}>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>92.52 Hrs</p>
            <p className='heading mb-0'>
              Driving Time
            </p>
          </div>
        </div>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>0.01 Hrs</p>
            <p className='heading mb-0'>Idling Time</p>
          </div>
        </div>
        <div className="mini-data-card">
          <div className='inner-card'>
            <p className='data-text mb-1'>0.00 Hrs</p>
            <p className='heading mb-0'>Stopped Time</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HoursPerDay;
