export const GET_COMMON_CONFIGURATION = "GET_COMMON_CONFIGURATION";
export const GET_COMMON_CONFIGURATION_SUCCESS = "GET_COMMON_CONFIGURATION_SUCCESS";
export const GET_COMMON_CONFIGURATION_ERROR = "GET_COMMON_CONFIGURATION_ERROR";

export const GET_EDIT_COMMON_CONFIGURATION = "GET_EDIT_COMMON_CONFIGURATION";
export const GET_EDIT_COMMON_CONFIGURATION_SUCCESS = "GET_EDIT_COMMON_CONFIGURATION_SUCCESS";
export const GET_EDIT_COMMON_CONFIGURATION_ERROR = "GET_EDIT_COMMON_CONFIGURATION_ERROR";

export const GET_EDIT_COMMON_CONFIGURATION_IMEI = "GET_EDIT_COMMON_CONFIGURATION_IMEI";
export const GET_EDIT_COMMON_CONFIGURATION_IMEI_SUCCESS = "GET_EDIT_COMMON_CONFIGURATION_IMEI_SUCCESS";
export const GET_EDIT_COMMON_CONFIGURATION_IMEI_ERROR = "GET_EDIT_COMMON_CONFIGURATION_IMEI_ERROR";

export const ADD_COMMON_CONFIGURATION = "ADD_COMMON_CONFIGURATION";
export const ADD_COMMON_CONFIGURATION_SUCCESS = "ADD_COMMON_CONFIGURATION_SUCCESS";
export const ADD_COMMON_CONFIGURATION_ERROR = "ADD_COMMON_CONFIGURATION_ERROR";

export const EDIT_COMMON_CONFIGURATION = "EDIT_COMMON_CONFIGURATION";
export const EDIT_COMMON_CONFIGURATION_SUCCESS = "EDIT_COMMON_CONFIGURATION_SUCCESS";
export const EDIT_COMMON_CONFIGURATION_ERROR = "EDIT_COMMON_CONFIGURATION_ERROR";

export const DELETE_COMMON_CONFIGURATION = "DELETE_COMMON_CONFIGURATION";
export const DELETE_COMMON_CONFIGURATION_SUCCESS = "DELETE_COMMON_CONFIGURATION_SUCCESS";
export const DELETE_COMMON_CONFIGURATION_ERROR = "DELETE_COMMON_CONFIGURATION_ERROR";
