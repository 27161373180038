import { GeoFencingResp } from "../../../../../global.types";
import { AppActions } from "../../../store/globalstore";
import {
  ADD_GEO_FENCING_SUCCESS,
  DELETE_GEO_FENCING_SUCCESS,
  GET_GEO_FENCING_SUCCESS,
  STATUS_GEO_FENCING_SUCCESS,
} from "./geoFencing.types";

interface InitialState {
  geoFencing: Partial<GeoFencingResp>;
}

const initialState: InitialState = {
  geoFencing: {},
};

export const geoFencingReducer = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_GEO_FENCING_SUCCESS:
      return {
        ...state,
        geoFencing: action.payload,
      };
    case ADD_GEO_FENCING_SUCCESS:
      return {
        ...state,
        geoFencing: {
          ...state.geoFencing,
          result: [...(state.geoFencing.result ?? []), action.payload.result],
        },
      };
    case DELETE_GEO_FENCING_SUCCESS:
      return {
        ...state,
        geoFencing: {
          ...state.geoFencing,
          result: state.geoFencing.result?.filter((item) => item.id !== action.id),
        },
      };
    case STATUS_GEO_FENCING_SUCCESS:
      let tmpArray = { ...state.geoFencing };
      if (tmpArray?.result?.length) {
        for (let i = 0; i < tmpArray.result.length; i++) {
          if (tmpArray.result[i].id === action.payload.result.id) {
            tmpArray.result[i] = action.payload.result;
            break;
          }
        }
      }
      return {
        ...state,
        geoFencing: tmpArray,
      };
    default:
      return state;
  }
};
