import { LiveTrackingResp, MapMonitoringResObj } from "../../../../global.types";
import { GET_LIVE_TRACKING, GET_MAP_MONITORING, GET_VEHICLE_JOURNEY, RESET_MAP_DATA } from "./map.types";

export const getMapMonitoring = (data: MapMonitoringResObj) => {
  return {
    type: GET_MAP_MONITORING as typeof GET_MAP_MONITORING,
    data,
    meta: { thunk: true },
  };
};

export const getLiveTracking = (data: LiveTrackingResp) => {
  return {
    type: GET_LIVE_TRACKING as typeof GET_LIVE_TRACKING,
    data,
    meta: { thunk: true },
  };
};

export const getVehicleJourney = (data: any) => {
  return {
    type: GET_VEHICLE_JOURNEY as typeof GET_VEHICLE_JOURNEY,
    data,
    meta: { thunk: true },
  };
};

export const resetMapData = () => {
  return {
    type: RESET_MAP_DATA as typeof RESET_MAP_DATA,
  };
};
