import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { getUsers } from "../../../../../redux/reducers/Management&Settings/usersManagement/usersManagement.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable";
import Delete from "./Components/delete";
import Edit from "./Components/edit";
import View from "./Components/view";
import { getUserRoleListDD } from "../../../../../redux/reducers/dropdowns/dropdown.actions";
import usePermission from "../../../../../constants/usePermission";
import { Tooltip } from "antd";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UsersManagementTab = (props: Props) => {
  const [users, setUsers] = useState([]);
  const isUserWrite = usePermission("USERS_MANAGEMENT_WRITE");

  const ActionBtns = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        <View data={data.data} />
        {isUserWrite && <Edit data={data.data} />}
        {isUserWrite && <Delete data={data.data} />}
      </div>
    );
  };

  const formatProjects = (data: $TSFixMe) => {
    const organizations = data && data.projectNames && data.projectNames.split(",");

    if (organizations && organizations.length > 2) {
      // Display ellipses (...) on hover
      return (
        <Tooltip title={data.projectNames} placement={"bottom"}>
          <span>{organizations.slice(0, 2).join(", ")} ...</span>
        </Tooltip>
      );
    } else {
      return organizations ? organizations.join(", ") : "";
    }
  };

  const apiCalls = async () => {
    try {
      props.startLoading();
      props.getUsers();
      props.getUserRoleListDD();
    } catch (error) {
      console.log("error in CtAdminTab api", error);
    } finally {
      await props.endLoading();
    }
  };

  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const columns = useMemo(() => [
    { title: "UserName", data: "name" },
    { title: "Email", data: "email" },
    { title: "Mobile", data: "mobileNumber" },
    { title: "Project", data: "projectNames", sorting: false, format: (data: $TSFixMe) => formatProjects(data) },
    { title: "Role", data: "role.name" },
    { title: "Status", format: (data: $TSFixMe) => <>{data.status + ""}</> },
    {
      title: "Action",
      format: (data: $TSFixMe) => <ActionBtns data={data} />,
    },
  ]);


  useEffect(() => {
    //@ts-expect-error
    setUsers([...(props.users.result ?? [])]);
    return () => { };
  }, [props.users]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCalls();
    }
    return () => { };
  }, [props.user.userId]);

  return (
    <>
      <DataTable
        data={users}
        columns={columns}
        bordered
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.userData.users,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getUsers: () => dispatch(getUsers()),
  getUserRoleListDD: () => dispatch(getUserRoleListDD()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersManagementTab);
