import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting'; // Import the exporting module
import { useContext, useEffect, useRef } from "react";

import { THEME_TYPE } from '../../../../../../../../../constants';
import { LayoutContext } from '../../../../../../../../../context/LayoutContext';
import COLORS from "../../../../../../../../../colors";

exportingInit(Highcharts); // Initialize the exporting module


function VideoGraph(props: $TSFixMe) {
  const { gyroInfo } = props
  const { selectedTheme } = useContext(LayoutContext);

  const xAxisArray = gyroInfo?.map((obj: $TSFixMe) => obj.Xaxis);
  const yAxisArray = gyroInfo?.map((obj: $TSFixMe) => obj.Yaxis);
  const zAxisArray = gyroInfo?.map((obj: $TSFixMe) => obj.Zaxis);

  const chartRef: $TSFixMe = useRef(null);

  var videoGraphOptions = (selectedTheme: any, gyroInfo: any) => {

    const videoGraph: any = {
      chart: {
        height: 250,
        type: "spline",
        backgroundColor:
          (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
            selectedTheme === THEME_TYPE.CLARET_BLUE) &&
          COLORS.veryDarkDesaturatedBlue,
        events: {
          load() {
            setTimeout((this as $TSFixMe).reflow.bind(this), 0);
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            symbolStroke: COLORS.mediumDarkShadeBlue,
            theme: {
              // Define your custom color scheme here
              fill: COLORS.veryDarkDesaturatedBlue, // Background color of the button
              stroke: COLORS.mediumDarkShadeBlue, // Border color of the button
              states: {
                hover: {
                  fill: COLORS.mischka, // Background color on hover
                  stroke: COLORS.mediumDarkShadeBlue, // Border color on hover
                },
              },
            },
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              {
                text: 'Download CSV',
                onclick: function (this: Highcharts.Chart) {
                  const columnNames = 'Timestamp,Ax,Ay,Az\n'; // Assuming Time is the x-axis category
                  const csv = columnNames + gyroInfo.map((point: $TSFixMe, i: number) => {
                    return `${point.timestamp},${point.Xaxis},${point.Yaxis},${point.Zaxis}`;
                  }).join('\n');
                  const blob = new Blob([csv], { type: 'text/csv' });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'chart-data.csv';
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  URL.revokeObjectURL(url);
                }
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [COLORS.vivideRed, COLORS.blueRibbon, COLORS.sushiBlue],
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        title: {
          text: "Time",
          style: {
            color:
              (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
                selectedTheme === THEME_TYPE.CLARET_BLUE) &&
              COLORS.white,
          },
        },
        labels: {
          enabled: false,
          color:
            (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
              selectedTheme === THEME_TYPE.CLARET_BLUE) &&
            COLORS.white,
        },
      },
      yAxis: {
        title: {
          text: "m/s²",
          style: {
            color:
              (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
                selectedTheme === THEME_TYPE.CLARET_BLUE) &&
              COLORS.white,
          },
        },
        labels: {
          enabled: true,
          style: {
            color:
              (selectedTheme === THEME_TYPE.BLACK_PINK_PURPLE ||
                selectedTheme === THEME_TYPE.CLARET_BLUE) &&
              COLORS.white,
          },
        },
      },
      series: [
        {
          name: "Ax",
          data: xAxisArray,
        },
        {
          name: "Ay",
          data: yAxisArray,
        },
        {
          name: "Az",
          data: zAxisArray,
        },
      ],
      plotOptions: {
        series: {
          allowPointSelect: true,
          point: {
            events: {
              click: function () { },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        y: [
          {
            title: {
              formatter: function (val: $TSFixMe) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val: $TSFixMe) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val: $TSFixMe) {
                return val;
              },
            },
          },
        ],
      },
    };
    return videoGraph;
  };


  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;

      const seriesAx = chart.series.find((s: $TSFixMe) => s.name === "Ax");
      const seriesAy = chart.series.find((s: $TSFixMe) => s.name === "Ay");
      const seriesAz = chart.series.find((s: $TSFixMe) => s.name === "Az");

      // Find the point closest to the current video time for each series
      const pointAx = seriesAx ? seriesAx.data.find((point: $TSFixMe) => Math.abs(point.x - props.videoTime) < 1) : null;
      const pointAy = seriesAy ? seriesAy.data.find((point: $TSFixMe) => Math.abs(point.x - props.videoTime) < 1) : null;
      const pointAz = seriesAz ? seriesAz.data.find((point: $TSFixMe) => Math.abs(point.x - props.videoTime) < 1) : null;

      if (pointAx || pointAy || pointAz) {
        // Calculate the index of the point based on the video time and the data length
        const index = Math.floor((gyroInfo.length - 1) * (props.videoTime / props.videoTotalTime));

        // Update tooltip position if index is valid
        if (index >= 0 && index < gyroInfo.length) {
          chart.tooltip.refresh([seriesAx.data[index], seriesAy.data[index], seriesAz.data[index]].filter(Boolean));
        }
      }
    }
  }, [chartRef, props.videoTime, props.videoTotalTime, gyroInfo]);


  return (
    <div className="vidchart tabAll">
      {gyroInfo && gyroInfo.length > 0 ?
        <HighchartsReact
          className="tabAll"
          highcharts={Highcharts}
          options={videoGraphOptions(selectedTheme, gyroInfo)}
          type="spline"
          ref={chartRef}
        />
        :
        <div className="highcharts-container d-flex align-items-center justify-content-center">
          <div className="text-gray">No data found...</div>
        </div>
      }
    </div>
  );
}

export default VideoGraph;
