import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ProjectMgMtType } from "../../../../../../../../global.types";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { DeleteProjectMgmt } from "../../../../../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { getProjectListDD } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import { useContext } from "react";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: ProjectMgMtType;
  };

const Delete = (props: Props) => {
  const { setSelectedProject, selectedProject }: $TSFixMe = useContext(LayoutContext);

  async function deletePost() {
    if (window.confirm("Are you sure to delete this record?")) {
      try {
        props.startLoading();
        if (props.data.id) {
          await toast.promise(props.deleteProject(props.data.id) as any, {
            success: TOAST_MSG.DELETED
          });
          const res: $TSFixMe = await props.getProjectListDD();
          if (res.status >= 200 && selectedProject.id === props.data.id) {
            setSelectedProject(res.result[0]);
            localStorage.setItem("selectedProjectData", JSON.stringify(res.result[0]));
          }
        }
      } catch (error) {
        console.log("Delete Project error", error);
      } finally {
        await props.endLoading();
      }
    }
  }

  return (
    <>
      <DeleteOutlineOutlinedIcon className="dropico" onClick={deletePost} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  deleteProject: (id: number) => dispatch(DeleteProjectMgmt(id)),
  getProjectListDD: () => dispatch(getProjectListDD()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
