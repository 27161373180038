import "./ToggleButton.css";

function ToggleButton({
  onClick,
  checked
}: $TSFixMe) {
  const handleToggle = (e: $TSFixMe) => {
    onClick(!checked);
  };
  return (
    <div className="form-group ToggleBtn DeviceStatus EditStatus">
      <div className="toggle-button-cover">
        <div className="button-cover">
          <div className="button r" id="button-2">
            <input
              type="checkbox"
              onChange={handleToggle}
              className="checkbox"
              checked={!checked}
            />
            <div className="knobs" />
            <div className="layer" />
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}
export default ToggleButton;
