import React from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import { measurement } from "../../../../../../constants";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { calculateTotalDrivenTime, convertKilometerToMile, secondToTimeFormat } from "../../../../../../services/common.functions.services";

function TripsDetailButton(props: $TSFixMe) {
  const { selectedTheme, tripData, user } = props;

  const tripsDetails = [
    {
      classHead: "tabAll",
      icnClass: " tripsDetailCardblue scoreCardHead driverSvg",
      icon: <ChatBubbleOutlineOutlinedIcon className="tripsDetailCardIcon " />,
      num: <>{(tripData?.startTime && tripData?.endTime) ? calculateTotalDrivenTime(tripData?.startTime, tripData?.endTime) : 0}</>,
      tex: "Duration",
    },
    {
      classHead: "tabAll px-2",
      icnClass: " tripsDetailCardred scoreCardHead driverSvg",
      icon: <HelpOutlineOutlinedIcon className="tripsDetailCardIcon " />,
      num: <>{tripData?.totalDistance ? user.measurmentUnit === measurement[1].value ? convertKilometerToMile(tripData?.totalDistance) : tripData?.totalDistance.toFixed(2) : 0} {`${user.measurmentUnit === measurement[1].value ? "ml" : "km"}`}</>,
      tex: "Distance",
    },
    {
      classHead: "tabAll pe-2",
      icnClass: " tripsDetailCardyellow scoreCardHead driverSvg",
      icon: <EqualizerRoundedIcon className="tripsDetailCardIcon " />,
      num: <>{tripData?.idealTime ? secondToTimeFormat(tripData?.idealTime) : 0}</>,
      tex: "Idle Time",
    },
    {
      classHead: "tabAll",
      icnClass: " tripsDetailCardgreen scoreCardHead driverSvg",
      icon: <DateRangeOutlinedIcon className="tripsDetailCardIcon" />,
      num: <>{(tripData?.highEventCount && tripData?.lowEventCount) ? (tripData?.highEventCount + tripData?.lowEventCount) : 0}</>,
      tex: "Harsh Events",
    },
  ];

  return (
    <Row className="mx-0">
      {tripsDetails.map((item, index) => (
        <div key={index} className={`col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 ${item.classHead}`}>
          <div className={`bg-card-${index} trip-card-box`}>
            <div className="pl-3 pt-3 pr-3 pt-0">
              <div className="card-label">
                {item.icon} {item.tex}
              </div>
              <div className="card-num">
                {item.num}
              </div>
            </div>
            <span>
              <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" className="path-0 transition duration-300 ease-in-out delay-150">
                <path d="M 0,400 L 0,150 C 29.80169926330624,135.2813326732782 59.60339852661248,120.56266534655641 91,119 C 122.39660147338752,117.43733465344359 155.3881051568563,129.03067128705257 181,149 C 206.6118948431437,168.96932871294743 224.84418084596223,197.31464950523335 248,208 C 271.1558191540378,218.68535049476665 299.2351714592948,211.71073069201407 333,209 C 366.7648285407052,206.28926930798593 406.21513331685827,207.84242772671047 435,188 C 463.78486668314173,168.15757227328953 481.90429527327217,126.919558401144 503,130 C 524.0957047267278,133.080441598856 548.1676855900531,180.47933866871347 577,205 C 605.8323144099469,229.52066133128653 639.4249623665154,231.16308692400204 674,213 C 708.5750376334846,194.83691307599796 744.1324649438851,156.8683136352783 770,146 C 795.8675350561149,135.1316863647217 812.0451778579439,151.36365853488476 839,169 C 865.9548221420561,186.63634146511524 903.6868236243392,205.6770522251826 935,184 C 966.3131763756608,162.3229477748174 991.2075276446997,99.92813256438482 1021,83 C 1050.7924723553003,66.07186743561518 1085.4830657968623,94.61041751727811 1113,122 C 1140.5169342031377,149.3895824827219 1160.860209167851,175.63019736650276 1182,177 C 1203.139790832149,178.36980263349724 1225.0760975317323,154.8687930167108 1258,151 C 1290.9239024682677,147.1312069832892 1334.8354007052192,162.89463056665403 1367,166 C 1399.1645992947808,169.10536943334597 1419.5822996473903,159.55268471667299 1440,150 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="rgb(255 255 255 / 35%)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path>
              </svg>
            </span>
          </div>
        </div>
      ))}
    </Row>
  );
}

export default TripsDetailButton;
