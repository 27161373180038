import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../../../../api/api.constants";
import { del, get, patch, post } from "../../../../services/axios.services";
import { onError, onSuccess, safe } from "../../../store/saga/sagaHelper";
import { ADD_GEO_FENCING, DELETE_GEO_FENCING, GET_GEO_FENCING, STATUS_GEO_FENCING } from "./geoFencing.types";
import { DeleteGeoFencingSuccess } from "./geoFencing.actions";

function* getGeoFencing({ id, meta }: $TSFixMe) {
  //@ts-expect-error
  const response = yield call(get, `${API.GEO_FENCING}/project/${id}`);
  return response;
}

function* AddGeoFencingSaga({ data, meta }: $TSFixMe) {
  //@ts-expect-error
  const response = yield call(post, API.GEO_FENCING, data);
  return response;
}

function* deleteGeoFencing({ id, meta }: $TSFixMe) {
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(del, `${API.GEO_FENCING}/${id}`);
  yield put(DeleteGeoFencingSuccess({ id, meta }));
}

function* changeGeoFencingStatus({ id, data, meta }: $TSFixMe) {
  const config = {
    params: { ...data }
  }
  // @ts-expect-error TS(7057): 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(patch, `${API.GEO_FENCING}/${id}`, null, config);
  return response;
}

function* geoFencingSaga() {
  yield takeLatest(GET_GEO_FENCING, safe(onError, getGeoFencing, onSuccess));
  yield takeLatest(DELETE_GEO_FENCING, safe(onError, deleteGeoFencing));
  yield takeLatest(STATUS_GEO_FENCING, safe(onError, changeGeoFencingStatus, onSuccess));
  yield takeLatest(
    ADD_GEO_FENCING,
    safe(onError, AddGeoFencingSaga, onSuccess)
  );
}
export default geoFencingSaga;
