import { useState, useEffect } from "react";
import DataTable from "../../../../../../../Common/DataTable";
import { connect } from "react-redux";
import { getCommonConfigurations } from "../../../../../../../../redux/reducers/Management&Settings/commonConfiguration/commonConfiguration.actions";
import {
  endLoading,
  startLoading,
} from "../../../../../../../../redux/reducers/general/general.actions";
import Edit from "./Components/edit";
import View from "./Components/view";
import Delete from "./Components/delete";
import { RootState } from "../../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../../redux/store/store";
import { CommonConfiguration } from "../../../../../../../../../global.types";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { convertTimestampToDate } from "../../../../../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    isWritable: boolean;
  };

const CommonConfigurationTab = (props: Props) => {
  const [commonConfigurationsList, setCommonConfigurationsList] = useState<
    CommonConfiguration[]
  >([]);
  const { isWritable } = props;
  const CommonConfigurationActionBtns = ({ data }: { data: $TSFixMe }) => {
    return (
      <div className="imptabbody">
        <span className="tableModalBtn">
          <Link
            to={{
              pathname: "/add-configuration",
              search: `?mode=view&id=${data.id}`,
            }}
          >
            <VisibilityOutlinedIcon className="dropico" />
          </Link>
        </span>
        {isWritable && (
          <span className="tableModalBtn">
            <Link
              to={{
                pathname: "/add-configuration",
                // state: { mode: "edit", data: data.id  },
                search: `?mode=edit&id=${data.id}`,
              }}
            >
              <EditOutlinedIcon className="dropico" />
            </Link>
          </span>
        )}
        {/* <CommonConfigurationEdit data={data} /> */}
        {isWritable && <Delete data={data} />}
      </div>
    );
  };

  useEffect(() => {
    apiCalls();
    return () => { };
  }, []);

  const apiCalls = async () => {
    try {
      props.startLoading();
      await props.getCommonConfigurations();
    } catch (error) {
      console.log("error in CommonConfigurationsTab", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    if (props.commonConfigurations?.result) {
      setCommonConfigurationsList([...props.commonConfigurations.result]);
    }
    return () => { };
  }, [props.commonConfigurations]);

  const columns = [
    { title: "Template Name", data: "name" },
    {
      title: "Created Date", format: (data: $TSFixMe) => (
        <>
          {data.createdOn ? convertTimestampToDate(props.user.timeZone.zoneId, data.createdOn, null, props.user.timeFormat) :
            ""}
        </>
      ),
    },
    {
      title: "Status",
      format: (data: $TSFixMe) => <>{data.status ? "Active" : "Inactive"}</>,
    },
    {
      title: "Action",
      format: (data: $TSFixMe) => <CommonConfigurationActionBtns data={data} />,
    },
    // {
    //   title: "Action",
    //   format: (data: $TSFixMe) => <ActionBtns data={data} />,
    // },
  ];

  return (
    <>
      <DataTable
        data={commonConfigurationsList}
        columns={columns}
        bordered
        hover={true}
        responsive={true}
      />
    </>
  );
};

//export default CommonConfigurationTab;
const mapStateToProps = (state: RootState) => ({
  commonConfigurations: state.commonConfigurationsData.commonConfigurations,
  user: state.userSlice.user
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getCommonConfigurations: () => dispatch(getCommonConfigurations()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonConfigurationTab);
