import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
// import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import LaptopMacOutlinedIcon from "@material-ui/icons/LaptopMacOutlined";
import PersonOutlinOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
// import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import GpsFixedOutlinedIcon from "@material-ui/icons/GpsFixedOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { RootState } from "../redux/reducers/rootReducer";

import { Link } from "react-router-dom";
import { ROUTES } from "../routes/routes";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Collapse,
} from "@material-ui/core";
import { connect } from "react-redux";
import { EVENTS_VIDEOS, MANAGEMENT_SETTINGS, MAP, REPORTS, THEME_TYPE, TRIPS_GEOFENCE } from ".";
import ShowPermission from "./showPermission";

export const SIDE_BAR_ITEMS = [
  {
    name: "Dashboard",
    state: "dashboard",
    icon: <HomeOutlinedIcon />,
    pathname: ROUTES.DASHBOARD,
    permissions: "ALL",
  },
  {
    name: "Events & Videos",
    icon: <CalendarTodayOutlinedIcon />,
    state: "events",
    permissions: EVENTS_VIDEOS,
    children: [
      // {
      //   name: "SA-AS Events",
      //   icon: <ArrowForwardIcon />,
      //   pathname: ROUTES.SA_AS_EVENT,
      //   state: "events",
      //   isSubMenu: true,
      //   permissions: "ALL",
      // },
      {
        name: "High Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.HIGH_EVENT,
        state: "events",
        isSubMenu: true,
        permissions: ["HIGH_EVENTS_READ", "HIGH_EVENTS_WRITE"],
      },
      {
        name: "Low Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.LOW_EVENT,
        state: "events",
        isSubMenu: true,
        permissions: ["LOW_EVENTS_READ", "LOW_EVENTS_WRITE"],
      },
      {
        name: "Requested Video",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.REQUESTED_VIDEO,
        state: "events",
        isSubMenu: true,
        permissions: ["REQUEST_VIDEO_READ", "REQUEST_VIDEO_WRITE"],
      },
      {
        name: "Geo-Fence Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.GEO_FENCE_EVENT,
        state: "events",
        isSubMenu: true,
        permissions: ["GEOFENCING_EVENT_READ", "GEOFENCING_EVENT_WRITE"],
      },
      {
        name: "Tamper Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.TAMPER_EVENTS,
        state: "events",
        isSubMenu: true,
        permissions: ["TAMPER_EVENTS_READ", "TAMPER_EVENTS_WRITE"],
      },
      {
        name: "Panic Button Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.PANIC_BUTTON_EVENT,
        state: "events",
        isSubMenu: true,
        permissions: ["PANIC_BUTTON_VIDEO_READ", "PANIC_BUTTON_VIDEO_WRITE"],
      },
      {
        name: "Active Safety Events",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.ACTIVE_SAFETY_EVENTS,
        state: "events",
        isSubMenu: true,
        permissions: ["ACTIVE_SAFATY_EVENT_READ", "ACTIVE_SAFATY_EVENT_WRITE"],
      },
    ],
  },
  {
    name: "Trips & Geo-fence",
    icon: <GpsFixedOutlinedIcon />,
    state: "tripsAndGeoFence",
    permissions: TRIPS_GEOFENCE,
    children: [
      {
        name: "Vehicle Trip",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.VEHICLE_TRIP,
        state: "tripsAndGeoFence",
        isSubMenu: true,
        permissions: ["VEHICLE_TRIP_READ", "VEHICLE_TRIP_WRITE"],
      },
      {
        name: "Geo Fencing",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.GEO_FENCING,
        state: "tripsAndGeoFence",
        isSubMenu: true,
        permissions: ["GEOFENCING_READ", "GEOFENCING_WRITE"],
      },
    ],
  },
  {
    name: "Maps",
    state: "maps",
    icon: <MapOutlinedIcon />,
    pathname: ROUTES.MAPS,
    permissions: MAP,
  },
  {
    name: "Reports",
    icon: <DescriptionOutlinedIcon />,
    state: "reports",
    permissions: REPORTS,
    children: [
      {
        name: "Location Report",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.LOCATION_REPORT,
        state: "reports",
        isSubMenu: true,
        permissions: ["LOCATION_REPORT_READ", "LOCATION_REPORT_WRITE"],
      },
      {
        name: "Event Report",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.EVENTS_REPORT,
        state: "reports",
        isSubMenu: true,
        permissions: ["EVENT_REPORT_READ", "EVENT_REPORT_WRITE"],
      },
      {
        name: "Video Request Report",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.VIDEO_REQUEST_REPORT,
        state: "reports",
        isSubMenu: true,
        permissions: [
          "VIDEO_REQUEST_REPORT_READ",
          "VIDEO_REQUEST_REPORT_WRITE",
        ],
      },
      {
        name: "Device Report",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.DEVICE_REPORT,
        state: "reports",
        isSubMenu: true,
        permissions: ["DEVICE_REPORT_READ", "DEVICE_REPORT_WRITE"],
      },
      // {
      //   name: "Organization Report",
      //   icon: <ArrowForwardIcon />,
      //   pathname: ROUTES.ORGANIZATION_REPORT,
      //   state: "reports",
      //   isSubMenu: true,
      //   permissions: ["ORGANIZATION_REPORT_READ", "ORGANIZATION_REPORT_WRITE"],
      // },
      {
        name: "Vehicle Trip Report",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.VEHICLE_TRIP_REPORT,
        state: "reports",
        isSubMenu: true,
        permissions: ["VEHICLETRIP_REPORT_READ", "VEHICLETRIP_REPORT_WRITE"],
      },
      {
        name: "Drive Statistics",
        state: "reports",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.DRIVER_STATISTIC,
        isSubMenu: true,
        permissions: ["DRIVER_STATISTICS_READ", "DRIVER_STATISTICS_WRITE"],
      },
    ],
  },
  {
    name: "Management & Settings",
    icon: <PersonOutlinOutlinedIcon />,
    state: "managmentSetting",
    permissions: MANAGEMENT_SETTINGS,
    children: [
      {
        name: "Manage Roles",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.MANAGE_ROLES,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["ROLES_MANAGEMENT_READ", "ROLES_MANAGEMENT_WRITE"],
      },
      {
        name: "Project Management",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.PROJECT_MANAGEMENT,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["PROJECT_MANAGEMENT_READ", "PROJECT_MANAGEMENT_WRITE"],
      },
      {
        name: "Users Management",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.USERS_MANAGEMENT,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["USERS_MANAGEMENT_READ", "USERS_MANAGEMENT_WRITE"],
      },
      {
        name: "Support Ticket",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.SUPPORT_TICKET,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["SUPPORT_TICKETS_WRITE", "SUPPORT_TICKETS_READ"],
      },
      {
        name: "Driver Management",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.DRIVER_MANAGEMENT,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["DRIVER_MANAGEMENT_READ", "DRIVER_MANAGEMENT_WRITE"],
      },
      {
        name: "Device Management",
        icon: <ArrowForwardIcon />,
        pathname: ROUTES.DEVICE_MANAGEMENT,
        state: "managmentSetting",
        isSubMenu: true,
        permissions: ["DEVICE_MANAGEMENT_READ", "DEVICE_MANAGEMENT_WRITE"],
      },
    ],
  },
  //   {
  //     name: "All Devices",
  //     icon: <LaptopMacOutlinedIcon />,
  //     state: "allDevice",
  //     children: [
  //       {
  //         name: "First Connect",
  //         icon: <ArrowForwardIcon />,
  //         pathname: ROUTES.FIRST_CONNECT,
  //         state: "allDevice",
  //         isSubMenu: true,
  //       },
  //       {
  //         name: "Import Devices",
  //         icon: <ArrowForwardIcon />,
  //         pathname: ROUTES.IMPORT_DEVICES,
  //         state: "allDevice",
  //         isSubMenu: true,
  //       },
  //       {
  //         name: "Device Status",
  //         icon: <ArrowForwardIcon />,
  //         pathname: ROUTES.DEVICE_STATUS,
  //         state: "allDevice",
  //         isSubMenu: true,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Engineer Service Calls",
  //     state: "serviceCall",
  //     icon: <SettingsOutlinedIcon />,
  //     pathname: ROUTES.ENGINEER_SERVICE_CALLS,
  //   },
  {
    name: "Archive",
    state: "archive",
    icon: <ArchiveOutlinedIcon />,
    pathname: ROUTES.ARCHIVE,
    permissions: "ALL",
  },
  //   {
  //     name: "Logout",
  //     state: "logout",
  //     icon: <ExitToAppOutlinedIcon />,
  //     pathname: ROUTES.LOGOUT,
  //   },
];

const SideBarMenu = ({
  selected,
  setSelected,
  open,
  handleDrawerOpen,
  classes,
  pathname,
  selectedTheme,
  openMenu,
}: $TSFixMe) => {
  return (
    <>
      <SidebarMenuitem
        sidebarItems={SIDE_BAR_ITEMS}
        selected={selected}
        openMenu={openMenu}
        setSelected={setSelected}
        open={open}
        selectedTheme={selectedTheme}
        handleDrawerOpen={handleDrawerOpen}
        classes={classes}
        pathname={pathname}
      />
    </>
  );
};

const SidebarMenuitem = ({
  sidebarItems,
  selected,
  setSelected,
  open,
  handleDrawerOpen,
  classes,
  pathname,
  openMenu,
  selectedTheme,
}: $TSFixMe) => {
  return (
    <List className={`${selectedTheme === THEME_TYPE.GREY_SCALE ? 'sidebar-light' : selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'sidebar_light_blue' : 'sidebar-dark'}`}>
      {sidebarItems?.map((item: $TSFixMe, index: $TSFixMe) => (
        <ShowPermission key={index} permission={item.permissions}>
          <SubMenu
            item={item}
            selected={selected}
            openMenu={openMenu}
            setSelected={setSelected}
            open={open}
            selectedTheme={selectedTheme}
            handleDrawerOpen={handleDrawerOpen}
            classes={classes}
            pathname={pathname}
          />
        </ShowPermission>
      ))}
    </List>
  );
};

const SubMenu = ({
  item,
  handleDrawerOpen,
  classes,
  open,
  selected,
  selectedTheme,
  setSelected,
  pathname,
  openMenu,
}: $TSFixMe) => {
  const itemColor = (selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) ? "text-color-dark" : "";
  return (
    <div
      className="sidebarList"
      style={{ height: !openMenu ? "43.19px" : "" }}
    >
      <Link
        to={{ pathname: item.pathname, state: item.state }}
        className="tabLink"
      >
        <ListItem
          button
          key={item.name}
          onClick={() => {
            if (!item.pathname) {
              if (selected === item.state) {
                setSelected("");
              } else {
                setSelected(item.state);
              }
            } else {
              setSelected(item.state);
            }
          }}
          className={
            item.pathname
              ? item.pathname === pathname
                ? item.isSubMenu
                  ? `subMenu active ${classes.item} `
                  : selected === item.state
                    ? `arrwhvr active ${selectedTheme}_hover-bg`
                    : `arrwhvr ${selectedTheme}_hover-bg`
                      ? `arrwhvr active ${selectedTheme}_hover-bg`
                      : ""
                : item.isSubMenu
                  ? `subMenu ${classes.item}`
                  : `arrwhvr ${selectedTheme}_hover-bg`
              : selected === item.state
                ? `${selectedTheme}-bg-active`
                : `arrwhvr ${selectedTheme}_hover-bg`
          }
        >
          <ListItemIcon className={`svgiconParent ${itemColor}`}>{item.icon}</ListItemIcon>

          <ListItemText primary={item.name} className={`${itemColor}`} />
          {item.children?.length &&
            (selected === item.state ? (
              <ExpandMore
                className={`${itemColor} ${classes?.item + " navIcn" + " SidebarItemDivText"}`}
              />
            ) : (
              <NavigateNextIcon
                className={`${itemColor} ${classes?.item + " navIcn" + " SidebarItemDivText"}`}
              />
            ))}
        </ListItem>
        <Collapse in={selected === item.state} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {openMenu && item.children?.length ? (
              <SidebarMenuitem
                sidebarItems={item.children}
                selected={selected}
                setSelected={setSelected}
                open={open}
                selectedTheme={selectedTheme}
                handleDrawerOpen={handleDrawerOpen}
                classes={classes}
                pathname={pathname}
              />
            ) : (
              ""
            )}
          </List>
        </Collapse>
      </Link>
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  openMenu: state.generalSlice.open,
});

export default connect(mapStateToProps)(SideBarMenu);
