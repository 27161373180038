import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { ProjectMgMtType } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  projectEditValidationSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { EditProjectMgmt } from "../../../../../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import SetLogo from "./setLogo";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import { getProjectListDD } from "../../../../../../../redux/reducers/dropdowns/dropdown.actions";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: ProjectMgMtType;
  };

const Edit = (props: Props) => {
  const { selectedTheme, setSelectedProject, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [logo, setLogo] = useState({
    logo: false,
    headerLargeLogo: "",
    headerSmallLogo: "",
    footerLargeLogo: "",
    footerSmallLogo: "",
  });

  const handleUpdateOrganization = async (
    values: $TSFixMe
  ) => {
    try {
      props.startLoading();
      if (props.data.id) {
        const res: $TSFixMe = await toast.promise(
          props.EditProjectCode({
            address: values.address ?? "",
            altEmail: values?.altEmail,
            city: values.city ?? "",
            contactPersonName: values.contactPersonName,
            contactPersonPhoneNo: values.contactPersonPhoneNo,
            contactPersonPhoneCode: values.contactPersonPhoneCode,
            country: values.country ?? "",
            email: values.email,
            faxNo: values.faxNo,
            logo: values.logo,
            leaderLogoLarge: values.headerLargeLogo,
            leaderLogoSmall: values.headerSmallLogo,
            footerLogoLarge: values.footerLargeLogo,
            footerLogoSmall: values.footerSmallLogo,
            name: values.name,
            officeNo: values.officeNo ?? "",
            postalcode: values.postalCode,
            services: values.service ?? "",
            state: values.state ?? "",
            website: values.website,
          }, props.data.id) as any,
          {
            success: TOAST_MSG.EDIT_PROJECT_SUCCESS,
          }
        );
        if (res.status >= 200) {
          const projectRes: $TSFixMe = await props.getProjectListDD();
          if (projectRes.status >= 200 && selectedProject.id === props.data.id && projectRes.result.length > 0) {
            const tempOrg = projectRes.result.filter((p: $TSFixMe) => p.id === props.data.id);
            setSelectedProject(tempOrg[0]);
            localStorage.setItem("selectedProjectData", JSON.stringify(tempOrg[0]));
          }
        }
        toggle();
      }
    } catch (error) {
      console.log("error in Organization edit api", error);
    } finally {
      await props.endLoading();
    }
  };

  return (
    <>
      <EditOutlinedIcon onClick={toggle} className="dropico" />
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <div className="modlabel modheadcont mb-0">Edit Project</div>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={projectEditValidationSchema}
          enableReinitialize={true}
          initialValues={{
            name: props.data.name ?? "",
            postalCode: props.data.postalcode ?? "",
            contactPersonName: props.data.contactPersonName ?? "",
            contactPersonPhoneNo: props.data.contactPersonPhoneNo ?? "",
            contactPersonPhoneCode: props.data.contactPersonPhoneCode ?? "",
            altEmail: props.data.altEmail ?? "",
            faxNo: props.data.faxNo ?? "",
            website: props.data.website ?? "",
            email: props.data.email ?? "",
            logo: props.data.logo ?? false,
            headerLargeLogo: props.data.leaderLogoLarge ?? "",
            headerSmallLogo: props.data.leaderLogoSmall ?? "",
            footerLargeLogo: props.data.footerLogoLarge ?? "",
            footerSmallLogo: props.data.footerLogoSmall ?? "",
            city: props.data.city ?? "",
            country: props.data.country ?? "",
            officeNo: props.data.officeNo ?? "",
            address: props.data.address ?? "",
            service: props.data.services ?? "",
            state: props.data.state ?? "",
          }}
          onSubmit={handleUpdateOrganization}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            setValues,
            touched,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label className="modlabel modinpulabel" htmlFor="projectName">
                        Project Name<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid " : "")
                        }
                        id="projectName"
                        placeholder="Project Name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label className="modlabel modinpulabel" htmlFor="address">Project Address</label>
                      <Input
                        autoComplete="disable"
                        placeholder="Project Address"
                        id="address"
                        value={values.address}
                        onChange={handleChange("address")}
                        onBlur={handleBlur("address")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="city"
                      >
                        City
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="city"
                        placeholder="City"
                        value={values.city}
                        onChange={handleChange("city")}
                        onBlur={handleBlur("city")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="state"
                      >
                        State
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="state"
                        value={values.state}
                        onChange={handleChange("state")}
                        onBlur={handleBlur("state")}
                        placeholder="State"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      <Input
                        autoComplete="disable"
                        type="text"
                        id="country"
                        value={values.country}
                        onChange={handleChange("country")}
                        onBlur={handleBlur("country")}
                        placeholder="Country"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        Postal Code<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.postalCode && touched.postalCode
                            ? " is-invalid "
                            : "")
                        }
                        pattern="\d*"
                        id="postalCode"
                        maxLength="10"
                        placeholder="Postal Code"
                        value={values.postalCode}
                        onChange={handleChange("postalCode")}
                        onBlur={handleBlur("postalCode")}
                      />
                      <ErrorMessage
                        name="postalCode"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="services"
                      >
                        Services
                      </label>
                      <Input
                        type="text"
                        id="services"
                        autoComplete="disable"
                        placeholder="Services"
                        value={values.service}
                        onChange={handleChange("service")}
                        onBlur={handleBlur("service")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="contactPersonName"
                      >
                        Contact Person Name<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.contactPersonName &&
                            touched.contactPersonName
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        name="contactPersonName"
                        id="contactPersonName"
                        placeholder="Contact Person Name"
                        value={values.contactPersonName}
                        onChange={handleChange("contactPersonName")}
                        onBlur={handleBlur("contactPersonName")}
                      />
                      <ErrorMessage
                        name="contactPersonName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <label
                    className="modlabel modinpulabel"
                    htmlFor="contactPersonPhoneNo"
                  >
                    Contact Person Mobile
                    <span className="required"> *</span>
                  </label>
                </Row>
                <Row className="formRow">
                  <Col md={3}>
                    <FormGroup>
                      <Select
                        className={`${touched.contactPersonPhoneCode &&
                          errors.contactPersonPhoneCode
                          ? "requireSelect"
                          : ""
                          }
                        `}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={props.dropdowns.dialCode?.dropDown}
                        placeholder="Code"
                        name="contactPersonPhoneCode"
                        value={values.contactPersonPhoneCode ? props.dropdowns.dialCode?.dropDown?.filter((d) => d.value === values.contactPersonPhoneCode) : null}
                        onChange={(option) =>
                          setFieldValue(
                            "contactPersonPhoneCode",
                            option?.value
                          )
                        }
                        onBlur={handleBlur("contactPersonPhoneCode")}
                      />
                      {touched.contactPersonPhoneCode &&
                        errors.contactPersonPhoneCode && (
                          <div className="validate">
                            {errors.contactPersonPhoneCode === "requireSelect"
                              ? "Required"
                              : errors.contactPersonPhoneCode}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup className="dropinput">
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.contactPersonPhoneNo &&
                            touched.contactPersonPhoneNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="contactPersonPhoneNo"
                        id="contactPersonPhoneNo"
                        placeholder="Contact Person Mobile"
                        value={values.contactPersonPhoneNo}
                        onChange={handleChange("contactPersonPhoneNo")}
                        onBlur={handleBlur("contactPersonPhoneNo")}
                      />
                      <ErrorMessage
                        name="contactPersonPhoneNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="officeNo"
                      >
                        Office No
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.officeNo && touched.officeNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="officeNo"
                        id="officeNo"
                        placeholder="Office Number"
                        value={values.officeNo}
                        onChange={handleChange("officeNo")}
                        onBlur={handleBlur("officeNo")}
                      />
                      <ErrorMessage
                        name="officeNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="faxNo"
                      >
                        Fax No<span className="required"> *</span>
                      </label>
                      <Field
                        autoComplete="disable"
                        className={
                          "form-control" +
                          (errors.faxNo && touched.faxNo
                            ? " is-invalid "
                            : "")
                        }
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        name="faxNo"
                        placeholder="Fax No"
                        value={values.faxNo}
                        onChange={handleChange("faxNo")}
                        onBlur={handleBlur("faxNo")}
                      />
                      <ErrorMessage
                        name="faxNo"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="website"
                      >
                        Website<span className="required"> *</span>
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.website && touched.website
                            ? " is-invalid "
                            : "")
                        }
                        type="text"
                        autoComplete="disable"
                        placeholder="Website"
                        value={values.website}
                        onChange={handleChange("website")}
                        onBlur={handleBlur("website")}
                      />
                      <ErrorMessage
                        name="website"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="email"
                      >
                        Email<span className="required"> *</span>
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.email && touched.email
                            ? " is-invalid "
                            : "")
                        }
                        type="userEmail"
                        name="email"
                        autoComplete="disable"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="altEmail"
                      >
                        Alternate Email Id
                      </label>
                      <Field
                        className={
                          "form-control" +
                          (errors.altEmail && touched.altEmail
                            ? " is-invalid "
                            : "")
                        }
                        autoComplete="disable"
                        type="userEmail"
                        name="altEmail"
                        placeholder="Alternate Email Id"
                        value={values.altEmail}
                        onChange={handleChange("altEmail")}
                        onBlur={handleBlur("altEmail")}
                      />
                      <ErrorMessage
                        name="altEmail"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="px-1">
                  <Col md={12}>
                    <SetLogo
                      initialValues={{
                        logo: values.logo,
                        footerLargeLogo: values.footerLargeLogo,
                        footerSmallLogo: values.footerSmallLogo,
                        headerLargeLogo: values.headerLargeLogo,
                        headerSmallLogo: values.headerSmallLogo,
                      }}
                      setLogo={(logoObj: any) =>
                        setValues({ ...values, ...logoObj })
                      }
                      logoData={logo}
                    />
                    {touched.logo && errors.logo && (
                      <div className="validate mb-3">
                        {errors.logo === "require"
                          ? "Required"
                          : errors.logo}
                      </div>
                    )}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    // @ts-expect-error
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    type="submit"
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading &&
                      <Spinner color="dark"
                        size="sm" className="mr-2" />
                    }
                    <>UPDATE</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  EditProjectCode: (data: ProjectMgMtType, id: number) => dispatch(EditProjectMgmt(data, id)),
  getProjectListDD: () => dispatch(getProjectListDD()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
