import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AddUserType, CTAdminUser } from "../../../../../../../../global.types";
import ASSETS from "../../../../../../../assets";
import {
  EditUserValidationType,
  UsersManagementEditValidationSchema,
} from "../../../../../../../constants";
import { TOAST_MSG } from "../../../../../../../constants/toast.constants";
import {
  endLoading,
  startLoading,
} from "../../../../../../../redux/reducers/general/general.actions";
import { EditUsersCode } from "../../../../../../../redux/reducers/Management&Settings/usersManagement/usersManagement.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import SelectProject from "../../../SelectProject/SelectProject";
import { LayoutContext } from "../../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { data: CTAdminUser };

const Edit = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleUpdateCtAdmin = async (values: EditUserValidationType) => {
    try {
      props.startLoading();
      if (props.data.id) {
        await toast.promise(
          props.EditUsersCode({
            allowEmailAlert: values.allowAlertEmail,
            allowMaintenanceEmail: values.allowMaintenanceEmail,
            email: values.email,
            mobileNumber: values.mobileNumber,
            mobilecode: values.mobilecode.value ?? "",
            name: values?.name,
            projects: values.projectIds,
            roleId: values?.roleId?.value ?? "",
            status: values.status,
            sendWelcomeEmail: values.sendWelcomeEmail,
          }, props.data.id) as any,
          {
            success: TOAST_MSG.EDIT_USERS_SUCCESS,
          }
        );
        toggle();
      }
    } catch (error) {
      console.log("error in CtAdmin Edit api", error);
    } finally {
      await props.endLoading();
    }
  };

  return (
    <>
      <EditOutlinedIcon onClick={toggle} className="dropico" />
      <Modal isOpen={modal} toggle={toggle} className="videoModal">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Edit User</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={UsersManagementEditValidationSchema}
          enableReinitialize={true}
          initialValues={{
            allowAlertEmail: props.data.allowEmailAlert,
            allowMaintenanceEmail: props.data.allowMaintenanceEmail,
            sendWelcomeEmail: props.data.sendWelcomeEmail,
            email: props.data.email ?? "",
            mobileNumber: props.data.mobileNumber ?? "",
            mobilecode: {
              label: props.data.mobilecode,
              value: props.data.mobilecode,
            },
            name: props.data.name,
            projectIds: props?.data?.projectIds
              ? props?.data?.projectIds
                ?.split(",")
                .map((each) => parseInt(each))
              : [],
            status: props.data.status,
            roleId: {
              label: props.data.role?.name,
              value: props.data.role?.id,
            },
          }}
          onSubmit={handleUpdateCtAdmin}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
            touched,
            errors,
          }) => (
            <>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
              }} className="scr">
                <Row>
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Username<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (touched.name && errors.name
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        type="text"
                        name="name"
                        placeholder="UserName"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={6}>
                    <div className="modalheader modinpulabel">
                      Select User role<span className="required"> *</span>
                    </div>{" "}
                    <Select
                      className={`" borderShadow" + ${touched.roleId && errors.roleId
                        }`}
                      placeholder={"Select User Role"}
                      classNamePrefix={"vd-select-dropdown"}
                      options={props.dropdowns.userRolesListDD.dropDown}
                      value={props.data.role ? values.roleId : null}
                      getOptionLabel={(option: $TSFixMe) => option.label}
                      getOptionValue={(option: $TSFixMe) =>
                        option.value.toString()
                      }
                      maxMenuHeight={180}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base: $TSFixMe) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(option) => setFieldValue("roleId", option)}
                      onBlur={handleBlur("roleId")}
                      name="roleId"
                    />
                    {touched.roleId && errors.roleId && (
                      <div className="validate">{errors.roleId}</div>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <div className="modalheader modinpulabel">
                        Email Id<span className="required"> *</span>
                      </div>
                      <Field
                        className={
                          "form-control" +
                          (touched.email && errors.email ? " is-invalid" : "")
                        }
                        autocomplete="disable"
                        type="userEmail"
                        name="email"
                        placeholder="Email Id"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="modalheader modinpulabel">
                    Mobile Number<span className="required"> *</span>
                  </div>
                </Row>
                <Row className="formRow">
                  <Col md={3}>
                    <FormGroup>
                      <Select
                        className={`${touched.mobilecode && errors.mobilecode ? "requireSelect" : ""}`}
                        placeholder={"Country Code"}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        options={props.dropdowns.dialCode?.dropDown}
                        value={values.mobilecode}
                        maxMenuHeight={180}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        name="mobilecode"
                        onChange={(option) =>
                          setFieldValue("mobilecode", option)
                        }
                        onBlur={handleBlur("mobilecode")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup className="dropinput">
                      <Field
                        className={
                          "form-control" +
                          (errors.mobileNumber && touched.mobileNumber
                            ? " is-invalid "
                            : "")
                        }
                        autocomplete="disable"
                        onInput={(e: $TSFixMe) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                        }}
                        maxLength={15}
                        placeholder="Digits only"
                        name="mobileNumber"
                        value={values.mobileNumber}
                        onChange={handleChange("mobileNumber")}
                        onBlur={handleBlur("mobileNumber")}
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div
                  className={
                    touched.projectIds && errors.projectIds
                      ? " is-invalid "
                      : ""
                  }
                >
                  <SelectProject
                    projectIds={values.projectIds}
                    setProjectsIds={(val) => {
                      setFieldValue("projectIds", val, true);
                    }}
                  />
                </div>
                {touched.projectIds && errors.projectIds && (
                  <div className="validate">
                    {errors.projectIds
                      ? "Required"
                      : errors.projectIds}
                  </div>
                )}
                <br />
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.allowAlertEmail}
                          onChange={(e) =>
                            setFieldValue("allowAlertEmail", e.target.checked)
                          }
                        />
                        Do you Allow to Alert Email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.allowMaintenanceEmail}
                          onChange={(e) =>
                            setFieldValue(
                              "allowMaintenanceEmail",
                              e.target.checked
                            )
                          }
                        />
                        Do you Allow to Maintenance Email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check className="inpucheck">
                        <Input
                          type="checkbox"
                          // @ts-expect-error
                          value={true}
                          checked={values.sendWelcomeEmail}
                          onChange={(e) =>
                            setFieldValue("sendWelcomeEmail", e.target.checked)
                          }
                        />
                        Do you want to send welcome email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <div className="modalheader">Status</div>
                  </Col>
                  <Col md={8}>
                    <RadioGroup
                      onChange={(e) => {
                        setFieldValue("status", e.target.value === "true");
                      }}
                      className="radioStatus"
                      onBlur={handleBlur("status")}
                      aria-label="status"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        checked={values.status}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={false}
                        checked={!values.status}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    // @ts-expect-error
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading &&
                      <Spinner color="dark"
                        size="sm" className="mr-2" />
                    }
                    <>UPDATE</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal >
      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  EditUsersCode: (data: AddUserType, id: number) => dispatch(EditUsersCode(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
