import Side from "./app/components/Sidebar&Navbar/index";
import "./App.css";
import { Scrollbars } from "react-custom-scrollbars";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import "./app/fonts/icomoonf33b.ttf";
import { toast } from "react-toastify";
import Login from "./app/components/Navbar/login/index";
import ProtectedRoute from "./app/components/Common/ProtectedRoute";
import { useContext, useEffect, useState } from "react";
import { LayoutContext, LayoutContextModel } from "./app/context/LayoutContext";
import ForgotPassword from "./app/components/Navbar/forgotPassword";
import ResetPassword from "./app/components/Navbar/resetPassword";

toast.configure({
  position: "top-right",
  autoClose: 1700,
  theme: "colored",
  pauseOnHover: true,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  draggable: true,
});

function App(props: $TSFixMe) {
  const { setSelectedTheme, token }: LayoutContextModel = useContext(LayoutContext);
  let userConfig: any = JSON.parse(localStorage?.getItem("user-config-vendor") || "null");

  useEffect(() => {
    if (userConfig && userConfig.theme) {
      setSelectedTheme(userConfig.theme);
    }
  }, [userConfig?.theme]);


  useEffect(() => {
    if (window.location.pathname === "/admin") {
      window.location.href = "/admin/";
    }
    if (window.location.pathname === "/engineer") {
      window.location.href = "/engineer/";
    }
  }, []);


  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/admin/" />
          <Route exact path="/engineer/" />
          <Route exact path="/">
            {token ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/forgot-password">
            {token ? <Redirect to="/dashboard" /> : <ForgotPassword />}
          </Route>
          <Route exact path="/reset-password">
            {token ? <Redirect to="/dashboard" /> : <ResetPassword />}
          </Route>
          <Route exact path="/?token=">
            {token ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <ProtectedRoute>
            <Route>
              <Side />
            </Route>
          </ProtectedRoute>
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = (state: $TSFixMe) => ({
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(App);
