import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { TamperEvents } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import DataTable from "../../../../Common/DataTable/dataTable";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";
import { Popover } from "antd";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Spinner } from "reactstrap";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { getLocation } from "../../../../../api/axios.resources";

interface TamperEventsTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & TamperEventsTableProps;

const TamperEventsTab = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [tamperEvents, setTamperEvents] = useState<TamperEvents[]>([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [location, setLocation] = useState("");

  const getAddressFromLatLng = async (latitude: number, longitude: number) => {
    try {
      if (!latitude && !longitude) {
        setLocation("No address found");
        return;
      }
      const response = await getLocation(latitude, longitude);
      if (response) {
        setLocation(response.data.display_name);
      }
      if (response.data.error && !response.data.display_name) {
        setLocation(response.data.error);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setLocation("Error fetching address");
    }
  };

  const tamperEventsColumns = [
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: " Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    {
      title: "Event",
      data: "event",
      format: (data: $TSFixMe) => data.eventName,
    },
    {
      title: "Location",
      format: (data: $TSFixMe) => (
        <Popover
          content={
            location ? (
              <>
                {location}
                {data?.latitude && data?.longitude &&
                  <div>
                    <a
                      onClick={() => setSelectedEventId(null)}
                      href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      View Location{" "}
                      <OpenInNewIcon color="primary" sx={{ fontSize: 12 }} className="wh-12-px" />
                    </a>
                  </div>
                }
              </>
            ) : (
              <Spinner color="primary" size="sm" className="mx-auto d-block" />
            )
          }
          trigger="click"
          placement="left"
          overlayClassName={`location-popover ${selectedTheme}`}
          visible={selectedEventId === data._id}
          onVisibleChange={(visible) => {
            if (visible) {
              getAddressFromLatLng(data.latitude, data.longitude);
            }
            setSelectedEventId(visible ? data._id : null);
          }}
        >
          <LocationOnOutlinedIcon className="dropico" />
        </Popover>
      ),
    },
    { title: "DateTime", data: "timestamp", format: (data: $TSFixMe) => data.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat) : "N/A", },
  ];

  useEffect(() => {
    setTamperEvents([...(props.tamperEvents?.result?.data ?? [])]);
    return () => { };
  }, [props.tamperEvents]);

  return (
    <>
      <DataTable
        data={tamperEvents}
        columns={tamperEventsColumns}
        bordered
        pageData={props.tamperEvents?.result?.page}
        totalRecrods={props.tamperEvents?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

// export default TamperEventsTab;
const mapStateToProps = (state: RootState) => ({
  tamperEvents: state.dataStore.tamperEvents,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TamperEventsTab);
