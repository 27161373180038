import React, { useContext, useState } from 'react'
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import ASSETS from '../../assets';
import { ErrorMessage, Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { changePasswordValidationSchema } from '../../constants';
import { LayoutContext } from '../../context/LayoutContext';
import { updatePassword } from '../../api/axios.resources';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ChangePassword = (props: $TSFixMe) => {
    const { selectedTheme } = useContext(LayoutContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const formSubmitHandler = async (values: $TSFixMe) => {
        setIsLoading(true);
        try {
            const res = await updatePassword(values);
            if (res.status === 200) {
                toast.success(res.message);
                props.toggle();
            } else {
                toast.error("Password not Update");
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} className="modtop w-25">
            <ModalHeader className={`mod ${selectedTheme}-bg`}>
                <h5 className="modlabel modheadcont mb-0">Change Password</h5>
                <button className={`mult ${selectedTheme}-bg`} onClick={() => props.toggle()}>
                    <img src={ASSETS.MULTIPLY} alt="multiply" />
                </button>
            </ModalHeader>
            <Formik
                validationSchema={changePasswordValidationSchema}
                enableReinitialize={true}
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                }}
                onSubmit={formSubmitHandler}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    touched,
                    isSubmitting,
                    errors,
                }) => (
                    <>
                        <ModalBody style={{
                            maxHeight: 'calc(100vh - 200px)',
                            overflowY: 'auto'
                        }} className="scr">
                            <Row>
                                <Col md={12} className="dropinput">
                                    <FormGroup className="dropinput">
                                        <div className="modalheader">
                                            Current Password<span className="required"> *</span>
                                        </div>
                                        <div className="position-relative">
                                            <Field
                                                className={
                                                    "form-control mt-1" +
                                                    (errors.currentPassword && touched.currentPassword
                                                        ? " is-invalid "
                                                        : "") +
                                                    " passwordFont "
                                                }
                                                autocomplete="disable"
                                                type={isCurrentPasswordVisible ? "text" : "password"}
                                                name="currentPassword"
                                                placeholder="Enter your Current Password"
                                                value={values.currentPassword}
                                                onChange={handleChange("currentPassword")}
                                                onBlur={handleBlur("currentPassword")}
                                            />
                                            <span className={(errors.currentPassword && touched.currentPassword
                                                ? " is-invalid "
                                                : "") + "eye-icon-for-password"} onClick={() => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)}>
                                                {isCurrentPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>
                                            <ErrorMessage
                                                name="currentPassword"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="dropinput mt-2">
                                        <div className="modalheader">
                                            New Password<span className="required"> *</span>
                                        </div>
                                        <div className="position-relative">
                                            <Field
                                                className={
                                                    "form-control mt-1" +
                                                    (errors.newPassword && touched.newPassword
                                                        ? " is-invalid "
                                                        : "") +
                                                    " passwordFont "
                                                }
                                                autocomplete="disable"
                                                type={isNewPasswordVisible ? "text" : "password"}
                                                name="newPassword"
                                                placeholder="Enter your new password"
                                                value={values.newPassword}
                                                onChange={handleChange("newPassword")}
                                                onBlur={handleBlur("newPassword")}
                                            />
                                            <span className={(errors.newPassword && touched.newPassword
                                                ? " is-invalid "
                                                : "") + "eye-icon-for-password"} onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}>
                                                {isNewPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>
                                            <ErrorMessage
                                                name="newPassword"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="footer-width">
                            <div className="footwidth">
                                <Button
                                    // @ts-expect-error
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    className="popbtn datewidth btnBox"
                                >
                                    {isLoading &&
                                        <Spinner color="dark"
                                            size="sm" className="mr-2" />
                                    }
                                    <>UPDATE</>
                                </Button>
                            </div>
                            <div className="divider" />
                            <div className="footwidth">
                                <Button onClick={() => props.toggle()} className="popbtn datewidth btnBox">
                                    <>CANCEL</>
                                </Button>
                            </div>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    )
}

export default ChangePassword;