import axios from "axios";
import { BASEURL } from "../api/api.constants";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getRefreshToken } from "../api/axios.resources";

const axiosCall = axios.create({
  baseURL: BASEURL,
});
const temUrl = "http://localhost:4002"
let is401Error = false;

axiosCall.interceptors.request.use(

  function (config) {
    const token = Cookies.get("road-devil-vendor-token");
    const orgInfo: $TSFixMe = localStorage.getItem('selectedOrg');

    if (token) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      config.headers['Authorization'] = 'Bearer ' + token
    }

    if (orgInfo !== null) {
      const org = JSON.parse(orgInfo);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      config.headers['OrgId'] = org.id
    }

    switch (true) {
      case config?.url?.includes("/geo-fencing"):
        config.baseURL = temUrl;
        break;

      // case config?.url?.includes("/common-configuration"):
      //   config.baseURL = temUrl;
      //   break;

      // case config?.url?.includes("/support-ticket"):
      //   config.baseURL = temUrl;
      //   break;

      case config?.url?.includes("/project-management"):
        config.baseURL = temUrl;
        break;
      default:
        break;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosCall.interceptors.response.use(
  (response) => {
    if (response.config.baseURL === temUrl) {
      response.data = {
        timestamp: "2022-06-01T07:53:15.652+00:00",
        status: 200,
        message: "Success",
        result: response.data,
      };
    }
    if (response.data.status === 400) {
      const error = response;
      return Promise.reject(error);
    } else {
      return response;
    }
  },
  async (error) => {
    console.log("interceptor Error ======================================", error)
    const originalRequest = error.config;
    console.log(
      "interceptor error",
      error,
      Object.keys(error),
      JSON.parse(JSON.stringify(error))
    );

    if (error.response.status >= 500) {
      toast.error(error.response.data.error);
    }

    setTimeout(() => {
      toast.error(error.response.data.message);
    }, 100);

    if (
      error.response &&
      error.response.status === 404 &&
      !error.response.data.message &&
      !originalRequest._retry &&
      originalRequest.baseURL !== temUrl
    ) {
      originalRequest._retry = true;
      console.log(
        "error originalRequest",
        error,
        Object.keys(error),
        originalRequest._retry
      );

      originalRequest.baseURL = temUrl;
      switch (originalRequest.url) {
        case "/api/v1/support-ticket":
          originalRequest.url = "/support-ticket";
          break;
        default:
          break;
      }
      return axiosCall(originalRequest);
    } else if (error.response.status === 401 && !is401Error) {
      is401Error = true;
      const refreshToken = Cookies.get("road-devil-vendor-refreshtoken");
      const res = await getRefreshToken({ refreshToken });
      if (res.status === 200) {
        Cookies.set("road-devil-vendor-token", res.result.jwttoken);
        is401Error = false;
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          window.location.reload();
          return axiosCall(originalRequest);
        }
      } else {
        window.location.href = "/";
        Cookies.remove("road-devil-vendor-token");
        Cookies.remove("road-devil-vendor-refreshtoken");
        localStorage.removeItem("user-config-vendor");
        localStorage.removeItem("selectedProjectData");
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosCall;

export const get = async (url: $TSFixMe, config = {}) => {
  return await axiosCall
    .get(url, { ...config })
    .then((response) => response.data);
};

export const post = async (url: $TSFixMe, data: $TSFixMe, config = {}) => {
  return await axiosCall
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
};

// for file uploading
export const postformData = async (url: string, formData: FormData, config = {}) => {
  return await axiosCall
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json, application/xml, text/plain, text/html, */*',
      },
      ...config
    })
    .then((response) => response.data);
};

export const put = async (url: $TSFixMe, data: $TSFixMe, config = {}) => {
  return axiosCall
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
};

export const del = async (url: $TSFixMe, config = {}) => {
  return await axiosCall
    .delete(url, { ...config })
    .then((response) => response.data);
};

export const patch = async (url: string, data: any, config = {}) => {
  return await axiosCall
    .patch(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...config,
    })
    .then((response) => response.data);
};