import { useEffect, useState } from "react";
import DataTable from "../../../../Common/DataTable";
import { getResourceRoleList } from "../../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { connect } from "react-redux";
import { getManageRoles } from "../../../../../redux/reducers/Management&Settings/manageRoles/manageRoles.actions";
import EditRole from "./Components/editRole";
import ViewRole from "./Components/viewRole";
import Delete from "./Components/delete";
import { AppDispatch } from "../../../../../redux/store/store";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { Role } from "../../../../../../global.types";
import { convertTimestampToDate } from "../../../../../services/common.functions.services";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { isRolesWrite: boolean };

const ManageRolesTab = (props: Props) => {
  const [manageRolesList, setManageRolesList] = useState<Role[]>([]);
  const ActionBtns = (data: { data: Role }) => {
    return (
      <div className="imptabbody">
        <ViewRole data={data.data} />
        {props.isRolesWrite && <EditRole data={data.data} />}
        {props.isRolesWrite && <Delete data={data.data} />}
      </div>
    );
  };

  const columns = [
    { title: "Role", data: "name" },
    {
      title: "Created Date",
      format: (data: $TSFixMe) => (
        <>
          {data.createdOn ? convertTimestampToDate(props.user.timeZone.zoneId, data.createdOn, null, props.user.timeFormat) :
            "N/A"}
        </>
      ),
    },
    {
      title: "Status",
      format: (data: Role) => <>{data.status ? "Active" : "Inactive"}</>,
    },
    {
      title: "Action",
      format: (data: Role) => <ActionBtns data={data} />,
    },
  ];

  const apiCalls = async () => {
    try {
      props.startLoading();
      await props.getManageRoles();
      await props.getResourceRoleList();
    } catch (error) {
      console.log("error in ManageRoles Tab api", error);
    } finally {
      await props.endLoading();
    }
  };

  useEffect(() => {
    setManageRolesList([...(props.manageRoles.result ?? [])]);
    return () => { };
  }, [props.manageRoles]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCalls();
    }
  }, [props.user.userId]);

  return (
    <>
      <DataTable
        data={manageRolesList}
        columns={columns}
        bordered
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  manageRoles: state.manageRolesData.manageRoles,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getManageRoles: () => dispatch(getManageRoles()),
  getResourceRoleList: () => dispatch(getResourceRoleList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageRolesTab);
