import { useContext, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Input, InputGroup, Row } from "reactstrap";
import DashboardButton from "./dashboardButton";
import HighEvents from "./highEvents";
import ProjectSplit from "./projectSplit";
import RequestedVideo from "./RequestVideo";
import Top10Drivers from "./top10Drivers";
import Trips from "./trips";
import VehicleEvent from "./trips/tabComponents/vehicleEvent";
import { connect } from "react-redux";
import {
  getDialCode,
  getOrgList,
} from "../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../redux/reducers/general/general.actions";
//@ts-expect-error
import Fade from "react-reveal/Fade";
import { getProject } from "../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { AppDispatch } from "../../../redux/store/store";
import { RootState } from "../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../context/LayoutContext";
import { DATE_OPTIONS, GLOBAL_SEARCH_TYPE_LABEL, GLOBAL_SEARCH_TYPE_PERMISSION, GLOBAL_SEARCH_TYPE_URL, THEME_TYPE, dashboardButtonDetails, measurement } from "../../../constants";
import ASSETS from "../../../assets";
import ShowPermission from "../../../constants/showPermission";
import { Link } from "react-router-dom";
import { getGlobalSearchData, getOnlineDevice, getProjectSplit, getTopDriver, getTotalDevice, getTotalMilesProtected, getVehicleEventExceptionSummary } from "../../../api/axios.resources";
import { convertDateToTimestamp, convertKilometerToMile } from "../../../services/common.functions.services";
import FullLoader from "../../Common/FullPageLoader/FullLoader";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Dashboard(props: Props) {
  const [selected, setSelected] = useState("change2");
  const { selectedTheme, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [vehicleSelected, setVehicleSelected] = useState("change1");
  const [vehicleSelectedType, setVehicleSelectedType] = useState("trip");
  const [open, setOpen]: $TSFixMe = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchBoxRef: $TSFixMe = useRef(null);
  const debounceTimeoutRef: $TSFixMe = useRef(null);
  const [filteredOptions, setFilteredOptions]: $TSFixMe = useState({});
  const [startDate, setStartDate]: $TSFixMe = useState(null);
  const [endDate, setEndDate]: $TSFixMe = useState(null);
  const [vehicleEvent, setVehicleEvent]: $TSFixMe = useState({});
  const [selectedDays, setSelectedDays] = useState(14);
  const [deviceInfoCard, setDeviceInfoCard] = useState(dashboardButtonDetails);
  const cardColorType = (selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) && 'cardcolor-light';
  const [isDataLoader, setIsDataLoader]: $TSFixMe = useState(false);
  const [projectSplit, setProjectSplit]: $TSFixMe = useState([]);
  const [topDriver, setTopDriver]: $TSFixMe = useState([]);

  const customDebounce = (func: $TSFixMe, delay: $TSFixMe) => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(func, delay);
  };

  const handleDaysClick = () => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - selectedDays);
    setStartDate(new Date(pastDate).toLocaleString('en-US', DATE_OPTIONS).replace(/\//g, '-').replace(',', ''));
    setEndDate(new Date(today).toLocaleString('en-US', DATE_OPTIONS).replace(/\//g, '-').replace(',', ''));
  };

  const handleInputChange = (e: $TSFixMe) => {
    setSearchTerm(e.target.value);
    customDebounce(async () => {
      // Filter options based on the input value
      try {
        if (e.target.value) {
          const res: $TSFixMe = await getGlobalSearchData({ search: e.target.value });
          if (res.status === 200 && res.result.length > 0) {
            const newArray: $TSFixMe = {};
            // Populate newArray
            res.result.forEach((item: $TSFixMe) => {
              const category = item.otherValue1;
              if (!newArray[category]) {
                newArray[category] = [];
              }
              newArray[category].push(item);
            });
            setFilteredOptions(newArray);
          } else {
            setFilteredOptions({});
          }
        } else {
          setFilteredOptions({});
        }
      } catch (error) {
        console.log(error);
      }
    }, 300); // Adjust the debounce delay as needed (300ms in this example)
  };

  //Highlight Text show in search dropdown
  const highlightText = (text: string, highlight: string) => {
    if (!highlight || highlight === '') return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts: $TSFixMe = text.split(regex);

    return (
      <span>
        {parts.map((part: $TSFixMe, index: number) => {
          if (part.toLowerCase() === highlight.toLowerCase()) {
            return (
              <mark key={index} className="highlighted">
                {part}
              </mark>
            );
          }
          return part;
        })}
      </span>
    );
  };

  const handleGetCardData = async () => {
    try {
      if (Object?.entries(selectedProject).length > 0 && startDate) {
        setIsDataLoader(true);
        const data = {
          projectId: selectedProject.id,
          endTimestamp: endDate ? convertDateToTimestamp(endDate, props?.user?.timeZone?.zoneId) : null,
          startTimestamp: startDate ? convertDateToTimestamp(startDate, props?.user?.timeZone?.zoneId) : null
        }
        const [
          onlineDeviceRes,
          totalDeviceRes,
          milesRes,
          projectRes,
          driverRes
        ] = await Promise.all([
          getOnlineDevice(data),
          getTotalDevice(data),
          getTotalMilesProtected(data),
          getProjectSplit(data),
          getTopDriver(data),
        ]);

        if (projectRes.status === 200 && projectRes.result.length > 0) {
          setProjectSplit(projectRes.result);
        }
        if (driverRes.status === 200 && driverRes.result.length > 0) {
          setTopDriver(driverRes.result);
        }
        const updatedData = [...deviceInfoCard];
        if (Number(totalDeviceRes.result) !== 0) {
          updatedData[0].infoNum = totalDeviceRes?.result.totalDevice ?? 0
        }
        if (Number(onlineDeviceRes.result) !== null) {
          updatedData[1].infoNum = onlineDeviceRes?.result
        }
        if (Number(milesRes.result) !== null) {
          updatedData[2].infoName = props.user.measurmentUnit == measurement[1].value ? 'Total Miles Protected' : 'Total Kilometers Protected'
          updatedData[2].infoNum = props.user.measurmentUnit == measurement[1].value ? convertKilometerToMile(milesRes?.result) : milesRes?.result
        }
        setDeviceInfoCard(updatedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDataLoader(false);
    }
  }

  const handleVehicleEventExceptionSummary = async () => {
    try {
      if (Object?.entries(selectedProject).length > 0 && startDate && vehicleSelectedType) {
        const data = {
          projectId: selectedProject.id,
          endTimestamp: endDate ? convertDateToTimestamp(endDate, props?.user?.timeZone?.zoneId) : null,
          startTimestamp: startDate ? convertDateToTimestamp(startDate, props?.user?.timeZone?.zoneId) : null
        }
        const res = await getVehicleEventExceptionSummary(data, vehicleSelectedType);
        if (res.status === 200 && res.result.length > 0) {
          const dateList = res.result.map((item: $TSFixMe) => props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(item.value) : item.value);
          const categoriesName = res.result.map((item: $TSFixMe) => item.vehicleregistrationno);
          setVehicleEvent({ data: dateList, categories: categoriesName, type: vehicleSelectedType });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleVehicleEventExceptionSummary();
    return () => { }
  }, [vehicleSelectedType, startDate, selectedProject])

  useEffect(() => {
    handleDaysClick()
    return () => { }
  }, [selectedDays]);

  useEffect(() => {
    if (props.user.measurmentUnit) {
      handleGetCardData()
    }
    return () => { }
  }, [selectedProject, props.user.measurmentUnit, startDate]);

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleOutsideClick = (e: $TSFixMe) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`dashboardMinDiv bg-gradient-info pb-1 pt-0 pt-md-8 ${selectedTheme}`}>
      {isDataLoader && <FullLoader />}
      <Container className="mt--7" fluid>
        <div className="card-stats md-4 mb-xl-0 pb-1">
          <Row>
            <Col sm={12} md={3} className={`${selectedTheme} headwelcome headicon`}>
              <>Welcome, <span className="custom-ellipsis ml-2">{props.user.userName}</span></>
            </Col>
            <Col sm={12} md={6} className="assi tabAll">
              <div className={`custom-search-dropdown ${open ? "open" : ""}`} ref={searchBoxRef}>
                <InputGroup className={`${selectedTheme} xzzz`} onClick={() => setOpen(true)} onChange={handleInputChange}>
                  <Input className="input-home" placeholder="Search here..." />
                  <div className="searchDash" />
                  <img alt="Search" src={ASSETS.SEARCH_ICON} className="searchImage" />
                </InputGroup>
                {open && (
                  <div className={`${selectedTheme} search-dropdown`}>
                    {filteredOptions && Object.keys(filteredOptions).length === 0 &&
                      <p className="text-center w-100 f-13 my-2"> No Data Available </p>
                    }
                    {filteredOptions && Object.keys(filteredOptions).length > 0 && Object.keys(filteredOptions).map((key) => {
                      const category = filteredOptions[key];
                      if (category.length > 0) {
                        return (
                          <ShowPermission permission={GLOBAL_SEARCH_TYPE_PERMISSION[key]}>
                            <div key={key} className="mb-3">
                              <div className="search-dropdown-border-bottom mb-1 pb-1 fw-bolder text-uppercase">{GLOBAL_SEARCH_TYPE_LABEL[key]}</div>
                              <div className="dropdown-box">
                                {category && category.map((item: $TSFixMe, index: number) => (
                                  <div key={index} className="search-item w-25 mb-2">
                                    <Link to={`${GLOBAL_SEARCH_TYPE_URL[key]}/?id=${item.id}`}>
                                      <p className="mb-0 f-13 text-capitalize">{highlightText(item.name, searchTerm)}</p>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </ShowPermission>
                        )
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
            </Col>
            <Col sm={12}
              md={3} className="tabAll">
              <div className="app-actions">
                <button
                  type="button"
                  className={`${selectedTheme} ${selected === "change1" ? "change1 active" : "change1"
                    }`}
                  onClick={() => {
                    setSelected("change1")
                    setSelectedDays(6);
                  }}
                >
                  7 Days
                </button>
                <button
                  type="button"
                  className={`${selectedTheme} ${selected === "change2" ? "change1 active" : "change1"
                    }`}
                  onClick={() => {
                    setSelected("change2")
                    setSelectedDays(14);
                  }}
                >
                  15 Days
                </button>
                <button
                  type="button"
                  className={`${selectedTheme} ${selected === "change3" ? "change1 active" : "change1"
                    }`}
                  onClick={() => {
                    setSelected("change3")
                    setSelectedDays(29);
                  }}
                >
                  30 Days
                </button>
              </div>
            </Col>
          </Row>

          <DashboardButton deviceInfoCard={deviceInfoCard} />
          <ShowPermission permission={["HIGH_EVENTS_READ", "HIGH_EVENTS_READ"]}>
            <Fade left duration={1500}>
              <Row>
                <Col xl="12">
                  <Card className={`card-stats md-4 mb-xl-0 shadowCard`}>
                    {/* <Paper elevation={6}> */}
                    <CardBody>
                      <CardTitle className="cardheader1">
                        High Events
                      </CardTitle>
                      <HighEvents endDate={endDate} startDate={startDate} />
                    </CardBody>
                    {/* </Paper> */}
                  </Card>
                </Col>
              </Row>
            </Fade>
          </ShowPermission>
          <ShowPermission permission={["REQUEST_VIDEO_READ", "REQUEST_VIDEO_WRITE"]}>
            <Fade left duration={1500}>
              <Row className="mt-2">
                <Col xl="12">
                  <Card className={`card-stats mb-4 mb-xl-0 shadowCard`}>
                    <CardBody>
                      <CardTitle className="cardheader1">
                        Requested Video
                      </CardTitle>
                      <RequestedVideo endDate={endDate} startDate={startDate} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Fade>
          </ShowPermission>

          <ShowPermission permission={["VEHICLE_TRIP_READ", "VEHICLE_TRIP_WRITE"]}>
            <Fade left duration={1500}>
              <Row className="mt-2">
                <Col xl="12">
                  <Card className={`card-stats md-4 mb-xl-0 shadowCard`}>
                    <CardBody>
                      <CardTitle className="cardheader1">
                        Trips
                      </CardTitle>
                      <Trips endDate={endDate} startDate={startDate} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Fade>
          </ShowPermission>
          <Fade left duration={1500}>
            <Row className="mt-2">
              <Col xl="12">
                <Card className={`card-stats md-4 mb-xl-0 shadowCard`}>
                  <CardBody>
                    <CardTitle className="cardheader1">
                      Vehicle Event Exception Summary
                    </CardTitle>
                    <Col className="mt-2 tripsname tabAll" sm={12} md={6}>
                      <div className="app-actions">
                        <button
                          type="button"
                          className={`${selectedTheme} ${vehicleSelected === "change1"
                            ? "change1 active"
                            : "change1"
                            }`}
                          onClick={() => {
                            setVehicleSelected("change1");
                            setVehicleSelectedType("trip");
                          }}
                        >
                          Trips
                        </button>
                        <button
                          type="button"
                          className={`${selectedTheme} ${vehicleSelected === "change2"
                            ? "change1 active"
                            : "change1"
                            }`}
                          onClick={() => {
                            setVehicleSelected("change2");
                            setVehicleSelectedType("distance");
                          }}
                        >
                          Distance
                        </button>
                        <button
                          type="button"
                          className={`${selectedTheme} ${vehicleSelected === "change3"
                            ? "change1 active"
                            : "change1"
                            }`}
                          onClick={() => {
                            setVehicleSelected("change3");
                            setVehicleSelectedType("high-event");
                          }}
                        >
                          High Events
                        </button>
                      </div>
                    </Col>
                    <VehicleEvent vehicleEvent={vehicleEvent} />
                  </CardBody>
                  {/* </Paper> */}
                </Card>
              </Col>
            </Row>
          </Fade>

          <Row className="mt-2">
            <Col className="col-lg-5 col-12 scoreLeft">
              <Fade right duration={1500}>
                <Card className={`shadowCard`}>
                  <CardBody className={`mb-2 cardBodyheight`}>
                    <CardTitle className="cardheader1">Project Split</CardTitle>
                    <div>
                      <ProjectSplit projectSplit={projectSplit} />
                    </div>
                  </CardBody>
                </Card>
              </Fade>
            </Col>
            <Col className="col-lg-7 col-12 scoreRight">
              <Fade left duration={1500}>
                <Card className={`shadowCard`}>
                  <CardBody className={`mb-2 cardBodywidth`}>
                    <div className="cardheader1">Top 10 Drivers</div>
                    <div className="cardheaderDiv">
                      <Top10Drivers topDriver={topDriver} />
                    </div>
                  </CardBody>
                </Card>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  state,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getOrgList: () => dispatch(getOrgList()),
  getDialCodeList: () => dispatch(getDialCode()),
  getProjectList: () => dispatch(getProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
