import { useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Form,
  Spinner,
} from "reactstrap";
import {
  endLoading,
  startLoading,
} from "../../../../../../redux/reducers/general/general.actions";
import { connect } from "react-redux";
import {
  getDialCode,
  getOrgList,
} from "../../../../../../redux/reducers/dropdowns/dropdown.actions";
import Select from "react-select";
import { Formik, Field, ErrorMessage } from "formik";
import ASSETS from "../../../../../../assets";
import {
  supportTicket,
  supportTicketValidationAddType,
} from "../../../../../../constants";
import { AddSupportTicketCode } from "../../../../../../redux/reducers/Management&Settings/supportTicket/supportTicket.actions";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../redux/store/store";
import { TOAST_MSG } from "../../../../../../constants/toast.constants";
import { toast } from "react-toastify";
import { AddSupportTicket } from "../../../../../../../global.types";
import { getProject } from "../../../../../../redux/reducers/Management&Settings/projectManagement/projectManagement.actions";
import { LayoutContext } from "../../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AddTicket = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleAddSupportTicket = async (
    values: supportTicketValidationAddType
  ) => {
    try {
      props.startLoading();
      await toast.promise(
        props.AddSupportTicketCode({
          contact: values.contactCode + "-" + values.contact,
          email: values.email,
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
          message: values.message,
        }) as any,
        {
          success: TOAST_MSG.SUPPORT_TICKET_SUCCESS,
        }
      );
      toggle();
    } catch (error) {
      console.log("error in support-ticket Add api", error);
    } finally {
      await props.endLoading();
    }
  };

  return (
    <>
      <Button onClick={toggle} className={`${selectedTheme} ml-2`}>
        Add Ticket
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modalTop">
        <ModalHeader className={`mod ${selectedTheme}-bg`}>
          <h5 className="modlabel modheadcont mb-0">Add Ticket</h5>
          <button className={`mult ${selectedTheme}-bg`} onClick={toggle}>
            <img src={ASSETS.MULTIPLY} alt="multiply" />
          </button>
        </ModalHeader>
        <Formik
          validationSchema={supportTicket}
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            contact: "",
            email: "",
            message: "",
            contactCode: "",
          }}
          onSubmit={handleAddSupportTicket}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        First Name
                      </label>
                      <Field
                        className={"form-control con"}
                        autocomplete="disable"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                        onBlur={handleBlur("firstName")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        Last Name
                      </label>
                      <Field
                        className={"form-control con"}
                        autocomplete="disable"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                        onBlur={handleBlur("lastName")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <label className="modlabel modinpulabel" htmlFor="postalCode">
                    Contact<span className="required"> *</span>
                  </label>
                </Row>
                <Row className="formRow">
                  <Col md={3}>
                    <FormGroup>
                      <Select
                        placeholder="Code"
                        className={`${touched.contactCode && errors.contactCode ? "requireSelect" : ""}`}
                        maxMenuHeight={180}
                        classNamePrefix={`filter-select-style_${selectedTheme}`}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base: $TSFixMe) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={props.dropdowns.dialCode?.dropDown}
                        // value={values.contactPersonMobileCode}
                        onChange={(option) =>
                          setFieldValue("contactCode", option?.value)
                        }
                        onBlur={handleBlur("contactCode")}
                      />
                      {touched.contactCode && errors.contactCode ? (
                        <div className="validate">Required</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup className="dropinput">
                      <Form>
                        <Field
                          className={
                            "form-control con" +
                            (errors.contact && touched.contact
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          onInput={(e: $TSFixMe) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                          }}
                          maxLength={15}
                          placeholder="Contact"
                          name="contact"
                          value={values.contact}
                          onChange={handleChange("contact")}
                          onBlur={handleBlur("contact")}
                        />
                        <ErrorMessage
                          name="contact"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Form>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        Email Id<span className="required"> *</span>
                      </label>
                      <Form>
                        <Field
                          className={
                            "form-control con" +
                            (errors.email && touched.email
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          type="userEmail"
                          name="email"
                          placeholder="Email Id"
                          value={values.email}
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Form>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="dropinput">
                      <label
                        className="modlabel modinpulabel"
                        htmlFor="postalCode"
                      >
                        Message<span className="required"> *</span>
                      </label>
                      <Form>
                        <Field
                          className={
                            "form-control range" +
                            (errors.message && touched.message
                              ? " is-invalid "
                              : "")
                          }
                          autocomplete="disable"
                          type="textarea"
                          as="textarea"
                          name="message"
                          placeholder="Message"
                          value={values.message}
                          onChange={handleChange("message")}
                          onBlur={handleBlur("message")}
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Form>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="footer-width">
                <div className="footwidth">
                  <Button
                    type="submit"
                    // @ts-expect-error
                    onClick={handleSubmit}
                    disabled={props.isLoading}
                    className="popbtn datewidth btnBox"
                  >
                    {props.isLoading && (
                      <Spinner
                        color="dark"
                        size="sm"
                        className="mr-2"
                      />
                    )}
                    <>SUBMIT</>
                  </Button>
                </div>
                <div className="divider" />
                <div className="footwidth">
                  <Button onClick={toggle} className="popbtn datewidth btnBox">
                    <>CANCEL</>
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  projects: state.projectData.projects,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getOrgList: () => dispatch(getOrgList()),
  getDialCodeList: () => dispatch(getDialCode()),
  getProjectList: () => dispatch(getProject()),
  AddSupportTicketCode: (data: AddSupportTicket) =>
    dispatch(AddSupportTicketCode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTicket);
