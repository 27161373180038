import { useState } from "react";
import { Collapse, Row, Input, FormGroup, Form, Col, Label } from "reactstrap";
import CustomInput from "../../../../../../../Common/CustomInput";

const SetLogo = (props: $TSFixMe) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <FormGroup check>
      <Row className="mb-2">
        <Col>
          <Label className="customcamera" check>
            <Input
              disabled
              type="checkbox"
              color="primary"
              checked={props.logo}
              onClick={toggle}
            />
            Set Logo
          </Label>
        </Col>
      </Row>
      <Row>
        {/* <Col> */}
        <Collapse isOpen={isOpen}>
          <Row>
            <Col md={4}>
              <Label className="modlabel modinpulabel">
                Header Large Logo
              </Label>
            </Col>
            <Col md={8}>
              <CustomInput />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
              <Label className="modlabel modinpulabel">
                Header Small Logo
              </Label>
            </Col>
            <Col md={8}>
              <CustomInput />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
              <Label className="modlabel modinpulabel">
                Footer Large Logo
              </Label>
            </Col>
            <Col md={8}>
              <CustomInput />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
              <Label className="modlabel modinpulabel">
                Footer Small Logo
              </Label>
            </Col>
            <Col md={8}>
              <CustomInput />
            </Col>
          </Row>
          <br></br>
        </Collapse>
        {/* </Col> */}
      </Row>
    </FormGroup>
  );
};

export default SetLogo;
