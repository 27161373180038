import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { createRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormGroup,
    Row,
    Spinner,
} from "reactstrap";
import { ForgotPasswordValidationSchema, TEST_SITE_KEY } from "../../../constants";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { loginUser } from "../../../redux/reducers/user/user.actions";
import { Link, useHistory } from "react-router-dom";
import { forgotPassword } from "../../../api/axios.resources";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ReCAPTCHA from "react-google-recaptcha";
import ASSETS from "../../../assets";

type Props = ReturnType<typeof mapDispatchToProps>;

const ForgotPassword = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    let reCaptchaRef: any = createRef();

    const handleOnSubmit = async (values: $TSFixMe) => {
        setIsLoading(true);
        try {
            const res = await forgotPassword(values);
            if (res.status === 200) {
                setIsLoading(false);
                toast.success("Email sent successfully");
                history.push("/");
            } else {
                setIsLoading(false);
                toast.error("Invalid Email ");
            }
        } catch (e) {
            toast.error((e as $TSFixMe).error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Container fluid style={{ padding: "0px", margin: "0px", width: "100%" }}>
                <Formik
                    validationSchema={ForgotPasswordValidationSchema}
                    enableReinitialize={true}
                    initialValues={{
                        email: "",
                        recaptcha: "",
                    }}
                    onSubmit={(values) => handleOnSubmit(values)}
                >
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        setSubmitting,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Row className="mt-0">
                                <Col>
                                    <div
                                        className="auth-screen">
                                        <Row className="d-flex justify-content-center align-items-center flex-column w-100 m-auto">
                                            <Col
                                                md={4}
                                                sm={6}
                                                style={{
                                                    border: "1px solid rgb(255 255 255 / 60%)",
                                                    padding: "20px 40px",
                                                    boxShadow: "inset rgb(255 255 255 / 70%) 0px -1px 12px 3px",
                                                    borderRadius: "10px",
                                                    backgroundColor: "rgb(255 255 255 / 75%)",
                                                }}
                                            >
                                                <div className="loginLogo m-auto">
                                                    <img src={ASSETS.STREET_ANGEL} alt="company logo" style={{ width: "300px" }} />
                                                </div>
                                                <h3
                                                    className="text-center"
                                                    style={{ color: "#333", fontSize: "18px", marginBottom: "40px" }}
                                                >
                                                    Forgot Password
                                                </h3>
                                                <FormGroup className="dropinput mb-1">
                                                    <div
                                                        className="modalheader"
                                                        // @ts-expect-error
                                                        htmlFor="email"
                                                    >
                                                        Your account email
                                                        <span className="required"> *</span>
                                                    </div>
                                                    <Field
                                                        className={
                                                            "form-control mt-1" +
                                                            (errors.email && touched.email
                                                                ? " is-invalid "
                                                                : "")
                                                        }
                                                        autoComplete="disable"
                                                        type="userEmail"
                                                        name="email"
                                                        placeholder="Enter the email address"
                                                        value={values.email}
                                                        onChange={handleChange("email")}
                                                        onBlur={handleBlur("email")}
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                <Row className="text-right">
                                                    <Link
                                                        className="ml-auto auth-link-text text-decoration-none w-auto d-flex align-items-center justify-content-end"
                                                        to={"/"}
                                                    >
                                                        <KeyboardArrowLeft /> Go back
                                                    </Link>
                                                </Row>
                                                <div className="text-center mt-4">
                                                    <ReCAPTCHA
                                                        className="re-captcha"
                                                        ref={reCaptchaRef}
                                                        onChange={(value) => {
                                                            setFieldValue("recaptcha", value);
                                                            setSubmitting(false);
                                                        }}
                                                        sitekey={TEST_SITE_KEY}
                                                    />
                                                    {errors.recaptcha && touched.recaptcha && (
                                                        <p className="recaptch-error">
                                                            {errors.recaptcha}
                                                        </p>
                                                    )}
                                                    <Button
                                                        className="my-3 w-40 py-2 h-35-px d-flex align-items-center justify-content-center mx-auto"
                                                        // @ts-expect-error
                                                        onClick={handleSubmit}
                                                        type="submit"
                                                    >
                                                        {isLoading ? (
                                                            <Spinner
                                                                color="light"
                                                                size="sm"
                                                            />
                                                        ) : "Send Mail"}

                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        loginUser: (values: { email: string; password: string }) => dispatch(loginUser(values)),
    };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
