import React from 'react'
import Cookies from 'js-cookie';
import { Redirect, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }: $TSFixMe) => {
    const location = useLocation();
    const cookies = Cookies.get("road-devil-vendor-token");

    if (!cookies) {
        return <Redirect to={{ pathname: "/", state: { from: location } }} />
    }
    return children
};

export default ProtectedRoute;