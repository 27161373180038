import React, { useContext } from "react";
import { Card, CardBody, Row, Col, Container, Button } from "reactstrap";
import SupportTicketTab from "./SupportTicketTab";
import AddTicket from "./Tab/AddTicket/index";
import { connect } from "react-redux";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import { THEME_TYPE, supportTicketDownloadColumns } from "../../../../constants";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { LayoutContext } from "../../../../context/LayoutContext";
import { convertTimestampToDate } from "../../../../services/common.functions.services";
import { CSVLink } from "react-csv";
import usePermission from "../../../../constants/usePermission";

type Props = ReturnType<typeof mapStateToProps>;

function SupportTicket(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const isDriveWrite = usePermission("SUPPORT_TICKETS_WRITE");
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      date: convertTimestampToDate(props.user.timeZone.zoneId, data.date, null, props.user.timeFormat)
    }));
  };


  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-6">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Row className="mb-4">
                <Col sm={12} md={6} className="tripsname">
                  <div className="tabpage">
                    <>Support Ticket</>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pageEnd modmarg">
                  {props.supportTickets &&
                    props.supportTickets.result &&
                    props.supportTickets.result.length ? (
                    <CSVLink
                      filename={"support-tickets-file.csv"}
                      data={preprocessCsvData(props?.supportTickets?.result)}
                      headers={supportTicketDownloadColumns}
                    >
                      <Button className={`${selectedTheme}`}>
                        Download
                      </Button>
                    </CSVLink>
                  ) : null}
                  {/* <Import /> */}
                  {isDriveWrite &&
                    <AddTicket />
                  }
                </Col>
              </Row>
              <CardBody className="tabAll">
                <SupportTicketTab />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  supportTickets: state.supportTicketsData.supportTickets,
  user: state.userSlice.user,
});

export default connect(mapStateToProps)(SupportTicket);
