import { Card, CardBody, Row, Col, Container, Button } from "reactstrap";
import GeoFencingTab from "./Tab";
import AddGeoFence from "./Tab/Components/addGeoFence";
import { connect } from "react-redux";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { useContext, useState } from "react";
import { LayoutContext } from "../../../../context/LayoutContext";
import { THEME_TYPE } from "../../../../constants";
import usePermission from "../../../../constants/usePermission";

type Props = ReturnType<typeof mapStateToProps>;

function GeoFencing(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [mode, setMode] = useState("add");
  const [selectedGeoFence, setSelectedGeoFence] = useState(null);
  const isGeoFencingWrite = usePermission("GEOFENCING_WRITE");

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-0">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <>
                <Row className="mb-4">
                  <Col sm={12} md={6} className="tripsname">
                    <div className="tabpage">
                      <>Geo Fencing</>
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="pageEnd">
                    {isGeoFencingWrite &&
                      <Button onClick={() => {
                        toggle();
                        setMode("add");
                      }}
                        className={`${selectedTheme}`}>
                        Add GeoFence
                      </Button>
                    }
                    <AddGeoFence toggle={toggle} modal={modal} mode={mode} selectedGeoFence={selectedGeoFence} />
                  </Col>
                </Row>
              </>
              <CardBody className="tabAll">
                <GeoFencingTab setMode={setMode} isGeoFencingWrite={isGeoFencingWrite} toggle={toggle} setSelectedGeoFence={setSelectedGeoFence} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
});

export default connect(mapStateToProps)(GeoFencing);
