import { useEffect } from "react";
//@ts-expect-error
import MyComponent from "react-fullpage-custom-loader";

const defaultProps = {
  sentences: [],
  loaderType: "ball-clip-rotate",
  // color: "#007ae1",
  color: "#eff1f5",
  fadeIn: true,
  startFadeOut: false,
};

function FullPageLoader(props: $TSFixMe) {
  // const loaderDivRef = useRef(null);

  useEffect(() => {
    if (!props.isLoading) fadeOutEffect();
  }, [props.isLoading]);

  function fadeOutEffect() {
    let loaderDivRef: any = document.getElementById("loader");

    if (loaderDivRef) {
      loaderDivRef.style.transition = "opacity 2000ms"; // Add a 3000ms transition for the opacity property
      loaderDivRef.style.opacity = "0.01";

      setTimeout(() => {
        loaderDivRef.style.display = "none";
        props.onLoadingDone(false);
      }, 1100); // Wait for the transition to complete before hiding the element
    }
  }

  return (
    <div id="loader">
      <MyComponent {...defaultProps} {...props} />
    </div>
  );
}

export default FullPageLoader;
