import { FormikErrors } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ResourceRole } from "../../../../../../global.types";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import DataTable from "../../../../Common/ModuleDataTable";
import { LayoutContext } from "../../../../../context/LayoutContext";

type Props = ReturnType<typeof mapStateToProps> & {
  permissions: Partial<{
    [key: string | number]: any;
  }>;
  setPermissions: (...args: any[]) => void;
  className: FormikErrors<{}> | undefined;
  disabled: boolean;
};

const PermissionsTable = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [post, setPost]: $TSFixMe = useState([]);
  const [isAllReadChecked, setIsAllReadChecked] = useState(false);
  const [isAllWriteChecked, setIsAllWriteChecked] = useState(false);

  const stateChange = (
    toggleType: "read" | "write",
    checked: boolean,
    data: ResourceRole
  ) => {
    let temp = post.map((checkbox: $TSFixMe) => {
      if (checkbox.permissionId === data.permissionId) {
        return {
          ...checkbox,
          permission: checkbox.permission,
          permissionId: checkbox.id ?? checkbox.permissionId,
          [toggleType]: checked
        }
      } else {
        return { ...checkbox }
      }
    });
    setPost(temp);
    props.setPermissions(temp);
  };

  const handleCheckAll = (toggleType: "read" | "write", checked: boolean) => {
    if (post.length > 0) {
      if (toggleType === "read") {
        const temp: $TSFixMe = post.map((checkbox: $TSFixMe) => ({
          ...checkbox,
          permission: checkbox.permission,
          permissionId: checkbox.id ?? checkbox.permissionId,
          write: checkbox.write ?? false,
          read: checked,
        }));
        setPost(temp);
        props.setPermissions(temp);
      } else if (toggleType === "write") {
        const temp: $TSFixMe = post.map((checkbox: $TSFixMe) => ({
          ...checkbox,
          permission: checkbox.permission,
          permissionId: checkbox.id ?? checkbox.permissionId,
          read: checkbox.read ?? false,
          write: checked,
        }));
        setPost(temp);
        props.setPermissions(temp);
      }
    }
  };

  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const columns = useMemo(() => [
    { title: "Sr No", data: "permissionId" },
    { title: "Module", data: "permission" },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-1">Read</span>
          <input
            type="checkbox"
            disabled={props.disabled}
            checked={isAllReadChecked}
            onChange={(e) => {
              setIsAllReadChecked(!isAllReadChecked);
              handleCheckAll("read", e.target.checked);
            }}
          />
        </div>
      ),
      format: (post: ResourceRole) => (
        <input
          type="checkbox"
          disabled={props.disabled}
          checked={post.read ? post.read : false}
          onChange={(e) => stateChange("read", e.target.checked, post)}
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-1">Write</span>
          <input
            type="checkbox"
            disabled={props.disabled}
            checked={isAllWriteChecked}
            onChange={(e) => {
              setIsAllWriteChecked(!isAllWriteChecked);
              handleCheckAll("write", e.target.checked);
            }}
          />
        </div>
      ),
      format: (post: ResourceRole) => (
        <input
          type="checkbox"
          disabled={props.disabled}
          checked={post.write ? post.write : false}
          onChange={(e) => stateChange("write", e.target.checked, post)}
        />
      ),
    },
  ]);

  useEffect(() => {
    if (post.length > 0) {
      const isAllReadChecked = post.every((item: $TSFixMe) => item.read);
      const isAllWriteChecked = post.every((item: $TSFixMe) => item.write);
      setIsAllReadChecked(isAllReadChecked);
      setIsAllWriteChecked(isAllWriteChecked);
    }
    return () => { }
  }, [post]);

  useEffect(() => {
    if (props.permissions.length > 0) {
      props.permissions.sort((a: $TSFixMe, b: $TSFixMe) => a.permissionId - b.permissionId);
      setPost(props.permissions);
    } else {
      setPost(props.resourceRolesList ?? []);
    }
  }, []);

  return (
    <div className="modal-screen-table">
      <DataTable
        data={post}
        columns={columns}
        bordered
        hover={true}
        selectedTheme={selectedTheme}
        responsive={true}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  resourceRolesList: state.dropdownList.dropdowns.resourceRolesList.dropDown,
});

export default connect(mapStateToProps, {})(PermissionsTable);
